import React, { useContext } from 'react';
import api from '../api';
import { Context } from '../contexts/StateContext';

import { SetFunctions } from '../../componentes/Functions';
import { ApiEvento } from '../../services/FiltrosApi/eventoApi';
//----------------------------------------------------
export function Api_Veiculos() {
    const { state, dispatch } = useContext(Context);
    const { cliente, logado } = state.cliente;
    const { veiculo } = state.veiculo;
    const { evento } = state.evento;
    const { parametroInit, playLista, playmodal, listaPesquisa } = state.parametros;
    const { loading } = parametroInit;
    const { id_Cliente } = cliente;
    const { id_Tipo, id_Veiculo } = veiculo;

    const { SetEvento } = SetFunctions();
    const { PesqIdEvento } = ApiEvento();

    // ----------------------------------------------
    async function PesqVeiXcliente() {
        if (id_Cliente) {
            await api.get(`veiculo/filtrar/${id_Cliente}`)
                .then(res => {
                    const buscar = res.data;
                    dispatch({
                        type: 'set_ListaPesquisa',
                        payload: { ...listaPesquisa, listaVeiculo: buscar }
                    });
                })
        }
    };

    //---------------Listar Todos Tipos Veic----------
    async function TodosTipos() {
        await api.get(`/tipoveic/todostipo`)
            .then(res => {
                const busca = res.data;

                dispatch({
                    type: 'set_veiculo',
                    payload: { ...veiculo, listaTipo: busca }
                });
            });
    };

    //---------------Listar Todos Planos--------------
    async function TodosPlanos() {
        await api.get(`/tipoveic/todosplanos`)
            .then(res => {
                const busca = res.data;
                const result = busca.map(s => ({ value: s._id, label: s.titulo }));
                return result;
            });
    };

    //----Listar Todos Planos Por Tipo--------------
    async function PlanosTipo() {
        if (id_Tipo) {
            await api.get(`tipoveic/planos/${id_Tipo}`)
                .then(res => {
                    const busca = res.data.id_Planos;
                    return busca;
                });
        }
    };

    // ---------------------------------------------------
    async function PesqVeiculoPlaca(placa) {
        if (placa) {
            if (!playmodal.playModalEven && !playmodal.playModalEvenPlaca) {
                dispatch({
                    type: 'SET_parametroInit',
                    payload: { ...parametroInit, loading: true }
                });
            };

            await api.get(`veiculo/filtrar/placa/${placa}`)
                .then(res => {
                    dispatch({
                        type: 'SET_parametroInit',
                        payload: {
                            ...parametroInit,
                            loading: loading && false,
                        }
                    });

                    dispatch({
                        type: 'set_playLista',
                        payload: { ...playLista, playListaVeic: true }
                    });

                    dispatch({
                        type: 'set_ListaPesquisa',
                        payload: {
                            ...listaPesquisa,
                            listaVeiculo: res.data.length > 0 ? res.data : [],
                            alertaNovo: res.data.length > 0 || !playmodal.playModalEven ? false : true
                        }
                    });

                    if (res.data.length > 0) {
                        if (playmodal.playModalEvenPlaca) {
                            alert("Placa Ja Existe!");
                            dispatch({
                                type: 'set_ListaPesquisa',
                                payload: { ...listaPesquisa, nomeItem: "" }
                            });

                            dispatch({
                                type: 'set_veiculo',
                                payload: { ...veiculo, placa: "" }
                            });
                            dispatch({
                                type: 'SET_parametroInit',
                                payload: { ...parametroInit, novoCadastro: false }
                            });
                        }
                    } else {
                        dispatch({
                            type: 'SET_parametroInit',
                            payload: { ...parametroInit, novoCadastro: true }
                        });
                    }
                });
        };
    };

    //---------------Pesquisar Veiculos por Id------------
    async function pesqIDveiculo() {
        if (id_Veiculo) {
            await api.get(`veiculo/${id_Veiculo}`)
                .then(res => {
                    dispatch({
                        type: 'set_ListaPesquisa',
                        payload: { ...listaPesquisa, listaVeiculo: [res.data] }
                    });

                    dispatch({
                        type: 'SET_parametroInit',
                        payload: { ...parametroInit, pesquisaIdVeiculo: false }
                    });

                    if (res.data.id_cliente) {
                        dispatch({
                            type: 'set_cliente',
                            payload: { ...cliente, id: res.data.id_cliente._id }
                        });
                    }
                })
        }
    };
    // ----------Add so Placa------------------
    async function AddPlaca() {
        await api.post('/veiculo', {
            userCadastro: logado.idLogado,
            id_Cliente: cliente.id_Cliente,
            placa: veiculo.placa,
            modelo: veiculo.modelo

        }).then(res => {
            if (res.data) {
                alert('Placa Adicionada!');
                dispatch({
                    type: 'set_veiculo',
                    payload: {
                        ...veiculo,
                        id_Veiculo: res.data._id,
                        placa: res.data.placa,
                        modelo: res.data.modelo,
                    }
                });
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload: {
                        ...listaPesquisa,
                        listaVeiculo: [res.data],
                        nomeItem: "",
                        selectItem: '',
                    }
                });
                dispatch({
                    type: 'SET_PLAYMODAL',
                    payload: {
                        ...playmodal,
                        playModalEven: true,
                        playModalEvenPlaca: false
                    }
                });
            };
        }).catch((error) => {
            alert("!!!! Ocorreu um erro..!!!!", error);
            return error;
        });
    };

    // ------------------------------------------
    async function AtualizarModelo() {
        await api.put(`veiculo/atualizar/${veiculo.id_Veiculo}`, {
            modelo: veiculo.modelo
        }).then(res => {
            if (res.data) {
                alert('Modelo Alterado!');
                dispatch({
                    type: 'set_veiculo',
                    payload: {
                        ...veiculo,
                        modelo: res.data.modelo,
                    }
                });
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload: {
                        ...listaPesquisa,
                        listaVeiculo: [res.data],
                    }
                });
                dispatch({
                    type: 'SET_parametroInit',
                    payload: {
                        ...parametroInit,
                        detalhesLista: true,
                    }
                });
                dispatch({
                    type: 'SET_PLAYMODAL',
                    payload: {
                        ...playmodal,
                        playModalEvenPlaca: false,
                        playModalDetalhesEven: true
                    }
                });

                PesqIdEvento(evento.id_Evento)
            };

        }).catch((error) => {
            alert("!!!! Ocorreu um erro..!!!!", error);
            return error;
        })
    };
    // ------------------------------------------------
    return { PesqVeiculoPlaca, AddPlaca, PlanosTipo, TodosPlanos, TodosTipos, PesqVeiXcliente, pesqIDveiculo, AtualizarModelo };
};



