import styled from 'styled-components';
import {breakpoints} from '../../breakpoints';

export const body = styled.div`
display: flex ;
overflow: ${props=>props.playModalEvenPlaca ? "none" : "auto" };
z-index: 1 ;
font-size: clamp(10px, 3vw, 12px);
border-radius:3px;
justify-content: center;

@media ${breakpoints.sm} {
  justify-content: center;
}

.titulo{
  display: flex ;
  width:100% ;
  height:30px ;
  align-items: center ;
  position: relative ;
}
.tituloHover{
  color: white ;
  &:hover{
    color: yellow ;
  }

}
.tituloUser{
  display: flex;
  width:100% ;
  justify-content: center ;
}
.fechar{
  position: absolute ;
  right: 10% ;
  color:#f0433d;
  border-radius:3px;
  padding:0px 5px;

  cursor: pointer;
  font-weight:bold;

  &:hover{
    color:white;
    background-color: #f0433d;
}}
select{
  background-color:#3881ff;
  color: ${props=>props.editModal ? '#ffecaa' : 'white'};
}
label, span{
  color: ${props=>props.editModal ? '#ffecaa' : '#00D594'};
}

.saldoCred{
    color:${props=>props.saldo < 0 ? "red":"black"} ;
    font-weight:600 ;
};
.saldoNovo{
    color:${props=>props.saldoNovo < 0 ? "red":"black"} ;
    font-weight:600 ;
};
`
//-----------------------------------------------
export const Container = styled.div`
display: flex;
flex-direction:column ;
width:100% ;
width:500px ;
max-height: ${props=>props.detalhesLista? "65vh":"100vh"};

@media ${breakpoints.sm} {
  width:100% ;
}

`
//--------------------------------------------
export const ContainerUser = styled.div`
display:${props=>props.playModalUsuario ? 'flex' : 'none'};

`
//-----------------------------------------------
export const ContainerBanco = styled.div` 
display:${props=>props.playModalBanco ? 'flex' : 'none'};

`
//--------------------------------------------
export const ContainerColab = styled.div`
display:${props=>props.playModalColab ? 'flex' : 'none'};
`
// ------------------------------------------
export const div3 = styled.div` 
display:flex;
flex-direction: column ;
width:100%;
color: #00D594;
align-items: center ;
background-color: #262626;

@media ${breakpoints.sm} {
  width:95vw ;
}

.spanBranco{
  margin:0px ;
  color: white ;
}

.novoCad{
  color: black ;
  background-color: white ;
  padding: 1px 3px ;
  cursor: pointer;
}
input{
color: white ;
border:none;
background:none;
outline:0;
background-color: #353535 ;
}
input, select{
  height:30px ;

}
.maiusculo{
  text-transform:capitalize
}
.uppercase{
  text-transform:uppercase
};

.intemSelect{
  display: flex ;
  align-items: center ;
  height: 30px ;
  background-color: #30353F;
  color: white ;
  min-height: 25px ;
  cursor: pointer;
  padding-left: 5px ;
  text-transform:capitalize;
};
.intem{
  display: flex ;
  background-color: #30353F;
  padding: 2px ;
  align-items: center ;
  color: white ;
  min-height: 25px ;
  padding-left: 5px ;
}

select{
  display:flex;
  border-radius:3px;
  border:none;
  outline:0;
}

textarea{
  display: flex ;
  width:98% ;
  min-height: 70px ;
  background:none;
  border:1px solid #3881ff;
  border-radius:5px;
  outline:0;
  color: white ;
  margin: 5px 5px ;
}

.listaFlexCol{
  display: flex ;
  flex:1 ;
  flex-direction: column ;
  text-transform:capitalize;
  color: white ;

  input{
    @media (max-width: 450px) {
      font-size: clamp(10px, 3vw, 12px);
    }
  }
}

.EditarSelect{
  display: flex ;
  flex:1 ;
  flex-direction: column ;
  margin: 0 5px ;
}
`
// -----------------------------------
export const divPesq = styled.div`
display: flex ;
flex-direction: column ;
position: relative ;
width:98% ;


`
// -----------------------------------
export const divListaBD = styled.div`
display: flex ;
flex-direction: column ;
left: clamp(120px, 13vw, 155px);
/* clamp(150px, 10vw, 10vw); */
margin-top:5px ;
position: fixed ;
z-index: 1;

@media ${breakpoints.sm} {
  left:20px;
}

`
// -----------------------------------
export const box = styled.div`
width:100%;
flex-direction:column;
border-bottom:1px solid #3881ff;
padding: 3px 0 ;

span{
  margin: 0 5px ;
}

.spa{
  color:white ;
}
.multiselect{
  color: black ;
  background-color: #3881ff;
}

`
// --------------------------------------------------------

export const boxCPF = styled.div` 
display:flex;
flex-wrap:wrap ;
border-bottom:1px solid #3881ff;
min-width: 300px ;
padding: 3px 0 ;

input{
  text-transform:capitalize;
}

@media ${breakpoints.sm} {
  flex-direction: column ;
}

div{
  display: flex ;
  flex:1 ;
  flex-direction:column ;
  margin: 0 5px ;
}

.spa{
  color:white ;
}

`
// ------------------------------------
export const boxFiles = styled.div`
display: flex ;
width:100% ;
max-height: 400px;
overflow-y:auto ;

`
// ------------------------------------
export const box1 = styled.div` 
display:flex;
flex-wrap: wrap ;
width:98% ;
border-bottom:1px solid #3881ff;
gap: 5px;
position: relative ;

input{
  text-transform:capitalize;
}

@media ${breakpoints.sm} {
  flex-direction: column ;
}

button{
  display: flex ;
  background-color: white ;
  align-items: center ;
  justify-content: center ;
  color: black ;
  height: 18px ;
  margin: 2px 0;
  border: none ;
  border-radius: 3px ;
  cursor: pointer;

  &:hover{
    background-color:#00ff9d;
    }
}



`
// ------------------------------
export const box2 = styled.div`
display:flex;
`
// --------------------------------
export const box3 = styled.div`
display:flex;
flex-wrap: wrap ;
width:100%;
gap: 5px;

@media ${breakpoints.sm} {
  flex-direction: column ;
}
`
// -------------------------------
export const lista = styled.div` 
flex-direction: column ;

button{
  display: flex ;
  background-color: white ;
  align-items: center ;
  color: black ;
  width: 140px ;
  height: 20px ;
  margin: 2px 0;
  border: none ;
  cursor: pointer;
}
`
// ---------------------------------
export const bodyresult = styled.div`
display: flex ;
width:95% ;
align-items: start ;
`
// -----------------------------------
export const pag = styled.div`
width:100%;
margin:5px;
border-bottom:1px solid #3881ff;

div{
  display:flex;
}

span{
  display:flex;
  margin-right:10px;
}
`
//-----------------------------
export const divButton = styled.div` 
display: flex ;
justify-content: center ;

button{
  background-color:#00D594;
  min-width: 80px;
  height:20px;
  margin:3px;
  justify-content:center;
  border:none;
  border-radius:3px;
  color:${props=>props.editModal? 'white':'black'};
  cursor: pointer;

}
`

// --------------------------------------------------------
export const Salvar = styled.div`

`
// --------------------------------------------------------
export const Atualizar = styled.div`

`
//--------------Banco----------------------------------

export const addBanco = styled.div` 
display:${props=>props.aletBanco ? 'flex' : 'none'};
position: fixed ;
top: 200px ;
font-weight:600;


.addbanco{
  display: flex ;
  flex-direction: column ;
  width: 150px ;
  height: 50px ;
  background-color: white ;
  border-radius: 2px;
  box-shadow: 0px 0px 50px black;
  justify-content: center ;
  padding: 10px ;

}
label{
  display: flex ;
  text-align: center ;
}
button{
  display: flex ;
  width: 50px ;
  border-radius: 2px;
  padding-top: 3px ;
  cursor:pointer ;
  border:none ;
  justify-content: center ;
  font-weight:700;
  background-color:#e2ecff
}
.simnao{
  display: flex ;
  width:100% ;
  justify-content: end ;
  margin-top: 7px;
  color: white ;

}
.nao{
  color:#f0433d ;
  &:hover{
  background-color:#f0433d;
  color: white ;
}
}
.sim{
  color:#3881ff ;
  margin-left: 10px ;
  &:hover{
  background-color:#16b992;
  color: white ;
}
}
`

// #############################################################################

export const ModalAviso = styled.div`
 display:flex;
 width:95% ;
 max-width: 400px ;
 position: fixed;
 z-index: 1 ;
 flex-direction: column ;
 background-color:#ff8e8e ;
 color:black ;
 justify-content: center ;
 align-items: center ;
 padding: 10px 0 ;

`
//-------------------------------
