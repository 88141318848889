import React, { useContext, useState, useEffect } from 'react';
import {Context} from '../../services/contexts/StateContext';
import * as S from './styles';
import editar from '../../midia/editar.svg';
import delet from '../../midia/delete.svg';
import ativar from '../../midia/ativar.svg';
import api from '../../services/api';
import {format} from 'date-fns';
import {AlertComp} from '../../componentes/alerte/index';
import {FunctModal} from '../../componentes/Functions';

//-----------------------------------------------
export const ListaEventos = (props) => {
    const {state, dispatch} = useContext(Context);
    const {evento, terceiro} = state.evento;
    const {veiculo} = state.veiculo;
    const {parametroInit, playmodal, listaPesquisa} = state.parametros;

    const[alertDelete, setAletDelete]=useState(false);
    const {FecharModal} = FunctModal();
//---------------------------------------------------

    function FecharAlerte(){
        setAletDelete(false)
    };
//----------------------------------------------

function lixeira(t){
    dispatch({
        type: 'set_Evento',
        payload:{...evento, 'id_Evento':t._id,}
    });
    setAletDelete(true);
    props.setNewStatus('E');
    props.setRefDelet("evento");
};
//-----------------------------------------------
async function ativarEste(t){
    await api.put(`evento/atualizar/${evento.id_Evento}`,{
        status:'A'
    })
    .then(res => {
        props.PesqEventoCod();
    })
};
//-----------------------------------------------
async function ExcluirEste(t){
    await api.put(`evento/atualizar/${evento.id_Evento}`,{
        status:'E'
    })
    .then(res => {
        props.PesqEventoCod();
        setAletDelete(false);
    })
};
// -----------------------------------------
useEffect(() => {
    if(listaPesquisa.listaEvento.length > 1){
        dispatch({
            type:'SET_parametroInit',
            payload: {
                ...parametroInit, 
                detalhesEven: false, 
            }
        });
    }
}, [listaPesquisa.listaEvento])

//---------------------------------------------------

function detalheEvento(t){
    // console.log(t)
    FecharModal();  
    dispatch({
        type: 'set_ListaPesquisa',
        payload:{
            ...listaPesquisa, 
            listaEvento: [t],
        }
    });

    dispatch({
        type: 'set_veiculo',
        payload:{
            ...veiculo, 
            "id_Veiculo":t?.id_Veiculo?._id,
        }
    });
    dispatch({
        type: 'set_Evento',
        payload:{
            ...evento, 
            "id_Evento":t._id,
            "nomeCliente": t?.id_Veiculo?.id_Cliente.nome,
            id_Fornecedor: t?.id_Fornecedor,
            "tipo":t.tipo ,
            "tipoEvento":t.tipoEvento ,
            "codEvento": t.codEvento ,
            "dataEvento": t.dataEvento,
            "ocorrenciaBO":t.ocorrenciaBO ,
            "descricao": t.descricao ,
            "status":t.status ,
            "placa": t.placa,
            orcamento: t.orcamento? t.orcamento : 0,
            dataPart: t.dataPart,
            valorPart: t.valorPart,
            detalhePart: t.detalhePart,
            
            dataReparo: t.dataReparo,
            valorReparo: t.valorReparo,
            detalheReparo: t.detalheReparo,
        }
    });

    if(t.terceiro){
        dispatch({
            type: 'set_Terceiro',
            payload:{
                ...terceiro, 
                "tipo":t.terceiro.tipo,
                "placa":t.terceiro.placa,
                "chassi":t.terceiro.chassi,
                "anoMod":t.terceiro.anoMod, 
                "montadora":t.terceiro.montadora, 
                "modelo":t.terceiro.modelo, 
                "cor":t.terceiro.cor, 
                "valorFipe":t.terceiro.valorFipe,
                "anexoCNH":t.terceiro.anexoCNH,
            }
        });
    };

    dispatch({
        type:'SET_parametroInit',
        payload: {
            ...parametroInit, 
            detalhesLista:true,
            detalhesEven: parametroInit.detalhesEven? false : true, 
            disabledEven:true,
        }
    });

    dispatch({
        type:'SET_PLAYMODAL',
        payload: {
            ...playmodal, 
            playModalPagamento: false,
            playModalEven: false,
        }
    });
};



//###################################################################
    return(
        <S.bodyresult>   
            {props.listaBusca.length > 0 && <>
            <S.bodyTitulo>
                <span style={{marginLeft:"3px"}}>Data</span>
                <span>Placa</span>
                <span>Evento</span>
                <span style={{justifyContent:"end"}}>Orçamento</span>
                <span style={{justifyContent:"center"}}>Status</span>
                <span style={{flex:"0.5"}}></span>


                {/* <div className={'titulo'}>
                    <div className={'tituloUser'}>
                        {props.titulo}
                    </div>
                    {/* <div 
                    className='fechar'
                    onClick={()=>fecharLista()}>X
                    </div> */}
                {/* </div> */}
            </S.bodyTitulo>
        
        
            {alertDelete && 
            <AlertComp
            alertDescrit={'Deseja mesmo Excluir?'}
            seSim={ExcluirEste}
            seNao={FecharAlerte}
            />
            }

            {props.listaBusca.map((t, k) => (
                <S.result key={k} statusLista={t.status}>
                    {/* <input 
                    className='checkbox'
                    type='checkbox'
                    onChange={(e)=>funcCheckBox(e,t)}
                    /> */}
                    <span style={{marginLeft:"3px"}}>{format(new Date(t.dataEvento), 'dd/MM/yyyy')}</span>
                    <span style={{'textTransform':"uppercase"}}>{t.placa}</span>
                    <span>{t.tipoEvento === "PT"? "Perca Total" : t.tipoEvento}</span>
                    <span style={{justifyContent:"end", color: t.orcamento? "blue" : "black"}}>
                        {parseFloat(t.orcamento? t.orcamento : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                    </span>
                    <span style={{justifyContent:"center"}}>
                        {t.status === "A"? "Abento" : t.status === "P"? "Pendente" : t.status === "C"&& "Concluido"}
                    </span>
                    <span style={{justifyContent:"end", flex:"0.5", minWidth:"42px"}}>
                        <img 
                        className='icone'
                        src={editar} 
                        onClick={()=>detalheEvento(t)} 
                        alt="icone"
                        />

                        {t.status !== 'E' && t.status !== 'C' &&
                        <img 
                        className='icone'
                        src={delet} 
                        onClick={()=>lixeira(t)} 
                        alt="icone"
                        />
                        }
                        
                        {t.status === 'E' &&
                        <img 
                        className='icone'
                        src={ativar} 
                        onClick={()=>ativarEste(t)} 
                        alt="icone"
                        />
                        }
                    </span>
                    

                </S.result>
            ))}
            </>}
        </S.bodyresult>
        
    
)}