import styled from 'styled-components';

// #################################################
export const Header = styled.div`
display:flex;
width:100vw ;
height: 40px ;
align-items: center ;
justify-content: end ;
position:fixed;
top:0 ;
z-index:2;
border-bottom: 10px solid #3881ff;
background-color:#262626;
font-size: clamp(11px, 3vw, 13px);

`
export const Header2 = styled.div`
display:flex;
width:75% ;
color: #F2F2F2;
align-items: center ;
justify-content: flex-end ;


a, button{
  text-decoration:none;
  color: white;
  outline:0;
  background:none;
  border:0;
  cursor: pointer;

  &:hover{
    color:#00D594;
    }
}

img{
    width:30px;
    height:30px;
    &:hover{
    opacity: 0.5;
    }
}
//-----------
#notification{
    background:none;
    border:none;
    cursor:pointer;

    span{
        background:#fff;
        color:#6D39FF;
        font-weight:bold;
        padding:1px 7px;
        border-radius:50%;
        position:relative;
        top:-15px;
        right:10px;
    }
}
//-----------
div{
    display:flex;

    img{
        width:30px;
        height:30px;
        cursor:pointer;

        &:hover{
        opacity: 0.5;
        }
    }
    }
//-----------
    .divisao::after{
        content:'|';
        margin: 0 10px;
        color: #fff;
    }
`
//-----------
export const Icone = styled.div`
display:flex;
position: absolute ;
left:15px ;
top: 10px;
z-index:1 ;
background-color: #262626 ;

@media (max-width: 400px) {
    position: relative ;
    left:5px ;
};

a{
    display:flex ;
    justify-content:center ;
}

img{
    width:80%;
    min-width:70px ;
}
`
//-----------
export const Conta = styled.div`
display:flex;
margin-right: 20px ;


span{
display:flex;
align-items:center;
color:#ffbf00;
margin-left:5px;
}
`
// ---------------------
export const iconConta = styled.div`
flex-direction:column;
position: absolute;
top:40px;

button{
    display:flex;
    width:80px;
    border-radius:2px;
    background-color:white;
    justify-content:center;

    &:hover{
        opacity: 0.5;
        }
}
span{
    color:black;
    }

#sair{
    margin-top:3px;
}
`
//-----------
