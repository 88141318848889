import React from 'react';
import * as S from './styles';
//---------COMPONENTES----------------------
import Header from '../../componentes/Header/index';
import Footer from '../../componentes/Footer/index';

import { LerImagem } from '../../componentes/Cadastro/Ler_img';

// ------------------------------------------
export default function Vistoria() {

    //###################################################################################################
    return (
        <S.App>
            <Header playLogo={true} />
            <S.body>
                <S.body2>
                    <S.ContainerLista>
                        <S.VistoriaModal>
                            <div style={{ display: "flex", overflow: "auto", flexDirection: "column" }}>
                                {/* <Geolocalizacao/> */}
                                <LerImagem />
                            </div>
                        </S.VistoriaModal>
                    </S.ContainerLista>
                </S.body2>
            </S.body>

            <Footer />
        </S.App>
    )
}