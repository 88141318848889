import React, {useContext, useState } from 'react';
import * as S from './styles';
import {Context} from '../../../src/services/contexts/StateContext';
import editar from '../../midia/editar.svg';
import delet from '../../midia/delete.svg';
import ativar from '../../midia/ativar.svg';
import api from '../../services/api';
import {BancoRecebedor} from '../../../src/services/FiltrosApi/bancoApi';

export const ListaBanco = (props) => {
    BancoRecebedor();
    const {state, dispatch} = useContext(Context);
    const {parametroInit, playmodal, listaPesquisa, playLista} = state.parametros;
    const {cobranca} = state.cobrancas;
    const {cliente} = state.cliente;
    const {playModalBanco} = playmodal;
    const {listaBanco} = listaPesquisa;
    const {playListaBanco} = playLista;

    const [checkbox, setcheckbox] = useState('');
//------------------------------
function novoBanco(){
    if(!cliente.cpfCnpj){
        alert('Selecione Cliente!')
        return
    };

    if(!playModalBanco){
        dispatch({
            type: 'SET_parametroInit',
            payload: {...parametroInit, bcarBanco:false}
        });
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {
                ...playmodal, 
                playModalBanco: true, 
            }
        });
    }else{
        dispatch({
            type: 'SET_parametroInit',
            payload: {...parametroInit, detalhesBanco:false, disabledBanco:false}
        });
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {
                ...playmodal, 
                playModalBanco: true, 
            }
        });
    };
};
//---------------------------------------------------
function fecharLista(){

    dispatch({
        type: 'set_ListaPesquisa',
        payload:{...listaPesquisa, listaBanco:[]}
    });
    dispatch({
        type: 'SET_parametroInit',
        payload: {
            ...parametroInit, 
            bcarBanco: false, 
            detalhesBanco:false,
        }
    });
    dispatch({
        type: 'SET_PLAYMODAL',
        payload: {...playmodal, playModalBanco: false}
    });
    dispatch({
        type: 'set_limparbanco'
    });
};

//---------------------------------------------------
function detalhar(t){
    dispatch({
        type: 'set_contaBanco',
        payload:
        {
            ...cobranca, 
            id:t._id,
            nome:t.cobranca.nome,
            tipoDoc:t.cobranca.tipoDoc,
            cpfCnpj:t.cobranca.cpfCnpj,
            banco:t.cobranca.banco,
            conta:t.cobranca.conta,
            agencia:t.cobranca.agencia,
            dv:t.cobranca.dv
        }
    });
    
    setcheckbox(t._id);
    dispatch({
        type: 'SET_PLAYMODAL',
        payload: {
            ...playmodal, 
            playModalBanco: true, 
        }
    });
    dispatch({
        type:'SET_parametroInit',
        payload: {
            ...parametroInit, 
            editModalBanco: true, 
            detalhesBanco: true, 
            disabledBanco: true
        }
    });
//------------------------------
    if(!playModalBanco){
        dispatch({
            type:'SET_parametroInit',
            payload: {
                ...parametroInit, 
                detalhesBanco: true, 
                disabledBanco: true, 
                editModalBanco:false, 
            }
        });
        dispatch({
            type:'SET_PLAYMODAL',
            payload: {
                ...playmodal, 
                playModalBanco: true, 
            }
        });
        
    }else{
        dispatch({
            type:'SET_parametroInit',
            payload: {
                ...parametroInit, 
                detalhesBanco: true, 
                disabledBanco: true, 
                editModalBanco:false, 
            }
        });
        dispatch({
            type:'SET_PLAYMODAL',
            payload: {
                ...playmodal, 
                playModalBanco: true, 
            }
        });
    }
    
};
//-----------------------------------------------

function lixeira(t){
    dispatch({
        type: 'SET_parametroInit',
        payload: {...parametroInit, bcarBanco: false}
    });
    dispatch({
        type: 'set_contaBanco',
        payload:{...cobranca, 'id_ContaBanco':t._id,}
    });
    props.setAletDelete(true);
    props.setNewStatus('E');
    props.setRefDelet("banco");
};
//-----------------------------------------------
async function ativarEste(t){
    dispatch({
        type: 'SET_parametroInit',
        payload: {...parametroInit, bcarBanco: false}
    });

    await api.put(`contabanco/atualizar/${t._id}`,{
        status:'A'
    })
    .then(res => {

        if(parametroInit.clienteDisplay){
            dispatch({
                type: 'SET_parametroInit',
                payload: {...parametroInit, bcarBanco: true}
            });
        };
        
    })
};
//-----------------------------------------------------------------
return(
    <>
        {playListaBanco && listaBanco.length === 0 && <>
        <S.bodyTitulo>
            <div className={'titulo'}>
                <div className={'tituloUser'}>
                    Banco Não Encontrado para este Recebedor!
                </div>
            </div>
            <S.abrir>
                <div
                className='abrirModal'
                onClick={()=>novoBanco()}
                >Adicionar Banco
                </div>
            </S.abrir>
        </S.bodyTitulo>
        </>}

        {playListaBanco && listaBanco.length > 0 && <>
        {parametroInit.clienteDisplay && !playmodal.playModalEven &&
        <S.bodyTitulo>
            <div className={'titulo'}>
                <div className={'tituloUser'}>
                    Lista de Bancos
                </div>
                <div 
                className='fechar'
                onClick={()=>fecharLista()}>X
                </div>
            </div>
        </S.bodyTitulo>
        }
        <S.bodyresult>
        {listaBanco.map((t, k) => (
            <S.result key={k} eventoLista={t.status}>
                <span>{t.cobranca.banco}</span>
                <span>{t.cobranca.conta}</span>
                <img 
                className='icone'
                src={editar} 
                onClick={()=>detalhar(t)} 
                alt="icone"
                />
                {t.status !== 'E' &&
                    <img 
                    className='icone'
                    src={delet} 
                    onClick={()=>lixeira(t)} 
                    alt="icone"
                    />
                    }
                
                {t.status === 'E' &&
                    <img 
                    className='icone'
                    src={ativar} 
                    onClick={()=>ativarEste(t)} 
                    alt="icone"
                    />
                    }
                
            </S.result>
            ))}
            
        </S.bodyresult>
        </>}
    </>
)}