
import styled from 'styled-components';

export const bodyAdd = styled.div`
display: flex ;

button{
  cursor: pointer;
}

.bottonAdd{
  display: flex ;
  border-radius: 3px ;
  background-color:gray ;
  margin-bottom: 7px ;

}

`
export const NovaDespesa = styled.div`
display: flex ;
height:100% ;
align-items: flex-end ;
`
// ------------------------------------
export const selectItem = styled.div`
display: flex ;
flex-direction: column ;
width: 100% ;


.intemSelect{
  display: flex ;
  align-items: center ;
  height: 30px ;
  font-size: 13px ;
  background-color: #30353F;
  color: white ;
  min-height: 25px ;
  cursor: pointer;
  padding-left: 5px ;
  text-transform:capitalize;
}

`

// ------------------------------------
export const BarraPesquisa = styled.div`
  display: flex ;
  position: relative ;
  flex-direction: column ;
`
//--------------Add Pagamento----------

export const bodyModal = styled.div` 
display:flex;
flex-direction: column;
background-color:#262626;
color: ${props=> props.clickComprar? 'white' : "#00ff9d"};
font-size: 13px;
width:100vw;
height: 70vh ;
max-width:500px ;
overflow: auto ;
margin-top:5px ;
padding: 0 ;

button{
  cursor: pointer;
}

input{
  width: 95%;
  text-transform:capitalize;
  height: 18px ;
  color: white ;
  outline:none ;
  border: none ;
}
select{
    background-color:#5656f7;
    color: white;
    border-radius: 3px ;
    outline:none ;
    border: none ;
    height: 19px ;
  }

  a{
    text-decoration:none;
    color: white;
    outline:0;
    background:none;
    border:0;
    cursor: pointer;

    &:hover{
      color:#00ff9d;
      }
  }

  img{
    width:20px ;
    height:20px ;
    cursor: pointer;
  }
  .icone{
    display:flex;
    align-self:center;
    width:15px;
    height:15px;
    margin: 0 3px;
    cursor: pointer;
  }

  .btAdd{
    display: flex ;
    align-items: flex-end ;
    justify-content: center ;
    margin-left: 3px;

    button{
      display: flex ;
      color: white ;
      background-color: #5656f7 ;
      align-items: center ;
      cursor: pointer;
      border: none ;
    }
  }
  .flexCaixa{
    display: flex ;
    padding-bottom: 5px ;
    font-size:13px ;
    text-transform:capitalize;

    span{
      color: #00ff9d;
    }
    select,input{
      min-height: 23px;
    }
  }
`

// --------------------------------------
export const divLancamento = styled.div`
display: flex ;
width:100%;
flex-direction: column ;
font-size: 12px ;

.tagLista{
  height: 40vh ;
}

button{
  display: flex ;
  align-items: center ;
  height: 20px ;
  color: black ;
  cursor: pointer;
}

.intemSelect{
  display: flex ;
  align-items: center ;
  height: 30px ;
  background-color: #30353F;
  color: white ;
  min-height: 25px ;
  cursor: pointer;
  padding-left: 5px ;
  text-transform:capitalize;
};
`

//--------------------------
export const Finalizar = styled.div`
display: flex ;
width:100vw;
flex: 1 ;
max-width: 500px ;
position: absolute ;
bottom: 50px;
border-top:1px solid #5656f7;
border-bottom:1px solid #5656f7;
color: white ;
justify-content: space-around ;

.divFinal{
  display: flex ;
}
`
// --------------------------------
export const Arquivos = styled.div`
display: flex;
flex-direction: column;
width:100% ;

.fechar{
  display:flex;
  color:white;
  border-radius:3px;
  width:20px;
  height:20px;
  font-size:13px;
  justify-content:center;
  align-items:center;
  padding-bottom:1px;
  position:relative;
  left:20px;
  cursor: pointer;
  font-weight:bold;

  &:hover{
  background-color: #f0433d;
  }

}
`
//--------------------------
export const CameraButton = styled.div`
display: flex;
gap: 5px ;
z-index: 2 ;

button{
  width: 150px ;
  cursor: pointer;
}
`


