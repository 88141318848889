import React, { useState, useEffect} from 'react';
import * as S from './styles';
import {Redirect} from 'react-router-dom';

import Header from '../../componentes/Header/index';
import Footer from '../../componentes/Footer/index';
import {estaLogado} from '../../services/FiltrosApi/LoginToken';

//------------------------------------------------------------------------------
function Estoque() {
  let conectado = estaLogado();

//##################################################################################
  return (
  
  <S.App>
    { !conectado && <Redirect to='/login' />}
      
    <Header 
    conectado={conectado}
    />

        <S.body>

        </S.body>

      <Footer/>
    </S.App>
  );
  
}

export default (Estoque);
