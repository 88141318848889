import React, { useContext, useState } from 'react';
import {Context} from '../../services/contexts/StateContext';
import * as S from './styles';
import editar from '../../midia/editar.svg';
import delet from '../../midia/delete.svg';
import ativar from '../../midia/ativar.svg';
import api from '../../services/api';
import {format} from 'date-fns';

import {AlertComp} from '../../componentes/alerte/index';

//-----------------------------------------------
export const ListaUsuario = (props) => {
    const {state, dispatch} = useContext(Context);
    const {usuario} = state.cliente;
    const {parametroInit, playmodal, playLista} = state.parametros;
    const {playListaUsuario} = playLista;

//---------------------------------------------------
function lixeira(t){
    dispatch({
        type: 'set_usuario',
        payload:{...usuario, 'id_Usuario':t._id}
    });
    props.setAletDelete(true);
    props.setNewStatus('E');
    props.setRefDelet("usuario");
};
//-----------------------------------------------
async function ativarEste(t){
    await api.put(`usuario/atualizar/${t._id}`,{
        status:'A'
    })
    .then(res => {
        props.pesqTodos();
    })
};

//---------------------------------------------------
function detalhe(t){
    dispatch({
        type: 'set_usuario',
        payload:
        {
            ...usuario, 
            id_Usuario:t._id,
            cpf:t.cpf,
            nome:t.nome,
            email:t.email,
            senha:t.senha, 
            id_Franquia:t.id_Franquia,  
        }
    });
    dispatch({
        type:'SET_parametroInit',
        payload: {
            ...parametroInit, 
            detalhesLista:true,
            detalhesModal: true, 
            pesquisaIdUsuario:true,
            disabled:true
        }
    });

    dispatch({
        type:'SET_PLAYMODAL',
        payload: {...playmodal, playModalUsuario: true}
    });
};

//###################################################################
    return(
        <div style={{'display':"flex", "flexDirection":"column"}}> 
            {playListaUsuario && props.listaBusca.length > 0 && 
            <>
            <S.bodyTitulo>
                <div className={'titulo'}>
                    <div className={'tituloUser'}>
                        {props.titulo}
                    </div>
                </div>
            </S.bodyTitulo>
            
            <S.bodyresult>
                {props.alertDelete && 
                <AlertComp
                alertDescrit={'Deseja mesmo Excluir?'}
                seSim={props.ExcluirEste}
                seNao={props.FecharAlerte}
                />
                }

                {props.listaBusca.map((t, k) => (
                <S.result key={k} statusLista={t.status}>
                    {/* <input 
                    className='checkbox'
                    type='checkbox'
                    onChange={(e)=>funcCheckBox(e,t)}
                    /> */}
                    <span style={{textTransform:"capitalize"}}>{t.nome}</span>
                    <span>{t.email}</span>
                
                    <img 
                    className='icone'
                    src={editar} 
                    onClick={()=>detalhe(t)} 
                    alt="icone"
                    />

                    {t.status !== 'E' &&
                    <img 
                    className='icone'
                    src={delet} 
                    onClick={()=>lixeira(t)} 
                    alt="icone"
                    />
                    }
                
                    {t.status === 'E' &&
                    <img 
                    className='icone'
                    src={ativar} 
                    onClick={()=>ativarEste(t)} 
                    alt="icone"
                    />
                    }

                </S.result>
                ))}
            </S.bodyresult>
            </>}
        </div>
    
)}