import React, {useEffect, useState, useContext} from 'react';
import api from '../../services/api';
import MaskedInput from 'react-text-mask';
import * as S from './styles';

import {PesquisaPadrao} from '../../componentes/Pesquisas/PesquisaPadrao';
import {ListaItemBD} from '../ListarPesquisas/listaItemBD';
import {Api_Clientes} from '../../services/FiltrosApi/clienteApi';

import {UsuarioLog} from '../../services/FiltrosApi/logado';
import {Context} from '../../services/contexts/StateContext';
import {ListaFranquia, Servicos} from '../../services/FiltrosApi/franquiaApi';
import {ColabFranquia} from '../../services/FiltrosApi/colaboradorApi';

function ModalVeic(props) { 
    const {state, dispatch} = useContext(Context);

    const {veiculo} = state.veiculo;
    const {franquia} = state.franquia;
    
    const {parametroInit, listaPesquisa} = state.parametros;
    const {editModalVeic, detalhesVeic, disabled} = parametroInit; 

    const {idLogado} = UsuarioLog();
    

    const listaFranquia = ListaFranquia();
    const listaColaborador = ColabFranquia();
    const listaServ = Servicos();

    const {pesqClienteNome} = Api_Clientes();
    
    //---------------------------------------------------
    const setVeiculo = (key, value) => {
        dispatch({
            type: 'set_veiculo',
            payload: {...veiculo, [key]: value}
            });
    };
    //---------------------------------------------------
    useEffect(() => {
        dispatch({
            type: 'SET_Franquia',
            payload: {...franquia, id: veiculo.id_Franquia}
            });
    }, [veiculo.id_Franquia])
    //--Limpar apos Salvar com sucesso------------------
    function limparModal(){
        dispatch({
            type: 'set_limparVeiculo'
            });
    };
    //-------------------------------------------
    function Atualizar(){
        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit, 
                disabled:false,
                editModal:true, 
            }
        }); 
    };
    //----------------------------Add-----------------
    async function addVeiculo(){
        if(parametroInit.editModalVeic===false){

            await api.post('/veiculo', {
                userCadastro:idLogado,
                id_Cliente:veiculo.id_Cliente,
                id_Franquia:veiculo.id_Franquia,
                id_Colaborador:veiculo.id_Colaborador,

                placa:veiculo.placa,
                chassi:veiculo.chassi,
                anoMod:veiculo.anoMod,
                marca:veiculo.marca,
                modelo:veiculo.modelo,
                cor:veiculo.cor,  

                id_Tipo:veiculo.id_Tipo,
                id_Plano:veiculo.id_Plano,

                n_motor:veiculo.n_motor,
                renavam:veiculo.renavam,
                anoFab:veiculo.anoFab,
                valorFipe:veiculo.valorFipe,
                codigoFipe:veiculo.codigoFipe,
                status:veiculo.status, 
                dataCadastro:veiculo.dataCadastro,

            }).then(res => {
                    alert('Veiculo Salvo!');
                    limparModal();
                    props.buscarVeiculos();
            }).catch(erro => alert(" ERRO - algo preenchido errado!"))
        }
        //-------Editar-----
        if(parametroInit.editModalVeic){
            await api.put(`/veiculo/atualizar/${veiculo.id_Veiculo}`, { 
                
                id_Cliente:veiculo.id_Cliente,
                id_Franquia:veiculo.id_Franquia,
                id_Colaborador:veiculo.id_Colaborador,

                id_Tipo:veiculo.id_Tipo,
                id_Plano:veiculo.id_Plano,
                
                placa:veiculo.placa,
                chassi:veiculo.chassi,
                anoFab:veiculo.anoFab,
                anoMod:veiculo.anoMod,
                marca:veiculo.marca,
                modelo:veiculo.modelo,
                cor:veiculo.cor, 
                n_motor:veiculo.n_motor,
                renavam:veiculo.renavam, 
                
                km:veiculo.km,
                estado:veiculo.estado,
                cidade:veiculo.cidade,
                valorFipe:veiculo.valorFipe,
                codigoFipe:veiculo.codigoFipe,
                status:veiculo.status, 

                data_modificado:Date.now(),
                        
            }).then(res => {
                if(res.data.error){
                    alert('erro')
                } else{
                    alert('Veiculo Atualizado!');
                    limparModal();
                    props.FecharModal();
                    props.pesqIDveiculo();
                }
            })
        }
    };
    // ---------------------

//###################################################################################

  return (
    <S.body
    detalhesLista={parametroInit.detalhesLista}
    editModal={parametroInit.editModal}
    >
        <S.div3>
            <div className='titulo'>
                <div className={'tituloUser'}>
                    <span>{detalhesVeic ? 'Detalhes Veiculo': editModalVeic? 'EDITAR Veiculo':'Novo Veiculo'}</span>
                </div>
                <div 
                className='fechar'
                onClick={()=>props.FecharModal()}>X
                </div>
            </div>
            {!parametroInit.detalhesLista &&
            <div>
                {!listaPesquisa.selectItem?
                <div style={{display:"flex", 'flexDirection':"column", margin:"0 5px", position:"relative"}}>
                    <PesquisaPadrao
                    lupa={props.lupa}
                    titulo="Buscar Cliente:"
                    placeholder="nome"
                    nomeBotao="Novo Veiculo"
                    playModal_Add={props.playModal_Add}
                    pesquisar={pesqClienteNome}
                    playBotao={false}
                    />

                    {listaPesquisa.nomeItem &&
                    <ListaItemBD
                    listaItem={listaPesquisa.listaCliente}
                    />
                    } 
                </div>
                :
                listaPesquisa.selectItem && <>
                <div
                style={{display:"flex", 'flexDirection':"column", margin:"0 5px"}} 
                onClick={()=>props.EditarSelect()}
                >
                    <span>Associado:</span>
                    <div className='intemSelect'>
                        {listaPesquisa.selectItem}
                    </div>
                </div>
                </>}
            </div>
            }
            {/* ----------------------------------- */}
            {editModalVeic &&
            <S.box1>
                <div>
                    <span>Status:</span>
                        <select
                        disabled={disabled}
                        value={veiculo.status}
                        onChange={e=>setVeiculo('status', e.target.value)} 
                        required>
                            <option value={'A'}>Ativo</option>
                            <option value={'I'}>Inativo</option>
                            <option value={'E'}>Excluido</option>
                        </select>
                </div>
            </S.box1>
            }
            
            <S.box1>
                <div>
                <span>Regional:</span>
                    <select
                    disabled={disabled}
                    value={veiculo.id_Franquia}
                    onChange={e=>setVeiculo('id_Franquia', e.target.value)} 
                    required>
                        <option></option>
                        { listaFranquia.map((s, key) =>
                        <option key={key} value={s.value}>{s.label}</option>
                        )}
                    </select>
                </div>
                <div>
                <span>Colaborador:</span>
                    <select
                    disabled={disabled}
                    value={veiculo.id_Colaborador}
                    onChange={e=>setVeiculo('id_Colaborador', e.target.value)} 
                    required>
                        <option></option>
                        { listaColaborador.map((s, key) =>
                        <option key={key} value={s.value}>{s.label}</option>
                        )}
                    </select>
                </div>
            </S.box1>
            <S.box1>
                <div>
                    <span>Tipo de Veiculo:</span>
                    <select
                    disabled={disabled}
                    value={veiculo.id_Tipo}
                    onChange={e=>setVeiculo('id_Tipo', e.target.value)} 
                    required>
                        <option></option>
                        {veiculo.listaTipo.map((s, key)=>
                        <option key={key} value={s._id}>{s.tipo}</option>
                        )}
                    </select>
                </div>
                <div>
                    <span>Placa:</span>
                    <MaskedInput
                    disabled={disabled}
                    value={veiculo.placa}
                    className='uppercase'
                    type='text'
                    placeholder='placa'
                    mask={[/\D/, /\D/, /\D/,/\d/,/\w/,/\d/, /\d/]}
                    onChange={e=>setVeiculo('placa', e.target.value)}
                    guide={false}
                    />
                </div>
                {/* <div>
                    <span>Plano:</span>
                    <select
                    disabled={disabled}
                    value={veiculo.id_Plano}
                    onChange={e=>setVeiculo('id_Plano', e.target.value)} 
                    required>
                        <option></option>
                        {planosTipo.map((s, key)=>
                        <option key={key} value={s._id}>{s.titulo}</option>
                        )}
                    </select>
                </div> */}
                
            </S.box1>
            <S.box1>
                <div>
                    <span>Chassi:</span>
                    <input 
                    disabled={disabled}
                    value={veiculo.chassi}
                    className='uppercase'
                    type='text' 
                    placeholder='chassi'
                    onChange={e=>setVeiculo('chassi', e.target.value)}
                    />
                </div>
                <div>
                    <span>Numero Motor:</span>
                    <input 
                    disabled={disabled}
                    value={veiculo.n_motor}
                    className='uppercase'
                    type='text' 
                    placeholder='numero motor'
                    onChange={e=>setVeiculo('n_motor', e.target.value)}
                    />
                </div>
            </S.box1>

            <S.box1>
                <div>
                    <span>Renavam:</span>
                    <input 
                    disabled={disabled}
                    value={veiculo.renavam}
                    className='maiusculo'
                    type='text' 
                    placeholder='renavam'
                    onChange={e=>setVeiculo('renavam', e.target.value)}
                    />
                </div>
                <div>
                    <span>Ano Modelo:</span>
                    <MaskedInput
                    disabled={disabled}
                    value={veiculo.anoMod}
                    type='text'
                    placeholder='Ano Modelo'
                    mask={[/\d/,/\d/,/\d/,/\d/]}
                    onChange={e=>setVeiculo('anoMod', e.target.value)}
                    guide={false}
                    />
                </div>
            </S.box1>

            <S.box1>
                <div>
                    <span>Marca:</span>
                    <input 
                    disabled={disabled}
                    value={veiculo.marca}
                    className='maiusculo'
                    type='text' 
                    placeholder='Marca'
                    onChange={e=>setVeiculo('marca', e.target.value)}
                    />
                </div>
                <div>
                    <span>Modelo:</span>
                    <input 
                    disabled={disabled}
                    value={veiculo.modelo}
                    className='maiusculo'
                    type='text' 
                    placeholder='Modelo'
                    onChange={e=>setVeiculo('modelo', e.target.value)}
                    />
                </div>
            </S.box1>
            
            <S.box1>
                <div>
                    <span>Cor:</span>
                    <input
                    disabled={disabled}
                    value={veiculo.cor}
                    className='maiusculo' 
                    type='text' 
                    placeholder='Cor'
                    onChange={e=>setVeiculo('cor', e.target.value)}
                    />
                </div>
                <div>
                    <span>Valor FIPE:</span>
                    <input
                    disabled={disabled}
                    value={veiculo.valorFipe}
                    type='number' 
                    placeholder='valorFipe'
                    onChange={e=>setVeiculo('valorFipe', e.target.value)}
                    />
                </div>
            </S.box1>

            <S.divButton editModal={parametroInit.editModal}>
                {!veiculo.id_Veiculo?
                <S.Salvar>
                    <button type='button' onClick={addVeiculo}>
                        Salvar
                    </button>
                </S.Salvar>
                :
                veiculo.id_Veiculo && parametroInit.editModal ?
                    <S.Salvar>
                        <button type='button' onClick={addVeiculo}>
                            Salvar
                        </button>
                    </S.Salvar>
                :
                <S.Atualizar>
                    <button 
                    type='button' 
                    style={{backgroundColor:"#ffbf00"}} 
                    onClick={Atualizar}>
                        Atualizar
                    </button>
                </S.Atualizar>
                }
            </S.divButton>
        </S.div3>
    </S.body>
  );
}

export default ModalVeic;
