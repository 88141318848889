import styled from 'styled-components';
import breakpoints from '../../breakpoints';

export const Container = styled.div`
display:flex;

a{
  text-decoration: none ;
}

@media ${breakpoints.sm} {
  position:fixed ;
  top: 60px;
  justify-content: center ;
}
`
// ----------------------
export const Box2 = styled.div`
display:flex;
flex-direction:column;
margin-top: 20px ;

@media ${breakpoints.sm} {
  margin: 0px;
  flex-direction:row ;
  align-items: center ;
}
`
// ----------------------
export const boxBut = styled.div`
margin:10px 0;
border-radius: 2px ;
padding: 3px ;

@media ${breakpoints.sm} {
  margin: 0px 1px;
}

button{
  display: flex;
  background-color:${props=>props.Display? "#3881ff" : ""} ;
  color: ${props=>props.Display? "white" : "black"} ; ;
  outline:0;
  border:none;
  font-size:14px;
  cursor:pointer;
  width: clamp(50px, 20vw, 70px);
  justify-content: center ;

  @media ${breakpoints.sm} {
    font-size: clamp(10px, 5vw, 13px)
  }
}
`