import React, { useEffect, useContext } from 'react';
import api from '../api';
import {Context} from '../contexts/StateContext';
import {format} from 'date-fns';
import {UsuarioLog} from '../../services/FiltrosApi/logado';

//---------------------------
export function Api_Clientes(){
    const {state, dispatch} = useContext(Context);
    const {cliente, endereco, foneCelular} = state.cliente;
    const {listaPesquisa, parametroInit, playLista} = state.parametros;
    const id_Cliente = cliente.id;
    const {idLogado} = UsuarioLog();
        
    // --------------------------------------
    async function buscarPorId(){
        if(cliente.clientePorId){
            await api.get(`cliente/${id_Cliente}`)
            .then(res => {
                const buscar = res.data;
                dispatch({
                    type: 'set_cliente',
                    payload:{...cliente, listaCliente:buscar}
                });
            })
        };
    };
    
    //-------------------------------
    useEffect(() => {
        buscarPorId()
        }, [cliente.clientePorId])
    
    //-------------------------------
    async function BuscaTodosClientes(){
        dispatch({
            type: 'SET_parametroInit',
            payload:{...parametroInit, detalhesLista:false, loading:true}
        });

        await api.get('/cliente/todos')
            .then(res => {
                dispatch({
                    type: 'SET_parametroInit',
                    payload:{...parametroInit, loading:false}
                });
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaCliente:res.data}
                });
                dispatch({
                    type: 'set_playLista',
                    payload:{...playLista, playListaClie:true}
                });
            })
    };
    //-------------------------------
    async function pesqidcliente(){
        if(id_Cliente){
            await api.get(`cliente/${id_Cliente}`)
            .then(res => {
                const cli = res.data;

                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaCliente:res.data}
                });

                cli.map(e=>{
                    dispatch({
                        type: 'set_cliente',
                        payload:{
                            ...cliente, 
                            id_Franquia:e.id_Franquia._id,
                            id_Colaborador:e.id_Colaborador?e.id_Colaborador._id:"",
                            status:e.status,
                            nome:e.nome,
                            cpfCnpj:e.cpfCnpj,
                            dataNascimento:format(new Date(e.dataNascimento), 'yyyy-MM-dd'),
                            email:e.email,
                        }
                    }); 
                    dispatch({
                        type: 'set_endereco',
                        payload:{
                            ...endereco, 
                            cep: e.endereco.cep,
                            estado: e.endereco.estado,
                            cidade: e.endereco.cidade,
                            rua:e.endereco.rua,
                            numeroCasa: e.endereco.numeroCasa,
                            bairro:e.endereco.bairro,
                        }
                    });
                    dispatch({
                        type: 'set_fone',
                        payload:{
                            ...foneCelular, 
                            codigoArea:e.foneCelular.codigoArea,
                            numeroCel:e.foneCelular.numeroCel,
                        }
                    });
                })
            })
        }
    };
    // ---------------------------------------
    async function pesqClienteNome(nome){
        console.log(nome)
        if(nome){
            dispatch({
                type: 'SET_parametroInit',
                payload:{...parametroInit, loading:true}
            });

            await api.get(`cliente/nome/${nome}`)
                .then(res => {
                    const data = res.data;
                    
                    dispatch({
                        type: 'SET_parametroInit',
                        payload:{...parametroInit, loading:false}
                    });
                    
                    dispatch({
                        type: 'set_ListaPesquisa',
                        payload:{...listaPesquisa, listaCliente: data.length > 0 ? data : []}
                    }); 
                })
            }
    };
    // ---------------------------------------
    async function BuscaTodosUsuarios(){
        dispatch({
            type: 'SET_parametroInit',
            payload:{...parametroInit, detalhesLista:false}
        });

        await api.get('/usuario/todos')
            .then(res => {
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaUsuario:res.data}
                });
                dispatch({
                    type: 'set_playLista',
                    payload:{...playLista, playListaUsuario:true}
                });
            })
    };

    // ----------Add so CPF------------------
    async function AddCPF(cpf){
        if(cliente.nome.length >= 3 ){
            await api.post('/cliente/add', {
                userCadastro:idLogado,
                nome:cliente.nome,
                cpfCnpj:cpf,
    
            }).then(res => {
                if(res.data){
                    dispatch({
                        type: 'set_cliente',
                        payload:{
                            ...cliente, 
                            id_Cliente: res.data._id,
                            cpfCnpj: res.data.cpfCnpj,
                            nome: res.data.nome
                        }
                    });
                    dispatch({
                        type: 'SET_parametroInit',
                        payload:{...parametroInit, botaoCPF:false}
                    });
                };

                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaCliente: [res.data]}
                });

                alert('CPF Salvo!');
                
            }).catch((error) => {
                alert("Ocorreu um erro");
            }); 
        }else{
            alert("Prencha o Nome Completo!")
        }
    };
    
    // ------------------------------------------------
    return {BuscaTodosClientes, pesqidcliente, pesqClienteNome, AddCPF, BuscaTodosUsuarios}
};





