import React from "react";
import ContentLoader from "react-content-loader";

const MyLoader = (props) => (
  <ContentLoader 
    speed={0.7}
    width={300}
    height={160}
    viewBox="0 0 300 160"
    backgroundColor="#e2ecff"
    foregroundColor="#3881ff"
    {...props}
  >
    <rect x="5" y="8" rx="3" ry="3" width="350" height="22" /> 
    <rect x="5" y="36" rx="3" ry="3" width="150" height="22" />
  </ContentLoader>
)

export default MyLoader