
export const agendaInicialState = {
    nome:'Robson',
    agendamentoslista: [],
    
}
//-----------------------------------------------------
export const agendaReducer = (state = agendaInicialState, action)=>{

    switch(action.type){

        // case 'setLogado':
        //     return {...state, nome:action.payload};
        
        case 'SET_AGENDA':
            return {...state, agendamentoslista: action.payload};

            default:
            return state;
            
    }
};
//-----------------------------------------------------
