import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import Login from '../../screen/login';
import Home from '../../screen/home';
import Vistoria from '../../screen/Vistoria';
import AddCotacao from '../../screen/add_cotacao';
import Cadastro from '../../screen/Cadastro';
import Add from '../../screen/Add';
import Cobranca from '../../screen/Cobranca';
import Agendamento from '../../screen/agendamento';
import EstoquePecas from '../../screen/EstoquePecas';
import AddResult from '../../screen/contaUser';
import QrCode from '../../screen/qrcode';
import NaoEncontrada from '../../screen/naoEncontrada';
import Politica from '../../screen/Politica/politica';
import Termos from '../../screen/Politica/termos';


export default function Routes(){
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Add}/>
                <Route path="/home" exact component={Home}/>
                <Route path="/vistoria" exact component={Vistoria}/>   
                <Route path="/cotacao" exact component={AddCotacao}/>
                <Route path="/cadastro" exact component={Cadastro}/>
                <Route path="/login" exact component={Login}/>
                <Route path="/cobranca" exact component={Cobranca}/>
                <Route path="/agendamento" exact component={Agendamento}/>
                <Route path="/estoquePecas" exact component={EstoquePecas}/>
                <Route path="/evento/:id" exact component={Add}/>
                <Route path="/addresult/:_id" exact component={AddResult}/>
                <Route path="/qrcode" exact component={QrCode}/>
                <Route path="/NaoEncontrada" exact component={NaoEncontrada}/> 
                <Route path="/politicaprivacidade" exact component={Politica}/> 
                <Route path="/termocondicoes" exact component={Termos}/>   
                  
            </Switch>
        </BrowserRouter>
    )
}