import React from 'react';
import * as S from './styles';
import { useState} from 'react';
import {Redirect} from 'react-router-dom';
import QRCode from 'qrcode.react';
import Header from '../../componentes/Header/index';
import Footer from '../../componentes/Footer/index';


function QrCode() {

  const [mac, setMac] = useState();
  const [redirec, setRedirect] = useState(false);

  async function SalvarMac(){
    if(!mac)
      alert('Informe o codigo do celular!')
    else{
      await localStorage.setItem('@eventos/macaddress', mac);
      setRedirect(true);
      window.location.reload();
    }
  }
  return (
    <S.App>

      { redirec && <Redirect to='/' />}
        
        <Header></Header>
        <S.body>
          <h1>CAPTURE O QRCODE PELO APP</h1>
          <S.QrCodeArea>
            <QRCode value='getmacaddress' size={250} />
          </S.QrCodeArea>
          <S.ValidarCode>
            <span>Digite o codigo do celular:</span>
            <input type="text" onChange={e=>setMac(e.target.value)} value={mac} />
            <button type='button' onClick={SalvarMac}>SICRONIZA</button>
          </S.ValidarCode>
          <p>Suas atividades serão sincronizadas</p>
          
        </S.body>
        <Footer></Footer>
    </S.App>
    
)
}

export default QrCode;