import React from 'react';
import * as S from './styles';
import {Link} from 'react-router-dom';


function Page() {

  return (
    <S.App>        
        <h1>Pagina Não Encontrada!</h1>
        <Link to="/" >Voltar Para Inicio</Link>
    </S.App>
    
)
}

export default Page;