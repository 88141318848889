import React, {useContext } from 'react';
import api from '../../services/api';
import {Context} from '../../services/contexts/StateContext';

//--------------------------------------
export function ApiUsuario(){
    const {state, dispatch} = useContext(Context);
    const {logado} = state.cliente; 
    
    async function LogUsuario(token){
        await api.get(`/usuario/infologado/${token}`)
        .then(res=>{
            const dd = res.data[0];
            dispatch({
                type: 'set_logado',
                payload:{
                    ...logado, 
                    idLogado:dd._id,
                    nome:dd.nome,
                    cpf:dd.cpf,
                    nivel:dd.nivel,
                    conectado:true
                    }
            });
        })
    };
    return {LogUsuario}
};
