import React, {useEffect, useState, useContext} from 'react';
import api from '../../../services/api';
import * as S from './styles';
import {Context} from '../../../services/contexts/StateContext';
import MaskedInput from 'react-text-mask';

import {UsuarioLog} from '../../../services/FiltrosApi/logado';
import {CidadesApi} from '../../../services/FiltrosApi/cidadesApi';

import estados from '../../../services/FiltrosApi/estados.json';

export function Passo02_Endereco(props) { 
    const {state, dispatch} = useContext(Context);
    const {cliente, endereco, foneCelular} = state.cliente;
    const {veiculo} = state.veiculo;
    const {franquia} = state.franquia;
    
    const {parametroInit, displayModal, playmodal,playLista, listaPesquisa} = state.parametros;
    const {editModalVeic, detalhesVeic} = parametroInit; 
    const {
        disabled
    } = parametroInit;

    const {idLogado} = UsuarioLog();
    

    const ListaCidades = CidadesApi(estados);

//---------------------------------------------------
const setcliente = (key, value) => {
    dispatch({
        type: 'set_cliente',
        payload: {...cliente, [key]: value}
        });
};
//---------------------------------------------------
const setEndereco = (key, value) => {
    dispatch({
        type: 'set_endereco',
        payload: {...endereco, [key]: value}
        });
};

const setFone = (key, value) => {
    dispatch({
        type: 'set_fone',
        payload: {...foneCelular, [key]: value}
        });
};
//---------------------------------------------------
useEffect(() => {
    dispatch({
        type: 'SET_Franquia',
        payload: {...franquia, id: veiculo.id_Franquia}
        });
}, [veiculo.id_Franquia])
//-----------------Limpar apos Salvar com sucesso--------------------
function limparModal(){
    dispatch({
        type: 'set_limparVeiculo'
        });
};
//--------------------------Atualizar-------------------------------------------
function Atualizar(){
    if(detalhesVeic){
        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit, 
                editModalVeic:true, 
                disabledVeic:false, 
                detalhesVeic:false
            }
        }) 
    };
    dispatch({
        type: 'set_playLista',
        payload: {...playLista, playListaBenef:false, playListaEven:false}
    });
};
//-----------------------------------------------
function MaisDetalhes(){
    dispatch({
        type: 'set_ListaPesquisa',
        payload: {...listaPesquisa, listaCliente:[]}
      });
    
    dispatch({
    type: 'SET_Display',
    payload: {
        ...displayModal, 
        veiculosDisplay: true, 
        clienteDisplay: false,
    }
    });

    dispatch({
    type: 'set_limparcliente',
    });
    if(!displayModal.clienteDisplay){
        props.pesqIDveiculo();

    }
    
};
//----------------------------Add--------------------------------------------------
async function addVeiculo(){
    if(displayModal.editModalVeic===false){

        await api.post('/veiculo', { //post - Criar
            userCadastro:idLogado,
            id_Cliente:veiculo.id_Cliente,
            id_Franquia:veiculo.id_Franquia,
            colaborador:veiculo.colaborador,

            placa:veiculo.placa,
            chassi:veiculo.chassi,
            anoMod:veiculo.anoMod,
            marca:veiculo.marca,
            modelo:veiculo.modelo,
            cor:veiculo.cor,  

            id_Tipo:veiculo.id_Tipo,
            id_Plano:veiculo.id_Plano,

            n_motor:veiculo.n_motor,
            renavam:veiculo.renavam,
            anoFab:veiculo.anoFab,
            km:veiculo.km,
            estado:veiculo.estado,
            cidade:veiculo.cidade,
            valorFipe:veiculo.valorFipe,
            codigoFipe:veiculo.codigoFipe,
            status:veiculo.status, 
            dataCadastro:veiculo.dataCadastro,

        }).then(res => {
            const erro = (res.data.error);
            if(erro){
                if(erro.placa){
                    return alert(erro.placa.msg);
                }
            }else{
                if(parametroInit.editModalVeic===false){
                    alert('Veiculo Salvo!');
                }
                limparModal();
                props.buscarVeiculos();
            }
        })
    }
  //-------Editar-------------------------------
    if(parametroInit.editModalVeic){
        await api.put(`/veiculo/atualizar/${veiculo.id_Veiculo}`, { 
            
            id_Cliente:veiculo.id_Cliente,
            id_Franquia:veiculo.id_Franquia,
            colaborador:veiculo.colaborador,

            id_Tipo:veiculo.id_Tipo,
            id_Plano:veiculo.id_Plano,
            
            placa:veiculo.placa,
            chassi:veiculo.chassi,
            anoFab:veiculo.anoFab,
            anoMod:veiculo.anoMod,
            marca:veiculo.marca,
            modelo:veiculo.modelo,
            cor:veiculo.cor, 
            n_motor:veiculo.n_motor,
            renavam:veiculo.renavam, 
            
            km:veiculo.km,
            estado:veiculo.estado,
            cidade:veiculo.cidade,
            valorFipe:veiculo.valorFipe,
            codigoFipe:veiculo.codigoFipe,
            status:veiculo.status, 

            data_modificado:Date.now(),
                    
        }).then(res => {
            if(res.data.error){
                alert('erro')
            } else{
                alert('Veiculo Atualizado!');
                limparModal();
                FecharModal();
                props.pesqIDveiculo();
            }
        })
    }
};
//--------------------------------------
function buscarEventos(){
    if(!playLista.playListaEven){
        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit, 
                buscarEven: true, 
                editModal:false,
            }
        });
        dispatch({
            type: 'set_playLista',
            payload:{...playLista, playListaEven:true}
        });
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {...playmodal, playModalBenef:false}
        });
            
    }else{
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {...playmodal, playModalBenef:false}
        });
    }
};
//-----------------------------------------------------------------
function buscarBeneficios(){

}
//----------Pesquisar e Selecionar Cliente em Novo Veiculo---------------------
const[valuePesq, setvaluePesq]=useState('');
const[listaCli, setlistaCli]=useState([]);
const[playlistaCli, setplaylistaCli]=useState(true);

async function pesqCliente(e){
    setvaluePesq(e);
    if(e){
    await api.get(`cliente/nome/${e}`)
        .then(res => {
            setlistaCli( res.data);
        })
    }
};

function selectCliente(t){
    dispatch({
        type: 'set_cliente',
        payload: {...cliente, id_Cliente: t._id, nome: t.nome}
        });
        setplaylistaCli(false);
        setvaluePesq('');
};

useEffect(() => {
    if(valuePesq){
    setplaylistaCli(true)
    }else setplaylistaCli(false)
}, [valuePesq]);

//---------------------------------------------
function FecharModal(){
    dispatch({
        type: 'SET_PLAYMODAL',
        payload: {...playmodal, playModalVeiculo: false}
        });

    if(editModalVeic || detalhesVeic){
        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit, 
                editModalVeic: false, 
            }
        });
    };
    if(displayModal.veiculosDisplay){
        props.pesqVeiculoPlaca();
    };
    
    if(displayModal.clienteDisplay){
        dispatch({
            type: 'SET_parametroInit',
            payload: {...parametroInit, buscarVeic: true}
        });
    };
};
//###################################################################################

  return (
    <S.body>
      
      <S.ContainerVeic 
      playModalVeiculo={playmodal.playModalVeiculo} 
      editModalVeic={parametroInit.editModalVeic}
      >
        <S.div3>
        
            <div className='titulo'>
                <div className={'tituloUser'}>
                    <span>{detalhesVeic ? 'Detalhes Veiculo': editModalVeic? 'EDITAR Veiculo':'Novo Veiculo'}</span>
                </div>
                <div 
                className='fechar'
                onClick={()=>FecharModal()}>X
                </div>
            </div>

        {playmodal.playModalVeiculo && <>
        
            <S.box>
                <div>
                    <label htmlFor='ff'>Estado:</label>
                        <select
                        disabled={disabled}
                        value={endereco.estado}
                        onChange={e=>setEndereco('estado', e.target.value)} 
                        required>
                            <option></option>
                            { estados.map((s, key) => 
                            <option key={key} value={s.UF}>{s.label}</option>
                            )}
                    </select>
                </div>
                <div>
                    <label htmlFor='ff'>Cidade:</label>
                        <select
                        disabled={disabled}
                        value={endereco.cidade}
                        onChange={e=>setEndereco('cidade', e.target.value)} 
                        required>
                            <option></option>
                            { ListaCidades.map((s, key) => 
                            <option key={key} value={s.cidade}>{s.cidade}</option>
                            )}
                    </select>
                </div>
            </S.box>
            <S.box>
                <div>
                    <span>CEP:</span>
                    <input
                    disabled={disabled} 
                    value={endereco.cep}
                    className='maiusculo'
                    type='text' 
                    placeholder='cep' 
                    onChange={e=>setEndereco('cep', e.target.value)}/>
                </div>
                <div style={{flex:2}}>
                    <span>Rua:</span>
                    <input
                    disabled={disabled} 
                    className='maiusculo'
                    value={endereco.rua}
                    type='text' 
                    placeholder='Rua' 
                    onChange={e=>setEndereco('rua', e.target.value)}/>
                </div>
                
            </S.box>
            <S.box>
                <div style={{flex:3}}>
                    <span>Bairro:</span>
                    <input
                    disabled={disabled} 
                    className='maiusculo'
                    value={endereco.bairro}
                    type='text' 
                    placeholder='Bairro' 
                    onChange={e=>setEndereco('bairro', e.target.value)}/>
                </div>
                <div>
                    <span>Numero:</span>
                    <input
                    disabled={disabled} 
                    className='maiusculo'
                    value={endereco.numeroCasa}
                    type='text' 
                    placeholder='N°' 
                    onChange={e=>setEndereco('numeroCasa', e.target.value)}/>
                </div>
            </S.box>

            <S.box>
                <span>Complemento:</span>
                <input
                disabled={disabled} 
                className='maiusculo'
                value={endereco.complemento?endereco.complemento:''}
                type='text' 
                placeholder='Complemento' 
                onChange={e=>setEndereco('complemento', e.target.value)}/>
            </S.box>

            <S.box>
                <div>
                    <span>Cel. ddd:</span>
                    <MaskedInput
                    disabled={disabled}
                    value={foneCelular.codigoArea}
                    type='text'
                    placeholder='ddd'
                    mask={['(', /\d/, /\d/,')']}
                    onChange={e=>setFone('codigoArea', e.target.value)}
                    guide={false}
                    />
                </div>
                <div>
                    <span>Numero Celular:</span>
                    <MaskedInput
                    disabled={disabled}
                    value={foneCelular.numeroCel}
                    type='text'
                    placeholder='numero'
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/,'-',/\d/, /\d/, /\d/,/\d/]}
                    onChange={e=>setFone('numeroCel', e.target.value)}
                    guide={false}
                    />
                </div>
            </S.box>
            
            <S.box>
                <span>email:</span>
                <input
                disabled={disabled} 
                value={cliente.email}
                type='email' 
                placeholder='email'  
                onChange={e=>setcliente('email', e.target.value)}/>
            </S.box>
            
            
        </>}

        <S.divButton>
            {!detalhesVeic && 
            <S.Salvar>
                <button type='button' onClick={addVeiculo}>Salvar</button>
            </S.Salvar>
            }
            </S.divButton>

        </S.div3>
      </S.ContainerVeic>
    </S.body>
  );
}
