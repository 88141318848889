import React, { useState, useEffect, useContext } from 'react';
import api from '../../services/api';
import {Context} from '../../services/contexts/StateContext';

//---------------Pesquisar-------------
export function ListaCobranca(){
    const {state, dispatch} = useContext(Context);
    const {listaPesquisa} = state.parametros;
    
    async function pesqTodosCarnes(){
        await api.get(`cobranca/todos`)
        .then(res => {
            const buscar = res.data;
            const filtro = buscar.map(e=> ({
                charges: e.charges, 
                veiculo: e.id_Veiculo
            }));

            dispatch({
                type: 'set_ListaPesquisa',
                payload:{...listaPesquisa, listaCarnes:filtro.flat()}
            });
        })
    };

    return {pesqTodosCarnes}
};




