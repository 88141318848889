// const {add, format} = require('date-fns');

// module.exports = {
//     toCentavos:(preco) =>{
//         return parseInt(preco.toString().replace('.', '').replace(',', ''));
//     },
// //----------------------------------------------------------------------
//     horasToMinutos:(horasMin)=>{
//         // Ex. 1:20
//         const [horas, minutos] = horasMin.split(':');
//         return parseInt(parseInt(horas) * 60 + parseInt(minutos));
//     },
// //----------------------------------------------------------------------
//     slotDuracao:30,
// //----------------------------------------------------------------------
//     dividirMinutos:(inicio, fim, duracao)=>{
//         const partes = []; // [1:30, 2:00,]
//         let contador = 0;

//         while (fim > inicio){
//             partes.push(format(new Date(inicio), 'kk:mm'));
//             inicio = add(new Date(inicio),{minutes:duracao});
//             contador++;
//         }
//         return partes
//     },
// //----------------------------------------------------------------------
//     splitByValor:(array, valor)=>{
//         let newArray = [[]];
//         array.forEach((item)=>{
//             if(item !== valor){
//                 newArray[newArray.length - 1].push(item);
//             } else{
//                 newArray.push([]);
//             }
//         });
//         return newArray;
//     }
// //----------------------------------------------------------------------
// };