import React, { useEffect, useContext} from 'react';
import * as S from './styles';
import {Context} from '../../services/contexts/StateContext';
// COMPONENTES---------
import {ListaItemBD} from '../ListarPesquisas/listaItemBD';


//----------------------------------------------------
export function Add_Itens(props){  
    const {state, dispatch} = useContext(Context);  
    const {pagamento} = state.evento;  

    // ------------------------------------- 
    function Func_selectItem(e){
        dispatch({
            type: 'set_Pagamento',
            payload: {
                ...pagamento, 
                listaItem:[],
                nomeItem:'',
                selectItem:e
            }
        });
    };

    // ####################################################
    return(
        <S.bodyAdd>
            <div style={{'display':"flex",'flex':'1',"flexDirection":'column',"justifyContent":"flex-end"}}>
                {props.clickNovoItem && !props.selectItem &&
                <S.NovaDespesa>
                    <div style={{"display":"flex", 'minWidth':'90px', "flexDirection":"column",'position':"relative", 'marginRight':"10px"}}>
                        <span>Novo:</span>
                        <input 
                        style={{"display":"flex",'width':"100%", 'minHeight':"25px", "color":"black"}}
                        type='search'
                        placeholder='descrição' 
                        value={props.nomeItem}
                        onChange={e=> props.FiltrarItem(e.target.value)}>
                        </input>  

                        {props.listaItem.length > 0 &&
                        <ListaItemBD
                        listaItem={props.listaItem}
                        Func_selectItem={Func_selectItem}
                        />
                        } 
                    </div>

                    {props.novoItem.length >= 3?
                    <button 
                    className='bottonAdd'
                    onClick={()=>props.AddItem()}
                    >
                        add
                    </button>
                    :
                    <button 
                    className='bottonAdd'
                    style={{"backgroundColor":"red","color":"white"}} 
                    onClick={()=>props.setClickItem(false)}
                    >
                        X
                    </button>
                    }
                    
                </S.NovaDespesa>     
                }
            </div>
            {/* ----------------------- */}
            {props.selectItem && <>
            <div style={{display:"flex",'width':'100px', 'flexDirection':"column"}}>
                <span>Valor</span>
                <input 
                style={{"color":"black",'height':"27px"}} 
                type="number"
                value={props.valorItem}
                onChange={e=>props.setInfor("valorItem",parseFloat(e.target.value))}>
                </input>   
            </div> 

            <div style={{'display':"flex",position:"relative", alignItems:"flex-end"}}>
                <button
                className='bottonAdd'
                onClick={()=> props.Adicionar()}>
                    salvar
                </button>
            </div>
            </>}
        </S.bodyAdd>
)};