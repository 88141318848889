import React, {useContext, useEffect} from 'react';
import * as S from './styles';
import {Context} from '../../services/contexts/StateContext';
import MaskedInput from 'react-text-mask';
import lupa from '../../midia/lupa.svg';
import {FunctModal} from '../../componentes/Functions';
// /-------------------------------------------------
export function PesquisaPadrao(props){
    const {state, dispatch} = useContext(Context);
    const {displayModal,listaPesquisa, playmodal} = state.parametros;

    // --------------
    function SetListaPesquisa(key, value){
        dispatch({
            type: 'set_ListaPesquisa',
            payload: {...listaPesquisa, [key]: value}
        });
    };
    
    // --------------
    useEffect(() => {
        dispatch({
            type: 'set_ListaPesquisa',
            payload:{...listaPesquisa, alertaNovo:false}
        }); 
        if(!listaPesquisa.nomeItem){
            dispatch({
                type: 'set_ListaPesquisa',
                payload:{
                    ...listaPesquisa, 
                    listaVeiculo:[],
                    listaCliente:[],
                    listaFornecedor:[],
                }
            }); 
        }
    }, [listaPesquisa.nomeItem]);

    // --------------
    function PesquisarItem(item){
        props.Pesquisar(item);
        // if(!props.PesqDentroModal){
        //     FecharModal();
        // };
        
    };
    
//###################################################################################################
return(
    <S.divInfor>     
        <span>{props.titulo}:</span>
        <S.ddd>
            {displayModal.clienteDisplay || playmodal.playModalUsuario || playmodal.playModalEven1 ?
            <>
            {listaPesquisa.nomeItem.length <= 11?
                <MaskedInput
                value={listaPesquisa.nomeItem}
                type='search' 
                placeholder='cpf/cnpj'
                mask={[/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'-',/\d/, /\d/,/\d/]}
                onChange={e=>SetListaPesquisa('nomeItem', e.target.value.replace(/\D/gim, ''))}
                guide={false}
                />
                :
                <MaskedInput
                value={listaPesquisa.nomeItem}
                type='search' 
                placeholder='cpf/cnpj'
                mask={[/\d/, /\d/,'.',/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'/',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/]}
                onChange={e=>SetListaPesquisa('nomeItem', e.target.value.replace(/\D/gim, ''))}
                guide={false}
                />
            }
            </>
            :
            props.refPlaca?
            <MaskedInput
            value={listaPesquisa.nomeItem}
            className='uppercase'
            type='search'
            placeholder={props.placeholder}
            mask={[/\D/, /\D/, /\D/,/\d/,/\w/,/\d/, /\d/]}
            onChange={e=>SetListaPesquisa('nomeItem', e.target.value)}
            guide={false}
            />
            :
            props.refNomeCPF &&
            <input 
            type='search'
            style={{textTransform:"capitalize"}}
            placeholder={props.placeholder}
            value={listaPesquisa.nomeItem} 
            onChange={e=>SetListaPesquisa('nomeItem', e.target.value)}
            />  
            }
            
            {props.Lupa &&
            <S.Pesq>
                <button 
                type='button' 
                onClick={()=>PesquisarItem(listaPesquisa.nomeItem)}>
                <img src={lupa}/>
                </button>
            </S.Pesq>
            }
        </S.ddd>

        {props.playBotao &&
        <S.botao>
            <div
            style={{'backgroundColor':"#3881ff",'color':"white"}}
            className='abrirModal'
            onClick={()=>props.playModal_Add()}
            >{props.nomeBotao}
            </div>
        </S.botao>
        }
    </S.divInfor>
   
)}