import React, { useContext, useState } from 'react';
import {Context} from '../../services/contexts/StateContext';
import * as S from './styles';
import editar from '../../midia/editar.svg';
import delet from '../../midia/delete.svg';
import ativar from '../../midia/ativar.svg';
import api from '../../services/api';
import {format} from 'date-fns';

import {AlertComp} from '../../componentes/alerte/index';

//-----------------------------------------------
export default function ListaResultado(props){
    const {state, dispatch} = useContext(Context);
    const [listaChecked, setCheck]=useState([]) ;
    const {parametroInit, playmodal, listaPesquisa,playLista} = state.parametros;
    const {playListaClie} = playLista;
    const {listaCliente} = listaPesquisa;
    const {cliente, endereco, foneCelular} = state.cliente;
    const {cobranca, recebedor} = state.cobrancas;
//----------------------------------------------
function funcCheckBox(e,t){
    let addList = [...listaChecked];
    if(e.target.checked){
        addList = [...listaChecked, t._id];

    }else{
        addList.splice(listaChecked.indexOf(t._id),1);
    }
    setCheck(addList);
}
//-----------------------------------------------
async function ativarEste(t){
    await api.put(`cliente/atualizar/${t._id}`,{
        status:'A'
    })
    .then(res => {
        props.pesqclienteCpf();
    })
};
//-----------------------------------------------------------------
function detalheCliente(t){
    dispatch({
        type: 'set_cliente',
        payload:
        {
            ...cliente, 
            'id_Cliente':t._id,
            'status':t.status, 
            'nome':t.nome, 
            'tipoDoc':t.tipoDoc,
            'cpfCnpj':t.cpfCnpj,
            'dataNascimento': format(new Date(t.dataNascimento), 'yyyy-MM-dd') ,
            'sexo':t.sexo,
            'email':t.email,
            'senha':t.senha,
            'confirmeSenha':t.confirmeSenha,

            'id_Franquia':t.id_Franquia,
            'colaborador':t.colaborador,
        }
    });

    dispatch({
        type: 'set_endereco',
        payload:
        {
            ...endereco,
            'cep': t.endereco.cep,
            'estado': t.endereco.estado,
            'cidade': t.endereco.cidade,
            'rua':t.endereco.rua,
            'bairro':t.endereco.bairro,
            'numeroCasa': t.endereco.numeroCasa,
            'complemento':t.endereco.complemento,
        }
    });

    dispatch({
        type: 'set_fone',
        payload:
        {
            ...foneCelular,
            'codigoArea':t.foneCelular.codigoArea,
            'numeroCel':t.foneCelular.numeroCel,
        }
    });

    dispatch({
        type: 'set_contaBanco',
        payload:
        {
            ...cobranca,
            'id_Recebedor':t._id,
        }
    });

    dispatch({
        type: 'set_recebedor',
        payload:
        {
            ...recebedor, 
            'id':t._id,
            'nome':t.nome, 
            'tipoDoc':t.tipoDoc,
            'cpfCnpj':t.cpfCnpj,
            'email':t.email,
        }
    });
    
    dispatch({
        type:'SET_parametroInit',
        payload: {
            ...parametroInit, 
            detalhesLista:true,
            detalhesModal: true, 
            disabled: true,
            pesquisaIdCliente:true
        }
    });

    dispatch({
        type: 'SET_PLAYMODAL',
        payload: {...playmodal, playModalCliente: true}
    });
};
//-----------------------------------------------

function lixeira(t){
    dispatch({
        type: 'set_cliente',
        payload:{...cliente, 'id_Cliente':t._id}
    });
    props.setAletDelete(true)
    props.setNewStatus('E')
    props.setRefDelet("cliente");
};
//----------------------------------------------------
    return(
        <div style={{'display':"flex", "flexDirection":"column"}}> 
        {playListaClie && listaCliente.length > 0 && 
        <>
        <S.bodyTitulo>
            <div className={'titulo'}>
                <div className={'tituloUser'}>
                    Clientes
                </div>
            </div>
        </S.bodyTitulo>

        <S.bodyresult>
            {props.alertDelete && 
            <AlertComp
            alertDescrit={'Deseja mesmo Excluir?'}
            seSim={props.ExcluirEste}
            seNao={props.FecharAlerte}
            />
            }
            {props.listaBusca.map((t, k) => (
            <S.result key={k} statusLista={t.status}>
                <input 
                className='checkbox'
                type='checkbox'
                onChange={(e)=>funcCheckBox(e,t)}
                />
                <span>{t.nome}</span>
                <span>{t.cpfCnpj}</span>
                <img 
                className='icone'
                src={editar} 
                onClick={()=>detalheCliente(t)} 
                alt="icone"
                />

                <div>
                    {t.status !== 'E' &&
                    <img 
                    className='icone'
                    src={delet} 
                    onClick={()=>lixeira(t)} 
                    alt="icone"
                    />
                    }
                
                    {t.status === 'E' &&
                    <img 
                    className='icone'
                    src={ativar} 
                    onClick={()=>ativarEste(t)} 
                    alt="icone"
                    />
                    }
                </div>
            </S.result>
            ))}
            
        </S.bodyresult>
        </>}
    </div>
)}