import styled from 'styled-components';

export const App = styled.div `
  display: flex;
  background-color: rgb(36, 36, 36);
  min-height: 100vh;
  flex-direction:column;
  align-items: center;
  
`

export const body = styled.div`
  display: flex;
  width: 100%;
  
  `
  export const body1 = styled.div`
  display: flex;
  flex-direction:column;
  align-items:center;
  width: 100%;
  margin-top:70px;
  `
  export const bodyFiltro = styled.div`
  display:flex;
  width:100%;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  background-color: rgb(36, 36, 36);
  
  `
  export const body2 = styled.div`
  display: flex;
  width: 95%;
  flex-direction:column;
  align-items:center;
  margin-top: 10px;
  overflow: auto;
  `
  export const Container = styled.div`
  width:97%;
  color:#262626;
  display:flex;
  background-color:#b7b7b7;
  border-radius:3px;
  min-height:30px;
  margin-bottom:5px;
  
  `
 

export const tituloBody = styled.div`
  display:flex;
  width:100%;
  border-bottom:1px solid white; 
  justify-content:center;
  margin-bottom:5px;

span{
  color: white;
  font-size:14px;
  background-color: rgb(36, 36, 36);
  padding:0px 20px ;
  position:relative;
  top:10px;

}
`

export const Titulo = styled.div`
display:flex;
align-items:center;
margin-left:5px;

`
export const Titulo1 = styled.div`
flex:1;
display:flex;
font-weight:bold;
font-size:12px;
align-items:center;
justify-content:center;
border-radius:3px;

`
export const ddd = styled.div`
display:flex;
justify-content:center;
position:fixed;
bottom:60px ;
width:100%;

`
export const Select = styled.div`
display:flex;
height:40px;
width:100% ;
border-radius:5px;
justify-content:flex-end;
`
export const Icone = styled.div`
display:flex;
align-items:center;
margin:0px 15px;
cursor:pointer;

&:hover{
  opacity: 0.5;
}

img{
width:25px;
height:25px;
}

`
export const pagina = styled.div`
display:${props=>props.pagina === 1?"none":"flex"};
flex-direction: row ;
width: 90%;
margin: 5px;
justify-content: right ;


button{
  display: flex ;
  margin: 5px;
  width: 20px ;
  justify-content: center ;
  padding: 2px ;
  background-color:#b7b7b7;
  border-radius: 1px;
  border: none ;
  cursor: pointer;
}
`