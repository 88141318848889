import { useState, useEffect } from 'react';

  export const CidadesApi = (estado)=>{

    const [city, setCidades] = useState([]);

    const cidades = {
      TO:[
        "Abreulândia",
        "Aguiarnópolis",
        "Aliança do Tocantins",
        "Almas",
        "Alvorada",
        "Ananás",
        "Angico",
        "Aparecida do Rio Negro",
        "Aragominas",
        "Araguacema",
        "Araguaçu",
        "Araguaína",
        "Araguanã",
        "Araguatins",
        "Arapoema",
        "Arraias",
        "Augustinópolis",
        "Aurora do Tocantins",
        "Axixá do Tocantins",
        "Babaçulândia",
        "Bandeirantes do Tocantins",
        "Barra do Ouro",
        "Barrolândia",
        "Bernardo Sayão",
        "Bom Jesus do Tocantins",
        "Brasilândia do Tocantins",
        "Brejinho de Nazaré",
        "Buriti do Tocantins",
        "Cachoeirinha",
        "Campos Lindos",
        "Cariri do Tocantins",
        "Carmolândia",
        "Carrasco Bonito",
        "Caseara",
        "Centenário",
        "Chapada da Natividade",
        "Chapada de Areia",
        "Colinas do Tocantins",
        "Colméia",
        "Combinado",
        "Conceição do Tocantins",
        "Couto Magalhães",
        "Cristalândia",
        "Crixás do Tocantins",
        "Darcinópolis",
        "Dianópolis",
        "Divinópolis do Tocantins",
        "Dois Irmãos do Tocantins",
        "Dueré",
        "Esperantina",
        "Fátima",
        "Figueirópolis",
        "Filadélfia",
        "Formoso do Araguaia",
        "Fortaleza do Tabocão",
        "Goianorte",
        "Goiatins",
        "Guaraí",
        "Gurupi",
        "Ipueiras",
        "Itacajá",
        "Itaguatins",
        "Itapiratins",
        "Itaporã do Tocantins",
        "Jaú do Tocantins",
        "Juarina",
        "Lagoa da Confusão",
        "Lagoa do Tocantins",
        "Lajeado",
        "Lavandeira",
        "Lizarda",
        "Luzinópolis",
        "Marianópolis do Tocantins",
        "Mateiros",
        "Maurilândia do Tocantins",
        "Miracema do Tocantins",
        "Miranorte",
        "Monte do Carmo",
        "Monte Santo do Tocantins",
        "Muricilândia",
        "Natividade",
        "Nazaré",
        "Nova Olinda",
        "Nova Rosalândia",
        "Novo Acordo",
        "Novo Alegre",
        "Novo Jardim",
        "Oliveira de Fátima",
        "Palmas",
        "Palmeirante",
        "Palmeiras do Tocantins",
        "Palmeirópolis",
        "Paraíso do Tocantins",
        "Paranã",
        "Pau d'Arco",
        "Pedro Afonso",
        "Peixe",
        "Pequizeiro",
        "Pindorama do Tocantins",
        "Piraquê",
        "Pium",
        "Ponte Alta do Bom Jesus",
        "Ponte Alta do Tocantins",
        "Porto Alegre do Tocantins",
        "Porto Nacional",
        "Praia Norte",
        "Presidente Kennedy",
        "Pugmil",
        "Recursolândia",
        "Riachinho",
        "Rio da Conceição",
        "Rio dos Bois",
        "Rio Sono",
        "Sampaio",
        "Sandolândia",
        "Santa Fé do Araguaia",
        "Santa Maria do Tocantins",
        "Santa Rita do Tocantins",
        "Santa Rosa do Tocantins",
        "Santa Tereza do Tocantins",
        "Santa Terezinha do Tocantins",
        "São Bento do Tocantins",
        "São Félix do Tocantins",
        "São Miguel do Tocantins",
        "São Salvador do Tocantins",
        "São Sebastião do Tocantins",
        "São Valério",
        "Silvanópolis",
        "Sítio Novo do Tocantins",
        "Sucupira",
        "Taguatinga",
        "Taipas do Tocantins",
        "Talismã",
        "Tocantínia",
        "Tocantinópolis",
        "Tupirama",
        "Tupiratins",
        "Wanderlândia",
        "Xambioá"
      ],
      MA:[
        "Açailândia",
        "Afonso Cunha",
        "Água Doce do Maranhão",
        "Alcântara",
        "Aldeias Altas",
        "Altamira do Maranhão",
        "Alto Alegre do Maranhão",
        "Alto Alegre do Pindaré",
        "Alto Parnaíba",
        "Amapá do Maranhão",
        "Amarante do Maranhão",
        "Anajatuba",
        "Anapurus",
        "Apicum-Açu",
        "Araguanã",
        "Araioses",
        "Arame",
        "Arari",
        "Axixá",
        "Bacabal",
        "Bacabeira",
        "Bacuri",
        "Bacurituba",
        "Balsas",
        "Barão de Grajaú",
        "Barra do Corda",
        "Barreirinhas",
        "Bela Vista do Maranhão",
        "Belágua",
        "Benedito Leite",
        "Bequimão",
        "Bernardo do Mearim",
        "Boa Vista do Gurupi",
        "Bom Jardim",
        "Bom Jesus das Selvas",
        "Bom Lugar",
        "Brejo",
        "Brejo de Areia",
        "Buriti",
        "Buriti Bravo",
        "Buriticupu",
        "Buritirana",
        "Cachoeira Grande",
        "Cajapió",
        "Cajari",
        "Campestre do Maranhão",
        "Cândido Mendes",
        "Cantanhede",
        "Capinzal do Norte",
        "Carolina",
        "Carutapera",
        "Caxias",
        "Cedral",
        "Central do Maranhão",
        "Centro do Guilherme",
        "Centro Novo do Maranhão",
        "Chapadinha",
        "Cidelândia",
        "Codó",
        "Coelho Neto",
        "Colinas",
        "Conceição do Lago-Açu",
        "Coroatá",
        "Cururupu",
        "Davinópolis",
        "Dom Pedro",
        "Duque Bacelar",
        "Esperantinópolis",
        "Estreito",
        "Feira Nova do Maranhão",
        "Fernando Falcão",
        "Formosa da Serra Negra",
        "Fortaleza dos Nogueiras",
        "Fortuna",
        "Godofredo Viana",
        "Gonçalves Dias",
        "Governador Archer",
        "Governador Edison Lobão",
        "Governador Eugênio Barros",
        "Governador Luiz Rocha",
        "Governador Newton Bello",
        "Governador Nunes Freire",
        "Graça Aranha",
        "Grajaú",
        "Guimarães",
        "Humberto de Campos",
        "Icatu",
        "Igarapé do Meio",
        "Igarapé Grande",
        "Imperatriz",
        "Itaipava do Grajaú",
        "Itapecuru Mirim",
        "Itinga do Maranhão",
        "Jatobá",
        "Jenipapo dos Vieiras",
        "João Lisboa",
        "Joselândia",
        "Junco do Maranhão",
        "Lago da Pedra",
        "Lago do Junco",
        "Lago dos Rodrigues",
        "Lago Verde",
        "Lagoa do Mato",
        "Lagoa Grande do Maranhão",
        "Lajeado Novo",
        "Lima Campos",
        "Loreto",
        "Luís Domingues",
        "Magalhães de Almeida",
        "Maracaçumé",
        "Marajá do Sena",
        "Maranhãozinho",
        "Mata Roma",
        "Matinha",
        "Matões",
        "Matões do Norte",
        "Milagres do Maranhão",
        "Mirador",
        "Miranda do Norte",
        "Mirinzal",
        "Monção",
        "Montes Altos",
        "Morros",
        "Nina Rodrigues",
        "Nova Colinas",
        "Nova Iorque",
        "Nova Olinda do Maranhão",
        "Olho d'Água das Cunhãs",
        "Olinda Nova do Maranhão",
        "Paço do Lumiar",
        "Palmeirândia",
        "Paraibano",
        "Parnarama",
        "Passagem Franca",
        "Pastos Bons",
        "Paulino Neves",
        "Paulo Ramos",
        "Pedreiras",
        "Pedro do Rosário",
        "Penalva",
        "Peri Mirim",
        "Peritoró",
        "Pindaré Mirim",
        "Pinheiro",
        "Pio XII",
        "Pirapemas",
        "Poção de Pedras",
        "Porto Franco",
        "Porto Rico do Maranhão",
        "Presidente Dutra",
        "Presidente Juscelino",
        "Presidente Médici",
        "Presidente Sarney",
        "Presidente Vargas",
        "Primeira Cruz",
        "Raposa",
        "Riachão",
        "Ribamar Fiquene",
        "Rosário",
        "Sambaíba",
        "Santa Filomena do Maranhão",
        "Santa Helena",
        "Santa Inês",
        "Santa Luzia",
        "Santa Luzia do Paruá",
        "Santa Quitéria do Maranhão",
        "Santa Rita",
        "Santana do Maranhão",
        "Santo Amaro do Maranhão",
        "Santo Antônio dos Lopes",
        "São Benedito do Rio Preto",
        "São Bento",
        "São Bernardo",
        "São Domingos do Azeitão",
        "São Domingos do Maranhão",
        "São Félix de Balsas",
        "São Francisco do Brejão",
        "São Francisco do Maranhão",
        "São João Batista",
        "São João do Carú",
        "São João do Paraíso",
        "São João do Soter",
        "São João dos Patos",
        "São José de Ribamar",
        "São José dos Basílios",
        "São Luís",
        "São Luís Gonzaga do Maranhão",
        "São Mateus do Maranhão",
        "São Pedro da Água Branca",
        "São Pedro dos Crentes",
        "São Raimundo das Mangabeiras",
        "São Raimundo do Doca Bezerra",
        "São Roberto",
        "São Vicente Ferrer",
        "Satubinha",
        "Senador Alexandre Costa",
        "Senador La Rocque",
        "Serrano do Maranhão",
        "Sítio Novo",
        "Sucupira do Norte",
        "Sucupira do Riachão",
        "Tasso Fragoso",
        "Timbiras",
        "Timon",
        "Trizidela do Vale",
        "Tufilândia",
        "Tuntum",
        "Turiaçu",
        "Turilândia",
        "Tutóia",
        "Urbano Santos",
        "Vargem Grande",
        "Viana",
        "Vila Nova dos Martírios",
        "Vitória do Mearim",
        "Vitorino Freire",
        "Zé Doca"
      ],
      PA:[
        "Abaetetuba",
        "Abel Figueiredo",
        "Acará",
        "Afuá",
        "Água Azul do Norte",
        "Alenquer",
        "Almeirim",
        "Altamira",
        "Anajás",
        "Ananindeua",
        "Anapu",
        "Augusto Corrêa",
        "Aurora do Pará",
        "Aveiro",
        "Bagre",
        "Baião",
        "Bannach",
        "Barcarena",
        "Belém",
        "Belterra",
        "Benevides",
        "Bom Jesus do Tocantins",
        "Bonito",
        "Bragança",
        "Brasil Novo",
        "Brejo Grande do Araguaia",
        "Breu Branco",
        "Breves",
        "Bujaru",
        "Cachoeira do Arari",
        "Cachoeira do Piriá",
        "Cametá",
        "Canaã dos Carajás",
        "Capanema",
        "Capitão Poço",
        "Castanhal",
        "Chaves",
        "Colares",
        "Conceição do Araguaia",
        "Concórdia do Pará",
        "Cumaru do Norte",
        "Curionópolis",
        "Curralinho",
        "Curuá",
        "Curuçá",
        "Dom Eliseu",
        "Eldorado dos Carajás",
        "Faro",
        "Floresta do Araguaia",
        "Garrafão do Norte",
        "Goianésia do Pará",
        "Gurupá",
        "Igarapé-Açu",
        "Igarapé-Miri",
        "Inhangapi",
        "Ipixuna do Pará",
        "Irituia",
        "Itaituba",
        "Itupiranga",
        "Jacareacanga",
        "Jacundá",
        "Juruti",
        "Limoeiro do Ajuru",
        "Mãe do Rio",
        "Magalhães Barata",
        "Marabá",
        "Maracanã",
        "Marapanim",
        "Marituba",
        "Medicilândia",
        "Melgaço",
        "Mocajuba",
        "Moju",
        "Monte Alegre",
        "Muaná",
        "Nova Esperança do Piriá",
        "Nova Ipixuna",
        "Nova Timboteua",
        "Novo Progresso",
        "Novo Repartimento",
        "Óbidos",
        "Oeiras do Pará",
        "Oriximiná",
        "Ourém",
        "Ourilândia do Norte",
        "Pacajá",
        "Palestina do Pará",
        "Paragominas",
        "Parauapebas",
        "Pau d'Arco",
        "Peixe-Boi",
        "Piçarra",
        "Placas",
        "Ponta de Pedras",
        "Portel",
        "Porto de Moz",
        "Prainha",
        "Primavera",
        "Quatipuru",
        "Redenção",
        "Rio Maria",
        "Rondon do Pará",
        "Rurópolis",
        "Salinópolis",
        "Salvaterra",
        "Santa Bárbara do Pará",
        "Santa Cruz do Arari",
        "Santa Isabel do Pará",
        "Santa Luzia do Pará",
        "Santa Maria das Barreiras",
        "Santa Maria do Pará",
        "Santana do Araguaia",
        "Santarém",
        "Santarém Novo",
        "Santo Antônio do Tauá",
        "São Caetano de Odivela",
        "São Domingos do Araguaia",
        "São Domingos do Capim",
        "São Félix do Xingu",
        "São Francisco do Pará",
        "São Geraldo do Araguaia",
        "São João da Ponta",
        "São João de Pirabas",
        "São João do Araguaia",
        "São Miguel do Guamá",
        "São Sebastião da Boa Vista",
        "Sapucaia",
        "Senador José Porfírio",
        "Soure",
        "Tailândia",
        "Terra Alta",
        "Terra Santa",
        "Tomé-Açu",
        "Tracuateua",
        "Trairão",
        "Tucumã",
        "Tucuruí",
        "Ulianópolis",
        "Uruará",
        "Vigia",
        "Viseu",
        "Vitória do Xingu",
        "Xinguara"
      ]
    };

    useEffect(() => {
      if(estado === "TO"){
          const buscar = cidades.TO
          const cidd = buscar.map(c => ({cidade:c}))
          setCidades(cidd)
      };

      if(estado === "MA"){
          const buscar = cidades.MA
          const cidd = buscar.map(c => ({cidade:c}))
          setCidades(cidd)
      };

      if(estado === "PA"){
          const buscar = cidades.PA
          const cidd = buscar.map(c => ({cidade:c}))
          setCidades(cidd)
      };
  }, [estado])


   return city
  }