import React, { useState, useEffect, useContext } from 'react';
import api from '../../services/api';
import {Context} from '../../services/contexts/StateContext';
import * as S from './styles';


//----------------------------------------------------
function ModalBanco(props) {
    const {state, dispatch} = useContext(Context);
    const {cobranca, recebedor} = state.cobrancas;
    const {logado} = state.cliente;
    const {colaborador} = state.colaborador;
    const {parametroInit, playmodal} = state.parametros;
    
    const {playModalBanco, playModalCliente, playModalColab, playModalFranquia} = playmodal; 
    const {editModalBanco, detalhesBanco} = parametroInit;   
    

    const [checked, setChecked] = useState(false);
    const [reducer, setReducer] = useState(cobranca);
    const [model, setModel] = useState('');

//----------------------------------------------------    
function funccheck(){
    if(!checked){
        setChecked(true)
    }else{
        setChecked(false)
    }

    dispatch({
        type: 'set_contaBanco',
        payload:
        {
            ...cobranca, 
            id_Recebedor:recebedor.id,
            nome: recebedor.nome,
            tipoDoc:recebedor.tipoDoc,
            cpfCnpj:recebedor.cpfCnpj
            
        }
    });
};
//---------------------------------------------------
useEffect(() => {
    if(checked){
        setReducer(recebedor)
    }
}, [checked]);
//---------------------------------------------------
useEffect(() => {
    if(playModalCliente){
        setModel('cliente_testes')
    }
    if(playModalColab){
        setModel('colaboradors')
    }
}, [playModalCliente, playModalColab]);

//---------------------------------------------------
const setcobranca = (key, value) => {
    dispatch({
        type: 'set_contaBanco',
        payload: {...cobranca, [key]: value}
        })
    };   

//--------------------------Atualizar-------------------------------------------
function Atualizar(){
    if(detalhesBanco){
        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit, 
                editModalBanco:true, 
                disabledBanco:false, 
                detalhesBanco:false
            }
            }) 
    }
};
//--------------Add cliente--------------------

async function addBanco(){

    await api.post('/contabanco', {

        userCadastro:logado.idLogado,
        id_Recebedor:cobranca.id_Recebedor,
        
        nome:cobranca.nome,
        tipoDoc:cobranca.tipoDoc,
        cpfCnpj:cobranca.cpfCnpj,
        email:cobranca.email,

        onModel:model,

        cobranca:{
            nome:cobranca.nome,
            cpfCnpj:cobranca.cpfCnpj,
            tipoDoc:cobranca.tipoDoc,
            banco:cobranca.banco,
            agencia:cobranca.agencia,
            conta:cobranca.conta,
            dv:cobranca.dv,
        }
            
    }).then(res => {
        const erro = (res.data.error);
        if(erro){
            alert("Preencha os campos corretamente!");
        }else{
            alert('SUCESSO!!!!!');

            FecharModal();
        }
    })
};
//----------------------------------------------------
function FecharModal(){
    dispatch({
        type: 'set_limparbanco',
    });
    dispatch({
        type:'SET_PLAYMODAL',
        payload: {
            ...playmodal, 
            playModalBanco: false, 
        }
    });
}
//--------------------------------------------------
//###############################################################################
return (
<S.body>
    <S.ContainerBanco 
    playModalBanco={playModalBanco}
    editModalBanco={editModalBanco}
    >
        <S.div3>
            <div className={'titulo'}>
                <div className={'tituloUser'}>
                    Conta Banco
                </div>
                <div 
                className='fechar'
                onClick={()=>FecharModal()}>X
                </div>
            </div>

            {!parametroInit.detalhesBanco && <>
                <S.box>
                <span> 
                    <input 
                    type='checkbox' 
                    onChange={funccheck}
                    /> 
                    O mesmo!
                </span>
                </S.box>
            </>}

            <S.box>
                <span>Titular:</span> 
                <input 
                disabled={parametroInit.disabledBanco}
                type='text' 
                className='maiusculo'
                value={cobranca.nome}
                placeholder='Nome'  
                onChange={e=>setcobranca('nome', e.target.value)}/>
            </S.box>
            <S.box>
                <span>Fisica/Juridica:</span>
                    <select 
                    disabled={parametroInit.disabledBanco}
                    value={cobranca.tipoDoc}
                    onChange={e=>setcobranca('tipoDoc', e.target.value)} 
                    required>
                        <option></option>
                        <option value={'individual'}>Fisica</option>
                        <option value={'company'}>Juridica</option>
                    </select>
            </S.box>
            <S.box>
                <span>CPF/CNPJ:</span>
                <input 
                disabled={parametroInit.disabledBanco}
                className='maiusculo'
                value={cobranca.cpfCnpj}
                type='text' 
                placeholder='CPF/CNPJ'  
                onChange={e=>setcobranca('cpfCnpj', e.target.value)}/>
            </S.box>
            <S.box>
                <span>Banco:</span>
                <input 
                disabled={parametroInit.disabledBanco}
                className='maiusculo'
                value={cobranca.banco}
                type='text' 
                placeholder='Codigo do Banco'  
                onChange={e=>setcobranca('banco', e.target.value)}/>
            </S.box>
            <S.box>
                <span>Conta:</span>
                <input 
                disabled={parametroInit.disabledBanco}
                className='maiusculo'
                value={cobranca.conta}
                type='text' 
                placeholder='Conta'  
                onChange={e=>setcobranca('conta', e.target.value)}/>
            </S.box>
            <S.box>
                <span>Agencia:</span>
                <input 
                disabled={parametroInit.disabledBanco}
                className='maiusculo'
                value={cobranca.agencia}
                type='text' 
                placeholder='Agencia'  
                onChange={e=>setcobranca('agencia', e.target.value)}/>
            </S.box>
            <S.box>
                <span>Digito:</span>
                <input 
                disabled={parametroInit.disabledBanco}
                className='maiusculo'
                value={cobranca.dv}
                type='text' 
                placeholder='Digito'  
                onChange={e=>setcobranca('dv', e.target.value)}/>
            </S.box>

            <S.divButton>
                {!detalhesBanco && 
                <S.Salvar>
                    <button type='button' onClick={addBanco}>Salvar</button>
                </S.Salvar>
                }

                {detalhesBanco && 
                <>
                <S.Atualizar>
                    <button type='button' onClick={Atualizar}>Atualizar</button>
                </S.Atualizar>
                </>
                }
            </S.divButton> 


        </S.div3>
    
    </S.ContainerBanco>
        
</S.body>

)     
}

export default ModalBanco;