import styled from 'styled-components';

export const alerte = styled.div` 
display:flex;
justify-content: center ;
position: fixed ;
top: 200px;
left: 0px ;
right: 0px;
font-weight:600;
z-index: 2;

.alertar{
  display: flex ;
  flex-direction: column ;
  background-color: white ;
  border-radius: 2px;
  box-shadow: 0px 0px 50px black;
  justify-content: center ;
  padding: 10px ;

  label{
  display: flex ;
  color: black ;
  font-size: 13px;
  justify-content: center ;
  text-align: center ;
}

}

button{
  display: flex ;
  width: 50px ;
  border-radius: 2px;
  padding-top: 3px ;
  cursor:pointer ;
  border:none ;
  justify-content: center ;
  font-weight:700;
  background-color:#e2ecff
}
.simnao{
  display: flex ;
  width:100% ;
  justify-content: end ;
  margin-top: 7px;
  color: white ;
  font-size: 15px;

}
.nao{
  color:#f0433d ;
  &:hover{
  background-color:#f0433d;
  color: white ;
}
}
.sim{
  color:#3881ff ;
  margin-left: 10px ;
  &:hover{
  background-color:#16b992;
  color: white ;
}
}
`