import React, {useContext } from 'react';
import {Context} from '../../services/contexts/StateContext';
import * as S from './styles';

// ---------------------------------------------------
export function ListaItemBD(props){  
    const {state, dispatch} = useContext(Context);
    const {parametroInit, listaPesquisa, playmodal} = state.parametros;
    const {cliente} = state.cliente;
    const {veiculo} = state.veiculo;
    const {evento} = state.evento;

    // ------------------------------
    function Func_selectItem(item){
        if(props.referenciaRef === "veiculoPlaca"){
            dispatch({
                type:'set_veiculo',
                payload:{
                    ...veiculo, 
                    id_Veiculo: item._id,
                    placa: item.placa,
                    modelo: item.modelo,
                    nomeCliente: item.id_Cliente.nome

                }
            });
        };

        // ----
        if(props.referenciaRef === "RefFornecedor"){
            dispatch({
                type: 'set_Evento',
                payload: {
                    ...evento, 
                    id_Fornecedor: item._id,
                    fornecedor: item.nome,
                    chavePix: item.chavePix
                }
            });
        };

        // -----
        dispatch({
            type: 'set_ListaPesquisa',
            payload: {
                ...listaPesquisa, 
                nomeItem:""
            }
        });

        dispatch({
            type: 'SET_parametroInit',
            payload:{
                ...parametroInit, 
                disabled: parametroInit.detalhesModal? true : false,
            }
        });

        // -----
        if(playmodal.playModalCobranca){
            const veic = listaPesquisa.listaVeiculo.filter(p=> p.placa === item.nome);
            console.log(veic)
            veic.map(v=>{
                dispatch({
                    type: 'set_cliente',
                    payload:{
                        ...cliente, 
                        cpfCnpj: v.id_Cliente.cpfCnpj,
                        nome: v.id_Cliente.nome
                    }
                })
            })
        };

        // -----
        if(playmodal.playModalEven1){
            listaPesquisa.listaCliente.map(e=>(
                dispatch({
                    type: 'set_cliente',
                    payload:{
                        ...cliente, 
                        id_Cliente: e._id,
                        cpfCnpj: e.cpfCnpj,
                        nome: e.nome
                    }
                })
            ))
        };
    };
    // ----------------------------
    // ###############################################################
    return(
        <>
        {props.listaItem.length > 0 ?
        <S.ListaDescricao> 
            {props.listaItem.map((t, key)=>(
            <S.listaItemDesp key={key}>                        
                <span 
                style={{'display':"flex",'width':"100%","cursor":"pointer", "color":"black"}}
                onClick={()=>Func_selectItem(t)}> 
                {t.placa? t.placa : t.nome? t.nome : ""} 
                </span>
            </S.listaItemDesp>
            ))}
        </S.ListaDescricao> 
        :
        listaPesquisa.alertaNovo && playmodal.playModalEven ?
        <S.ListaDescricao>
            <S.listaItemDesp>
                <span
                style={{'display':"flex",'width':"100%","cursor":"pointer", justifyContent:"center", "color":"red"}}
                onClick={()=>props.NovoCadastro()}
                > 
                    Não Encontrado.. <br/>
                    Clique para Cadastrar 
                </span>
            </S.listaItemDesp>
        </S.ListaDescricao>
        :
        null
        }
        </>
    )
};