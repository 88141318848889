import React from 'react';
import * as S from './styles';
import { useState } from 'react';
import Cookies from 'js-cookie';
import MaskedInput from 'react-text-mask';
import api from '../../services/api';
import {Redirect} from 'react-router-dom';
import Header from '../../componentes/Header/index';
import Footer from '../../componentes/Footer/index';
import {estaLogado} from '../../services/FiltrosApi/LoginToken';


function Cadastro() {

    const [nome, editNome]=useState();
    const [cpf, editCPF]=useState();
    const [fone, editTelef]=useState();
    const [email, setEmail] = useState();
    const [senha, setSenha] = useState();
    const [confirmeSenha, setConfSenha] = useState();

    const [redirect, setRedirect]=useState(false);

    let conect = estaLogado();

    //----------------------------Add Usuario-----------------------------------------------------

    async function addUsuario(e){
        e.preventDefault(); // prevenir que o formulario não seja enviado antes de preenchido

        if(senha !== confirmeSenha){
            alert('Senha não Confirmada!')
            return;
        }
        await api.post('/usuario', { //post - Criar
            nome,
            cpf,
            fone,
            email,
            senha,            

        }).then(res => {
            const erro = (res.data.error);
            if(erro){
                if(erro.email){
                    return alert(erro.email.msg);
                }if(erro.senha){
                    return alert(erro.senha.msg);
                }else{
                    return alert("Preencha os campos corretamente!");
                }
            }else{
                Cookies.set('token', res.data.token);
                alert('SUCESSO!'); 
                window.location.href = '/login';
            }
        });
    }
    
//-------------------------------------------------------------------------------------------------
    
  return (
    <S.App>
        {redirect && <Redirect to='/' />}
        <Header></Header>
        <S.body>
        <S.div2>
                <S.div3>
                    <div>Cadastro Usuario</div>

                    <S.box>
                    <span>Nome:</span>
                    <input 
                    value={nome}
                    type='text' 
                    placeholder='Nome' 
                    onChange={e=>editNome(e.target.value)}/>
                </S.box>

                <S.box>
                    <span>CPF:</span>
                    <MaskedInput
                    type='text'
                    placeholder='cpf'
                    mask={[/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'-',/\d/, /\d/]}
                    onChange={e=>editCPF(e.target.value)}
                    />
                </S.box>

                <S.box>
                    <span>Tel. Celular:</span>
                    <MaskedInput
                    type='text'
                    placeholder='celular'
                    mask={['(', /\d/, /\d/,')', /\d/, /\d/, /\d/, /\d/, /\d/,'-',/\d/, /\d/, /\d/,/\d/]}
                    onChange={e=>editTelef(e.target.value)}
                    />
                    
                </S.box>

                <S.box>
                    <span>email:</span>
                    <input type='email' placeholder='email' value={email} 
                    onChange={e=>setEmail(e.target.value)}/>
                </S.box>

                <S.box>
                    <span>Senha:</span>
                    <input type='password' placeholder='Senha' value={senha} 
                    onChange={e=>setSenha(e.target.value)}/>
                </S.box>
                <S.box>
                    <span>Confirmar Senha:</span>
                    <input type='password' placeholder='Senha' value={confirmeSenha} 
                    onChange={e=>setConfSenha(e.target.value)}/>
                </S.box>
            </S.div3>
            <S.Salvar>
                <button type='button' onClick={addUsuario}>Salvar</button>
            </S.Salvar>
          </S.div2>
        </S.body>
        <Footer></Footer>
    </S.App>
    
)
}

export default Cadastro;