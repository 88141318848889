import React, { useState, useEffect, useContext } from 'react';
import {Context} from '../../services/contexts/StateContext';
import * as S from './styles';
import {Redirect} from 'react-router-dom';

import Header from '../../componentes/Header/index';
import Footer from '../../componentes/Footer/index';

//------------------------------------------------------------------------------
function Estoque() {
  const {state, dispatch} = useContext(Context);
  const {logado} = state.cliente;

//##################################################################################
  return (
  
  <S.App>
    { !logado.conectado && <Redirect to='/login' />}
      
    <Header 
    conectado={logado.conectado}
    />

        <S.body>

        </S.body>

      <Footer/>
    </S.App>
  );
  
}

export default (Estoque);
