import React, { useState, useEffect, useContext } from 'react';
import {Context} from '../../services/contexts/StateContext';
import * as S from './styles';
import {format} from 'date-fns';
import api from '../../services/api';
import {Redirect} from 'react-router-dom';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Header from '../../componentes/Header/index';
import Footer from '../../componentes/Footer/index';

// ------------------------------
function AddResult({match}) {
    const {state, dispatch} = useContext(Context);
    const {logado} = state.cliente;
    
    const [userLogado, setUserLog]=useState([]);
    const [nome, editNome]=useState();
    const [cpf, editCPF]=useState();
    const [fone, editTelef]=useState();
    const [email, setEmail] = useState();
    const [senha, setSenha] = useState();
    const [confirmeSenha, setConfSenha] = useState();

    const [redirect, setRedirect]=useState(false);

    const [idVeic, editidVeic]=useState();
    const [placa, editPlaca]=useState();
    const [anoMod, editAnoMod]=useState();
    const [marca, editMarca]=useState();
    const [modelo, editModelo]=useState();
    const [cor, editCor]=useState();

    const [concluido, setConcluido]=useState(false);
    const [cancelado, setCancelado]=useState(false);
    const [excluir, setExcluir]=useState(false);
    
    const [buscarVeiculo, setBuscarVeiculo]=useState([]);
    const [veiculo, editVeiculo]=useState();
    const [tipo, settipo]=useState();
    const [regional, setRegional]=useState();
    const [dataEvento, editDataEvento]=useState();
    const [dataPart, editDataPart]=useState();
    const [pedido, editPedido]=useState();
    const [valor_pedido, editValorPedido]=useState();
    const [descricao, setDescricao]=useState();

    const [ppVeiculo, setppVeic] = useState([]);
    const [resutVeic, setResultVeic] = useState();

    const [ppUsuario, setppUsua] = useState([]);
    const [resultUsuario, setResutUsuario] = useState();

    const [disabled, setDisabled] = useState(true);


    async function usuarioLogado(){
        await api.get(`/usuario/infologado/`,) //get - Buscar do banco de dados
        .then(res=>{
            setUserLog(res.data);  
        })
    };


    async function addUsuario(e){
        e.preventDefault(); // prevenir que o formulario não seja enviado antes de preenchido
        setDisabled(true);
        if(senha !== confirmeSenha){
            alert('Senha não Confirmada!')
            setDisabled(false);
            return;
        }
        await api.post('/usuario', { //post - Criar
            nome,
            cpf,
            fone,
            email,
            senha,            

        }).then(()=>alert('Salvo com 2 sucesso!'))
        setDisabled(false);
    }

    async function addVeiculo(){
        await api.post('/veiculo', { //post - Criar
            usuario:logado.idLogado,
            placa,
            anoMod,
            marca,
            modelo,
            cor,            

        }).then(()=>alert('Veiculo Sucesso!'))
    }
    

    async function addEvento(){
        if(!placa)
        return alert ('Informe a Placa!')
        else if(!pedido)
        return alert ('Informe o Pedido')

            await api.post('/evento', { //post - Criar
                veiculo:idVeic,
                tipo,
                dataEvento,
                dataPart,
                pedido,
                valor_pedido,
                descricao,
                concluido,
                cancelado,
                excluir,
            }).then(()=>alert('Evento Sucesso!'))
    
}

    async function loadDetalhes(){
        await api.get(`/evento/${match.params.id}`,) //get - Buscar do banco de dados
        .then(resposta=>{
            
            editVeiculo(resposta.data.veiculo)
            editDataEvento(format(new Date(resposta.data.dataEvento), 'yyyy-MM-dd'))
            editPedido(resposta.data.pedido)
            editValorPedido(resposta.data.valor_pedido)
            editNome(resposta.data.nome)
            editTelef(resposta.data.fone)
            editModelo(resposta.data.modelo)
            setConcluido(resposta.data.concluido)
            setCancelado(resposta.data.cancelado)
            setExcluir(resposta.data.excluir)
            
        })
    }

    
    async function filtrarVeicID(){
        await api.get(`/veiculo/${veiculo}`,) //get - Buscar do banco de dados
        .then(res=>{
            setBuscarVeiculo(res.data);  
        })
    }
    

    async function pesqUsuario() {
        await api.get(`usuario/filtrar/${resultUsuario}`)
        .then(res => {
            setppUsua(res.data);
        })
    }

    const resultUser = () =>{
        ppUsuario.map(pesq=>(
            editNome(pesq.nome),
            editCPF(pesq.cpf),
            editTelef(pesq.fone),
            setEmail(pesq.email)
       )) 
      }

    async function pesqVeiculo(){
        await api.get(`veiculo/filtrar/placa/${resutVeic}`)
        .then(res => {
          setppVeic(res.data);
        })
      }

      const resultVeic = () =>{
        ppVeiculo.map(pesq=>(
            editidVeic(pesq._id),
            editPlaca(pesq.placa),
            editAnoMod(pesq.anoMod),
            editMarca(pesq.marca),
            editModelo(pesq.modelo),
            editCor(pesq.cor)
       ))  
      }

    useEffect(()=>{
        if(!logado.conectado)
        setRedirect(true);
        loadDetalhes();
        filtrarVeicID();
        usuarioLogado();
    },[logado.conectado]);

    useEffect(()=>{
        resultVeic();
        resultUser();
    
    },[pesqVeiculo]);
    
    

    async function Remover(){
        const resp = window.confirm('Deseja mesmo Remover?')
        if(resp == true){
            alert('ok, Removido!!')
            await api.delete(`/evento/${match.params.id}`,) // delete - excluir definitivo
            .then(() => setRedirect(true));
        } 
    }

    const valorMask = createNumberMask({ //formatando numero valores
        prefix:'R$',
        includeThousandsSeparator:true,
        thousandsSeparatorSymbol:'.',
        allowDecimal:true,
        decimalSymbol:',',
    });

    // console.log(ppVeiculo);

    // console.log(buscarVeiculo);
    // console.log(veiculo);



  return (
    <S.App>
        {redirect && <Redirect to='/' />}
        <Header></Header>
        <S.body>

            <span>DADOS CADASTRO</span>
            <S.div2>
            <S.div3>
                    <S.box>
                        <span>Nome:</span>
                        <input 
                        type='text' 
                        placeholder='Nome' 
                        value={nome} 
                        disabled={disabled}
                        onChange={e=>editNome(e.target.value)}/>
                    </S.box>

                    <S.box>
                        <span>CPF:</span>
                        <input type='text' placeholder='CPF' value={cpf} disabled={disabled}
                        onChange={e=>editCPF(e.target.value)}/>
                    </S.box>

                    <S.box>
                        <span>Tel. Celular:</span>
                        <input type='text' placeholder='Telefone' value={fone} disabled={disabled}
                        onChange={e=>editTelef(e.target.value)}/>
                    </S.box>

                    <S.box>
                        <span>email:</span>
                        <input type='email' placeholder='email' value={email} disabled={disabled}
                        onChange={e=>setEmail(e.target.value)}/>
                    </S.box>

                    <S.box>
                        <span>Senha:</span>
                        <input type='password' placeholder='Senha' value={senha} disabled={disabled}
                        onChange={e=>setSenha(e.target.value)}/>
                    </S.box>

                </S.div3>

            <S.div3>
                    <S.box>
                        <span>Nome:</span>
                        <input 
                        type='text' 
                        placeholder='Nome' 
                        value={nome} 
                        disabled={disabled}
                        onChange={e=>editNome(e.target.value)}/>
                    </S.box>

                    <S.box>
                        <span>CPF:</span>
                        <input type='text' placeholder='CPF' value={cpf} disabled={disabled}
                        onChange={e=>editCPF(e.target.value)}/>
                    </S.box>

                    <S.box>
                        <span>Tel. Celular:</span>
                        <input type='text' placeholder='Telefone' value={fone} disabled={disabled}
                        onChange={e=>editTelef(e.target.value)}/>
                    </S.box>

                    <S.box>
                        <span>email:</span>
                        <input type='email' placeholder='email' value={email} disabled={disabled}
                        onChange={e=>setEmail(e.target.value)}/>
                    </S.box>

                    <S.box>
                        <span>Senha:</span>
                        <input type='password' placeholder='Senha' value={senha} disabled={disabled}
                        onChange={e=>setSenha(e.target.value)}/>
                    </S.box>

                </S.div3>

                <S.div3>
                    <S.box>
                        <span>Nome:</span>
                        <input 
                        type='text' 
                        placeholder='Nome' 
                        value={nome} 
                        disabled={disabled}
                        onChange={e=>editNome(e.target.value)}/>
                    </S.box>

                    <S.box>
                        <span>CPF:</span>
                        <input type='text' placeholder='CPF' value={cpf} disabled={disabled}
                        onChange={e=>editCPF(e.target.value)}/>
                    </S.box>

                    <S.box>
                        <span>Tel. Celular:</span>
                        <input type='text' placeholder='Telefone' value={fone} disabled={disabled}
                        onChange={e=>editTelef(e.target.value)}/>
                    </S.box>

                    <S.box>
                        <span>email:</span>
                        <input type='email' placeholder='email' value={email} disabled={disabled}
                        onChange={e=>setEmail(e.target.value)}/>
                    </S.box>

                    <S.box>
                        <span>Senha:</span>
                        <input type='password' placeholder='Senha' value={senha} disabled={disabled}
                        onChange={e=>setSenha(e.target.value)}/>
                    </S.box>

                    

                </S.div3>

            </S.div2>

                <hr/>  {/* Linha */}
                    <span>
                        <S.Salvar>
                            <button type='button' disabled={disabled} onClick={addUsuario}>Salvar</button>
                        </S.Salvar>
                    </span>
        </S.body>
        <Footer></Footer>
    </S.App>
    
)
}

export default AddResult;