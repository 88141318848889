
export const franquiaInicialState = {
    franquia:{
        id:"",
        nome:"",
        tipoDoc:"",
        cpfCnpj:"",
        dataNascimento:"",
        sexo:"",
        email:"",
        senha:"",
        confirmeSenha:"",

        userCadastro:"",
        id_Recebedor_Pagarme:"",

        buscarCliente:false,
        listaClente:[],
    },
    foneCelular:{
        codigoArea:"",
        numeroCel:"",
    },
    endereco:{
        cep: "",
        estado: "",
        cidade: "",
        rua:"",
        numeroCasa: "",
        bairro:"",
        complemento:"",
    },
}
//-----------------------------------------------------
export const franquiaReducer = (state = franquiaInicialState, action)=>{

    switch(action.type){
        
        case 'SET_Franquia':
            return {...state, franquia: action.payload};
            
        case 'set_enderFranquia':
            return {...state, endereco: action.payload};

            case 'set_foneFranquia':
        return {...state, foneCelular: action.payload};
                
        case 'set_limparFranquia':
            return franquiaInicialState;   

            default:
            return state;
            
    }
};
//-----------------------------------------------------
