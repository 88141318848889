import React, { useState, useEffect, useContext} from 'react';
import {Context} from '../../services/contexts/StateContext';
import * as S from '../Modal/styles';
import LoadingContent from '../loading/loading';
import Axios from 'axios';
//----------------------------------------------------
export function Geolocalizacao(props) {
    const {state, dispatch} = useContext(Context);
    const {parametroInit, playmodal} = state.parametros;
    const {loading} = parametroInit;

    const [location, setLocation] = useState({
        latitude: null,
        longitude: null,
        error: null,
    });
    const [address, setAddress] = useState('');

    useEffect(() => {
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(position => {
                const {latitude, longitude} = position.coords;
                setLocation({
                    latitude: latitude,
                    longitude: longitude,
                    error: null,
                });
                GetAddress(latitude, longitude);
            },
            (error)=>{
                setLocation({
                    latitude: null,
                    longitude: null,
                    error: error.message,
                });
            }
        );
        } else {
            setLocation({
                latitude: null,
                longitude: null,
                error: 'Geolocalização não suportada no seu navegador',
            });
        }   
    }, []);

    // ----------------
    async function GetAddress(lat, lng){
        try{
            const response = await Axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.API_KEY_GOOGLE_MAPS}`);
            if(response.data.results[0]){
                setAddress(response.data.results[0].formatted_address);
            }else{
                setAddress('Endereço não encontrado.');
            }
        }catch(error){
            console.log('Erro ao buscar endereço', error)
        };
    };

    // -----------------
    function OpenGoogleMaps(){
        if(location.latitude && location.longitude){
            const url = `https://www.google.com/maps?q=${location.latitude}, ${location.longitude}`;
            window.open(url, '_blank');
        } else{
            alert('Não foi possível obter sua localização.');
        }
    };

    // -----------------

useEffect(() => {
    dispatch({
        type: 'SET_parametroInit',
        payload:{...parametroInit, loading:true}
    });
    if(location.latitude && location.latitude){
        dispatch({
            type: 'SET_parametroInit',
            payload:{...parametroInit, loading:false}
        });
    }
}, [location.latitude, location.longitude]);

// #####################
return (
    <div style={{display:"flex"}}>
    {loading? 
        <S.bodyresult>
            <LoadingContent/>
        </S.bodyresult>
        :
        <div style={{display:"flex", flexDirection:"column"}}>
            {location.error ? 
                <span style={{color:"red"}}>Erro ao obter localização: {location.error}</span>
                :
                <div style={{color:"white"}}>
                    <p>Latitude: {location.latitude}</p>
                    <p>Longitude: {location.longitude}</p>
                    <p>Endereço: {address}</p>
                </div>
            }
            <button onClick={OpenGoogleMaps}>Abrir no Google Maps</button>
        </div>
    }
    </div>
)
}

