import React, {useEffect, useState} from 'react';
import {format} from 'date-fns';
import * as S from './styles';


function BoxFiltro(props) {
  
  const [dataForm, setDataForm] = useState()
    
  useEffect(() => {
      if(props.dataEvento){
        setDataForm(format(new Date(props.dataEvento), 'dd/MM/yyyy'))
      } if(props.dataPag){
        setDataForm(format(new Date(props.dataPag), 'dd/MM/yyyy'))
      }
      
  },[]);
//############################################################################# 
  return (
    
      <S.Container concluido={props.concluido}>
{/*---------------------------------------------------------------------------------- */}
        {props.playUser && <> 
          <S.box> <input type='checkbox'/> </S.box>
          {/* <Link to={`/evento/${props.id}`}> */}
            <S.boxF> {props.nome} </S.boxF>

            <S.boxF> {props.email} </S.boxF>
          {/* </Link> */}
        </>
        }
{/*---------------------------------------------------------------------------------- */}
        {props.playEven && <> 
          <S.box>
            <input type='checkbox'/>
          </S.box>
          {/* <Link to={`/evento/${props.id}`}> */}
            <S.boxF>
              {props.nome}
            </S.boxF>
              
            <S.boxF>
              {props.placa}
            </S.boxF>

            <S.boxF>
              {props.tipo}
            </S.boxF>

            <S.boxF>
              {dataForm}
            </S.boxF>

            <S.boxF>
              {props.TotalEven}
            </S.boxF>
                        
            <S.boxF>
              {props.TotalEven}
            </S.boxF>
          {/* </Link> */}
        </>
        }
{/*---------------------------------------------------------------------------------- */}
{props.playPag && <> 
          <S.box> 
            <input type='checkbox'/> 
          </S.box>
          {/* <Link to={`/evento/${props.id}`}> */}
            <S.boxF> 
              {props.nome} 
            </S.boxF>

            <S.boxF> 
              {props.placa} 
            </S.boxF>

            <S.boxF> 
              {dataForm} 
            </S.boxF>

            <S.boxF> 
              {props.formaPagamento} 
            </S.boxF>

            <S.boxF> 
              {'R$ ' + props.valor_pago} 
            </S.boxF>
            
          {/* </Link> */}
        </>
        }
{/*---------------------------------------------------------------------------------- */}
      </S.Container>
      
     )
}

export default BoxFiltro;