import React, { useState, useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';

import {deslogar} from '../../services/FiltrosApi/LoginToken';

import {Context} from '../../services/contexts/StateContext';

import * as S from './styles';
import logo from '../../midia/maisAP.svg';
import iconeUser from '../../midia/user_padrao.png';
import notif from '../../midia/bell.svg';

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

function Header(props) {
  const {state, dispatch} = useContext(Context);  
  const [pendente, setPendente] = useState('');
  const [sair, setSair] = useState(false);
  const {playmodal, displayModal} =  state.parametros;
  const {logado} =  state.cliente


//------------------------------------------------------------------

  // async function VerifyPendte(){
  //   await api.get(`/evento/pendente`) // Buscar pendente por Regional
  //   .then(resposta => {
  //     if(resposta.data.length===0){
  //       setPendente(false)
  //     }else{
  //     setPendente(resposta.data.length);
  //   }})
  // }


  // useEffect(() => {
  //   VerifyPendte();

  // },[])

  //-----------------------------
  function iconConta(){
    sair===false ? setSair(true) : setSair(false);
  }
  // ---------------------------
  useEffect(() => {
    setSair(false)
  }, [displayModal])
  //-----------------------------
  function Logout(){
    deslogar();
    window.location.reload();
  }
  //-----------------------------
  function NewSenha(){
    dispatch({
      type: 'SET_PLAYMODAL',
      payload:{...playmodal, playModalUsuario:true}
  });
  }
//---------------------------------------------------------------------------------
  return (

    <S.Header>
        {props.playLogo&&
        <S.Icone>
          <Link to='/'><img src={logo}/></Link>
        </S.Icone>
        }

      <S.Header2>
        {logado.conectado && 
        <>
        {/* <div style={{'display':"flex"}}>
          <Link to='/'>Home</Link>
        </div> */}

        {pendente &&    
        <button onClick={props.clickNotf} id='notification'>
          <img src={notif} alt='Notificação'/>
            <span>{pendente}</span> 
        </button>
        }

        <S.Conta>
          <div>
              <img src={iconeUser} onClick={iconConta}/>
                <span>{logado.nome}</span>
          </div>
          
          {sair &&
          <S.iconConta>
            {/* <button type='button' onClick={NewSenha}>
              <span>Senha</span>
            </button> */}
            <button type='button' onClick={Logout} id='sair'>
              <span>Sair</span>
            </button>
          </S.iconConta>
          }
        </S.Conta>
        </>}
      </S.Header2>
    </S.Header>
)}
export default Header;