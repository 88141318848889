import React, { useState, useEffect} from 'react';
import api from '../api';
import Cookies from 'js-cookie';
//--------------------------------------
export function UsuarioLog(){
    const [nomeLogado, setUserLog]=useState();
    const [cpfLogado, setCPF]=useState('');
    const [idLogado, setidLogado]=useState('');
    const [nivelLogado, setnivelUser]=useState('');

    const token = Cookies.get('token');

    //--------------------------------------
    async function UsuarioLogado(){
        if(token){
            await api.get(`/usuario/infologado/${token}`)
            .then(res=>{
                const dd = res.data;
                dd.map(e=>{
                    setUserLog(e.nome); 
                    setCPF(e.cpf);
                    setidLogado(e._id);
                    setnivelUser(e.nivel);
                    // setUserCadastro(e._id);
                    // setNivelUserLogado(e.nivel)
                    //console.log(res)
                })
            })
        };
    };

    useEffect(()=>{
        UsuarioLogado();
    },[token]);

    return {idLogado, nomeLogado, nivelLogado, cpfLogado};
};
