import styled from 'styled-components';

export const bodyresult = styled.div`
display: flex ;
position:relative ;
margin-top:5px ;
max-width: 500px ;
font-size: clamp(10px, 3vw, 12px);

input{
  width:90% ;
}
button{
  display: flex ;
  background-color: white ;
  align-items: center ;
  color: black ;
  height: 18px ;
  margin: 2px 0;
  border: none ;
  border-radius: 3px ;
  cursor: pointer;

  &:hover{
    background-color:#00ff9d;
    }
}
`
export const detalhes = styled.div`
display:flex;
background-color: black;
width:100% ;
color:black;
border-radius:2px;
margin:2px 0;
padding:2px;

.listaDetalhe{
  display: flex;
  gap: 10px ;
  width:100% ;
}
.lista{
  display: flex ;
  flex:1 ;
  flex-direction: column ;
  text-transform:capitalize;
  color: white ;

  input{
    @media (max-width: 450px) {
      font-size: clamp(10px, 3vw, 12px);
    }
  }

}
.titulo{
  color: #00D594;
}

span{
  display: flex ;
}

img{
  width:17px;
  height:17px;
  cursor: pointer;
}

.icone{
  display:flex;
  align-self:center;
  margin: 0 3px;
  cursor: pointer;
}
`

export const ListaPag = styled.div` 
display:flex;
flex-direction: column ;
align-items: end ;
margin-bottom: 5px ;
border-top: 1px solid #3881ff ;

.listaPg{
  display: flex ;
  width: 100% ;
  background-color:#e2ecff;
  color: black ;
  margin: 0.5px 0 ;
  padding: 1px 0 ;

  span{
    display: flex ;
    flex:1 ;
  }
}

.botaoTotal{
  margin-left: 3px ;
  cursor: pointer;

  &:hover{
    color: white ;
    font-weight:500 ;
  }
}

span{
  display: flex ;
}

input{
  width:60px ;
  border:none;
  background:none;
  outline:0;
  color: white ;
}

`
// -----------------------------
export const botao = styled.div` 
display:flex;
`
export const abrir = styled.div`
display:flex ;

`
//##########################################################

