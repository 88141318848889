import styled from 'styled-components';
import { breakpoints } from '../../breakpoints';

export const divInfor = styled.div` 
display:flex;
flex-direction:column;
width:100% ;
max-width:500px ;
font-size: clamp(10px, 3vw, 12px);

span{
color: white;
}

input{
width:100%;
border:none;
background:none;
color: white;
margin-top:5px;
margin-bottom:5px;
color: #00D594;
outline:0;
padding-left: 5px ;

::placeholder{
  text-transform:lowercase ;
}
}

.abrirModal{
  display:flex;
  height:17px;
  margin: 3px 0;
  align-items: center ;
  justify-content: center ;
  border-radius:1px;
  padding: 2px 5px;
  cursor:pointer ;
  background-color: blue ;
}

.selectData{
  display:flex;
  flex-direction: column ;
  
  input{
    background-color:#30353f ;
    border-radius:5px ;
    height: 20px ;
    margin-top:0 ;

  }
}
`

// ---------------------
export const boxInfor = styled.div`
display: flex;
flex-direction:column;


`
export const ddd = styled.div`
display: flex;
flex-direction:row;
background-color: #30353f ;

input{
  text-transform:uppercase ;
}

`
export const Pesq = styled.div`
display:flex;
justify-content:center;
align-items: center ;

button{
  background:none;
  border:none;
  outline:0;
  &:hover{
    background:none;
}}
img{
  width:17px;
  height:17px;
  cursor: pointer;

  &:hover{
    opacity: 0.5;
  }}

`


export const result = styled.div`
display:flex;
background-color: ${props =>
    props.eventoLista === 'E' ? '#ff8787' : props.eventoLista === 'I' ? '#ffecaa' : '#efefef'
  }; 
align-items: center ;
justify-content: center ;
color:black;
border-radius:2px;
margin:5px 0;
padding:2px 0; ;

.checkbox{
  display:flex;
  align-self:center;
}
span{
  display:flex;
  flex:1;
  justify-content:space-between;
  align-items:center;
}

.editar{
  display:flex;
  align-self:center;
  width:17px;
  height:17px;
  margin: 0 3px;
  cursor: pointer;
}

`
export const botao = styled.div` 
display:flex;
`
export const checked = styled.div` 
display:flex;
align-items: start ;
margin:0 ;

span{
  display: flex ;
  white-space:nowrap ;
  font-weight:620 ;
}

`
// -----------------------------------
export const divListaBD = styled.div`
display: flex ;
z-index: 1;

`

