import React from 'react';
import * as S from './styles';


function Footer() {

  return (
      <S.Footer>
        <div>Desenvolvido por </div> 
        <span 
        style={{'color':"#00ff9d",'cursor':'pointer', marginLeft:"5px"}}
        onClick={()=> window.location.href = 'https://wa.me/qr/J6BX3LNBZACLH1'} 
        >
          RBento 
        </span> 
        <span style={{fontSize:"15px"}}>®</span>
     </S.Footer>
     
)
}

export default Footer;