
export const cobrancaInicialState = {
    
    cobranca:{
        id_ContaBanco:"",
        id_Recebedor:"",
        nome:"",
        tipoDoc:"",
        cpfCnpj:"",
        banco:"",
        conta:"",
        agencia:"",
        dv:"",

        vencimento:"",
        valorParcela:"",
        N_carnes:"", //ate 24 carnes
        total:""
    },
    recebedor:{
        id:"",
        nome:"",
        tipoDoc:"",
        cpfCnpj:"",
        email:"",        
    },

}
//-----------------------------------------------------
export const cobrancaReducer = (state = cobrancaInicialState, action)=>{

    switch(action.type){
            
        case 'set_cobranca':
            return {...state, cobranca: action.payload};  
        case 'set_recebedor':
            return {...state, recebedor: action.payload};  
             
        case 'set_limparCobranca':
            return cobrancaInicialState;

            default:
                return state;
            
    }
};
//-----------------------------------------------------
