import React from 'react';
import * as S from './styles';
//---------COMPONENTES----------------------
import Header from '../../componentes/Header/index';
import Footer from '../../componentes/Footer/index';

import {CameraWeb} from '../../componentes/Cadastro/CameraWeb';

// ------------------------------------------
export default function Vistoria() {
   
//###################################################################################################
  return (
    <S.App>
        <Header playLogo={true}/>
        <S.body>
            <S.body2>
                {/* -------------VISTORIA----------------- */}
                <S.ContainerLista>
                    <S.VistoriaModal>
                        <div style={{display:"flex", overflow:"auto", flexDirection:"column"}}>
                            {/* <Geolocalizacao/> */}
                            <CameraWeb/>
                        </div>
                    </S.VistoriaModal>
                </S.ContainerLista>
            </S.body2>
        </S.body>

        <Footer/>
    </S.App>
    )
}