import React, { createContext, useReducer} from 'react';

import {logadoInicialState, logadoReducer} from '../reducers/logadoReducer';
import {clienteInicialState, clienteReducer} from '../reducers/clienteReducer';
import {agendaInicialState, agendaReducer} from '../reducers/agendaReducer';
import {colabInicialState, colabReducer} from '../reducers/colaboradorReducer';
import {franquiaInicialState, franquiaReducer} from '../reducers/franquiaReducer';
import {veiculoInicialState, veiculoReducer} from '../reducers/veiculosReducer';
import {parametrosInicialState, parametrosReducer} from '../reducers/parametrosReducer';
import {cobrancaInicialState, cobrancaReducer} from '../reducers/CobrancaReducer';
import {tipo_planoInicialState, tipoPlanoReducer} from '../reducers/tipoVeic_PlanoReducer';
import {EventoInicialState, EventoReducer} from '../reducers/EventoReducer';

import {sorteioInicial, sorteioReducer} from '../reducers/sorteioReducer';
import {produtoInicial, produtosReducer} from '../reducers/produtoReducer';

//-----------------------------------------------------

const inicialState = {
    logado: logadoInicialState,
    cliente: clienteInicialState,
    colaborador: colabInicialState,
    veiculo:veiculoInicialState,
    franquia: franquiaInicialState,
    agenda: agendaInicialState,
    parametros: parametrosInicialState,
    cobrancas: cobrancaInicialState,
    tipoPlano: tipo_planoInicialState,
    evento: EventoInicialState,

    sorteiomix: sorteioInicial,
    produtos: produtoInicial
}

//-----------------------------------------------------
export const Context = createContext({
    state: inicialState,
    dispatch: () => {}
});
//-----------------------------------------------------

const principalReducer = (state, action) => ({
    
    logado: logadoReducer(state.logado, action),
    cliente: clienteReducer(state.cliente, action),
    agenda: agendaReducer(state.agenda, action),
    colaborador: colabReducer(state.colaborador, action),
    veiculo: veiculoReducer(state.veiculo, action),
    franquia: franquiaReducer(state.franquia, action),
    parametros: parametrosReducer(state.parametros, action),
    cobrancas: cobrancaReducer(state.cobrancas, action),
    tipoPlano: tipoPlanoReducer(state.tipoPlano, action),
    evento: EventoReducer(state.evento, action),

    sorteiomix: sorteioReducer(state.sorteiomix, action), 
    produtos: produtosReducer(state.produtos, action),

});
// type UserContextProps = {
//     children: ReactNode;
// };
//-----------------------------------------------------
export const ContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(principalReducer, inicialState);

    return(
        <Context.Provider value={{state, dispatch}}>
            {children}
        </Context.Provider>
    );
}
