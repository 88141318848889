import React, { useContext } from 'react';
import * as S from './styles';
import {Context} from '../../services/contexts/StateContext';
import {Link} from 'react-router-dom';

import {Functions} from '../../componentes/Functions';

function BarraLateral(props) {
  const {state, dispatch} = useContext(Context);
  const {parametroInit, playLista, playmodal, displayModal} = state.parametros;
  const {logado} = state.cliente;
  const {clienteDisplay} = parametroInit;

  const {Limpar_Modal} = Functions();

//--------------cliente---------
function FormCliente(){
  dispatch({type: 'InicialPlayModal'});

    if(!clienteDisplay){
      dispatch({
        type: 'SET_Display',
        payload: {
          ...displayModal,
          clienteDisplay:true, 
          veiculosDisplay:false, 
          eventoDisplay:false,
          colaboradorDisplay:false,
          usuarioDisplay:false,
          CobrancaDisplay:false,
          contaDisplay:false,
        }
      })
      
    };

    Limpar_Modal();
};

  //--------------Veiculo------------
  function formVeiculo(){
    dispatch({type: 'InicialPlayModal'});

    if(!displayModal.veiculosDisplay){
        dispatch({
          type: 'SET_Display',
          payload: {
            ...displayModal, 
            veiculosDisplay: true, 
            clienteDisplay: false, 
            eventoDisplay:false,
            colaboradorDisplay:false,
            CobrancaDisplay:false,
            usuarioDisplay:false,
            contaDisplay:false,
          }
        });
        dispatch({
          type: 'set_playLista',
          payload: {...playLista, playListaVeic: false, playListaEven:false}
        });
    };

    Limpar_Modal();
  };
  //--------------Evento------------
  function DisplayEnto(){
    dispatch({type: 'InicialPlayModal'});

    if(!displayModal.eventoDisplay){
      dispatch({
        type: 'SET_Display',
        payload: {
          ...displayModal, 
          eventoDisplay: true,
          clienteDisplay: false, 
          veiculosDisplay:false,
          usuarioDisplay:false,
          CobrancaDisplay:false,
          contaDisplay:false,
          vistoriaDisplay:false
        }
      })
    };

    Limpar_Modal();
    
  };
  //--------------Orçamento------------
  function formOracamento(){
    if(!displayModal.orcamentoDisplay){
      dispatch({
        type: 'SET_Display',
        payload: {
          ...displayModal, 
          orcamentoDisplay: true,
          clienteDisplay: false, 
          veiculosDisplay:false,
          colaboradorDisplay:false,
          eventoDisplay:false,
          CobrancaDisplay:false,
          contaDisplay:false,
        }
      });

    }else{
      dispatch({
        type: 'SET_Display',
        payload: {...displayModal, orcamentoDisplay: false}
      })
    };

    Limpar_Modal();
    
  };
  //-------Pagamentos---------------
  function playModalpag(){
    if(!displayModal.pagamentoDisplay){
      dispatch({
        type: 'SET_Display',
        payload: {
          ...displayModal, 
          pagamentoDisplay: true, 
          clienteDisplay: false, 
          veiculosDisplay:false,
          eventoDisplay:false,
          CobrancaDisplay:false,
          contaDisplay:false,
        }
      })
    }else{
      dispatch({
        type: 'SET_Display',
        payload: {...displayModal, pagamentoDisplay: false}
      })
    }
  };
  //----Usuario-----------------
  function DisplayUsuario(){
    dispatch({type: 'InicialPlayModal'});

    if(!displayModal.usuarioDisplay){
      dispatch({
        type: 'SET_Display',
        payload: {
          ...displayModal, 
          usuarioDisplay: true,
          clienteDisplay: false, 
          veiculosDisplay:false,
          eventoDisplay:false,
          CobrancaDisplay:false,
          contaDisplay:false,
          vistoriaDisplay:false
        }
      })
    };

    Limpar_Modal();
  };
  //----Vistoria-----------------
  function DisplayVistoria(){
    dispatch({type: 'InicialPlayModal'});

    if(!displayModal.vistoriaDisplay){
      dispatch({
        type: 'SET_Display',
        payload: {
          ...displayModal, 
          vistoriaDisplay: true,
          usuarioDisplay: false,
          clienteDisplay: false, 
          veiculosDisplay:false,
          eventoDisplay:false,
          CobrancaDisplay:false,
          contaDisplay:false,
        }
      })
    };

    Limpar_Modal();
  };

  //----Teste-----------------
  function DisplayTestes(){
    dispatch({type: 'InicialPlayModal'});

    if(!displayModal.testeDisplay){
      dispatch({
        type: 'SET_Display',
        payload: {
          ...displayModal, 
          testeDisplay:true,
          vistoriaDisplay: false,
          usuarioDisplay: false,
          clienteDisplay: false, 
          veiculosDisplay:false,
          eventoDisplay:false,
          CobrancaDisplay:false,
          contaDisplay:false,
          
        }
      })
    };

    Limpar_Modal();
  };
  
  //----Conta-----------------
  function DisplayConta(){
    dispatch({type: 'InicialPlayModal'});

    if(!displayModal.contaDisplay){
      dispatch({
        type: 'SET_Display',
        payload: {
          ...displayModal, 
          contaDisplay: true,
          usuarioDisplay: false,
          clienteDisplay: false, 
          veiculosDisplay:false,
          eventoDisplay:false,
          CobrancaDisplay:false,
        }
      })
    };

    Limpar_Modal();
  };

  //-------------Cobranças------------
  function formBoleto(){
    dispatch({type: 'InicialPlayModal'});

    dispatch({
      type: 'SET_Display',
      payload: {
        ...displayModal, 
        usuarioDisplay: false,
        clienteDisplay: false, 
        veiculosDisplay:false,
        eventoDisplay:false,
        contaDisplay:false,
      }
    })

    Limpar_Modal();
  };
  //--------------Servicos---------
  function playModalServicos(){
    props.limparPesq();
    if(!props.ModalServ){
      props.setModalServ(true);
    }else{
      props.setModalServ(false)
    }
  };
  //--------------Representante-----
  function playModalFranquia(){
    if(!displayModal.franquiaDisplay){
      dispatch({
        type: 'SET_Display',
        payload: {...displayModal, franquiaDisplay: true}
      })
    }else{
      dispatch({
        type: 'SET_Display',
        payload: {...displayModal, franquiaDisplay: false}
      })
    }
  };
  //--------------Colaborador---------
  function playModalColaborador(){
    dispatch({
    type: 'SET_Display',
    payload: {...displayModal, colaboradorDisplay: true}
    });


    dispatch({
      type: 'SET_PLAYMODAL',
      payload: {...playmodal, playModalColab: true}
    })
  };
//#################################################
  return (
    <S.Container>
      <S.Box2>
        {/* <S.boxBut>
          <Link to='/'>
            <button onClick={FormCliente}>Associado</button>
          </Link>
        </S.boxBut> */}

        {/* <S.boxBut>
          <button onClick={formVeiculo}>Veiculo</button>
        </S.boxBut> */}

        <S.boxBut Display={displayModal.eventoDisplay}>
          <Link to='/'>
            <button onClick={DisplayEnto}>Evento</button>
          </Link>
        </S.boxBut>

        {/* <S.boxBut>
          <Link to='/cobranca'> 
            <button onClick={formBoleto}>Boleto</button>
          </Link>
        </S.boxBut> */}

        {/* <S.boxBut>
          <button onClick={playModalFranquia}>Representante</button>
        </S.boxBut> */}
        
        {logado.nivel === "master" &&
        <>
        <S.boxBut Display={displayModal.usuarioDisplay}>
          <Link to='/'>
            <button onClick={DisplayUsuario}>Usuarios</button>
          </Link>
        </S.boxBut>

        <S.boxBut Display={displayModal.vistoriaDisplay}>
          <Link to='/vistoria'>
            <button onClick={DisplayVistoria}>Vistoria</button>
          </Link>
        </S.boxBut>

        <S.boxBut Display={displayModal.testeDisplay}>
          <Link to='/teste'>
            <button onClick={DisplayTestes}>Testes</button>
          </Link>
        </S.boxBut>
        </>
        }

        {/* <S.boxBut Display={displayModal.contaDisplay}>
          <Link to='/'>
            <button onClick={DisplayConta}>Conta</button>
          </Link>
          <div>
            <button>Alterar Senha</button>
          </div>
        </S.boxBut> */}
        
        
        {/* <S.boxBut>
          <button onClick={formOracamento}>Orçamento</button>
        </S.boxBut> */}

        {/* <S.boxBut>
          <button onClick={playModalpag}>Pagamentos</button>
        </S.boxBut> */}

        

        {/* <S.boxBut>
          <button onClick={playModalColaborador}>Colaborador</button>
        </S.boxBut> */}

        {/* <S.boxBut>
          <button onClick={playModalServicos}>Servicos</button>
        </S.boxBut> */}
      </S.Box2>
    </S.Container>
     
)
  }
export default BarraLateral;