import React, { useState, useEffect, useContext } from 'react';
import {Context} from '../contexts/StateContext';
import api from '../api';

//-----------------------------------------------------------
export function Api_Produtos(){
    const {state, dispatch} = useContext(Context);
    const {listaPesquisa} = state.parametros;
    const {inforProduto, inforCaixa, inforSaldoCredito} = state.produtos;
    
    const {evento, pagamento} = state.evento;
    const {dataFim, dataInicio, tipoOperacao, fornecedor} = inforProduto;

    const [buscaListaEntradas, setListaEntradas] = useState();
    const [buscaListaProd,setbuscaProd] = useState([]);
    const [buscaRelatorio, setRelatorio] = useState([]);
    const [buscaTodosCreditos, setListaCreditos] = useState([]);
    const [buscaListaSaidas, setListaSaidas] = useState([]);
    const [listaFornecedor, setFornecedor] = useState([]);
    const [buscaTagData, setTagData] = useState([]);    
    const [buscaTodosCaixa, setListaCaixa] = useState([]);

    //-----------------------------------------
    async function PesqCaixaTodos(){
        useEffect(() => {
            async function pesquisar(){
                await api.get(`foodbar/caixa/todos`) 
                .then(res => {
                    const buscar = res.data;
                    setListaCaixa(buscar);
                });
            };
            pesquisar();
        }, [inforCaixa.codigoAtualiza])
    
        return {buscaTodosCaixa}
    };
    //-----------------------------------------
    async function PesqCreditoTodos(){
        useEffect(() => {
            async function pesquisar(){
                await api.get(`foodbar/saldo/todos`) 
                .then(res => {
                    const buscar = res.data;
                    setListaCreditos(buscar);
    
                    dispatch({
                        type: 'set_saldoCredito',
                        payload: {
                            ...inforSaldoCredito, 
                            pesqSaldoCred:false
                        }
                    });
                });
            };
            pesquisar();
        }, [inforSaldoCredito.pesqSaldoCred])
    
        return {buscaTodosCreditos}
    };
    //----------------------------------------
    async function PesqProdutosTodos(){
        useEffect(() => {
            async function pesquisar(){
                await api.get(`foodbar/filtrarProdutos/todos`) 
                .then(res => {
                    const buscar = res.data;
                    setbuscaProd(buscar);
                    
                });

            };
            pesquisar();
        }, [inforProduto.pesqProduto, inforProduto.fornecedor])

        return {buscaListaProd}
    };
    //----------------------------------------
    async function PesqEntradasTodos(){
        useEffect(() => {
            async function pesquisar(){
                await api.get(`foodbar/entradas/todos`) 
                .then(res => {
                    const buscar = res.data;
                    setListaEntradas(buscar);
                    
                });

            };
            pesquisar();
        }, [inforProduto.fornecedor])

        return {buscaListaEntradas}
    };
    //----------------------------------------
    async function PesqSaidaTodos(){
        useEffect(() => {
            async function pesquisar(){
                await api.get(`foodbar/saidas/todos`) 
                .then(res => {
                    const buscar = res.data;
                    setListaSaidas(buscar);
                    
                });

            };
            pesquisar();
        }, [inforProduto.pesqProduto, inforProduto.fornecedor])

        return {buscaListaSaidas}
    };
    //-----------------------------------------
    async function PesqFornecedorTodos(){
        useEffect(() => {
            async function pesquisar(){
                await api.get(`foodbar/fornecedor/todos`) 
                .then(res => {
                    const buscar = res.data;
                    setFornecedor(buscar);
                    
                });

            };
            pesquisar();
        }, [inforProduto.fornecedor, inforProduto.novoFornecedor, inforSaldoCredito.fonteCredito, inforSaldoCredito.pesqSaldoCred])

        return {listaFornecedor}
    };
    //----------------------------------------
    async function PesquisarTipoTodos(){
        await api.get(`foodbar/ItemDespesa/todos`) 
        .then(res => {
            const buscar = res.data;
            let xx = [];
            buscar.map(e=>{
                xx.push(e.tipo)
            });
            const DespLS = localStorage.getItem(evento.placa);
            
            dispatch({
                type: 'set_ListaPesquisa',
                payload: {
                    ...listaPesquisa,
                    listaItemLS: DespLS? JSON.parse(DespLS) : [],
                    listaTipo:xx.length > 0 ? xx : []
                }
            })            
        });
    };
    //----------------------------------------
    async function PesquisarItemTipo(){
        await api.get(`foodbar/tipoitem/${pagamento.tipo}`) 
        .then(res => {
            const buscar = res.data;
            dispatch({
                type: 'set_ListaPesquisa',
                payload:{
                    ...listaPesquisa, 
                    listaTipoItem:buscar,
                    selectItem:'',
                    nomeItem:''
                }
            });   
        });
    };
    //----------------------------------------
    async function PesqRelatorio(){
        useEffect(() => {
            if(tipoOperacao === "Compra"){
                async function pesquisar(){
                    await api.get(`foodbar/entrada/filtrarRelatorio/?fornecedor=${fornecedor}&dataIninio=${dataInicio}&dataFim=${dataFim}`) 
                    .then(res => {                
                        const buscar = res.data;
                        setRelatorio(buscar);
                    });
                };
                pesquisar();
            }else{setRelatorio([])};
                //----------
                if(tipoOperacao === "Venda" && dataInicio && dataFim){

                    async function pesquisar(){
                        await api.get(`foodbar/saida/filtrarRelatorio/?dataIninio=${dataInicio}&dataFim=${dataFim}`) 
                        .then(res => {                
                            const buscar = res.data;
                            setRelatorio(buscar);
                        });
                    };
                    pesquisar();
                }else{setRelatorio([])};
                
                
            }, [inforProduto.fornecedor, tipoOperacao, dataInicio, dataFim])

        return {buscaRelatorio}
    };
    //----------------------------------------
    async function PesqTagData(){
        useEffect(() => {     
            if(inforProduto.PesqData === 'hoje' && inforProduto.tipoOperacao){    
                async function pesquisar(){
                    await api.get(`foodbar/datahoje/?tipo=${inforProduto.tipoOperacao}`) 
                    .then(res => {                
                        const buscar = res.data;
                        if(buscar.length > 0){
                            setTagData(buscar);
                        }
                    });
                };
                pesquisar();
            }
            if(inforProduto.PesqData === 'ontem'){    
                async function pesquisar(){
                    await api.get(`foodbar/dataOntem/?tipo=${inforProduto.tipoOperacao}`) 
                    .then(res => {                
                        const buscar = res.data;
                        if(buscar.length > 0){
                            setTagData(buscar);
                        }
                    });
                };
                pesquisar();
            }
            if(inforProduto.PesqData === 'semanaAtual'){    
                async function pesquisar(){
                    await api.get(`foodbar/destaSemana/?tipo=${inforProduto.tipoOperacao}`) 
                    .then(res => {                
                        const buscar = res.data;
                        if(buscar.length > 0){
                            setTagData(buscar);
                        }
                    });
                };
                pesquisar();
            }
            if(inforProduto.PesqData === 'mesAtual'){   
                async function pesquisar(){
                    await api.get(`foodbar/desteMes/?tipo=${inforProduto.tipoOperacao}`) 
                    .then(res => {                
                        const buscar = res.data;
                        if(buscar.length > 0){
                            setTagData(buscar);
                        }
                    });
                };
                pesquisar();
            }
            if(inforProduto.PesqData === 'mesPassado'){    
                async function pesquisar(){
                    await api.get(`foodbar/mesPassado/?tipo=${inforProduto.tipoOperacao}`) 
                    .then(res => {                
                        const buscar = res.data;
                        if(buscar.length > 0){
                            setTagData(buscar);
                        }
                    });
                }
                pesquisar();
            }else{
                setTagData([])
            }
        }, [inforProduto.PesqData, inforProduto.tipoOperacao])

    return {buscaTagData}
};


return{PesqCaixaTodos, PesqTagData, PesqRelatorio, PesqCreditoTodos, PesquisarTipoTodos,
    PesqEntradasTodos, PesqProdutosTodos, PesqSaidaTodos, PesqFornecedorTodos, PesquisarItemTipo}
};

// 
// 




