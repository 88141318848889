
export const sorteioInicial = {
    sorteiomix:{  
        premioUm:'',
        premioDois:'',
        premioTres:'',

        nomePremioUm:'',
        nomePremioDois:'',
        nomePremioTres:'',

        numBolaUm:'',
        numBolaDois:'',
        numBolaTres:'',
    },

    logadoSorteio:{
        id_Cliente:'',
        id_Franquia:'',
        nomeFranquia:'',
        email:'lagobeer_aux@gmail.com',
        nome:'',
        cpf:'',
        celular:'',
        celularLogado:'',
        perfil:'',
        listaCliente:[],
        pesqLogado:false,

        access_token:'',
        refresh_token:'',
        public_key:'',
        dataExpira_AccToken:'',

    },

    inforConcurso:{
        id_Concurso:'',
        codigo:'',
        titulo:'',
        descricao:'',
        produtos:'',
        preco:'',
        status:'',
        dataSorteio:'',
        dataPremio:'',
        quantSorteios:'',
        numPremiado:{
            premioUm:'',
            premioDois:'',
            premioTres:'',
        },
        inforConcAtual:[],
        listaConcurso:[],
        pesqConsurso:false,
    },

    inforAposta:{
        id_Aposta:'',
        numSelect:[],
        numSlctPend:[],
        valorTotal:'',
        txid_Pix:'',
        statusAposta:'',
        statusPix:'',
        listaAposta:[],
        dataCadPendente:'',
        nomeEmpresa:'',
        dataPixGerado:'',
        imagemQrcode:'',
        qrcode:'',
        valorPix:'',
        

    },
    
}
//-----------------------------------------------------
export const sorteioReducer = (state = sorteioInicial, action)=>{

    switch(action.type){
            
        case 'set_sorteiomix':
            return {...state, sorteiomix: action.payload};   

        case 'set_limparsorteiomix':
            return sorteioInicial;

        case 'set_inforConcurso':
            return {...state, inforConcurso: action.payload};  

        case 'set_logadoSorteio':
            return {...state, logadoSorteio: action.payload};

        case 'set_inforAposta':
            return {...state, inforAposta: action.payload};

        case 'set_todasAposta':
            return {...state, todasAposta: action.payload};

            default:
                return state;
            
    }
};
//-----------------------------------------------------
