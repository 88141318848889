
import styled from 'styled-components';

export const body = styled.div`
display: flex ;
margin-top: 5px ;
font-size:13px;

textarea, select, input{
  color: white ;
}

`
//--------------------------------------------
export const ContainerVeic = styled.div`
display:${props=>props.playModalVeiculo ? 'flex' : 'none'};
width:100% ;
select{
  background-color:#3881ff;
  color: ${props=>props.editModalVeic ? '#ffecaa' : 'white'};
}
label, span{
  color: ${props=>props.editModalVeic ? '#ffecaa' : '#00D594'};
}

`

//-----------------------------------------------
export const Container = styled.div`
display: ${props=>props.playModalCliente ? 'flex' : 'none'};

`
//--------------------------------------------


export const div3 = styled.div` 
display:flex;
flex-direction: column;
align-items: center;
padding:10px;
color: #00D594;
background-color:#262626;
border-radius:3px;
width:100% ;


.spanBranco{
  margin:0px ;
  color: white ;
}
.titulo{
  display: flex ;
  width: 100% ;

}
.tituloUser{
  display: flex ;
  flex: 1;
  align-items: center ;
  justify-content: center ;
}

.fechar{
  display:flex;
  flex:0 ;
  align-items:center;
  color:#f0433d;
  border-radius:3px;
  width:20px;
  height:20px;
  font-size:12px;
  margin-right: 10px ;
  padding:0px 5px;
  cursor: pointer;
  font-weight:bold;

  &:hover{
    color:white;
    background-color: #f0433d;
}}
.novoCad{
  color: black ;
  background-color: white ;
  padding: 1px 3px ;
  cursor: pointer;
}
input{
border:none;
background:none;
outline:0;

}
.maiusculo{
  text-transform:capitalize
}
.uppercase{
  text-transform:uppercase
}
select{
  display:flex;
  border-radius:3px;
  border:none;
  outline:0;
  margin: 5px 5px 5px 0 ;
}

textarea{
  width:100%;
  margin: 5px 0px;
  background:none;
  border:1px solid #3881ff;
  border-radius:5px;
  outline:0;
}
`

// --------------------------------------------------------

export const box = styled.div` 
display:flex;
width:100%;
margin:5px;
border-bottom:1px solid #3881ff;


div{
  display: flex ;
  flex:1 ;
  flex-direction:column ;
  margin: 0 5px ;
}
span{
  display: flex ;
}
input{
  width:100% ;
}
.spa{
  color:white ;
}
.terceiroCheckbox{
  width: 30px ;
}

`
// --------------------------------------------------------

export const lista = styled.div` 
flex-direction: column ;

button{
  display: flex ;
  background-color: white ;
  align-items: center ;
  color: black ;
  width: 140px ;
  height: 20px ;
  margin: 2px 0;
  border: none ;
  cursor: pointer;
}
`
// --------------------------------------------------------
export const Salvar = styled.div`
display:flex;
height:20px;
margin:3px;
justify-content:center;

button{
  background-color:${props=>props.detalhesModal ? '#ffbf00':'#00D594'};
  font-size: 11px;
  min-width: 80px;
  border:none;
  border-radius:3px;
  color:${props=>props.editModal ? 'black':'white'};
  cursor: pointer;

&:hover{
  background-color:#5656F7;
  color:white;
}
}
`
// --------------------------------------------------------
export const Atualizar = styled.div`
display:flex;
height:20px;
margin:3px;
justify-content:center;

button{
  background-color:#ffbf00;
  font-size: 11px;
  min-width: 80px;
  border:none;
  border-radius:3px;
  color:black;
  cursor: pointer;

&:hover{
  background-color:#5656F7;
  color:white;
}
}
`
//-----------------------------
export const divButton = styled.div` 
display:flex;
margin: 10px 0 ;
`
// -------------------------------
