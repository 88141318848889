import React, { useState, useEffect, useContext } from 'react';
import api from '../../services/api';
import {Context} from '../../services/contexts/StateContext';
import MaskedInput from 'react-text-mask';
import * as S from './styles';

import {AlertComp} from '../alerte/index'

import {ListaItemBD} from '../ListarPesquisas/listaItemBD';
import {PesquisaPadrao} from '../../componentes/Pesquisas/PesquisaPadrao';


import {ListaFranquia} from '../../services/FiltrosApi/franquiaApi';
import {ColabFranquia} from '../../services/FiltrosApi/colaboradorApi';
import estados from '../../services/FiltrosApi/estados.json';
import {CidadesApi} from '../../services/FiltrosApi/cidadesApi';
import {Api_Veiculos} from '../../services/FiltrosApi/veiculos_api';

import {SetFunctions, Functions} from '../../componentes/Functions';


//----------------------------------------------------
function ModalCliente(props) {
    const {state, dispatch} = useContext(Context);
    const {cliente, endereco, foneCelular, logado} = state.cliente;
    const {parametroInit, playmodal,listaPesquisa,playLista} = state.parametros;
    const {franquia} = state.franquia;

    const {
        detalhesModal, 
        disabled,
    } = parametroInit;
    
    const estado = endereco.estado;

    const {SetCliente} = SetFunctions();
    const {EditarSelect} = Functions();

    const listaFranquia = ListaFranquia();
    const listaColaborador = ColabFranquia();
    const ListaCidades = CidadesApi(estado);
    const {PesqVeiculoPlaca} = Api_Veiculos();

    const [aletBanco, setAletBanco] = useState(false);
    
    //---------------------------------------------------
    useEffect(() => {
        dispatch({
            type: 'SET_Franquia',
            payload: {...franquia, id: cliente.id_Franquia}
            });
    }, [cliente.id_Franquia])
    //---------------------------------------------------
    const setEndereco = (key, value) => {
        dispatch({
            type: 'set_endereco',
            payload: {...endereco, [key]: value}
            });
    };

    // ------------------------------------
    const setFone = (key, value) => {
        dispatch({
            type: 'set_fone',
            payload: {...foneCelular, [key]: value}
            });
    };

//-------------------------------------------
    function Atualizar(){
        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit, 
                disabled:false,
                editModal:true, 
            }
        }); 
    };
// -----------------------------------------------
    async function addcliente(){
        //-------Editar-------
        if(cliente.id_Cliente){
            await api.put(`/cliente/atualizar/${cliente.id_Cliente}`, { 
                id_Franquia:cliente.id_Franquia,
                id_Colaborador:cliente.id_Colaborador,
                
                status:cliente.status,
                nome:cliente.nome,
                tipoDoc:cliente.tipoDoc,
                cpfCnpj:cliente.cpfCnpj,
                dataNascimento:cliente.dataNascimento,
                sexo:cliente.sexo,
                email:cliente.email,
                senha:cliente.senha,
                confirmeSenha:cliente.confirmeSenha,

                foneCelular:{
                    codigoArea: foneCelular.codigoArea,
                    numeroCel: foneCelular.numeroCel,
                },
                endereco:{
                    cep: endereco.cep,
                    estado: endereco.estado,
                    cidade: endereco.cidade,
                    numeroCasa: endereco.numeroCasa,
                    rua:endereco.rua,
                    bairro: endereco.bairro,
                    complemento:endereco.complemento,
                },   
                data_modificado:Date.now(),
                        
            }).then(res => {
                if(res.data.error){
                    alert('erro')
                } else{
                    alert('Associado Atualizado!');
                    // setAletBanco(true);
                    props.pesqidcliente();

                    dispatch({
                        type: 'SET_parametroInit',
                        payload:{
                            ...parametroInit, 
                            editModal:true,
                            disabled:true,
                        }
                    });
                }
            })
        }else{
            if(cliente.senha !== cliente.confirmeSenha){
                alert('Senha não Confirmada!')
                return;
            };
            //--------NOVO--------------
            await api.post('/cliente/add', {
                userCadastro:logado.idLogado,
                status:cliente.status,
                id_Franquia:cliente.id_Franquia,
                id_Colaborador:cliente.id_Colaborador,
                
                nome:cliente.nome,
                tipoDoc:cliente.tipoDoc,
                cpfCnpj:cliente.cpfCnpj,
                dataNascimento:cliente.dataNascimento,
                sexo:cliente.sexo,
                email:cliente.email,
                senha:cliente.senha,
                confirmeSenha:cliente.confirmeSenha,

                foneCelular:{
                    codigoArea: foneCelular.codigoArea,
                    numeroCel: foneCelular.numeroCel,
                },
                endereco:{
                    cep: endereco.cep,
                    estado: endereco.estado,
                    cidade: endereco.cidade,
                    numeroCasa: endereco.numeroCasa,
                    rua:endereco.rua,
                    bairro: endereco.bairro,
                    complemento:endereco.complemento,
                }
            }).then(res => {
                const erro = (res.data.error);
                if(erro){
                    alert("Preencha os campos corretamente!");
                }else{
                    alert('SUCESSO!!!!!');
                    props.FecharModal();
                }
            })
        }
    };

    //------------------------------
    function buscarBanco(){
        if(!playLista.playListaBanco){
            dispatch({
                type: 'SET_parametroInit',
                payload: {
                    ...parametroInit, 
                    bcarBanco:true,
                    buscarVeic: false, 
                    editModalBanco:false,
                }
            });
            dispatch({
                type: 'set_playLista',
                payload: {...playLista, playListaBanco:true, playListaVeic:false}
            });
            dispatch({
                type: 'SET_PLAYMODAL',
                payload: {
                    ...playmodal, 
                    playModalVeiculo:false, 
                    playModalBanco:false
                }
            });
            dispatch({
                type: 'set_ListaPesquisa',
                payload:{...listaPesquisa, listaVeiculo:[]}
            });
                
        }else{
            dispatch({
                type: 'SET_PLAYMODAL',
                payload: {
                    ...playmodal, 
                    playModalVeiculo:false, 
                    playModalBanco:false,
                }
            });
        }
    };

    //----Retirando Pontos do cpf------------------
    const [cpfFormat, setcpfFormat]=useState('');

    useEffect(() => {
    let value = cpfFormat;
    if(cpfFormat){
        let rr = value.replace(/\D/g,''); //.replace(/\D/gim, '')
        SetCliente('cpfCnpj', rr)
        }
    }, [cpfFormat])
    // ----------------------

    useEffect(() => {
        setcpfFormat(cliente.cpfCnpj)
    }, [cliente.cpfCnpj])

//###############################################################################
  return (
    <S.body 
    detalhesLista={parametroInit.detalhesLista}
    editModal={parametroInit.editModal}
    >
        <S.div3>
            <div className='titulo'>
                <div className={'tituloUser'}>
                    <span>{playmodal.playModalCliente? "CLIENTE" : "Gerar Cobrança" } </span>
                </div>
                <div 
                className='fechar'
                onClick={()=>props.FecharModal()}>X
                </div>
            </div>
            {/* -------------------------------------- */}
            {playmodal.playModalCobranca &&
            <div>
                {!listaPesquisa.selectItem?
                <div style={{display:"flex", 'flexDirection':"column", margin:"0 5px", position:"relative"}}>
                    <PesquisaPadrao
                    Lupa={true}
                    titulo="Buscar Placa:"
                    placeholder="placa"
                    nomeBotao="Novo Veiculo"
                    playModal_Add={props.playModal_Add}
                    pesquisar={PesqVeiculoPlaca}
                    playBotao={false}
                    />

                    {listaPesquisa.listaVeiculo.length > 0 && 
                    <ListaItemBD
                    listaItem={listaPesquisa.listaVeiculo}
                    />
                    } 
                </div>
                :
                listaPesquisa.selectItem &&
                <div
                style={{display:"flex", 'flexDirection':"column", margin:"0 5px"}} 
                onClick={()=>EditarSelect()}
                >
                    <span>PLACA:</span>
                    <div className='intemSelect'>
                        {listaPesquisa.selectItem}
                    </div>
                </div>
                }
            </div>
            }
            {/* ----------------------------------------- */}

            <S.box1>
                {cliente.cpfCnpj.length <= 11?
                <div>
                    <span>CPF:</span>
                    <MaskedInput
                    disabled={playmodal.playModalCobranca} 
                    value={cpfFormat}
                    type='text'
                    placeholder='cpf/cnpj'
                    mask={[/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'-',/\d/, /\d/,/\d/]}
                    onChange={e=>setcpfFormat(e.target.value)}
                    guide={false}
                    />
                </div>
                :
                <div>
                    <span>CNPJ:</span>
                    <MaskedInput
                    disabled={playmodal.playModalCobranca} 
                    value={cpfFormat}
                    type='text'
                    placeholder='cpf/cnpj'
                    mask={[/\d/, /\d/,'.',/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'/',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/]}
                    onChange={e=>setcpfFormat(e.target.value)}
                    guide={false}
                    />
                </div>
                }
                {parametroInit.detalhesModal &&
                <div style={{flex:"0.3"}}>
                    <span>Status:</span>
                    <select
                    disabled={disabled}
                    value={cliente.status}
                    onChange={e=>SetCliente('status', e.target.value)} 
                    required>
                        <option value=''/>
                        <option value={'A'}>Ativo</option>
                        <option value={'I'}>Inativo</option>
                        <option value={'E'}>Excluido</option>
                    </select>
                </div>
                }
            </S.box1>

            <S.box1>
                <div>
                    <span>Nome:</span>
                    <input
                    disabled={playmodal.playModalCobranca} 
                    className='maiusculo'
                    value={cliente.nome}
                    type='text' 
                    placeholder='Nome' 
                    onChange={e=>SetCliente('nome', e.target.value)}/>
                </div>
                
                <div style={{flex:"0.5"}}>
                    <span>Data Nascimento:</span>
                    <input
                    disabled={playmodal.playModalCobranca} 
                    className='maiusculo'
                    value={cliente.dataNascimento}
                    type="date" 
                    placeholder='Data Nascimento' 
                    onChange={e=>SetCliente('dataNascimento', e.target.value)}/>
                </div>
            </S.box1>

            {/* <S.box1>
                <div>
                    <span>Fisica/Juridica:</span>
                    <select
                    disabled={disabled}
                    value={cliente.tipoDoc}
                    onChange={e=>SetCliente('tipoDoc', e.target.value)} 
                    required>
                        <option></option>
                        <option value={'individual'}>Fisica</option>
                        <option value={'company '}>Juridica</option>
                    </select>
                </div>
            </S.box1> */}

            {playmodal.playModalCliente &&
            <>
            <S.box1>
                <div>
                    <label htmlFor='ff'>Estado:</label>
                        <select
                        disabled={disabled}
                        value={endereco.estado}
                        onChange={e=>setEndereco('estado', e.target.value)} 
                        required>
                            <option value=''/>
                            { estados.map((s, key) => 
                            <option key={key} value={s.UF}>{s.label}</option>
                            )}
                    </select>
                </div>
                <div>
                    <label htmlFor='ff'>Cidade:</label>
                        <select
                        disabled={disabled}
                        value={endereco.cidade}
                        onChange={e=>setEndereco('cidade', e.target.value)} 
                        required>
                            <option value=''/>
                            { ListaCidades.map((s, key) => 
                            <option key={key} value={s.cidade}>{s.cidade}</option>
                            )}
                    </select>
                </div>
            </S.box1>
            <S.box1>
                <div>
                    <span>CEP:</span>
                    <input
                    disabled={disabled} 
                    value={endereco.cep}
                    className='maiusculo'
                    type='text' 
                    placeholder='cep' 
                    onChange={e=>setEndereco('cep', e.target.value)}/>
                </div>
                <div style={{flex:2}}>
                    <span>Rua:</span>
                    <input
                    disabled={disabled} 
                    className='maiusculo'
                    value={endereco.rua}
                    type='text' 
                    placeholder='Rua' 
                    onChange={e=>setEndereco('rua', e.target.value)}/>
                </div>
                
            </S.box1>
            <S.box1>
                <div style={{flex:3}}>
                    <span>Bairro:</span>
                    <input
                    disabled={disabled} 
                    className='maiusculo'
                    value={endereco.bairro}
                    type='text' 
                    placeholder='Bairro' 
                    onChange={e=>setEndereco('bairro', e.target.value)}/>
                </div>
                <div>
                    <span>Numero:</span>
                    <input
                    disabled={disabled} 
                    className='maiusculo'
                    value={endereco.numeroCasa}
                    type='text' 
                    placeholder='N°' 
                    onChange={e=>setEndereco('numeroCasa', e.target.value)}/>
                </div>
            </S.box1>

            <S.box>
                <span>Complemento:</span>
                <input
                disabled={disabled} 
                className='maiusculo'
                value={endereco.complemento?endereco.complemento:''}
                type='text' 
                placeholder='Complemento' 
                onChange={e=>setEndereco('complemento', e.target.value)}/>
            </S.box>

            <S.box1>
                <div>
                    <span>Cel. ddd:</span>
                    <MaskedInput
                    disabled={disabled}
                    value={foneCelular.codigoArea}
                    type='text'
                    placeholder='ddd'
                    mask={['(', /\d/, /\d/,')']}
                    onChange={e=>setFone('codigoArea', e.target.value.replace(/\D/g,''))}
                    guide={false}
                    />
                </div>
                <div>
                    <span>Numero Celular:</span>
                    <MaskedInput
                    disabled={disabled}
                    value={foneCelular.numeroCel}
                    type='text'
                    placeholder='numero'
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/,'-',/\d/, /\d/, /\d/,/\d/]}
                    onChange={e=>setFone('numeroCel', e.target.value.replace(/\D/g,''))}
                    guide={false}
                    />
                </div>
            </S.box1>
            
            <S.box>
                <span>email:</span>
                <input
                disabled={disabled} 
                value={cliente.email}
                type='email' 
                placeholder='email'  
                onChange={e=>SetCliente('email', e.target.value)}/>
            </S.box>
            <S.box1>
                <div>
                    <span>Representante:</span>
                    <select
                    disabled={disabled}
                    value={cliente.id_Franquia}
                    onChange={e=>SetCliente('id_Franquia', e.target.value)} 
                    required>
                        <option value=''/>
                        { listaFranquia.map((s, key) =>
                        <option key={key} value={s.value}>{s.label}</option>
                        )}
                    </select>
                </div>
                <div>
                    <span>Colaborador:</span>
                    <select
                    disabled={disabled}
                    value={cliente.id_Colaborador}
                    onChange={e=> SetCliente("id_Colaborador", e.target.value)}
                    required>
                            
                    <option value='' />
                    { listaColaborador.map((s, key) =>
                    <option key={key} value={s.value}>{s.label}</option> //JSON.stringify(s)
                    )}
                    </select>
                </div>
            </S.box1>
            {!detalhesModal &&
            <S.box1>
                <div>
                    <span>Senha:</span>
                    <input
                    disabled={disabled} 
                    type='password' 
                    placeholder='Senha' 
                    onChange={e=>SetCliente('senha', e.target.value)}/>
                </div>
                <div>
                    <span>Confirmar Senha:</span>
                    <input
                    disabled={disabled} 
                    type='password' 
                    placeholder='Senha' 
                    onChange={e=>SetCliente('confirmeSenha', e.target.value)}/>
                </div>
            </S.box1>
            }

            <S.divButton editModal={parametroInit.editModal}>
                {!cliente.id_Cliente?
                <S.Salvar>
                    <button type='button' onClick={addcliente}>
                        Salvar
                    </button>
                </S.Salvar>
                :
                cliente.id_Cliente && parametroInit.editModal ?
                    <S.Salvar>
                        <button type='button' onClick={addcliente}>
                            Salvar
                        </button>
                    </S.Salvar>
                :
                <S.Atualizar>
                    <button 
                    type='button' 
                    style={{backgroundColor:"#ffbf00"}} 
                    onClick={Atualizar}>
                        Atualizar
                    </button>
                </S.Atualizar>
                }

                {/* {detalhesModal && 
                <>
                    <S.Salvar>
                        <button 
                        type='button' 
                        style={{backgroundColor:"#3881ff"}} 
                        onClick={buscarVeiculos}>Veiculos</button>
                    </S.Salvar>
                    <S.Salvar>
                        <button 
                        type='button' 
                        style={{backgroundColor:"#ef8e3e"}} 
                        onClick={buscarBanco}>Eventos</button>
                    </S.Salvar>
                    <S.Salvar>
                        <button 
                        type='button' 
                        style={{backgroundColor:"#587aa2"}} 
                        onClick={buscarBanco}>Banco</button>
                    </S.Salvar>
                    
                </>
                } */}
            </S.divButton>
            </>}

            {aletBanco && <>
            <AlertComp
            alertDescrit={'Adicionar Conta Banco?'}
            seSim={buscarBanco}
            seNao={props.FecharModal}
            />
            </>}
            
        </S.div3>
    </S.body>
  );
}

export default ModalCliente;
