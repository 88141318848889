
export const EventoInicialState = {
    evento:{
        id_Evento:"",
        placa:"",
        nomeCliente:"",
        tipo:"",
        tipoEvento:"",
        dataEvento: "",
        ocorrenciaBO:"",
        descricao: "",

        codEvento: "",
        codEvenBD:"",

        origem:"",
        destino:"",
        orcamento:"",
        
        id_Fornecedor:"",
        fornecedor:"",
        cnpjForne:"",
        chavePix:"",
        
        dataPart: "",
        valorPart: "",
        detalhePart: "",
        
        dataPag: "",
        valorPag: "",
        formaPag: "",
        
        status:"",
        anexoBO:"",
        anexoCNH: "",

        arquivos:[]
    },
    terceiro:{
        tipo:"",
        placa:"",
        chassi:"",
        anoMod:"", 
        montadora:"", 
        modelo:"", 
        cor:"", 
        valorFipe:"",
        anexoCNH:"",
        dataParticipacao:""
    },

    pagamento:{
        data:"",
        tipo:"",
        novoTipo:"",
        nomeItem:"",
        novoItem:"",
        valorItem:"",
        selectItem:"", 
        codigoAtualiza:"",

    },
}
//-----------------------------------------------------
export const EventoReducer = (state = EventoInicialState, action)=>{

    switch(action.type){
            
        case 'set_Evento':
            return {...state, evento: action.payload};
            
            case 'set_Terceiro':
                return {...state, terceiro: action.payload};

            case 'set_Pagamento':
                return {...state, pagamento: action.payload};
             
        case 'set_limparEvento':
            return EventoInicialState;

            default:
                return state;
            
    }
};
//-----------------------------------------------------
