import React, { useState, useEffect, useContext } from 'react';
import * as S from './styles';
import {Redirect} from 'react-router-dom';
import {Context} from '../../services/contexts/StateContext';
import api from '../../services/api';
//---------COMPONENTES----------------------
import Header from '../../componentes/Header/index';
import Footer from '../../componentes/Footer/index';
import BarraLateral from '../../componentes/BarraLateral/index';

import Modalcliente from '../../componentes/Modal/ModalCliente';
import ModalVeiculo from '../../componentes/Modal/ModalVeiculo';
import ModalEvento from '../../componentes/Modal/ModalEvento';
import ModalBanco from '../../componentes/Modal/ModalBanco';
import ModalOrcamento from '../../componentes/Modal/ModalOrcamento';
import {ModalUsuario} from '../../componentes/Modal/ModalUsuario';
import {ModalPadrao} from '../../componentes/Modal/ModalPadrao';
import ModalPDF from '../../componentes/Modal/ModalPDF';

import ListaClientes from '../../componentes/ListarPesquisas/ListaCliente';
import {ListaVeiculos} from '../../componentes/ListarPesquisas/ListaVeiculo';
import {ListaEventos} from '../../componentes/ListarPesquisas/ListaEvento';
import {ListaUsuario} from '../../componentes/ListarPesquisas/ListaUsuario';
import {ListaBanco} from '../../componentes/ListarPesquisas/ListaBanco';

import {AddPagamento} from '../../componentes/Cadastro/addPagamento';

import { DetalheEvento } from '../../componentes/Detalhes/detalheEvento';

import PesquisaOrcanento from '../../componentes/Pesquisas/pesqOrcamento';
import {PesquisaPadrao} from '../../componentes/Pesquisas/PesquisaPadrao';

import LoadingContent from '../../componentes/loading/loading';
import {Functions, FunctModal} from '../../componentes/Functions';


//---------SERVICES--------------------------
import {Api_Clientes} from '../../services/FiltrosApi/clienteApi';
import {Api_Veiculos} from '../../services/FiltrosApi/veiculos_api';
import {ApiEvento} from '../../services/FiltrosApi/eventoApi';
import {estaLogado} from '../../services/FiltrosApi/LoginToken';
import {UsuarioLog} from '../../services/FiltrosApi/logado';

// ------------------------------------------
function Add() {
    const {state, dispatch} = useContext(Context);
    const {cliente, usuario} = state.cliente;
    const {veiculo} = state.veiculo;
    const {evento} = state.evento;
 
    const {parametroInit, displayModal, playmodal, listaPesquisa, playLista} = state.parametros;
    const {
        pesquisaIdVeiculo ,
        pesquisaIdEvento,
        pesquisaIdUsuario,
        loading, 
    } = parametroInit;

    const {id_Usuario} = usuario;
    const {id_Veiculo} = veiculo;
    const {id_Evento, codEvenBD} = evento;
    const {listaCliente, listaUsuario, listaEvento} = listaPesquisa;
    
    const [idPag, setIdPag]=useState('');
    const [concluido, setConcluido]=useState('');
    const [codEvento, setCodEvento]=useState('');
    const [valor_pago, setvalor_pago] = useState('');
    const [somaValorPago, setSomaValorPago]=useState('')
    const [valorPendente, setValorPend] = useState('');
    const [checkEven, setcheckEven] = useState(false);
    const [checkPag, setcheckPag] = useState(false);
    const [newValor_produtos, editValorProdutos]=useState('');
    const [newValor_servico, editValorServicos]=useState('');
    const [TotalEven, setTotalEven]=useState('');
    const [filtroPag, setFiltroPag] = useState('');
    const [listaPag, setListaPag] = useState([]);

    const[ModalFormClie, setModalFormClie] = useState(false);
    const[ModalFormVeic, setModalFormVeic] = useState(false);
    const[ModalEve, setModalEve] = useState(false);
    const[ModalPag, setModalPag] = useState(false);
    const[ModalUser, setModalUser] = useState(false);
    const[ModalFranq, setModalFranq] = useState(false);
    const[ModalColab, setModalColab] = useState(false);
    const[ModalServ, setModalServ] = useState(false);

    const[alertDelete, setAletDelete]=useState(false);
    const[newStatus, setNewStatus]=useState('');
    const[refDelete, setRefDelet]=useState('');

    let conectado = estaLogado();
    const {idLogado} = UsuarioLog();

    const {Play_Modal_Add, EditarSelect, ExcluirEste} = Functions();
    const {FecharModal} = FunctModal();
    
    const {PesqVeiculoPlaca, PesqVeiXcliente, pesqIDveiculo} = Api_Veiculos();
    const {BuscaTodosClientes, BuscaTodosUsuarios, pesqidcliente} = Api_Clientes();
    const {PesqEventoCod, PesqIdEvento, PesqEventoPlaca, BuscaTodosEventos} = ApiEvento();

    //--------------Pesquisar cliente por CPF-------
    async function pesqclienteCpf(cpf) {
        dispatch({
            type: 'set_limparcliente'
        });
        
        dispatch({
            type: 'set_veiculo',
            payload:{...veiculo, id:''}
        });
        dispatch({
            type: 'set_playLista',
            payload:{...playLista, playListaClie:false, playListaVeic:false}
        });
        dispatch({
            type: 'SET_PLAYMODAL',
            payload:{
                ...playmodal, 
                playModalCliente:false, 
                playModalVeiculo:false,
                playModalBanco:false
            }
        });
        
        if(cpf){
            dispatch({
                type: 'SET_parametroInit',
                payload:{...parametroInit, loading:true}
            });
            
            await api.get(`cliente/filtrar/${cpf}`)
            .then(res => {
                const buscar = res.data;
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaCliente:buscar}
                });
                dispatch({
                    type: 'SET_parametroInit',
                    payload:{
                        ...parametroInit, 
                        loading:false, 
                        bcarBanco: false, 
                        buscarVeic: false,
                    }
                });
                dispatch({
                    type: 'set_playLista',
                    payload:{playListaClie:true}
                });
            })
        }else{
            BuscaTodosClientes();
        }
    };

    //-----------------------------------------------
    useEffect(() => {
        if(pesquisaIdVeiculo){
            pesqIDveiculo();
        }
    }, [pesquisaIdVeiculo])
    

    // -------------------
    useEffect(() => {
        if(pesquisaIdEvento){
            PesqIdEvento(evento.id_Evento);
        }
    }, [pesquisaIdEvento])

    // -------------------
    useEffect(() => {
        if(pesquisaIdUsuario){
            pesqIdUsuario();
        }
    }, [pesquisaIdUsuario])



    //--Pesquisar Usuario por id----------------------------
    async function pesqIdUsuario(){
        if(id_Usuario){
            await api.get(`/usuario/id/${id_Usuario}`)
            .then(res => {
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaUsuario:[res.data]}
                });
                dispatch({
                    type: 'set_playLista',
                    payload:{...playLista, playListaUsuario:true}
                });
                dispatch({
                    type: 'SET_parametroInit',
                    payload:{...parametroInit, pesquisaIdUsuario:false}
                });

            })
        }
    };
    
    //----Pesquisar Evento por Veiculo---------------
    async function pesqEventoXveiculo(){
        if(id_Veiculo){
            await api.get(`/evento/idveiculo/${id_Veiculo}`)
            .then(res => {
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaEvento:res.data}
                });
            })
        }
    };

    //---Pesquisar Usuario por Nome----------------------------
    async function PesqNomeCpfUsuario(nome){
        if(nome){
            dispatch({
                type: 'SET_parametroInit',
                payload:{...parametroInit, loading:true}
            });

            await api.get(`/usuario/${nome}`)
            .then(res => {
                dispatch({
                    type: 'SET_parametroInit',
                    payload:{...parametroInit, loading:false}
                });
                dispatch({
                    type: 'set_playLista',
                    payload:{...playLista, playListaUsuario:true}
                });
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaUsuario:res.data}
                });
            })
        } else{
            BuscaTodosUsuarios();
        };

        dispatch({
            type: 'SET_PLAYMODAL',
            payload:{...playmodal, playModalUsuario:false}
        });
    };

    //---Pesquisar Pag pelo codPagmt----------------------------
    async function pesqPagCod(){
        dispatch({
            type: 'set_cliente',
            payload:{...cliente, id:''}
        });
        dispatch({
                type: 'set_veiculo',
                payload:{...veiculo, 'id':''}
            });
        setIdPag();
        
        if(filtroPag){
            await api.get(`paguevento/codPag/${filtroPag}`)
            .then(res => {
                setListaPag(res.data);
                setIdPag();
            }) 
        }
        dispatch({
            type: 'set_ListaPesquisa',
            payload:{...listaPesquisa, listaCliente:[]}
        });
        dispatch({
            type: 'set_ListaPesquisa',
            payload:{...listaPesquisa, listaEvento:[]}
        }); 
    };

    //---Pesquisar Pag por Id evento----------------------------
    async function pesqPagXevento(){
        if(id_Evento){
            await api.get(`paguevento/idevento/${id_Evento}`)
            .then(res => {
                setListaPag(res.data);

                const busc = res.data
                //-----------Soma Valores Pagos-----------------------
                const restbusc = busc.map(t=>t.valor_pago);
                let soma = 0;
                for(let i = 0; i < restbusc.length; i++){
                    soma += restbusc[i]
                }
                setSomaValorPago(soma)

            });
        }
    };

    //-Atualizar Evento - Colocar como Pago "concluido" ou Aberto---------
    async function AtualizarConcluido(){
        if(somaValorPago >= TotalEven && concluido === false){
            await api.put(`/evento/atualizar/${id_Evento}`, { 
                concluido: true,
                data_modificado:Date.now(),
                        
            }).then(res => {
                if(res.data.error){
                    alert('erro')
                } else{
                    alert('Evento Pago!');
                }
            })
        };
        //--------
        if(somaValorPago < TotalEven && concluido === true){
            await api.put(`/evento/atualizar/${id_Evento}`, { 
                concluido: false,
                data_modificado:Date.now(),
                        
            }).then(res => {
                if(res.data.error){
                    alert('erro')
                } else{
                    alert('Evento Esta em Abeto!');
                }
            })
        };
    };

    //------------------------------------
    useEffect(() => {
        AtualizarConcluido()
    }, [somaValorPago && TotalEven && concluido])

    //----Valores Falta Pagar Pendente----
    useEffect(() => {
        setValorPend(TotalEven - somaValorPago) 
    }, [valor_pago, newValor_servico, newValor_produtos, somaValorPago])

    //---Pesquisar Pag por id pag----------
    async function PesqIdPag(){
        if(idPag){
            await api.get(`/paguevento/${idPag}`)
            .then(res => {
                
                setListaPag([res.data]);
            });
        }
    };

    // ------------------------------
    useEffect(() => {
        pesqidcliente();
    }, [cliente.id_Cliente]);

    //--------------------------------
    useEffect(() => {
        if(checkEven){
            if(!checkPag){  
            pesqPagXevento(); // pesquisar todos os pagamento deste evento
        }}
        if(!checkEven){ 
        setcheckPag(false);
        setIdPag();
        setListaPag([])
        }    
    }, [checkEven])

    // ---------------------------------
    useEffect(() => {
        dispatch({
            type:'SET_parametroInit',
            payload: {
                ...parametroInit, 
                detalhesLista: false,
                playModalDetalhesEven: false, 
            }
        });
    }, [parametroInit.filtroPlaca])

    //---------------------------------
    useEffect(() => {
        if(checkPag){
            PesqIdPag() // pesquisar id do pagamento
            setcheckPag(idPag) //selecionar o id pesquisado
        }
    }, [idPag])

    //---Se checkEven for desselecionado fazer pesquisa de todos eventos----
    useEffect(() => {
        if(!checkEven)
        pesqEventoXveiculo();
    }, [checkEven])

    //---Se checkPag for desselecionado fazer pesquisa de todos Pagamentos----
    useEffect(() => {
        if(!checkPag)
        pesqPagXevento();
    }, [checkPag])

    //------------Somar o Total-----------------
    const[Total, setTotal]=useState(0);
    let tt = parseFloat(newValor_servico)+parseFloat(newValor_produtos);

    useEffect(() => {
        if(tt){
            setTotal(tt)
        }
    }, [tt])

    //-------------------Gerar Codigos-----------
    useEffect(() => { 
        let inic = (1);
        let codinic =  veiculo.placa+'-0'+inic;
        const regex = new RegExp(codinic, 'i'); // Expresao regular
        let verific = regex.test(codEvenBD) // verificar se ja existe este codigo no banco de dados
        //--------
        if(verific) { // se cod for igual, gerar novo
            for(; verific; inic++){ 
                let codNovo = veiculo.placa+'-0'+inic
                const regex = new RegExp(codNovo, 'i');
                let verific = regex.test(codEvenBD)
                
                if(!verific){
                    dispatch({
                        type: 'set_Evento',
                        payload: {...evento, codEvento:codNovo}
                    });
                    break
                }
            };
        }else{
            if(veiculo.placa){
                dispatch({
                    type: 'set_Evento',
                    payload: {...evento, codEvento:codinic}
                });
            }
        }
    }, [codEvenBD && veiculo.placa]);
    
    //---------------------------------------------------
    function FecharAlerte(){
        setAletDelete(false)
    };

    //---------------------------------------------------
    const [abertoEvento, setabertoEvento] = useState([]);
    const [pendenteEvento, setpendenteEvento] = useState([]);
    const [Concluidovento, setConcluidovento] = useState([]);
    //---------------------------------------------------
    useEffect(() => {
        const aber = listaPesquisa.listaEvento.filter(ev=> ev.status === "A");
        const pend = listaPesquisa.listaEvento.filter(ev=> ev.status === "P");
        const concluido = listaPesquisa.listaEvento.filter(ev=> ev.status === "C");
        setabertoEvento(aber);
        setpendenteEvento(pend);
        setConcluidovento(concluido);
    }, [listaPesquisa.listaEvento])

    //----------Codição de formaçao de Grid----------
    const[listaGrid, setListaGrid] = useState([]);

    useEffect(() => {
        const xx = [];
        if(abertoEvento.length > 0){xx.push("1")}
        if(pendenteEvento.length > 0){xx.push("1")}
        if(Concluidovento.length > 0){xx.push("1")}
        setListaGrid(xx)
    }, [abertoEvento, pendenteEvento, Concluidovento])

    // ----------------------------------------------

    
//###################################################################################################
  return (
    <S.App 
    fundoBloque={
        alertDelete ||
        playmodal.playModalEven && !parametroInit.detalhesLista || 
        playmodal.playModalEven1 || 
        playmodal.playModalUsuario ||
        playmodal.playModalCliente ||
        playmodal.playModalVeiculo
    }>
        { !conectado && <Redirect to='/login' />}
        <div className='fundoBloque'/>
        <Header playLogo={true}/>
        <S.body>
            <BarraLateral 
            setModalFormClie={setModalFormClie}
            setModalFormVeic={setModalFormVeic} 
            setModalEve={setModalEve}
            setModalPag={setModalPag}
            setModalUser={setModalUser}
            setModalFranq = {setModalFranq}
            setModalColab = {setModalColab}
            setModalServ = {setModalServ}

            ModalFormClie={ModalFormClie}
            ModalFormVeic={ModalFormVeic}
            ModalEve={ModalEve}
            ModalPag={ModalPag}
            ModalUser={ModalUser}
            ModalFranq = {ModalFranq}
            ModalColab = {ModalColab}
            ModalServ = {ModalServ}
            codEvento={codEvento}
            
            limparPesq={()=>{}}
            />

            <S.body2>
                {/* {mensagemModal &&
                <div style={{display:"flex", height:"60%", alignItems:"center", justifyContent:"center"}}>
                    <span style={{color: '#d8d8d8'}}> Seja forte e corajoso! josué 1:9</span>
                </div>
                } */}
                
                
                    {/* --------------CLIENTE--------------- */} 
                    {displayModal.clienteDisplay && 
                    <S.containerPesq>
                        <PesquisaPadrao
                        playBotao={true}
                        Lupa={true}
                        titulo="Buscar Cliente:"
                        placeholder="cpf"
                        nomeBotao="Novo Cliente"
                        Pesquisar={pesqclienteCpf}
                        playModal_Add={Play_Modal_Add}
                        PesqDentroModal={false}
                        />
                    </S.containerPesq>
                    }

                    {/* --------------VEICULO--------------- */} 
                    {displayModal.veiculosDisplay &&
                    <S.containerPesq>
                        <PesquisaPadrao
                        playBotao={true}
                        Lupa={true}
                        titulo="Buscar Veiculo:"
                        placeholder="placa"
                        nomeBotao="Novo Veiculo"
                        Pesquisar={PesqVeiculoPlaca}
                        playModal_Add={Play_Modal_Add}
                        PesqDentroModal={false}
                        />
                    </S.containerPesq>
                    }          
                    
                    {/* --------------ORÇAMENTO Pesquisa---------*/}  
                    {displayModal.orcamentoDisplay &&
                    <S.containerPesq>
                        <PesquisaOrcanento
                        Lupa={true}
                        PesqEventoCod={PesqEventoCod}
                        PesqDentroModal={false}
                        />
                    </S.containerPesq>
                    }
                

                
                {/* //---------PDF-------------*/}
                {/* <S.ContainerLista> */}
                    {/* <S.bodyLista>
                        <div style={{'display':"flex", 'flexDirection':'column'}} >
                            <ModalPDF
                            pesqclienteCpf={pesqclienteCpf}
                            Lupa={true}
                            />
                        </div>
                    </S.bodyLista> */}
                {/* </S.ContainerLista> */}

                {/*---------CLIENTE-------------*/}
                {displayModal.clienteDisplay && 
                <S.ContainerLista>
                    <S.bodyLista>
                        {loading && !playmodal.playModalCliente &&
                        <S.bodyresult>
                            <LoadingContent/>
                        </S.bodyresult>
                        }

                        {!loading && 
                        <div style={{'display':"flex", 'flexDirection':'column'}} >
                            <ListaClientes
                            listaBusca={listaCliente}
                            setAletDelete={setAletDelete}
                            setNewStatus={setNewStatus}
                            alertDelete={alertDelete}
                            pesqclienteCpf={pesqclienteCpf}
                            setRefDelet={setRefDelet}
                            FecharAlerte={FecharAlerte}
                            ExcluirEste={ExcluirEste}
                            />
                            {playmodal.playModalCliente &&
                            <Modalcliente
                            FecharModal={FecharModal}
                            EditarSelect={EditarSelect}
                            pesqclienteCpf={pesqclienteCpf}
                            Lupa={true}
                            />
                            }
                        </div>
                        } 
                    </S.bodyLista>
                </S.ContainerLista>
                }
                {/* --------------VEICULO----------------- */}
                {displayModal.veiculosDisplay &&
                <S.ContainerLista>
                    <S.bodyLista>
                        {loading && !playmodal.playModalVeiculo &&
                        <S.bodyresult>
                            <LoadingContent/>
                        </S.bodyresult>
                        }
                        
                        <div style={{'display':"flex", 'flexDirection':'column'}} >
                            {!loading &&
                            <ListaVeiculos
                            alertDelete={alertDelete}
                            setAletDelete={setAletDelete}
                            newStatus={newStatus}
                            setNewStatus={setNewStatus}
                            setRefDelet={setRefDelet}
                            pesqVeiXcliente={PesqVeiXcliente}
                            PesqVeiculoPlaca={PesqVeiculoPlaca}
                            />
                            }
                            {playmodal.playModalVeiculo && 
                            <ModalVeiculo
                            FecharModal={FecharModal}
                            Lupa={true}
                            pesqIDveiculo={pesqIDveiculo}
                            PesqVeiculoPlaca={PesqVeiculoPlaca}
                            EditarSelect={EditarSelect}
                            />
                            }
                        </div>
                    </S.bodyLista>
                </S.ContainerLista>
                }

                {/* -------------EVENTO------------------------ */}
                {displayModal.eventoDisplay && 
                <S.ContainerLista>
                    <S.containerPesq>
                        <PesquisaPadrao
                        refPlaca = {true}
                        playBotao={true}
                        Lupa={true}
                        titulo="Buscar Eventos"
                        placeholder="placa"
                        nomeBotao="Novo Evento"
                        Pesquisar={PesqEventoPlaca}
                        playModal_Add={Play_Modal_Add}
                        PesqDentroModal={false}
                        />
                    </S.containerPesq>

                    <S.ModalConteine>
                        {loading && !playmodal.playModalEven && <>
                        <S.bodyresult>
                            <LoadingContent/>
                        </S.bodyresult>
                        </>
                        }
                        {playLista.playListaEven && listaPesquisa.listaEvento.length === 0 && <>
                        <S.bodyTitulo>
                            <div className={'titulo'}>
                                <div className={'tituloUser'}>
                                    Evento Não Encontrado!
                                </div>
                            </div>
                        </S.bodyTitulo>
                        </>
                        }
                        {!loading && <>
                        {listaPesquisa.listaEvento.length > 0 &&
                        <S.divGrid listaGrid={listaGrid.length}>  
                            {abertoEvento.length > 0 &&
                            <div className='colunaA'>
                                <ListaEventos
                                titulo="Aberto"
                                listaBusca={abertoEvento}
                                alertDelete={alertDelete}
                                setAletDelete={setAletDelete}
                                newStatus={newStatus}
                                setNewStatus={setNewStatus}
                                setRefDelet={setRefDelet}
                                PesqEventoCod={PesqEventoCod}
                                />
                            </div>
                            }
                            {pendenteEvento.length > 0 &&
                            <div className='colunaP'>
                                <ListaEventos
                                titulo="Pendente"
                                listaBusca={pendenteEvento}
                                alertDelete={alertDelete}
                                setAletDelete={setAletDelete}
                                newStatus={newStatus}
                                setNewStatus={setNewStatus}
                                setRefDelet={setRefDelet}
                                PesqEventoCod={PesqEventoCod}
                                />
                            </div>
                            }
                            {Concluidovento.length > 0 &&
                            <div className='colunaC'>
                                <ListaEventos
                                titulo="Concluido"
                                listaBusca={Concluidovento}
                                alertDelete={alertDelete}
                                setAletDelete={setAletDelete}
                                newStatus={newStatus}
                                setNewStatus={setNewStatus}
                                setRefDelet={setRefDelet}
                                PesqEventoCod={PesqEventoCod}
                                />
                            </div>
                            }
                        </S.divGrid>
                        }

                        {parametroInit.detalhesLista &&
                        <div className='detalhes'>
                            {playmodal.playModalDetalhesEven &&
                            <DetalheEvento
                            titulo="Concluido"
                            alertDelete={alertDelete}
                            setAletDelete={setAletDelete}
                            newStatus={newStatus}
                            setNewStatus={setNewStatus}
                            setRefDelet={setRefDelet}
                            PesqEventoCod={PesqEventoCod}
                            />
                            }

                            {playmodal.playModalPagamento &&
                            <AddPagamento
                            nomeLS={evento.placa}
                            />
                            }
                        </div>
                        }
                        </>}
                        
                        {playmodal.playModalEven &&
                        <S.Modal>
                            <ModalEvento
                            FecharModal={FecharModal}
                            Lupa={true}
                            idLogado={idLogado}
                            PesqEventoCod={PesqEventoCod}
                            PesqIdEvento={PesqIdEvento}
                            BuscaTodosEventos={BuscaTodosEventos}
                            />
                        </S.Modal>
                        }
                        {playmodal.playModalEven1 &&
                        <ModalPadrao
                        FecharModal={FecharModal}
                        Lupa={true}
                        idLogado={idLogado}
                        PesqEventoCod={PesqEventoCod}
                        PesqIdEvento={PesqIdEvento}
                        BuscaTodosEventos={BuscaTodosEventos}
                        playModal_Add={Play_Modal_Add}
                        />
                        }
                    </S.ModalConteine>
                </S.ContainerLista>
                }   

                {/* -------- EVENTO ORÇAMENTOS PAGAMENTOS----- */}
                {displayModal.orcamentoDisplay && 
                <S.ContainerLista>
                    <S.bodyLista>
                        {loading && <>
                        <S.bodyresult>
                            <LoadingContent/>
                        </S.bodyresult>

                        <ListaEventos
                        alertDelete={alertDelete}
                        setAletDelete={setAletDelete}
                        newStatus={newStatus}
                        setNewStatus={setNewStatus}
                        setRefDelet={setRefDelet}
                        PesqEventoCod={PesqEventoCod}
                        listaBusca={listaEvento}
                        />
                        
                        <ModalOrcamento
                        FecharModal={FecharModal}
                        PesqVeiculoPlaca={PesqVeiculoPlaca}
                        />
                        </>} 
                    </S.bodyLista>
                </S.ContainerLista>
                } 

                {/* -------------USUARIO----------------- */}
                {displayModal.usuarioDisplay &&
                <S.ContainerLista>
                    <S.containerPesq>
                        <PesquisaPadrao
                        refNomeCPF={true}
                        Lupa={true}
                        playBotao={true}
                        Pesquisar={PesqNomeCpfUsuario}
                        playModal_Add={Play_Modal_Add}
                        titulo="Buscar Usuario"
                        placeholder="nome ou cpf"
                        nomeBotao="Novo Usuario"
                        PesqDentroModal={false}
                        />
                    </S.containerPesq>

                    <S.ModalConteine>
                        {loading && !playmodal.playModalUsuario && <>
                        <S.bodyresult>
                            <LoadingContent/>
                        </S.bodyresult>
                        </>
                        }
                        
                        {!loading && 
                        <div style={{'display':"flex", 'flexDirection':'column', width:"100%"}} >
                            <ListaUsuario
                            titulo="Usuarios"
                            listaBusca={listaUsuario}
                            setAletDelete={setAletDelete}
                            alertDelete={alertDelete}
                            setNewStatus={setNewStatus}
                            setRefDelet={setRefDelet}
                            pesqTodos= {BuscaTodosUsuarios}
                            ExcluirEste={ExcluirEste}
                            FecharAlerte={FecharAlerte}
                            />
                        </div>
                        }
                        {playmodal.playModalUsuario &&
                        <S.Modal>
                            <ModalUsuario 
                            FecharModal={FecharModal}
                            idLogado={idLogado}
                            playModal_Add={Play_Modal_Add}
                            /> 
                        </S.Modal>
                        }
                    </S.ModalConteine>
                </S.ContainerLista>
                }

                {/* -------------CONTA----------------- */}
                {displayModal.contaDisplay && 
                <S.ContainerLista>
                    <S.bodyLista>
                        {loading && !playmodal.playModalConta && <>
                        <S.bodyresult>
                            <LoadingContent/>
                        </S.bodyresult>
                        </>}

                        {!loading && 
                        <div style={{'display':"flex", 'flexDirection':'column'}}>
                            {playmodal.playModalConta &&
                            <ModalUsuario 
                            FecharModal={FecharModal}
                            idLogado={idLogado}
                            playModal_Add={Play_Modal_Add}
                            /> 
                            }
                        </div>
                        }
                    </S.bodyLista>
                </S.ContainerLista>
                }

                {/* //---------2------------------ */}
                {displayModal.clienteDisplay &&
                <S.ContainerLista>
                    <ListaBanco
                    alertDelete={alertDelete}
                    setAletDelete={setAletDelete}
                    newStatus={newStatus}
                    setNewStatus={setNewStatus}
                    setRefDelet={setRefDelet}
                    />
                    <ModalBanco/>
                </S.ContainerLista>
                }            
     
                {/* -----PESQUISA Pagamento------- */}
                {/* {displayModal.pagamentoDisplay && <>
                <PesquisaPag
                api={api}
                Lupa={true}

                
                setIdPag={setIdPag}
                checkPag={checkPag}
                listaPag={listaPag}
                setListaPag={setListaPag}
                setcheckPag={setcheckPag}

                setFormaPagto={setFormaPagto}
                setNumCheq={setNumCheq}
                setValorCheq={setValorCheq}
                setvalor_pago={setvalor_pago}
                setbancoCheq={setbancoCheq}
                setRefCheq={setRefCheq}
                setdataPag={setdataPag}

                filtroPag={filtroPag}
                setFiltroPag={setFiltroPag}
                pesqPagCod={pesqPagCod}

                buscaPegarFiltroPag={buscaPegarFiltroPag}
                
                

                ModalPag={ModalPag}
                setModalPag={setModalPag}

                />
    </>} */}
                {/* -----PESQUISA Franquia-------------- */}
                {/* {displayModal.franquiaDisplay && <>
                <PesquisaFranquia
                Lupa={true}
                setcheckEven={setcheckEven}
                setCodEvento={setCodEvento}
                ModalFormClie={ModalFormClie}
                setModalFormClie={setModalFormClie}
                //pesqclienteCpf={pesqclienteCpf}
                />

                </>}
                {displayModal.bancoDisplay && <>
                <PesquisaBanco/>
                </>} */}

                {/* ----MODAL Colaborador------------- */}
                {/* {displayModal.colaboradorDisplay && <>
                <ModalColaborador/>
                </>} */}
            
        </S.body2>
    </S.body>

        {/* ---------MODAL Pagamento------------------ */}
        {/* <ModalPagEven
        idPag={idPag}
        formaPagamento={formaPagamento} 
        numCheq={numCheq} 
        valorCheq={valorCheq} 
        valor_pago={valor_pago} 
        valorPendente={valorPendente}
        setValorPend={setValorPend}
        editDataEvento={editDataEvento}
        dataEvento={dataEvento}
        idLogado={idLogado}
        concluido={concluido}

        bancoCheq={bancoCheq} 
        refCheq={refCheq} 
        dataPag={dataPag} 
        setFormaPagto={setFormaPagto}
        setNumCheq={setNumCheq}
        setValorCheq={setValorCheq}
        setvalor_pago={setvalor_pago}

        setbancoCheq={setbancoCheq}
        setRefCheq={setRefCheq}
        setdataPag={setdataPag}
        
        ModalPag={ModalPag} 
        setModalPag={setModalPag}
        codEvento={codEvento}
        tipo={tipo}
        TotalEven={TotalEven}
        idEvento={idEvento}
        checkEven={checkEven}
        setcheckEven={setcheckEven}
        pesqPagCod={pesqPagCod}
        PesqEventoCod={PesqEventoCod}

        

        somaValorPago={somaValorPago}

        /> */}
        {/* ---------MODAL Franquia--------- */}
        {/* <ModalFranquia 
        ModalFranq={ModalFranq} 
        setModalFranq={setModalFranq}
        
        
        /> */}

        {/* -------MODAL Servico------------ */}
        {/* <ModalServico
        ModalServ={ModalServ} 
        setModalServ={setModalServ}

        idLogado={idLogado}
        
        /> */}
        
        <Footer/>
    </S.App>
    )
}
export default Add;