export const tipo_planoInicialState = {
    tipo_veic:{
        tipo:"",
        plano:"",
    },
    plano_veic:{
        titulo:"",
        descricao:"",
        preco:"",
        comissao:"",
    }
}
//-----------------------------------------------------
export const tipoPlanoReducer = (state = tipo_planoInicialState, action)=>{

    switch(action.type){
        
        case 'set_tipo':
            return {...state, tipo_veic: action.payload}; 

        case 'set_plano':
            return {...state, plano_veic: action.payload}; 

        case 'set_limparTipoPlano':
        return tipo_planoInicialState; 

            default:
            return state;
            
    }
};
//-----------------------------------------------------
