import React, { useContext, useEffect, useState, useRef } from 'react';
import { Context } from '../../services/contexts/StateContext';
import * as S from './styles';
import { format } from 'date-fns';

import api from '../../services/api';
import { ApiEvento } from '../../services/FiltrosApi/eventoApi';

import { AlertComp } from '../../componentes/alerte/index';
import { SetFunctions } from '../../componentes/Functions';
import { Add_Arquivos } from '../../componentes/Cadastro/Add_Arquivos';

// ---Midia---
import editar from '../../midia/editar_branco.svg';
import coin from '../../midia/coin_red.svg';
import icon_addArquivo from '../../midia/add-upload.svg';

//-----------------------------------------------
export const DetalheEvento = (props) => {
    const { state, dispatch } = useContext(Context);
    const { evento, terceiro } = state.evento;
    const { veiculo } = state.veiculo;
    const { parametroInit, playmodal, playLista, listaPesquisa } = state.parametros;
    const { disabledEven } = parametroInit;
    const { playListaPagto } = playLista;

    const [alertDelete, setAletDelete] = useState(false);
    const { AtualizarPartic, BaixarEvento, PesqIdEvento } = ApiEvento();
    const { SetEvento } = SetFunctions();
    const { SelectFile, AddArquivos } = Add_Arquivos();

    // -----------------------------------------
    const SetPlayLista = (key, value) => {
        dispatch({
            type: 'set_playLista',
            payload: { ...playLista, [key]: value }
        });
    };

    //-----------------------------------------
    function FecharAlerte() {
        setAletDelete(false)
    };

    //------------------------------------------
    function EditaEvento() {
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {
                ...playmodal,
                playModalEditar: true,
                playModalEven: playmodal.playModalEven ? false : true,
                playModalPagamento: false,
                playModalDetalhesEven: false,
            }
        });

        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit,
                editModal: true,
                detalhesLista: false
            }
        });
    };

    // --------------------
    function addPagamento() {
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {
                ...playmodal,
                playModalPagamento: playmodal.playModalPagamento ? false : true,
                playModalEven: false,
                playModalDetalhesEven: false,
            }
        });

        dispatch({
            type: 'set_playLista',
            payload: {
                ...playLista,
                playListaPagto: false
            }
        });
    };

    //-----------------------------------------------
    async function ExcluirEste(t) {
        await api.put(`evento/atualizar/${evento.id_Evento}`, {
            status: 'E'
        })
            .then(res => {
                props.PesqEventoCod();
                setAletDelete(false);
            })
    };

    // -----------------------------------
    const [ComParticip, setComPart] = useState(false);
    const [FoiColisao, setColisao] = useState(false);

    useEffect(() => {
        const ComPart = evento.tipoEvento.split("_").filter(e => e === "part").length > 0;
        setComPart(ComPart);

        const Colisao = /colisao/i.test(evento.tipoEvento) && !/guincho/i.test(evento.tipoEvento);
        setColisao(Colisao);
    }, [evento.tipoEvento])

    // --------------------------------
    const [ListaOrdPag, setListaOrd] = useState([]);
    const [somaTotalPG, setSomaTotalPG] = useState(0);
    const [SelectValor, setSelectValor] = useState(false);
    const [editPart, seteditPart] = useState(false);
    const [editPag, seteditPag] = useState(false);

    useEffect(() => {
        //-Ordenar pagamento por data-
        var xx = listaPesquisa.listaPagamentos.sort((a, b) => (
            new Date(a.dataPG) - new Date(b.dataPG)
        ));
        setListaOrd(xx);

        //-Soma Total--
        const somaX = listaPesquisa.listaPagamentos.map(e => e.valorPG);
        if (somaX.length > 0) {
            let somaTotal = 0;
            for (let i = 0; i < somaX.length; i++) {
                somaTotal += somaX[i];
            }
            setSomaTotalPG(somaTotal);
        };

        //  ---------------
        listaPesquisa.listaEvento.map(t => {
            if (t.dataPart) { seteditPart(false) } else { seteditPart(true) };
            // if(t.dataPag){seteditPag(false)}else{seteditPag(true)};
        })

    }, [listaPesquisa.listaPagamentos, listaPesquisa.listaEvento]);

    // ------------------------------------
    async function AddOrcamento() {
        const orcamento = evento.orcamento.toString();

        await api.put(`/evento/atualizarSimples/${evento.id_Evento}`, {
            orcamento: parseFloat(orcamento.replace(",", "."))
        })
            .then(res => {
                alert("Sucesso!")
                setSelectValor(false);
                SetEvento("orcamento", parseFloat(orcamento.replace(",", ".")));
                PesqIdEvento(evento.id_Evento)
            })
            .catch(() => alert("erro!!!"))
    };

    // ---Click Dentro e Fora do Componente-----
    const ClickRef = useRef(null);
    const ClickRefPart = useRef(null);
    const ClickRefReparo = useRef(null);

    // ------------
    useEffect(() => {
        document.addEventListener("click", EventoDeClick, true);
    }, [])

    function EventoDeClick(e) {
        if (!ClickRef.current?.contains(e.target)) {
            setSelectValor(false);
        };
        if (!ClickRefPart.current?.contains(e.target)) {
            seteditPart(false);
        };
        if (!ClickRefReparo.current?.contains(e.target)) {
            seteditPag(false);
        };
    };

    // ------------------------------
    useEffect(() => {
        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit,
                disabledEven: editPag ? false : true,
            }
        });
    }, [editPag])

    // ------------------------------
    const EditarModel = () => {
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {
                ...playmodal,
                playModalEvenPlaca: true,
                playModalDetalhesEven: false,

            }
        });

        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit,
                detalhesLista: false,
            }
        });
    };


    //###################################################################
    return (
        <S.bodyresult>
            {alertDelete &&
                <AlertComp
                    alertDescrit={'Deseja mesmo Excluir?'}
                    seSim={ExcluirEste}
                    seNao={FecharAlerte}
                />
            }

            <S.detalhes>
                <div style={{ display: "flex", 'width': "100%", "flexDirection": "column" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "column", "width": "100%" }}>
                            <div className='listaDetalhe'>
                                <div className='lista'>
                                    <span className='titulo'>Data Evento:</span>
                                    <span>{evento?.dataEvento}</span>
                                </div>
                                <div className='lista'>
                                    <span className='titulo'>Associado:</span>
                                    <span>{evento?.nomeCliente}</span>
                                    <span>Celular</span>
                                </div>
                                <div className='lista'>
                                    <span className='titulo'>Evento: </span>
                                    <span>{evento.tipoEvento === "PT" ? "Perca Total" : evento.tipoEvento}</span>
                                    <span className='titulo' style={{ textTransform: "uppercase" }}>BO: </span>
                                    <span>{evento?.ocorrenciaBO}</span>

                                </div>
                            </div>

                            <div className='listaDetalhe' style={{ marginTop: "5px" }}>
                                <div className='lista'>
                                    <span className='titulo'>Veiculo: </span>
                                    <span
                                        style={{ cursor: "pointer", color: "yellow" }}
                                        onClick={() => EditarModel()}>
                                        {evento?.placa}
                                    </span>
                                    <span>
                                        {veiculo?.modelo}
                                    </span>
                                </div>
                                <div className='lista'>
                                    <span className='titulo'>Valor Fipe: </span>
                                </div>

                                <div className='lista'>
                                    <span className='titulo'>Terceiro:</span>
                                    <span style={{ textTransform: "uppercase" }}>{terceiro?.placa}</span>
                                    <span style={{ textTransform: "uppercase" }}>{terceiro?.modelo}</span>
                                    <span>{parseFloat(terceiro?.valorFipe).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                </div>

                                <div className='lista'>
                                    <span className='titulo'>R$ Serviço::</span>
                                    <span style={{ color: "orange", cursor: "pointer" }} onClick={() => seteditPag(true)}>
                                        {parseFloat(evento?.orcamento).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                    </span>
                                </div>

                                <div className='lista'>
                                </div>
                            </div>

                            <div className='lista'>
                                <span className='titulo'>Descrição:</span>
                                <span style={{ display: "flex", textTransform: "lowercase", maxWidth: "400px", wordBreak: "break-all" }}>{evento?.descricao}</span>
                            </div>
                        </div>

                        <div style={{ display: 'flex', 'flexDirection': "column", justifyContent: "space-around" }}>
                            <img
                                className='icone'
                                src={editar}
                                onClick={() => EditaEvento()}
                                alt="icone"
                            />

                            <img
                                style={{ width: "20px", height: "20px" }}
                                className='icone'
                                src={coin}
                                onClick={() => { FoiColisao ? addPagamento() : seteditPag(true) }}
                                alt="icone"
                            />
                        </div>
                    </div>

                    {/*----Com PARTICIPAÇÃO------*/}
                    {ComParticip && <>
                        <div style={{ borderBottom: "1px solid #3881ff" }} />
                        <div ref={ClickRefPart} style={{ display: "flex", "alignItems": "end", padding: "5px 0" }}>
                            {editPart ?
                                <>
                                    <div className='lista'>
                                        <span className='titulo'>Data Participação: </span>
                                        <input
                                            type="date"
                                            placeholder='Data Participação'
                                            value={evento.dataPart ? format(new Date(evento.dataPart + 'T00:00:00'), 'yyyy-MM-dd') : ""}
                                            onChange={e => SetEvento('dataPart', e.target.value)}
                                        />
                                    </div>
                                    <div className='lista'>
                                        <span className='titulo'>Valor Participação: </span>
                                        <input
                                            type="number"
                                            placeholder='valor'
                                            value={evento.valorPart}
                                            onChange={e => SetEvento('valorPart', e.target.value)}
                                        />
                                    </div>
                                    <div className='lista'>
                                        <span className='titulo'>Observação: </span>
                                        <input
                                            type="text"
                                            placeholder='detalhes'
                                            value={evento.detalhePart}
                                            onChange={e => SetEvento('detalhePart', e.target.value)}
                                        />
                                    </div>
                                </>
                                :
                                <>

                                    <div className='lista'>
                                        <span className='titulo'>Data Participação: </span>
                                        <span>{evento.dataPart ? format(new Date(evento.dataPart), 'dd/MM/yyyy') : ""}</span>
                                    </div>
                                    <div className='lista'>
                                        <span className='titulo'>Valor Participação: </span>
                                        <span>{parseFloat(evento.valorPart).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                    </div>
                                    <div className='lista'>
                                        <span className='titulo'>Observação: </span>
                                        <span>{evento.detalhePart}</span>
                                    </div>
                                </>
                            }

                            {editPart ?
                                <button
                                    type='button'
                                    onClick={() => AtualizarPartic()}>add
                                </button>
                                :
                                <img
                                    className='icone'
                                    src={editar}
                                    onClick={() => seteditPart(true)}
                                    alt="icone"
                                />
                            }
                        </div>
                    </>}

                    {/* -----PAGAMENTO-------- */}
                    {(evento.valorPag || editPag) &&
                        <div >
                            <div style={{ borderBottom: "1px solid #3881ff", marginBottom: "5px" }} />

                            <div ref={ClickRefReparo} style={{ display: "flex", "alignItems": "end" }}>
                                {(!evento.valorPag || editPag) ?
                                    <>
                                        <div className='lista'>
                                            <span className='titulo'>Data Pagamento: </span>
                                            <input
                                                type="date"
                                                placeholder='Data Participação'
                                                value={evento.dataPag}
                                                onChange={e => SetEvento('dataPag', e.target.value)}
                                            />
                                        </div>

                                        <div className='lista'>
                                            <span className='titulo'>Valor: </span>
                                            <span>{parseFloat(evento?.orcamento).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                        </div>

                                        <div className='lista'>
                                            <span className='titulo'>Forma Pagamento: </span>
                                            <input
                                                type="text"
                                                placeholder='detalhes'
                                                value={evento.formaPag}
                                                onChange={e => SetEvento('formaPag', e.target.value)}
                                            />
                                        </div>

                                        <div>
                                            <img
                                                src={icon_addArquivo}
                                                onClick={() => !disabledEven ? SelectFile() : ""}
                                                style={{ width: "25px", cursor: !disabledEven ? "pointer" : "not-allowed" }}
                                            />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='lista'>
                                            <span className='titulo'>Data Pagamento: </span>
                                            <span>{evento.dataPag && format(new Date(evento.dataPag + 'T00:00:00'), 'dd/MM/yyyy')}</span>
                                        </div>
                                        <div className='lista'>
                                            <span className='titulo'>Valor Pago: </span>
                                            <span>{parseFloat(evento?.valorPag).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                        </div>
                                        <div className='lista'>
                                            <span className='titulo'>Forma Pagamento: </span>
                                            <span>{evento.formaPag}</span>
                                        </div>
                                    </>
                                }

                                {(!evento.valorPag || editPag) ?
                                    <button
                                        type='button'
                                        onClick={() => BaixarEvento(seteditPag)}>add
                                    </button>
                                    :
                                    <img
                                        className='icone'
                                        src={editar}
                                        onClick={() => seteditPag(true)}
                                        alt="icone"
                                    />
                                }
                            </div>

                            <S.boxFiles>
                                <AddArquivos />
                            </S.boxFiles>

                        </div>}

                    {/* ---------COLISAO ORÇAMENTO----------- */}
                    {FoiColisao &&
                        <S.ListaPag>
                            <div style={{ display: "flex", justifyContent: "space-between", color: "#00D594", width: "100%", padding: "5px 0" }}>
                                <div ref={ClickRef} style={{ display: "flex", flexDirection: "row" }}>
                                    <span>Orçamento:</span>
                                    {SelectValor ?
                                        <>
                                            <input
                                                style={{ backgroundColor: "#30353F", borderRadius: "3px", marginLeft: "5px" }}
                                                type="text"
                                                autoFocus
                                                value={evento.orcamento}
                                                onChange={(e) => SetEvento("orcamento", e.target.value)}
                                            />

                                            <button
                                                style={{ marginLeft: "5px" }}
                                                onClick={() => AddOrcamento()}
                                            >
                                                add
                                            </button>
                                        </>
                                        :
                                        <span
                                            className='botaoTotal'
                                            style={{ color: "yellow" }}
                                            onClick={() => setSelectValor(true)}
                                        >
                                            {parseFloat(evento.orcamento).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    }
                                </div>

                                <span>Pago:
                                    <span
                                        className='botaoTotal'
                                        style={{ color: "orange" }}
                                        onClick={() => SetPlayLista("playListaPagto", playListaPagto ? false : true)}
                                    >
                                        {parseFloat(somaTotalPG).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                    </span>
                                </span>

                                <span>Pendente:
                                    <span style={{ color: "red", marginLeft: "3px" }}>
                                        {parseFloat(evento.orcamento - somaTotalPG).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                    </span>
                                </span>
                            </div>
                            {playLista.playListaPagto &&
                                <div style={{ display: "flex", width: "100%", flexDirection: "column", maxHeight: "150px", overflow: "auto" }}>
                                    {ListaOrdPag.map((p, k) => (
                                        <div className='listaPg' key={k}>
                                            <span style={{ paddingLeft: "3px" }}>{evento.dataPart ? format(new Date(p.dataPG), 'dd/MM/yyyy') : ""}</span>
                                            <span>{p.tipoPG}</span>
                                            <span>{p.nomeItem}</span>
                                            <span>{parseFloat(p.valorPG).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                        </div>
                                    ))}
                                </div>}
                        </S.ListaPag>
                    }
                </div>

            </S.detalhes>
        </S.bodyresult>

    )
}