import React, { useEffect, useContext } from 'react';
import * as S from './styles';
import {Context} from '../../services/contexts/StateContext';
import MaskedInput from 'react-text-mask';

export function PesquisaCotacaoPlaca(props){
    const {state, dispatch} = useContext(Context);
    const {parametroInit, playmodal, listaPesquisa, playLista} = state.parametros;

//---------------------------------------------------
const setparametroInit = (key, value) => {
    dispatch({
        type: 'SET_parametroInit',
        payload: {...parametroInit, [key]: value}
    });
};

//-----------------------------------------------------------------
function novoCadastro(){
    dispatch({type: 'set_limparVeiculo'});
    
    dispatch({
        type: 'SET_parametroInit',
        payload: {
            ...parametroInit, 
            editModalVeic:false, 
            disabledVeic:false,
            detalhesVeic:false
        }
    });
    dispatch({
        type: 'set_playLista',
        payload:{...playLista, playListaVeic:false}
    });
    dispatch({
        type: 'set_ListaPesquisa',
        payload:{...listaPesquisa, listaVeiculo:[]}
    });
    dispatch({
        type: 'SET_PLAYMODAL',
        payload: {...playmodal, playModalVeiculo: true}
    });
};
//-------------Limpar Pesquisa atual quando digitar---------------------
useEffect(() => {
    if(parametroInit.filtroPlaca){
        dispatch({
            type: 'set_limparVeiculo'
        });
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {...playmodal, playModalVeiculo:false}
        });
        dispatch({
            type: 'set_playLista',
            payload: {...playLista, playListaVeic:false}
        });
        dispatch({
            type: 'set_ListaPesquisa',
            payload:{...listaPesquisa, listaVeiculo:[]}
        });
    }
}, [parametroInit.filtroPlaca])
//###################################################################################################
//------------------------
return(

<S.divInfor>
     <S.boxInfor>
         <span>Pesquisar Placa:</span>
         <S.ddd>
            <MaskedInput
            type='search' 
            placeholder='placa'
            mask={[/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'-',/\d/, /\d/]}
            onChange={e=>setparametroInit('filtroPlaca', e.target.value)}
            guide={false}
            />
             
             <S.Pesq>
                 <button 
                 type='button' 
                 onClick={()=>props.pesqVeiculoPlaca()}
                 >
                 <img src={props.lupa} alt="lupa"/>
                 </button>
             </S.Pesq>
         </S.ddd>
         <S.botao>
            <div
            className='abrirModal'
            onClick={()=>novoCadastro()}
            >Novo Cadastro
            </div>
        </S.botao>
     </S.boxInfor>
 </S.divInfor>
   
)}