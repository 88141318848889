import React, { useState, useEffect, useContext } from 'react';
import {Context} from '../../services/contexts/StateContext';
import api from '../api';
import Cookies from 'js-cookie';

// //-----------------------------------------------------------
export function PesqConcurso(){
    const {state, dispatch} = useContext(Context);
    const {inforConcurso, logadoSorteio} = state.sorteiomix;

    useEffect(() => {
        async function pesquisar(){
                await api.get(`sorteiomix/filtrarconcurso/?status=A`)
                .then(res => {
                    const buscar = res.data; 
                    dispatch({
                        type: 'set_inforConcurso',
                        payload: {
                            ...inforConcurso,
                            pesqConsurso:false,
                            codigo:buscar.codigo,
                            inforConcAtual:buscar,
                            titulo:buscar.titulo,
                            preco:buscar.preco,
                            dataSorteio:buscar.dataSorteio,
                            listaConcurso:[buscar]
                        }
                    })
                })
        };
        pesquisar();
    }, [])
};
//-----------------------------------------------------------
export function PesqTodosConcurso(){
    const {state, dispatch} = useContext(Context);
    const {inforConcurso} = state.sorteiomix;

    useEffect(() => {
        async function pesquisar(){
                await api.get(`sorteiomix/filtrarconcurso/todos`)
                .then(res => {
                    const buscar = res.data; 
                    if(buscar.length > 0){
                        buscar.map(t=>
                            dispatch({
                                type: 'set_inforConcurso',
                                payload: {
                                    ...inforConcurso,
                                    listaConcurso:buscar
                                }
                            })
                        );
                    };
                })
        };
        pesquisar();
    }, [])
};



//--------------------------------------------------------------
export function PesqLogado(){    
    const {state, dispatch} = useContext(Context);
    const {logadoSorteio} = state.sorteiomix;

    const [buscaLogado, setLogado] = useState([]);


    async function pesquisar(){
        const getCell = Cookies.get('sorteio_celular');

        if(getCell){
            await api.get(`sorteiomix/filtrar/${getCell}`)
                .then(res => {
                    const buscar = res.data;
                    setLogado(buscar);                    
                }); 
        }; 
    };
    useEffect(() => {
        pesquisar()
    }, [logadoSorteio.pesqLogado])

    return {buscaLogado}
};

//--------------------------------------------------------------
export function PesqAdmin(){    
    const {state, dispatch} = useContext(Context);
    const {logadoSorteio} = state.sorteiomix;
    const [getCell, setgetCell]=useState('');

    async function pesquisar(){
        if(Cookies.get('sorteio_celular')){
            const get = Cookies.get('sorteio_celular');
            const cell = get.replace(/\W/g,'');
            setgetCell(cell);
        }

        if(getCell && logadoSorteio.pesqLogado){
            await api.get(`sorteiomix/filtrar/${getCell}`)
                .then(res => {
                    const buscar = res.data;
                    if(buscar.length > 0){
                        buscar.map(t=>
                            dispatch({
                                type: 'set_logadoSorteio',
                                payload: {
                                    ...logadoSorteio, 
                                    id_Cliente: t._id, 
                                    id_Franquia: t.id_Franquia._id,
                                    nomeFranquia:t.id_Franquia.nome,
                                    nome: t.nome, 
                                    perfil:t.perfil,
                                    cpf: t.cpf, 
                                    celularLogado: t.celular, 
                                    celular: t.celular, 
                                    listaCliente: buscar, 
                                    pesqLogado:false,                                
                                }
                            })
                        );
                    }
                }); 
        }; 
    };
    
    useEffect(() => {
        pesquisar()
    }, [logadoSorteio.pesqLogado])
};

// //---------------------------------------------------------------------  
export function SorteioApi(){
    const {state, dispatch} = useContext(Context);
    const {sorteiomix} = state.sorteiomix;
    const [listaGanhadores, setlistaGanhadores]=useState([]);

    async function pesqPremiados(){
        if(sorteiomix.concurso){
            await api.get(`sorteiomix/premiados/?concurso=xxx`)
            .then(res => {
                const buscar = res.data; 
                setlistaGanhadores(buscar);
            })
        }
    };

    useEffect(() => {
        pesqPremiados();
    }, []) 

    const sorteioApi = {
        listaGanhadores
    };

    return sorteioApi

};
//------------------------------------------------------------