const size = {
    sm: '530px',
    md: '750px',
    bg: '1060px'
}

const breakpoints = {
    sm: `(max-width:${size.sm})`,
    md: `(max-width:${size.md})`,
    bg: `(max-width:${size.bg})`,
}

export default breakpoints;