import React, {useEffect, useState, useContext} from 'react';
import api from '../../services/api';
import * as S from './styles';

import {Context} from '../../services/contexts/StateContext';

import MaskedInput from 'react-text-mask';

function ModalEvento(props) { 
    const {state, dispatch} = useContext(Context);
    const {veiculo} = state.veiculo;
    const {evento, terceiro} = state.evento;
    const {logado} = state.cliente;
    
    const {parametroInit, displayModal, playmodal,playLista, listaPesquisa} = state.parametros;
    const {editModal, disabledEven} = parametroInit; 
    const {playModalDetalhesEven} = playmodal; 

    const [teveTerceiro, setTerceiro]=useState(false);
    //---------------------------------------------
    function funcCheckBox(e,t){
        setTerceiro(e.target.checked)
    };

    useEffect(() => {
    if(terceiro.chassi || terceiro.placa){
        setTerceiro(true)
    }
    }, [terceiro.chassi, terceiro.placa])

    function FecharModal(){
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {...playmodal, playModalEven: false}
            });

            if(editModal || playModalDetalhesEven){
                dispatch({
                    type: 'SET_parametroInit',
                    payload: {...parametroInit, editModal: false}
                });
            }
    };
    //---------------------------------------------------
    const setEvento = (key, value) => {
        dispatch({
            type: 'set_Evento',
            payload: {...evento, [key]: value}
            });
    };

    const setEventoTerc = (key, value) => {
        dispatch({
            type: 'set_Terceiro',
            payload: {...terceiro, [key]: value}
            });
    };

    //-----------------Limpar apos Salvar com sucesso--------------------
    function limparModal(){
        dispatch({
            type: 'set_limparVeiculo'
            });
    };
    //--------------------------Atualizar-------------------------------------------
    function Atualizar(){
        if(playModalDetalhesEven){
            dispatch({
                type: 'SET_parametroInit',
                payload: {
                    ...parametroInit, 
                    editModal:true, 
                    disabledEven:false, 
                }
            });
        }
    };
    //----------------------------Add--------------------------------------------------
    async function addEvento(){
        if(parametroInit.editModal===false){

            await api.post('/evento', { 
                userCadastro:logado.idLogado,
                id_Evento:evento.id_Evento ,
                id_Servico:evento.id_Servico,
                id_Veiculo:evento.id_Veiculo,
                tipo:evento.tipo,
                codEvento: evento.codEvento,
                dataEvento: evento.dataEvento,
                dataPart:evento.dataPart,
                ocorrenciaBO:evento.ocorrenciaBO,
                valorPart:evento.valorPart,
                valor_produtos:evento.valor_produtos,
                valor_servico:evento.valor_servico,
                TotalEven: evento.TotalEven,
                comissao:evento.comissao,
                descricao: evento.descricao,
                status:evento.status,

                tipo:terceiro.tipo,
                placa:terceiro.placa,
                chassi:terceiro.chassi,
                anoMod:terceiro.anoMod,
                montadora:terceiro.montadora,
                modelo:terceiro.modelo,
                cor:terceiro.cor,  
                n_motor:evento.n_motor,
                renavam:evento.renavam,
                anoFab:evento.anoFab,
                km:evento.km,
                valorFipe:evento.valorFipe,
                codigoFipe:evento.codigoFipe,

            }).then(res => {
                const erro = (res.data.error);
                if(erro){
                    if(erro.placa){
                        return alert(erro.placa.msg);
                    }
                }else{
                    if(parametroInit.editModal===false){
                        alert('Evento Salvo!');
                    }
                    limparModal();
                    props.buscarVeiculos();
                }
            })
        }
    //-------Editar-------------------------------
        if(parametroInit.editModal){
            await api.put(`/evento/atualizar/${evento.id_Evento}`, { 
                
                id_Evento:evento.id_Evento ,
                id_Servico:evento.id_Servico,
                id_Veiculo:evento.id_Veiculo,
                tipo:evento.tipo,
                codEvento: evento.codEvento,
                dataEvento: evento.dataEvento,
                dataPart:evento.dataPart,
                ocorrenciaBO:evento.ocorrenciaBO,
                valorPart:evento.valorPart,
                valor_produtos:evento.valor_produtos,
                valor_servico:evento.valor_servico,
                TotalEven: evento.TotalEven,
                comissao:evento.comissao,
                descricao: evento.descricao,
                status:evento.status,

                tipo:terceiro.tipo,
                placa:terceiro.placa,
                chassi:terceiro.chassi,
                anoMod:terceiro.anoMod,
                montadora:terceiro.montadora,
                modelo:terceiro.modelo,
                cor:terceiro.cor,  
                n_motor:evento.n_motor,
                renavam:evento.renavam,
                anoFab:evento.anoFab,
                km:evento.km,
                valorFipe:evento.valorFipe,
                codigoFipe:evento.codigoFipe,

                data_modificado:Date.now(),
                        
            }).then(res => {
                if(res.data.error){
                    alert('erro')
                } else{
                    alert('Evento Atualizado!');
                    limparModal();
                    FecharModal();
                }
            })
        }
    };
    //--------------------------------------
    function buscarPagamentos(){
        if(!playLista.playListaEven){
            dispatch({
                type: 'SET_parametroInit',
                payload: {
                    ...parametroInit, 
                    buscarEven: true, 
                    editModal:false,
                }
            });
            dispatch({
                type: 'set_playLista',
                payload:{...playLista, playListaEven:true}
            });
            dispatch({
                type: 'SET_PLAYMODAL',
                payload: {...playmodal, playModalBenef:false}
            });
                
        }else{
            dispatch({
                type: 'SET_PLAYMODAL',
                payload: {...playmodal, playModalBenef:false}
            });
        }
    };

    //----------Pesquisar e Selecionar Veiculo em Novo Evento---------------------
    const[playlistaPesq, setplaylistaPesq]=useState(true);
    const[pesqInput, setpesqInput]=useState('');

    function pesqVeiculo(e){
        setpesqInput(e)
        dispatch({
            type: 'SET_parametroInit',
            payload: {...parametroInit, filtroPlaca: e}
        });
        dispatch({
            type: 'set_veiculo',
            payload: {...veiculo, placa: e}
        });
    };
    // ----------------------------
    function clickPesq(){
        setplaylistaPesq(true);
        dispatch({
            type: 'set_ListaPesquisa',
            payload: {...listaPesquisa, listaVeiculo:[]}
        });
    };
    // ----------------------------
    function selectVeiculo(t){
        dispatch({
            type: 'set_veiculo',
            payload: {...veiculo, id_Veiculo: t._id, placa: t.placa, nomeCliente:t.id_Cliente.nome}
            });
        dispatch({
            type: 'SET_parametroInit',
            payload: {...parametroInit, filtroPlaca:''}
        });
        setplaylistaPesq(false)
    };

    useEffect(() => {
        if(playmodal.playModalEven && pesqInput){
            props.pesqVeiculoPlaca()
        }
        if(!pesqInput){
            dispatch({
                type: 'set_ListaPesquisa',
                payload: {...listaPesquisa, listaVeiculo:[]}
            });
        }    
    }, [pesqInput])

//###################################################################################

  return (
    <S.body>
        <S.div3>
        
            <div className='titulo'>
                <div className={'tituloUser'}>
                    <span>{playModalDetalhesEven ? 'Detalhes Orçamento': editModal? 'Atualizar Orçamento':'Novo Orçamento'}</span>
                </div>
                <div 
                className='fechar'
                onClick={()=>FecharModal()}>X
                </div>
            </div>

        {playmodal.playModalEven && <>

            <S.box1>
            {displayModal.eventoDisplay &&
            playModalDetalhesEven?
                <div>
                    <span>Veiculo:</span> 
                    <span className={'spa'}>{veiculo.placa}</span>                   
                </div>
                :
                <div>
                    <span>Pesquisar Veiculo:</span> 
                    <input 
                    disabled={disabledEven}
                    type='text' 
                    value={veiculo.placa}
                    placeholder='Digite a Placa'
                    onChange={e=>pesqVeiculo(e.target.value)}
                    onFocus={()=> clickPesq()}
                    />
                    {playlistaPesq &&
                    <S.lista >
                        <div>
                            {listaPesquisa.listaVeiculo.map((t,key) => 
                            <button 
                            type='button' 
                            key={key}
                            onClick={()=>selectVeiculo(t)}
                            >
                                {t.placa}
                            </button>
                            )}
                        </div>
                    </S.lista>
                    }
                </div>
                }
                <div>
                    <span>Cliente:</span> 
                    <span className={'spa'}>{veiculo.nomeCliente}</span>                   
                </div>
            </S.box1>
            

            <S.box1>
                <div>
                    <span>Lista de Peças:</span>
                    <input 
                    disabled={disabledEven}
                    type="text"
                    className='maiusculo'
                    placeholder='Lista' 
                    value={evento.ocorrenciaBO}
                    onChange={e=>setEvento('ocorrenciaBO',e.target.value)}
                    />
                </div>
                <div>
                    <span>Lista de Serviços:</span>
                    <input 
                    disabled={disabledEven}
                    type="text"
                    className='maiusculo'
                    placeholder='Lista' 
                    value={evento.ocorrenciaBO}
                    onChange={e=>setEvento('ocorrenciaBO',e.target.value)}
                    />
                </div>
            </S.box1>
            <S.box1>
                <div>
                    <span>Valor Peças R$ </span>
                                        
                    <MaskedInput
                    disabled={disabledEven}
                    value={evento.newValor_produtos}
                    className='uppercase'
                    type='text'
                    placeholder='R$'
                    mask={[/\d/, /\d/, ",",/\d/,/\d/]}
                    onChange={e=>setEventoTerc('newValor_produtos', e.target.value)}
                    guide={false}
                    />
                </div>
                <div>
                    <span>Valor Serviços R$ </span>
                    <input 
                    disabled={disabledEven}
                    type='text'
                    placeholder='R$' 
                    value={evento.newValor_servico}
                    onChange={e=>setEvento('newValor_servico',e.target.value)}
                    />
                </div>
                <div>
                    <span>TOTAL R$ </span>
                    <input
                    disabled={disabledEven}
                    type='text'
                    placeholder='Total' 
                    />
                    {/* <span className={'spa'}>{props.Total.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})} 
                    </span> */}
                </div>
            </S.box1>
            <S.box1>
                <div>
                    <span> Data PG Participação: </span>
                    <input 
                    disabled={disabledEven}
                    type='date' 
                    placeholder='Data Participação' 
                    value={evento.dataPart}
                    onChange={e=>setEvento('dataPart',e.target.value)}
                    />
                </div>
                <div>
                    <span>Valor Participação R$ </span>
                    <input 
                    disabled={disabledEven}
                    type="text"
                    placeholder='R$' 
                    value={evento.valorPart}
                    onChange={e=>setEvento('valorPart',e.target.value)}
                    />
                </div>
                
            </S.box1>
            
            
            <S.box1>
                <div>
                    <span>Parte da Associação R$ </span>
                    <input 
                    disabled={disabledEven}
                    type='text'
                    placeholder='R$' 
                    value={evento.newValor_produtos}
                    onChange={e=>setEvento('newValor_produtos',e.target.value)}
                    />
                </div>
                <div>
                    <span> Previsão de Pagamento: </span>
                    <input 
                    disabled={disabledEven}
                    type='date' 
                    value={evento.dataPart}
                    onChange={e=>setEvento('dataPart',e.target.value)}
                    />
                </div>
            </S.box1>
            <S.box1>
                <div style={{flexDirection:'row'}}>
                    <span>Orçamento Terceiro</span>
                    <input 
                    className='terceiroCheckbox'
                    disabled={disabledEven}
                    type={'checkbox'}
                    onChange={(e)=>funcCheckBox(e)}
                    />
                </div>
                {teveTerceiro && <>
                <div>
                    <span>Placa Terceiro:</span>
                    <MaskedInput
                    disabled={disabledEven}
                    value={terceiro.placa}
                    className='uppercase'
                    type='text'
                    placeholder='placa'
                    mask={[/\D/, /\D/, /\D/,/\d/,/\w/,/\d/, /\d/]}
                    onChange={e=>setEventoTerc('placa', e.target.value)}
                    guide={false}
                    />
                </div>
                </>}
            </S.box1>
            {teveTerceiro && <>
                <S.box1>
                <div>
                    <span>Lista de Peças:</span>
                    <input 
                    disabled={disabledEven}
                    type="text"
                    className='maiusculo'
                    placeholder='Lista' 
                    value={evento.ocorrenciaBO}
                    onChange={e=>setEvento('ocorrenciaBO',e.target.value)}
                    />
                </div>
                <div>
                    <span>Lista de Serviços:</span>
                    <input 
                    disabled={disabledEven}
                    type="text"
                    className='maiusculo'
                    placeholder='Lista' 
                    value={evento.ocorrenciaBO}
                    onChange={e=>setEvento('ocorrenciaBO',e.target.value)}
                    />
                </div>
            </S.box1>
            <S.box1>
                <div>
                    <span>Valor Peças R$ </span>
                                        
                    <MaskedInput
                    disabled={disabledEven}
                    value={evento.newValor_produtos}
                    className='uppercase'
                    type='text'
                    placeholder='R$'
                    mask={[/\d/, /\d/, ",",/\d/,/\d/]}
                    onChange={e=>setEventoTerc('newValor_produtos', e.target.value)}
                    guide={false}
                    />
                </div>
                <div>
                    <span>Valor Serviços R$ </span>
                    <input 
                    disabled={disabledEven}
                    type='text'
                    placeholder='R$' 
                    value={evento.newValor_servico}
                    onChange={e=>setEvento('newValor_servico',e.target.value)}
                    />
                </div>
                <div>
                    <span>TOTAL R$ </span>
                    <input
                    disabled={disabledEven}
                    type='text'
                    placeholder='Total' 
                    />
                    {/* <span className={'spa'}>{props.Total.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})} 
                    </span> */}
                </div>
            </S.box1>
            <S.box1>
                <div>
                    <span> Data PG Participação: </span>
                    <input 
                    disabled={disabledEven}
                    type='date' 
                    placeholder='Data Participação' 
                    value={evento.dataPart}
                    onChange={e=>setEvento('dataPart',e.target.value)}
                    />
                </div>
                <div>
                    <span>Valor Participação R$ </span>
                    <input 
                    disabled={disabledEven}
                    type="text"
                    placeholder='R$' 
                    value={evento.valorPart}
                    onChange={e=>setEvento('valorPart',e.target.value)}
                    />
                </div>
                
            </S.box1>
            
            
            <S.box1>
                <div>
                    <span>Parte da Associação R$ </span>
                    <input 
                    disabled={disabledEven}
                    type='text'
                    placeholder='R$' 
                    value={evento.newValor_produtos}
                    onChange={e=>setEvento('newValor_produtos',e.target.value)}
                    />
                </div>
                <div>
                    <span> Previsão de Pagamento: </span>
                    <input 
                    disabled={disabledEven}
                    type='date' 
                    value={evento.dataPart}
                    onChange={e=>setEvento('dataPart',e.target.value)}
                    />
                </div>
            </S.box1>
            </>}
            <textarea 
            disabled={disabledEven}
            rows={5} 
            placeholder='Detalhes da Ocorrencia:' 
            value={evento.descricao}
            onChange={e=>setEvento('descricao',e.target.value)}
            />
        </>}

        <S.divButton>
            {!playModalDetalhesEven && 
            <S.Salvar>
                <button type='button' onClick={addEvento}>Salvar</button>
            </S.Salvar>
            }

            {playModalDetalhesEven && 
            <>
                <S.Atualizar>
                    <button 
                    type='button' 
                    onClick={Atualizar}>Atualizar</button>
                </S.Atualizar>

                <S.Salvar>
                    <button 
                    type='button' 
                    style={{backgroundColor:"#ff7c7c"}} 
                    onClick={buscarPagamentos}>Evento</button>
                </S.Salvar>
                
            </>
            }
        </S.divButton>

        </S.div3>

    </S.body>
  );
}

export default ModalEvento;
