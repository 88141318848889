import React, { useState, useEffect, useContext } from 'react';
import * as S from './styles';
import {Redirect} from 'react-router-dom';
import {Context} from '../../services/contexts/StateContext';
import api from '../../services/api';
import {format} from 'date-fns';
import lupa from '../../midia/lupa.svg';

//---------COMPONENTES----------------------
import Header from '../../componentes/Header/index';
import Footer from '../../componentes/Footer/index';
import BarraLateral from '../../componentes/BarraLateral/index';

import Modalcliente from '../../componentes/Modal/ModalCliente';
import ModalCobranca from '../../componentes/Modal/ModalCobranca';

import {ListaCarnes} from '../../componentes/ListarPesquisas/ListaCarnes';

import {PesquisaPadrao} from '../../componentes/Pesquisas/PesquisaPadrao';
import {Functions, FunctModal} from '../../componentes/Functions';

import LoadingContent from '../../componentes/loading/loading';

//---------SERVICES--------------------------
import {Api_Veiculos} from '../../services/FiltrosApi/veiculos_api';
import {ListaCobranca} from '../../services/FiltrosApi/cobrancaApi';
import {estaLogado} from '../../services/FiltrosApi/LoginToken';
import {UsuarioLog} from '../../services/FiltrosApi/logado';
import {Api_Clientes} from '../../services/FiltrosApi/clienteApi';

// ------------------------------------------
export default function Cobranca(props) {
    const {state, dispatch} = useContext(Context);
    const {cliente, usuario, endereco, foneCelular} = state.cliente;
    const {veiculo} = state.veiculo;
    const {evento} = state.evento;
    const {inforCaixa} = state.produtos;
    const {parametroInit, displayModal, playmodal, listaPesquisa, playLista} = state.parametros;
    const {cobranca} = state.cobrancas;
    const {
        pesquisaIdVeiculo ,
        pesquisaIdEvento,
        pesquisaIdUsuario,
        loading, 
    } = parametroInit;

    const {id_Cliente} = cliente;
    const {id_Usuario} = usuario;
    const {id_Veiculo} = veiculo;
    const {id_Evento} = evento;
    
    const [idPag, setIdPag]=useState('');

    const [concluido, setConcluido]=useState('');

    const [codEvento, setCodEvento]=useState('');

    const [valor_pago, setvalor_pago] = useState('');
    const [somaValorPago, setSomaValorPago]=useState('')
    const [valorPendente, setValorPend] = useState('');
    
    const [checkEven, setcheckEven] = useState(false);
    const [checkPag, setcheckPag] = useState(false);
    
    const [newValor_produtos, editValorProdutos]=useState('');
    const [newValor_servico, editValorServicos]=useState('');
    const [TotalEven, setTotalEven]=useState('');

    const [listaPag, setListaPag] = useState([]);

    const[ModalFormClie, setModalFormClie] = useState(false);
    const[ModalFormVeic, setModalFormVeic] = useState(false);
    const[ModalEve, setModalEve] = useState(false);
    const[ModalPag, setModalPag] = useState(false);
    const[ModalUser, setModalUser] = useState(false);
    const[ModalFranq, setModalFranq] = useState(false);
    const[ModalColab, setModalColab] = useState(false);
    const[ModalServ, setModalServ] = useState(false);
    const[newStatus, setNewStatus]=useState('');
    const[refDelete, setRefDelet]=useState('');

    const[alertDelete, setAletDelete]=useState(false);

    const[etapa, setEtapa]=useState(1);

    let conectado = estaLogado();
    const {idLogado} = UsuarioLog();

    const {PesqVeiculoPlaca} = Api_Veiculos();
    const {pesqidcliente} = Api_Clientes();
    
    const {pesqTodosCarnes} = ListaCobranca();
    const {Play_Modal_Add, ExcluirEste} = Functions();
    const {FecharModal} = FunctModal();

    // ---------------------
    useEffect(() => {
        pesqidcliente();
    }, [id_Cliente]);
    
    // ---------------------
    useEffect(() => {
        if(listaPesquisa.selectItem || veiculo.placa){
            PesqVeiculoPlaca();
        }
    }, [listaPesquisa.selectItem, veiculo.placa]);

    // ---------------------
    useEffect(() => {
        const Veiculo = listaPesquisa.listaVeiculo.filter(e=> listaPesquisa.selectItem === e.placa);
        Veiculo.map(e=>{
            if(e.id_Cliente._id){
                dispatch({
                    type: 'set_cliente',
                    payload:{...cliente, id_Cliente:e.id_Cliente._id}
                }); 
            };
        })
    }, [listaPesquisa.selectItem])

    //---Pesquisar Veiculos por Id-------
    async function pesqIDveiculo(){
        if(id_Veiculo){
            await api.get(`veiculo/${id_Veiculo}`)
            .then(res => {
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaVeiculo:[res.data]}
                });

                dispatch({
                    type: 'SET_parametroInit',
                    payload:{...parametroInit, pesquisaIdVeiculo:false}
                });
                
                if(res.data.id_cliente){
                    dispatch({
                        type: 'set_cliente',
                        payload:{...cliente, id:res.data.id_cliente._id}
                    });
                }
            }) 
        }
    };
    
    //--------------------------------------------
    const setparametroInit = (key, value) => {
        dispatch({
            type: 'SET_parametroInit',
            payload: {...parametroInit, [key]: value}
        });
    };

    // -------------------------------------------
    const paginaEtapa = [1,2]

    function ModalEtapas(){
        if(etapa === 1){
            return (
                <Modalcliente
                FecharModal={FecharModal}
                pesqclienteCpf={(e)=>e}
                playModal_Add={Play_Modal_Add}
                PesqEventoPlaca={PesqEventoPlaca}
                pesqidcliente={pesqidcliente}
                pesquisar={PesqVeiculoPlaca}
                />
            )
        };
        if(etapa === 2){
            return (
                <ModalCobranca
                FecharModal={FecharModal}
                idLogado={idLogado}
                PesqEventoCod={PesqEventoCod}
                PesqIdEvento={PesqIdEvento}
                BuscaTodosEventos={BuscaTodosEventos}
                />
                
            )
        };
        // if(etapa === 3){
        //     return (
        //         <ModalEvento
        //         idLogado={idLogado}
        //         PesqEventoCod={PesqEventoCod}
        //         PesqIdEvento={PesqIdEvento}
        //         BuscaTodosEventos={BuscaTodosEventos}
        //         />
        //     )
        // };
    };
//-------------------------------------------------
    useEffect(() => {
        if(pesquisaIdVeiculo){
            pesqIDveiculo();
        }
    }, [pesquisaIdVeiculo])
    
// ------------------------------------------------
    useEffect(() => {
        if(!parametroInit.filtroCodigoEven){
            BuscaTodosEventos()
        }
    }, [parametroInit.filtroCodigoEven])
    // -------------------
    useEffect(() => {
        if(pesquisaIdEvento){
            PesqIdEvento();
        }
    }, [pesquisaIdEvento])

    // -------------------
    useEffect(() => {
        if(pesquisaIdUsuario){
            pesqIdUsuario();
        }
    }, [pesquisaIdUsuario])
    


    //---Pesquisar Evento por codEvento----------------
    async function PesqEventoCod(){
        if(parametroInit.filtroCodigoEven){
            dispatch({
                type: 'SET_parametroInit',
                payload:{...parametroInit, loading:true}
            });

            await api.get(`/evento/filtrar/${parametroInit.filtroCodigoEven}`)
            .then(res => {
                dispatch({
                    type: 'SET_parametroInit',
                    payload:{...parametroInit, loading:false}
                });
                dispatch({
                    type: 'set_playLista',
                    payload:{...playLista, playListaEven:true}
                });
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaEvento:res.data}
                });
            })
        } else{
            BuscaTodosEventos();
        };

        dispatch({
            type: 'set_cliente',
            payload:{...cliente, id_Cliente:''}
        });
        dispatch({
            type: 'set_veiculo',
            payload:{...veiculo, id_Veiculo:''}
        });
        dispatch({
            type: 'set_Evento',
            payload:{...evento, id_Evento:''}
        });
        dispatch({
            type: 'set_playLista',
            payload:{...playLista, playListaEven:false}
        });
        dispatch({
            type: 'SET_PLAYMODAL',
            payload:{...playmodal, playModalCobranca:false}
        });
    };
    //-------------------------------

    async function BuscaTodosEventos(){
    };
    //-------------------------------

    async function BuscaTodosClientes(){
        dispatch({
            type: 'SET_parametroInit',
            payload:{...parametroInit, detalhesLista:false}
        });

        await api.get('/cliente/todos')
            .then(res => {
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaCliente:res.data}
                });
                dispatch({
                    type: 'set_playLista',
                    payload:{...playLista, playListaClie:true}
                });
            })
    };
    //-------------------------------

    async function BuscaTodosUsuarios(){
        dispatch({
            type: 'SET_parametroInit',
            payload:{...parametroInit, detalhesLista:false}
        });

        await api.get('/usuario/todos')
            .then(res => {
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaUsuario:res.data}
                });
                dispatch({
                    type: 'set_playLista',
                    payload:{...playLista, playListaUsuario:true}
                });
            })
    };

    //---Pesquisar Evento por id-------------
    async function PesqIdEvento(){
        if(id_Evento){
            await api.get(`/evento/id/${id_Evento}`)
            .then(res => {
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaEvento:[res.data]}
                });
                dispatch({
                    type: 'set_playLista',
                    payload:{...playLista, playListaEven:true}
                });
                dispatch({
                    type: 'SET_parametroInit',
                    payload:{...parametroInit, pesquisaIdEvento:false}
                });

            })
        }
    };

    //-----------Pesquisar Usuario por id-------
    async function pesqIdUsuario(){
        if(id_Usuario){
            await api.get(`/usuario/id/${id_Usuario}`)
            .then(res => {
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaUsuario:[res.data]}
                });
                dispatch({
                    type: 'set_playLista',
                    payload:{...playLista, playListaUsuario:true}
                });
                dispatch({
                    type: 'SET_parametroInit',
                    payload:{...parametroInit, pesquisaIdUsuario:false}
                });

            })
        }
    };
    
    //--------Pesquisar Evento por Veiculo------------
    async function pesqEventoXveiculo(){
        if(id_Veiculo){
        await api.get(`/evento/idveiculo/${id_Veiculo}`)
        .then(res => {
            dispatch({
                type: 'set_ListaPesquisa',
                payload:{...listaPesquisa, listaEvento:res.data}
            });
        })
    }}

    //------Pesquisar Evento por Placa-----------------
    async function PesqEventoPlaca(placa){
        if(placa){
            dispatch({
                type: 'SET_parametroInit',
                payload:{...parametroInit, loading:true, detalhesLista:false}
            });

            await api.get(`/evento/${placa}`)
            .then(res => {
                dispatch({
                    type: 'SET_parametroInit',
                    payload:{...parametroInit, loading:false}
                });
                dispatch({
                    type: 'set_playLista',
                    payload:{...playLista, playListaEven:true}
                });
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload:{...listaPesquisa, listaEvento:res.data}
                });
                
                // const busc = res.data
                // busc.map(t=>(
                //     dispatch({
                //         type: 'set_Evento',
                //         payload:{...evento, codEvento:t.codEvento}
                //     }),
                //     setcodEvenBD(t.codEvento) // Pesquisar todos Evento por placa Para comparar os CodEvento
                // ));
            })
        } else{
            BuscaTodosEventos();
        };

        dispatch({
            type: 'set_Evento',
            payload:{...evento, id_Evento:''}
        });

        dispatch({
            type: 'set_caixa',
            payload:{...inforCaixa, dataCaixa:"", listaDespesaLS:[]}
        });

        dispatch({
            type: 'SET_PLAYMODAL',
            payload:{...playmodal, playModalCobranca:false}
        });
    };
    // -------------------------------------
    useEffect(() => {
        if(!parametroInit.filtroPesquisa){
            if(displayModal.usuarioDisplay){
                BuscaTodosUsuarios();
            };
            if(displayModal.clienteDisplay){
                BuscaTodosClientes();
            }
        }
    }, [parametroInit.filtroPesquisa])

    //---------------Pesquisar Pag por Id evento--------------
    async function pesqPagXevento(){
        if(id_Evento){
            await api.get(`paguevento/idevento/${id_Evento}`)
            .then(res => {
                setListaPag(res.data);

                const busc = res.data
                //-----------Soma Valores Pagos---------------
                const restbusc = busc.map(t=>t.valor_pago);
                let soma = 0;
                for(let i = 0; i < restbusc.length; i++){
                    soma += restbusc[i]
                }
                setSomaValorPago(soma)

            });
        }
    };
    // ------------------------------------------
    async function GerarCarne(){
        if(cobranca.vencimento && cobranca.valorParcela && cobranca.N_carnes){

            await api.post(`cobranca/GerarCarne`, {
                userCadastro:idLogado,
                id_Veiculo: veiculo.id_Veiculo,
                id_Franquia:cliente.id_Franquia,

                placa: veiculo.placa,

                nome: cliente.nome ,
                cpfCnpj: cliente.cpfCnpj,
                email: cliente.email ,
                celular: (foneCelular.codigoArea+foneCelular.numeroCel).replace(/\D/g,''),

                nomeProduto:"Mais Apoio Protecao",
                dataVencimento: format(new Date(cobranca.vencimento), 'yyyy-MM-dd'),
                valorParcela: cobranca.valorParcela,
                repeats: cobranca.N_carnes,
            })
            .then(res => {
                console.log(res.data);

                const erro = (res.data.error);
                    if(erro){
                        alert("Preencha os campos corretamente!");
                    }else{
                        alert('Carnes Gerados!!');
                        setEtapa(1);
                        FecharModal();
                    }
            }).catch((error) => {
                alert("Ocorreu um erro", error);
                return error;
            });
        }else{
            alert("Preencha todos os campos!")
        }
    };

    //----Atualizar Evento - Colocar como Pago "concluido" ou Aberto-----
    async function AtualizarConcluido(){
        if(somaValorPago >= TotalEven && concluido === false){
            await api.put(`/evento/atualizar/${id_Evento}`, { 
                concluido: true,
                data_modificado:Date.now(),
                        
            }).then(res => {
                if(res.data.error){
                    alert('erro')
                } else{
                    alert('Evento Pago!');
                }
            })
        }; 
    
        //--------
        if(somaValorPago < TotalEven && concluido === true){
            await api.put(`/evento/atualizar/${id_Evento}`, { 
                concluido: false,
                data_modificado:Date.now(),
                        
            }).then(res => {
                if(res.data.error){
                    alert('erro')
                } else{
                    alert('Evento Esta em Abeto!');
                }
            })
        };
    };

    //-----------------------
    useEffect(() => {
        AtualizarConcluido()
    }, [somaValorPago && TotalEven && concluido])

    //-----------Valores Falta Pagar Pendente-------
    useEffect(() => {
        setValorPend(TotalEven - somaValorPago) 
    }, [valor_pago, newValor_servico, newValor_produtos, somaValorPago])

    //----------Pesquisar Pag por id pag------------
    async function PesqIdPag(){
        if(idPag){
            await api.get(`/paguevento/${idPag}`)
            .then(res => {
                
                setListaPag([res.data]);
            });
        }
    };

    //----------------------------------
    useEffect(() => {
        if(checkEven){
            if(!checkPag){  
            pesqPagXevento(); // pesquisar todos os pagamento deste evento
        }}
        if(!checkEven){ 
        setcheckPag(false);
        setIdPag();
        setListaPag([])
        }    
    }, [checkEven])

    // ---------------------------------
    useEffect(() => {
        dispatch({
            type:'SET_parametroInit',
            payload: {
                ...parametroInit, 
                detalhesLista: false,
                detalhesLista: false, 
            }
        });
    }, [parametroInit.filtroPlaca])

    //---------------------------------
    useEffect(() => {
        if(checkPag){
            PesqIdPag() // pesquisar id do pagamento
            setcheckPag(idPag) //selecionar o id pesquisado
        }
    }, [idPag])

    //-----------Se checkEven for desselecionado fazer pesquisa de todos eventos----
    useEffect(() => {
        if(!checkEven)
        pesqEventoXveiculo();
    }, [checkEven])

    //-----------Se checkPag for desselecionado fazer pesquisa de todos Pagamentos----
    useEffect(() => {
        if(!checkPag)
        pesqPagXevento();
    }, [checkPag])
    //--------------------------------------------------
    function FecharAlerte(){
        setAletDelete(false)
    };
    
    //------------Somar o Total------------------------
    const[Total, setTotal]=useState(0);
    let tt = parseFloat(newValor_servico)+parseFloat(newValor_produtos);

    useEffect(() => {
        if(tt){
            setTotal(tt)
    }
    }, [tt])

    //-------------------Gerar Codigos------------------
    const [codEvenBD, setcodEvenBD] = useState('');

    useEffect(() => { 
        let inic = (1);
        let codinic =  veiculo.placa+'-0'+inic;
        const regex = new RegExp(codinic, 'i'); // Expresao regular
        let verific = regex.test(codEvenBD) // verificar se ja existe este codigo no banco de dados
        //---------
        if(verific) { // se cod for igual, gerar novo
            for(; verific; inic++){ 
                let codNovo = veiculo.placa+'-0'+inic
                const regex = new RegExp(codNovo, 'i');
                let verific = regex.test(codEvenBD)
                
                if(!verific){
                    dispatch({
                        type: 'set_Evento',
                        payload: {...evento, codEvento:codNovo}
                    });
                    break
                }
            };
        }else{
            if(veiculo.placa){
                dispatch({
                    type: 'set_Evento',
                    payload: {...evento, codEvento:codinic}
                });
            }
        }
    }, [codEvenBD && veiculo.placa]);

    //---------------------------------------------------
    const [abertoEvento, setabertoEvento] = useState([]);
    const [pendenteEvento, setpendenteEvento] = useState([]);
    const [Concluidovento, setConcluidovento] = useState([]);
    //---------------------------------------------------
    useEffect(() => {
        const aber = listaPesquisa.listaEvento.filter(ev=> ev.status === "A");
        const pend = listaPesquisa.listaEvento.filter(ev=> ev.status === "P");
        const concluido = listaPesquisa.listaEvento.filter(ev=> ev.status === "C");
        setabertoEvento(aber);
        setpendenteEvento(pend);
        setConcluidovento(concluido);
    }, [listaPesquisa.listaEvento]);

    //---------------------------------------------------
 
    
//###################################################################################################
  return (
    <S.App 
    fundoBloque={
        alertDelete || 
        playmodal.playModalCobranca && !parametroInit.detalhesLista ||
        playmodal.playModalCliente && !parametroInit.detalhesLista
    }>
        { !conectado && <Redirect to='/login' />}

        {playmodal.playModalCobranca &&
        <div className='fundoBloque'>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <div style={{display:"flex", flexDirection:"column", position:"fixed", top:"50px"}}>
                    <div style={{display:"flex", width:"50%", minWidth:"350px",'justifyContent':"center", "gap":"15px",}}>
                        {paginaEtapa.map( p =>
                        <div 
                        onClick={()=> {listaPesquisa.selectItem && setEtapa(p)}}
                        key={p} 
                        className='BotaoPagina' 
                        style={{backgroundColor: etapa === p? 'gray':"green", scale: etapa === p? '1.3':"none" }} 
                        >
                            {p}
                        </div>
                        )}
                    </div>
                    <span style={{'width':"350px", height:"15px", borderBottom: "1.5px solid white", marginBottom:"10px"}}/>
                </div>

                <div style={{display:"flex", position:"fixed", top:"130px"}}>
                    {ModalEtapas()}
                </div>

                {listaPesquisa.selectItem &&            
                <S.Etapas>
                    {etapa !== 1 &&
                    <S.Button 
                    onClick={()=> setEtapa(etapa - 1)}
                    disabled={etapa === 1}
                    >
                        Volta
                    </S.Button>
                    }
                    <S.Button
                    style={{backgroundColor: etapa === 2 && '#3881ff', color: etapa === 2 && "white"}}
                    onClick={()=> {etapa !== 2? setEtapa(etapa + 1) : GerarCarne()}}
                    >
                        {etapa === 2? "Enviar":"Proximo"}
                    </S.Button>
                </S.Etapas>
                }
            </div>
        </div>
        }

        <Header playLogo={true}/>
        <S.body>
            <S.BarraLateral>
                <BarraLateral 
                setModalFormClie={setModalFormClie}
                setModalFormVeic={setModalFormVeic} 
                setModalEve={setModalEve}
                setModalPag={setModalPag}
                setModalUser={setModalUser}
                setModalFranq = {setModalFranq}
                setModalColab = {setModalColab}
                setModalServ = {setModalServ}

                ModalFormClie={ModalFormClie}
                ModalFormVeic={ModalFormVeic}
                ModalEve={ModalEve}
                ModalPag={ModalPag}
                ModalUser={ModalUser}
                ModalFranq = {ModalFranq}
                ModalColab = {ModalColab}
                ModalServ = {ModalServ}
                codEvento={codEvento}
                
                limparPesq={()=>{}}
                />
            </S.BarraLateral>

            {/* --------------------Pesquisa----------------------- */}
            <S.pesquisa>       
                <S.containerPesq>
                    <PesquisaPadrao
                    playBotao={true}
                    lupa={lupa}
                    titulo="Pesquisar Boletos"
                    placeholder="placa"
                    nomeBotao="Gerar Cobrança"
                    playModal_Add={Play_Modal_Add}
                    Filtrar={setparametroInit}
                    pesquisar={pesqTodosCarnes}
                    />
                </S.containerPesq>    

                <S.ContainerLista>
                    <S.bodyLista>
                        {loading && !playmodal.playModalCobranca &&
                        <S.bodyresult>
                            <LoadingContent/>
                        </S.bodyresult>
                        }

                        {!loading && 
                        <div style={{'display':"flex", 'flexDirection':'column'}} >
                            <ListaCarnes
                            listaBusca={listaPesquisa.listaCarnes}
                            setAletDelete={setAletDelete}
                            setNewStatus={setNewStatus}
                            alertDelete={alertDelete}
                            pesqclienteCpf={(e)=>e}
                            setRefDelet={setRefDelet}
                            FecharAlerte={FecharAlerte}
                            ExcluirEste={ExcluirEste}
                            />
                        </div>
                        } 
                    </S.bodyLista>
                </S.ContainerLista> 
            </S.pesquisa>
        </S.body>

        <Footer/>
    </S.App>   
)}
