import React, { useContext} from 'react';
import * as S from './styles';
import {Redirect} from 'react-router-dom';

import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {format, parse, startOfWeek,endOfWeek, getDay, add} from 'date-fns';
import enUS from 'date-fns/locale/en-US';

import Header from '../../componentes/Header/index';
import Footer from '../../componentes/Footer/index';
import api from '../../services/api';

import {Context} from '../../services/contexts/StateContext';
import util from '../../services/util';


//------------------------------------------------------------------------------
function Agendamento() {
  const {state, dispatch} = useContext(Context);
  const {logado} = state.cliente;
  const {agendamentoslista} = state.agenda;
//---------------------------------------------------------------------
const formatRange = (periodo) => {

  let finalRange = {};

  if(Array.isArray(periodo)){
    finalRange = {
      start: format(new Date(periodo[0]), 'yyyy-MM-dd'),
      end: format(new Date(periodo[periodo.length -1]), 'yyyy-MM-dd'),
    };
  }else {
    finalRange = {
      start: format(new Date(periodo.start), 'yyyy-MM-dd'),
      end: format(new Date(periodo.end), 'yyyy-MM-dd'),
    };
  }

  return finalRange
};
//------------------------------------------------------------------------
async function buscarAgendamentos(start, end){
  await api.post('/evento/agenda',{
  id_Franquia:"61bf3abda50d3d3174519c27",
  periodo:{
      inicio:startOfWeek(new Date(start)),
      final:endOfWeek(new Date(end))
  }
})
  .then(res => {
      dispatch({
          type: 'SET_AGENDA',
          payload: res.data.agendamento,
        });
  })
  .catch((error) => {
      alert("Ocorreu um erro ao buscar os items");
  });

};

//---------------------------------------------------------
const formatEventos = agendamentoslista.map(t => ({
      title: t.id_Servico.titulo,
      start: new Date(t.dataEvento),
      end: add(new Date(t.dataEvento), { minutes:util.horasToMinutos(format(new Date(t.id_Servico.tempo_serv),'kk:mm')) }),
    }))

// const formatEventos = [{
//   title:'RRRRR', 
//   start:'2022-05-05T10:30:00',
//   end: '2022-05-05T11:30:00'
// }]
//---------------------------------------------------------
const locales = {
  'en-US': enUS,
}
//--------------------
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
  add
});
//--------------------

// ##################################################################################
  return (
  
  <S.App>
    { !logado.conectado && <Redirect to='/login' />}
      
    <Header 
    conectado={logado.conectado}
    />
        <S.body>
          
            <Calendar
              localizer={localizer}
              onRangeChange={(periodo) => { 
                const {start, end} = formatRange(periodo);
                buscarAgendamentos(start, end);
              }}
              events={formatEventos}
              selectable
              popup
              defaultView='week'
              style={{ height: 700, width:1000 }}
            />

        </S.body>

      <Footer/>
    </S.App>
  );
  
}

export default (Agendamento);
