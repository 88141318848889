import { useState, useEffect, useContext } from 'react';
import api from '../../services/api';
import {Context} from '../../services/contexts/StateContext';


//---------------Listar Banco por id_Recebedor--------------
export function BancoRecebedor(){
    const {state, dispatch} = useContext(Context);
    const {cobranca} = state.cobrancas;
    const {cliente} = state.cliente;
    const {id_Cliente} = cliente;
    const {parametroInit, listaPesquisa} = state.parametros;
    const {bcarBanco} = parametroInit;
    const {id_Recebedor} = cobranca;
    const [lista, setlista]=useState([]);

    async function pesquisar(){
        if(id_Cliente){
        await api.get(`contabanco/filtrar/${id_Cliente}`)
        .then(res => {
            const buscar = res.data;
            setlista(buscar);
            dispatch({
                type: 'set_ListaPesquisa',
                payload:{...listaPesquisa, listaBanco:buscar}
            });
        })
    }};
    useEffect(() => {
        if(bcarBanco){
            pesquisar()
        }  
    }, [bcarBanco])

    return lista
};
//---------------Listar Bancos por CPF--------------
export function BancoCpf(){
    const [lista, setlista]=useState([]);
    const {state} = useContext(Context);
    const {cobranca} = state.contabanco;
    const cpfCnpj = cobranca.cpfCnpj;
    
    async function pesquisar(){
        if(cpfCnpj){
        await api.get(`contabanco/filtrar/${cpfCnpj}`)
        .then(res => {
            const busca = res.data;
            setlista(busca);
        })
    }};
    useEffect(() => {
            pesquisar()
        }, [cpfCnpj])

    return lista
};



