import styled from 'styled-components';


// --------------------------------------------
export const FiltroArea = styled.div`
display:flex;

button{
background:none;
border:none;
outline:0;
}
`
//------------------------------------------------------
export const FiltroLateral = styled.div `
display: flex;
color: white;
border-right:1px solid white; 
min-height: 100vh;


`
export const containe1 = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top:70px;
  margin-right: 10px;
  

  select, input{
  border:none;
  border-radius: 3px;
  outline: none;
  font-size: 14px;
  }

  select{
    width: 140px;
    height: 25px;
    margin: 5px 5px;
  }
  
  button{
    width: 90px;
    height: 30px;
    border:none;
    outline: none;
    border-radius: 3px;
    background-color: #5656F7;
    cursor: pointer;
    color: white;
    margin: 5px 5px;
    opacity:0.5 ;

    &:active{
      opacity:1;
      
    }
  }
  `
export const pesq = styled.div `
display: flex;

`
 export const box = styled.div `
 display: flex;
 margin: 5px 5px;
 flex-direction: column;
 
 input{
  width: 140px;
  height: 25px;
  }
`
export const check = styled.div `
 display: flex;
 color: white;

`
export const result = styled.div`
display: flex;
flex-direction: row;
width: 160px;
min-height: 25px;
border-radius: 7px;
margin-top: 20px;
background-color: white;
cursor: pointer;

.checkbox{
  display:flex;
  align-self:center;

}
span{
  display:flex;
  flex:1;
  justify-content:space-between;
  align-items:center;
  margin:3px;
  color: black;
}
`


// --------------------------------------------

