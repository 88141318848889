import React, { useState, useEffect, useContext, useRef } from 'react';
import { Context } from '../../services/contexts/StateContext';
import * as S from './styles';
import LoadingContent from '../../componentes/loading/loading';
import Tesseract from 'tesseract.js';
import imgFrente from "../../midia/vistoria/carFrente.png";
import imgFundo from "../../midia/vistoria/carFundo.png";
import imgLado1 from "../../midia/vistoria/carLado1.png";
import imgLado2 from "../../midia/vistoria/carLado2.png";

//----------------------------------------------------
export function CameraWeb(props) {
    const { state, dispatch } = useContext(Context);
    const { parametroInit } = state.parametros;
    const { loading } = parametroInit;

    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [recording, setRecording] = useState(false);
    const [chunks, setChunks] = useState([]);
    const [imagCaptured, setimagCaptured] = useState([]);
    const arrayTeste = ["CHASSI", "FRENTE", "FUNDO", "LADO MOTORISTA", "LADO PASSAGEIRO", "CAPO ABERTO"];
    const [nomePosicao, setNomePosicao] = useState('');
    //----------------------------------------------------

    // iniciar a camera
    async function iniciarCamera() {
        setimagCaptured([]);
        try {
            const constraints = {
                audio: false,
                video: {
                    width: { ideal: 1280 },
                    height: { ideal: 720 },
                    frameRate: { ideal: 10, max: 30 },
                    facingMode: "environment", //camera traseira
                },
            };
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            setStream(stream);
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            };
        } catch (error) {
            console.error("Erro ao acessar a camera", error);
        };
    };

    // Parar Camera
    async function pararCamera() {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
            setStream(null);
        };
    };

    // Capturar foto
    async function capturarFoto() {
        if (videoRef.current && canvasRef.current && stream) {
            const context = canvasRef.current.getContext('2d');

            // Ajustar Tamanho do canvas para resolução da camera
            const video = videoRef.current;
            canvasRef.current.width = video.videoWidth;
            canvasRef.current.height = video.videoHeight;

            //desenhando a imagen no canvas
            context.drawImage(video, 0, 0, canvasRef.current.width, canvasRef.current.height);

            // Adicionando data e hora na imagem
            const captureTime = new Date().toLocaleString();
            context.font = "40px Arial";
            context.fillStyle = "white";
            context.strokeStyle = "black";
            context.lineWidth = 2;
            context.textAlign = "right";
            context.textBaseline = "bottom";

            // Posicionando a data e hora na imagem
            context.strokeText(captureTime, canvasRef.current.width - 30, canvasRef.current.height - 30); // Borda
            context.fillText(captureTime, canvasRef.current.width - 30, canvasRef.current.height - 30); // texto              

            // Converte o canvas para uma URL de imagem
            const imgURL = canvasRef.current.toDataURL('image/png', 1.0); // 1.0 é a qualidade da imagem 100% // 0.8 = 80%
            setimagCaptured((img) => [...img, imgURL]);
            // pode fazer o upload ou exibir a imagem
        };
    };


    const [arrayImgPosition, setarrayImgPosition] = useState([]);
    // ----------------
    useEffect(() => {
        pararCamera();
    }, [imagCaptured])

    // nome imagens
    const NomeImage = (imgUrl) => {
        const nome = { nome: `${nomePosicao}.png`, href: imgUrl };
        if (nome) {
            setarrayImgPosition((img) => [...img, nome]);
            setimagCaptured([]);
        }
    };
    console.log(arrayImgPosition)

    // Baixar imagens
    const DownloadImage = (imgUrl, index) => {
        const link = document.createElement('a');
        link.href = imgUrl;
        link.download = `foto_${index}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Iniciar Gravação video
    async function iniciarGravacao() {
        if (stream) {
            mediaRecorderRef.current = new MediaRecorder(stream, {
                mimeType: 'video/webm',
            });
            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data && event.data.size > 0) {
                    setChunks((prevChunks) => [...prevChunks, event.data]);
                }
            };
            mediaRecorderRef.current.start();
            setRecording(true);
        }
    };

    // Parar Gravação video
    async function pararGravacao() {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setRecording(false);
        }
    };

    // Baixar video Gravado
    async function baixarVideo() {
        const blob = new Blob(chunks, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'video.webm';
        a.click();
        URL.revokeObjectURL(url);
    };


    // #####################
    return (
        <S.bodyCamera>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
                <div style={{ display: stream ? "flex" : "none", position: "fixed", top: 0, bottom: 0, zIndex: 1 }}>
                    <div style={{ position: "absolute", top: "100px", right: "50px" }}>
                        <S.fechar onClick={pararCamera}>
                            <span style={{ fontSize: "15px", padding: "2px 6px" }}>X</span>
                        </S.fechar >
                    </div>
                    {/* CAMERA ATIVA */}
                    <video
                        ref={videoRef}
                        autoPlay
                        playsInline
                        muted
                        style={{ width: "90vw", height: "90vh", borderRadius: "10px", maxWidth: "500px" }}
                    />
                    <canvas ref={canvasRef} style={{ display: "none" }} />
                </div>

                <S.CameraButton>
                    {
                        stream &&

                        <button onClick={capturarFoto} className='capturar' />
                    }
                    {/* <button onClick={iniciarCamera} style={{borderRadius:"50%", width:"35px", height:"35px", backgroundColor:"blueviolet"}}/> */}
                    {/* <button onClick={pararCamera}>Parar Camera</button> */}
                    {/* <button onClick={iniciarGravacao} disabled={!stream || recording} >Iniciar Gravação</button> */}
                    {/* <button onClick={pararGravacao} disabled={!recording}> Parar Gravação</button> */}
                    {/* <button onClick={baixarVideo} disabled={chunks.length === 0}> Baixar Video</button> */}
                </S.CameraButton>
            </div>

            {imagCaptured.length > 0 && (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {imagCaptured.map((img, index) => (
                        <div key={index} style={{ display: "flex", width: "90vw", alignItems: "center", flexDirection: "column", marginBottom: "20px" }}>
                            <h2>{nomePosicao}</h2>
                            <img
                                src={img}
                                alt={`Capturada ${index + 1}`}
                                style={{ width: "90%", maxWidth: "500px", borderRadius: "10px", border: "2px solid blue" }}
                            />
                            <div style={{ display: "flex", gap: "10px" }}>
                                <button
                                    onClick={() => NomeImage(img)}
                                    style={{ display: "flex", marginTop: "10px", backgroundColor: "#008cff", color: "white" }}>
                                    Ficou Otima
                                </button>

                                <button
                                    onClick={() => iniciarCamera()}
                                    style={{ display: "flex", marginTop: "10px", backgroundColor: "red" }}>
                                    Não Gostei
                                </button>
                            </div>

                            {/* <button 
                    onClick={()=> DownloadImage(img, index)} 
                    style={{display:"flex", marginTop:"10px"}}>
                        Download {index + 1}
                    </button> */}
                        </div>
                    ))}
                </div>
            )}
            <div style={{ display: !stream ? "flex" : "none", marginTop: "10px" }}>
                <div style={{ display: "flex", gap: "10px", maxWidth: "500px", overflow: "hidden", flexWrap: "wrap" }}>
                    {arrayTeste.map((PO, k) => (
                        <div key={k} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span>{PO}</span>

                            {/* ["CHASSI", "FRENTE", "LADO MOTORISTA", "FUNDO", "LADO PASSAGEIRO"]; */}
                            {arrayImgPosition.filter(t => t.nome === `${PO}.png`).map(i => i.href).length === 0 ?
                                <div style={{ display: "flex", width: "100px", height: "100px", backgroundColor: "white", alignItems: "center" }}>
                                    <img
                                        src={
                                            PO === "FRENTE" ? imgFrente : PO === "FUNDO" ? imgFundo :
                                                PO === "LADO MOTORISTA" ? imgLado1 : PO === "LADO PASSAGEIRO" ? imgLado2 : ""
                                        }
                                        alt={`Capturada`}
                                        onClick={() => { iniciarCamera(); setNomePosicao(PO) }}
                                        style={{ display: "flex", backgroundColor: "white", width: "100px", height: "70px" }}
                                    />
                                </div>
                                :

                                <img
                                    src={arrayImgPosition.filter(t => t.nome === `${PO}.png`).map(i => i.href)}
                                    alt={`Capturada`}
                                    onClick={() => { iniciarCamera(); setNomePosicao(PO) }}
                                    style={{ display: "flex", backgroundColor: "white", width: "100px", height: "100px" }}
                                />
                            }
                        </div>
                    ))}
                </div>
            </div>

        </S.bodyCamera>
    );
};

