import eventoTeste from '../../services/FiltrosApi/eventosTeste';

export const parametrosInicialState = {
    displayModal: {
        clienteDisplay: false,
        veiculosDisplay: false,
        eventoDisplay: true,
        orcamentoDisplay: false,
        pagamentoDisplay: false,
        franquiaDisplay: false,
        colaboradorDisplay: false,
        servicoDisplay: false,
        usuarioDisplay: false,
        bancoDisplay: false,
        CobrancaDisplay: false,
        contaDisplay: false,
        vistoriaDisplay: false,
        testeDisplay: false
    },
    parametroInit: {
        detalhesModal: false,
        detalhesVeic: false,
        detalhesBanco: false,
        detalhesLista: false,

        atualizarModal: false,

        clickFora: false,
        editModal: false,

        editModalVeic: false,
        editModalBanco: false,
        editModalColab: false,
        editModalUsuario: false,

        disabled: false,
        disabledEven: false,
        disabledPag: false,
        disabledBanco: false,
        disabledSorteio: false,

        buscarCliente: false,
        buscarVeic: false,
        buscarEven: false,
        buscarBanco: false,
        buscarServico: false,

        novoCadastro: false, //modalEventoPlaca
        editarModelo: false,
        botaoCPF: false,

        recebedor: '',
        contaBanco: '',
        bancoouRecebedor: '',

        checkClie: false,
        checkVeic: false,
        checkBanco: false,

        loading: false,

        filtroPesquisa: '',
        filtroNome: '',
        filtroCpf: '',
        filtroPlaca: '',
        filtroCodigoEven: '',

        pesquisaCpf: false,
        pesquisaIdCliente: false,
        pesquisaIdVeiculo: false,
        pesquisaIdEvento: false,
        pesquisaPlaca: false,
        pesquisaIdUsuario: false,

        tipoOperacao: 'ENTRADA',
    },
    playLista: {
        playListaClie: false,
        playListaVeic: false,
        playListaBanco: false,
        playListaEven: false,
        playListaPagto: false,
        playListaBenef: false,
        playListaRelatorio: false,
        playListaUsuario: false,
        playListaCobranca: false,
    },

    playmodal: {
        playModalCliente: false,
        playModalVeiculo: false,
        playModalEven: false,
        playModalEvenPlaca: false,
        playModalDetalhesEven: false,
        playModalBenef: false,
        playModalBanco: false,
        playModalColab: false,
        playModalFranquia: false,
        playModalSorteio: false,
        playModalLogin: false,
        playModalUsuario: false,
        playModalDespesa: false,
        playModalPagamento: false,
        playModalCobranca: false,
        playModalConta: false,
        playModalEditar: false,

    },

    listaPesquisa: {
        listaCliente: [],
        listaVeiculo: [],
        listaEvento: [], // eventoTeste é um objeto json
        listaPagamentos: [],
        listaOrcamento: [],
        listaCarnes: [],
        listaBanco: [],
        listaSorteio: [],
        listaUsuario: [],
        listaTipo: [],
        listaItem: [],
        listaItemLS: [],
        listaTipoItem: [],
        listaFornecedor: [],
        listaArquivos: [],
        listaPagina: [],

        nomeItem: null,
        selectItem: '',
        categoriaItem: "",
        novoItem: '',
        valorItem: "",
        codigoAtualiza: "",
        alertaNovo: false,

        nomeForn: "",
        SelectForn: "",
        id_nomeForn: false,
    },
}
//-----------------------------------------------------
export const parametrosReducer = (state = parametrosInicialState, action) => {
    switch (action.type) {
        case 'SET_Display':
            return { ...state, displayModal: action.payload };

        case 'SET_parametroInit':
            return { ...state, parametroInit: action.payload };
        case 'set_playLista':
            return { ...state, playLista: action.payload };

        case 'SET_PLAYMODAL':
            return { ...state, playmodal: action.payload };

        case 'set_ListaPesquisa':
            return { ...state, listaPesquisa: action.payload };

        case 'set_limparlistaPesquisa':
            return { ...state, listaPesquisa: parametrosInicialState.listaPesquisa };

        case 'InicialPlayModal':
            return { ...state, playmodal: parametrosInicialState.playmodal };

        case 'InicialDisplayModal':
            return { ...state, displayModal: parametrosInicialState.displayModal };

        default:
            return state;

    }
};
//-----------------------------------------------------
