import React, { useState, useEffect, useContext } from 'react';
import api from '../api';
import {Context} from '../contexts/StateContext';


//---------------Listar Franquia--------------
export const ListaFranquia = ()=>{

    const [lista, setLista]=useState([]);

    async function buscarFranquia(){
        await api.get('/franquia/tudo')
        .then(res => {
            const busca = res.data;
            const result = busca.map(s=> ({value:s._id, label:s.nome}) )
            setLista(result);
        });
    };
    useEffect(() => {
        buscarFranquia()
        }, [])

    return lista
};
//---------------Listar por CPF/CNPJ--------------
export const ListaFranquiaCPF = ()=>{
    const {state} = useContext(Context);
    const {franquia} = state.franquia;
    const [lista, setLista]=useState([]);

    const cpfCnpj = franquia.cpfCnpj

    async function buscarFranquia(){
        if(cpfCnpj){
            await api.get(`/franquia/filtrar/${cpfCnpj}`)
            .then(res => {
                setLista(res.data);
            });
        };
    };
    useEffect(() => {
        buscarFranquia()
        }, [cpfCnpj])

    return lista
};
//---------------Listar Todos Colaborador por Franquia--------------
export const ColabFranquia=()=>{
    const [lista, setLista]=useState([]);
    const {state, dispatch} = useContext(Context);
    const {cliente} = state.cliente;
    const {franquia} = state.franquia;
    const id_Franquia = cliente.id_Franquia;
    
    async function buscar(){
        if(id_Franquia){
        await api.get(`/franquia/colaborador/${id_Franquia}`)
        .then(res => {
            const busca = res.data;
            const result = busca.map(s=> ({value:s._id, label:s.nome}) )
            setLista(result);

            dispatch({
                type:"SET_Franquia",
                payload: {...franquia, "listaColaborador": result}
            })
        });
        }
    };
//----------------------------------------------
    useEffect(() => {
        buscar()
        }, [id_Franquia])

    return lista
};

//---------------Listar Todos Servicos--------------
export const Servicos=()=>{
    const [lista, setServico]=useState([]);
    const {state, dispatch} = useContext(Context);

    async function buscarServicos(){
        
        await api.get(`/servico/todos`)
        .then(res => {
            const busca = res.data;
            const result = busca.map(s=> ({value:s._id, label:s.titulo}) )
            setServico(result);
        });
        
    };
//----------------------------------------------
    useEffect(() => {
        buscarServicos()
    }, [])

    return lista
};
//---------------Listar Todos Servicos por Franquia--------------
export const ServFranquia=()=>{
    const [listaServico, setServico]=useState([]);
    const {state, dispatch} = useContext(Context);
    const {colaborador} = state.colaborador;
    const {franquia} = state.franquia;
    const id_Franquia = colaborador.id_Franquia;

    async function buscarServicos(){
        if(id_Franquia){
        await api.get(`/franquia/servico/${id_Franquia}`)
        .then(res => {
            const busca = res.data;
            const result = busca.map(s=> ({value:s._id, label:s.titulo}) )
            setServico(result);

            dispatch({
                type:"SET_Franquia",
                payload: {...franquia, "listaServicos": result}
            })
        });
        }
    };
//----------------------------------------------
    useEffect(() => {
        buscarServicos()
        }, [id_Franquia])

    return listaServico
};
//---------------------------------------------



