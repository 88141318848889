import React, {useEffect, useState, useContext} from 'react';
import api from '../../../services/api';
import * as S from './styles';
import {Context} from '../../../services/contexts/StateContext';

import {UsuarioLog} from '../../../services/FiltrosApi/logado';
import {CidadesApi} from '../../../services/FiltrosApi/cidadesApi';

import estados from '../../../services/FiltrosApi/estados.json';

export function Passo01_Placa(props) { 
    const {state, dispatch} = useContext(Context);
    const {cliente, endereco, foneCelular} = state.cliente;
    const {veiculo} = state.veiculo;
    const {franquia} = state.franquia;
    
    const {parametroInit, displayModal, playmodal,playLista, listaPesquisa} = state.parametros;
    const {editModalVeic, detalhesVeic} = parametroInit; 
    const {
    } = parametroInit;

    const {idLogado} = UsuarioLog();
    

    const ListaCidades = CidadesApi(estados);
//---------------------------------------------------
const setVeiculo = (key, value) => {
    dispatch({
        type: 'set_veiculo',
        payload: {...veiculo, [key]: value}
        });
};
//---------------------------------------------------
const setcliente = (key, value) => {
    dispatch({
        type: 'set_cliente',
        payload: {...cliente, [key]: value}
        });
};
//---------------------------------------------------
const setEndereco = (key, value) => {
    dispatch({
        type: 'set_endereco',
        payload: {...endereco, [key]: value}
        });
};

const setFone = (key, value) => {
    dispatch({
        type: 'set_fone',
        payload: {...foneCelular, [key]: value}
        });
};
//---------------------------------------------------
useEffect(() => {
    dispatch({
        type: 'SET_Franquia',
        payload: {...franquia, id: veiculo.id_Franquia}
        });
}, [veiculo.id_Franquia])
//-----------------Limpar apos Salvar com sucesso--------------------
function limparModal(){
    dispatch({
        type: 'set_limparVeiculo'
        });
};
//--------------------------Atualizar-------------------------------------------
function Atualizar(){
    if(detalhesVeic){
        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit, 
                editModalVeic:true, 
                disabledVeic:false, 
                detalhesVeic:false
            }
        }) 
    };
    dispatch({
        type: 'set_playLista',
        payload: {...playLista, playListaBenef:false, playListaEven:false}
    });
};
//-----------------------------------------------
function MaisDetalhes(){
    dispatch({
        type: 'set_ListaPesquisa',
        payload: {...listaPesquisa, listaCliente:[]}
      });
    
    dispatch({
    type: 'SET_Display',
    payload: {
        ...displayModal, 
        veiculosDisplay: true, 
        clienteDisplay: false,
    }
    });

    dispatch({
    type: 'set_limparcliente',
    });
    if(!displayModal.clienteDisplay){
        props.pesqIDveiculo();

    }
    
};
//----------------------------Add--------------------------------------------------
async function addVeiculo(){
    if(parametroInit.editModalVeic===false){

        await api.post('/veiculo', { //post - Criar
            userCadastro:idLogado,
            id_Cliente:veiculo.id_Cliente,
            id_Franquia:veiculo.id_Franquia,
            colaborador:veiculo.colaborador,

            placa:veiculo.placa,
            chassi:veiculo.chassi,
            anoMod:veiculo.anoMod,
            marca:veiculo.marca,
            modelo:veiculo.modelo,
            cor:veiculo.cor,  

            id_Tipo:veiculo.id_Tipo,
            id_Plano:veiculo.id_Plano,

            n_motor:veiculo.n_motor,
            renavam:veiculo.renavam,
            anoFab:veiculo.anoFab,
            km:veiculo.km,
            estado:veiculo.estado,
            cidade:veiculo.cidade,
            valorFipe:veiculo.valorFipe,
            codigoFipe:veiculo.codigoFipe,
            status:veiculo.status, 
            dataCadastro:veiculo.dataCadastro,

        }).then(res => {
            const erro = (res.data.error);
            if(erro){
                if(erro.placa){
                    return alert(erro.placa.msg);
                }
            }else{
                if(parametroInit.editModalVeic===false){
                    alert('Veiculo Salvo!');
                }
                limparModal();
                props.buscarVeiculos();
            }
        })
    }
  //-------Editar-------------------------------
    if(parametroInit.editModalVeic){
        await api.put(`/veiculo/atualizar/${veiculo.id_Veiculo}`, { 
            
            id_Cliente:veiculo.id_Cliente,
            id_Franquia:veiculo.id_Franquia,
            colaborador:veiculo.colaborador,

            id_Tipo:veiculo.id_Tipo,
            id_Plano:veiculo.id_Plano,
            
            placa:veiculo.placa,
            chassi:veiculo.chassi,
            anoFab:veiculo.anoFab,
            anoMod:veiculo.anoMod,
            marca:veiculo.marca,
            modelo:veiculo.modelo,
            cor:veiculo.cor, 
            n_motor:veiculo.n_motor,
            renavam:veiculo.renavam, 
            
            km:veiculo.km,
            estado:veiculo.estado,
            cidade:veiculo.cidade,
            valorFipe:veiculo.valorFipe,
            codigoFipe:veiculo.codigoFipe,
            status:veiculo.status, 

            data_modificado:Date.now(),
                    
        }).then(res => {
            if(res.data.error){
                alert('erro')
            } else{
                alert('Veiculo Atualizado!');
                limparModal();
                FecharModal();
                props.pesqIDveiculo();
            }
        })
    }
};
//--------------------------------------
function buscarEventos(){
    if(!playLista.playListaEven){
        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit, 
                buscarEven: true, 
                editModal:false,
            }
        });
        dispatch({
            type: 'set_playLista',
            payload:{...playLista, playListaEven:true}
        });
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {...playmodal, playModalBenef:false}
        });
            
    }else{
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {...playmodal, playModalBenef:false}
        });
    }
};
//-----------------------------------------------------------------
function buscarBeneficios(){

}
//----------Pesquisar e Selecionar Cliente em Novo Veiculo---------------------
const[valuePesq, setvaluePesq]=useState('');
const[listaCli, setlistaCli]=useState([]);
const[playlistaCli, setplaylistaCli]=useState(true);

async function pesqCliente(e){
    setvaluePesq(e);
    if(e){
    await api.get(`cliente/nome/${e}`)
        .then(res => {
            setlistaCli( res.data);
        })
    }
};

function selectCliente(t){
    dispatch({
        type: 'set_cliente',
        payload: {...cliente, id_Cliente: t._id, nome: t.nome}
        });
        setplaylistaCli(false);
        setvaluePesq('');
};

useEffect(() => {
    if(valuePesq){
    setplaylistaCli(true)
    }else setplaylistaCli(false)
}, [valuePesq]);

//---------------------------------------------
function FecharModal(){
    dispatch({
        type: 'SET_PLAYMODAL',
        payload: {...playmodal, playModalVeiculo: false}
        });

    if(editModalVeic || detalhesVeic){
        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit, 
                editModalVeic: false, 
            }
        });
    };
    if(displayModal.veiculosDisplay){
        props.pesqVeiculoPlaca();
    };
    
    if(displayModal.clienteDisplay){
        dispatch({
            type: 'SET_parametroInit',
            payload: {...parametroInit, buscarVeic: true}
        });
    };
};
//###################################################################################

  return (
    <S.body>
      
      <S.ContainerVeic 
      playModalVeiculo={playmodal.playModalVeiculo} 
      editModalVeic={parametroInit.editModalVeic}
      >
        <S.div3>
        
            <div className='titulo'>
                <div className={'tituloUser'}>
                    <span>{detalhesVeic ? 'Detalhes Veiculo': editModalVeic? 'EDITAR Veiculo':'Novo Veiculo'}</span>
                </div>
                <div 
                className='fechar'
                onClick={()=>FecharModal()}>X
                </div>
            </div>

        {playmodal.playModalVeiculo && <>
            <S.box>
                <div>
                    <span>Placa: QQA4F13</span>
                    <span>Toyota Hilux Cd Sr 4x4 2.8 Tdi Diesel Aut.</span>
                    <span>Ano 2019</span>
                    <span>Valor R$ 196.000,00</span>
                </div>
            </S.box>
            <S.box>
                <div>
                    <span>Veículo movido a Gás Natural Veicular (GNV):</span>
                    <span>Veículo de leilão:</span>
                </div>
            </S.box>
            <S.box>
                <div>
                    <span>Particular/Familiar:</span>
                    <span>Comercial:</span>
                    <span>de Aplicativo:</span>
                </div>
            </S.box>
        </>}

        <S.divButton>
            {!detalhesVeic && 
            <S.Salvar>
                <button type='button' onClick={addVeiculo}>Salvar</button>
            </S.Salvar>
            }
            </S.divButton>

        </S.div3>
      </S.ContainerVeic>
    </S.body>
  );
}
