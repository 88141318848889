import styled from 'styled-components';
import breakpoints from '../../breakpoints';

export const bodyresult = styled.div`
display: flex ;
position:relative ;
flex-direction: column ;
max-width: 500px ;
height:100% ;
overflow:auto ;
font-size: clamp(10px, 3vw, 12px);

.listaPagamento{
  background-color: #262626 ;
  
  span{
    color: white ;
  }
}
`
export const result = styled.div`
display:flex;
background-color: ${props=>
props.statusLista === 'A'? '#efefef' : 
props.statusLista === 'C'? '#1fe3ab' :
props.statusLista === 'P'? '#ffe175': 

props.statusLista === 'waiting'? '#efefef' : 
props.statusLista === 'paid'? '#1fe3ab' :
props.statusLista === 'unpaid'? '#ffe175': '#ff8787'
}; 
height: 20px;
color:black;
border-radius:2px;
margin:2px 0;
padding:2px 0;

span{
  display:flex;
  flex:1;
  align-items:center;
  overflow:hidden ;
  white-space:nowrap ;
  text-overflow:ellipsis ;
  color:black;
}

img{
  width:17px;
  height:17px;
  cursor: pointer;
}

.icone{
  display:flex;
  align-self:center;
  width:17px;
  height:17px;
  margin: 0 3px;
  cursor: pointer;
}

`
export const botao = styled.div` 
display:flex;
`
export const abrir = styled.div`
display:flex ;

`
//##########################################################


//-------------------Resultado-2------------------------------
 export const bodyTitulo = styled.div`
 display:flex;
 width:100% ;
 color:white ;
 align-items: center ;
 
 span{
  display:flex;
  flex: 1 ;
  align-items:center;
 }

 .titulo{
  display: flex ;
  width: 100% ;
  color:white ;

}
.tituloUser{
  display: flex ;
  flex: 1;
  align-items: center ;
  justify-content: center ;
  width: 100% ;
}

 .fechar{
  display:flex;
  flex:0 ;
  align-items:center;
  color:#f0433d;
  border-radius:3px;
  width:20px;
  height:20px;
  margin-right: 10px ;
  padding:0px 5px;
  cursor: pointer;
  font-weight:bold;

  &:hover{
    color:white;
    background-color: #f0433d;
  }
}
 `
//-------------------------------
export const ListaPrincProd = styled.div`
  overflow:auto ;
  flex-direction: column ;
  width: 100% ;
  margin-top: 5px ;

`
//-------------------------------
export const ListaTodosCaixa = styled.div`
  overflow:auto ;
  flex-direction: column ;
  width: 100% ;
  margin-top: 3px ;
  max-height:300px ;

`
//-------------------------------
export const ListaDescricao = styled.div`
  display:flex ;
  flex-direction: column ;
  width: 200px ;
  max-height: 230px ;
  background-color: white ;
  position:absolute ;
  top: 55px;
  padding: 3px ;
  overflow:auto ;
  z-index:1 ;

`
export const listaItemDesp = styled.div`
  display: flex;
  min-height:20px ;
  align-items: center ;
  margin: 1px 0 ;
  text-transform:capitalize;

  &:hover{
    background-color:  #1fe3ab;
  }
  
`
//-------------------------------------------
export const ListaDespesa = styled.div`
  display:flex ;
  overflow:auto ;
  flex-direction: column ;
  width: 100% ;
  height:100% ;
  margin-top:10px ;
`
export const storyDespesa = styled.div`
  display: flex;
  width: 100% ;
  height: 100% ;
  overflow: auto ;
  flex-direction: column ;
  
`
export const listaStory = styled.div`
  display: flex;
  width:100% ;
  min-height:20px ;
  background-color: #282C34 ;
  align-items: center ;
  color: white ;
  margin: 3px 0 ;

  .icone{
  display:flex;
  align-self:center;
  width:15px;
  height:15px;
  margin: 0 3px;
  cursor: pointer;
}
  
`
