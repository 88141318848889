import React, { useEffect, useState, useContext } from 'react';
import api from '../../services/api';
import * as S from './styles';
import MaskedInput from 'react-text-mask';
import { Context } from '../../services/contexts/StateContext';
import { PesquisaPadrao } from '../../componentes/Pesquisas/PesquisaPadrao';

import { Functions, FunctModal } from '../../componentes/Functions';

export function ModalUsuario(props) {
    const { state, dispatch } = useContext(Context);
    const { parametroInit, listaPesquisa } = state.parametros;
    const { usuario, logado } = state.cliente;
    const { id_Usuario } = usuario;
    const {
        detalhesModal,
        disabled, detalhesLista, editModal, atualizarModal } = parametroInit;
    // const listaFranquia = ListaFranquia();
    const { PesqCPFUsuario, Limpar_Modal } = Functions();
    const { FecharModal } = FunctModal();


    //---------------------------------------------
    const setUsuario = (key, value) => {
        dispatch({
            type: 'set_usuario',
            payload: { ...usuario, [key]: value }
        });
    };
    //--------------------------Atualizar----------
    function Atualizar() {
        if (detalhesModal) {
            dispatch({
                type: 'SET_parametroInit',
                payload: {
                    ...parametroInit,
                    editModal: true,
                    disabled: false,
                    detalhesModal: false
                }
            });
        };

    };

    //----------Add Usuario----------
    async function addUsuario(e) {
        if (!parametroInit.editModal) {
            e.preventDefault(); // prevenir que o formulario não seja enviado antes de preenchido

            if (props.senha !== props.confirmeSenha) {
                alert('Senha não Confirmada!')
                return;
            }
            await api.post('/usuario/add', { //post - Criar
                userCadastro: logado.idLogado,
                nome: usuario.nome,
                cpf: listaPesquisa.nomeItem,
                email: usuario.email,
                senha: usuario.senha,

            }).then(res => {
                alert('Usuario Cadastrado!');
                Limpar_Modal();
                FecharModal();
            }).catch(erro => alert(erro))
        }
        //-------atualizar------
        if (parametroInit.editModal) {
            await api.put(`/usuario/atualizar/${id_Usuario}`, {
                nome: usuario.nome,
                email: usuario.email,
                senha: usuario.senha,
                data_modificado: Date.now(),

            }).then(res => {
                alert('Usuario Atualizado!');
                Limpar_Modal();
                FecharModal();
            }).catch(erro => alert(erro))
        }
    };
    //----Retirando Pontos do cpf------------------
    const [cpfFormat, setcpfFormat] = useState('');

    useEffect(() => {
        let value = cpfFormat;
        if (cpfFormat) {
            let rr = value.replace(/\D/g, ''); //.replace(/\D/gim, '')
            setUsuario('cpf', rr)
        }
    }, [cpfFormat])
    // ----------------------

    useEffect(() => {
        if (listaPesquisa.nomeItem?.length == 11) {
            PesqCPFUsuario(listaPesquisa.nomeItem)
        };

        if (listaPesquisa.nomeItem?.length <= 11) {
            dispatch({
                type: 'SET_parametroInit',
                payload: { ...parametroInit, botaoCPF: false }
            });
        };
    }, [listaPesquisa.nomeItem]);
    // ---------------------------

    // #########################################################
    return (
        <S.body
            detalhesLista={parametroInit.detalhesLista}
            editModal={editModal}
        >
            <S.div3>
                <div className='titulo'>
                    <div className={'tituloUser'}>
                        <span>{detalhesLista ? 'Detalhes Usuario' : editModal || atualizarModal ? 'EDITAR Usuario' : 'Novo Usuario'}</span>
                    </div>
                    <div
                        className='fechar'
                        onClick={() => FecharModal()}>X
                    </div>
                </div>
                {!parametroInit.detalhesLista ?
                    <PesquisaPadrao
                        lupa={props.lupa}
                        titulo="CPF"
                        placeholder="cpf"
                        nomeBotao="Novo"
                        valorItem={listaPesquisa.nomeItem}
                        playModal_Add={props.playModal_Add}
                        pesquisar={() => { }}
                        playBotao={false}
                        Lupa={false}
                    />
                    :
                    <div className='listaFlexCol' style={{ minWidth: "100px" }}>
                        <span>CPF:</span>
                        <MaskedInput
                            disabled={disabled}
                            value={usuario.cpf}
                            type='text'
                            placeholder='cpf/cnpj'
                            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                            onChange={() => { }}
                            guide={false}
                        />
                    </div>
                }

                <S.box1>
                    <div className='listaFlexCol'>
                        <span>Nome:</span>
                        <input
                            disabled={disabled}
                            className='maiusculo'
                            value={usuario.nome}
                            type='text'
                            placeholder='Nome'
                            onChange={e => setUsuario('nome', e.target.value)} />
                    </div>

                    <div className='listaFlexCol'>
                        <span>email:</span>
                        <input
                            disabled={disabled}
                            style={{ textTransform: "lowercase" }}
                            value={usuario.email}
                            type='email'
                            placeholder='email'
                            onChange={e => setUsuario('email', e.target.value)} />
                    </div>
                </S.box1>
                {/* <S.box1>
                <div>
                    <span>CPF:</span>
                    <MaskedInput
                    value={cpfFormat}
                    type='text'
                    placeholder='cpf'
                    mask={[/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'.',/\d/, /\d/, /\d/,'-',/\d/, /\d/,/\d/]}
                    onChange={e=>setcpfFormat(e.target.value)}
                    guide={false}
                    />
                </div> */}

                {/* <span>Franquia:</span>
                <select
                value={usuario.id_Franquia}
                onChange={e=>setUsuario('id_Franquia', e.target.value)} 
                required>
                    <option></option>
                    { listaFranquia.map((s, key) =>
                    <option key={key} value={s.value}>{s.label}</option>
                    )}
                </select> */}
                {/* </S.box1> */}

                {!parametroInit.detalhesLista &&
                    <S.box1>
                        <div className='listaFlexCol'>
                            <span>Senha:</span>
                            <input
                                disabled={disabled}
                                type='password'
                                placeholder='Senha'
                                onChange={e => setUsuario('senha', e.target.value)} />
                        </div>
                        <div className='listaFlexCol'>
                            <span>Confirmar Senha:</span>
                            <input
                                disabled={disabled}
                                type='password'
                                placeholder='Senha'
                                onChange={e => setUsuario('confirmeSenha', e.target.value)} />
                        </div>
                    </S.box1>
                }

                <S.divButton>
                    {!detalhesModal &&
                        <S.Salvar>
                            <button type='button' onClick={addUsuario}>Salvar</button>
                        </S.Salvar>
                    }

                    {detalhesModal &&
                        <>
                            <S.Atualizar>
                                <button
                                    type='button'
                                    onClick={Atualizar}>Atualizar</button>
                            </S.Atualizar>

                        </>
                    }
                </S.divButton>
            </S.div3>
        </S.body>
    );
}
