import React from 'react';
import * as S from './styles';
import iconeDelete from '../../midia/delete.svg'

export function ListaItemLS(props){
    function BtDeletarProd(t){
        const bb = props.listaItemLS.filter(e=> e.codigo !== t.codigo);
        localStorage.setItem(props.nomeLS, JSON.stringify(bb));
        if(bb){
            props.setInfor("listaItemLS", bb)
        };
    };
    //###############################
    return(
        <S.bodyresult>    
            {props.listaItemLS.map((t, key)=>(
            <S.result className='listaPagamento' key={key}>   
                <span> {t.data} </span>      
                <span> {t.tipo} </span>               
                <span> {t.nomeItem} </span>
                <span style={{"justifyContent":"flex-start"}}>{parseFloat(t.valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                <div>
                    <img 
                    style={{'width':'12px'}}
                    src={iconeDelete} 
                    alt='detalhe' 
                    onClick={()=>BtDeletarProd(t)} 
                    />
                </div>
            </S.result>
            ))}
        </S.bodyresult>
    )
};