import React from 'react';
import * as S from './style';

export const AlertComp=(props)=>{
    return(
        <S.alerte>
            <div className='alertar'>
                <label>{props.alertDescrit}</label>
                <div className='simnao'>
                    <button 
                    className='nao'
                    onClick={props.seNao}
                    >
                        NÃO
                    </button>
                    <button 
                    className='sim'
                    onClick={props.seSim}
                    >
                        SIM
                    </button>
                </div>
            </div>
        </S.alerte>
)}