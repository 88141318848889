import React from 'react';
import { createRoot } from 'react-dom/client'
import './index.css';

import {ContextProvider} from './services/contexts/StateContext';
import Routes from '../src/services/routes'

createRoot(document.getElementById('root')).render(
  <ContextProvider>

      <Routes />

  </ContextProvider>
);
