
export const clienteInicialState = {
    cliente:{
        id_Cliente:"",
        status:'',
        nome:"",
        tipoDoc:"",
        cpfCnpj:"",
        dataNascimento:"",
        sexo:"",
        email:"",
        chevePix:"",
        senha:"",
        confirmeSenha:"",

        userCadastro:"",
        id_Franquia:undefined,
        id_Colaborador:undefined,
       
        id_Recebedor_Pagarme:"",

        buscarCliente:false,
        clientePorId:false,
    },
    foneCelular:{
        codigoArea:"",
        numeroCel:"",
    },
    endereco:{
        cep: "",
        estado: "",
        cidade: "",
        rua:"",
        numeroCasa: "",
        bairro:"",
        complemento:"",
    },

    usuario:{
        id_Usuario:"",
        id_Franquia:"",
        status:'',
        nome:"",
        cpf:"",
        email:"",
        nivel:"",
        senha:"",
        confirmeSenha:"",
        userCadastro:"",
    },
    logado:{
        idLogado:"",
        nome:"",
        cpf:"",
        email:"",
        nivel:"",
        conectado:false
    },
}
//-----------------------------------------------------
export const clienteReducer = (state = clienteInicialState, action)=>{

    switch(action.type){
        
        case 'set_cliente':
            return {...state, cliente: action.payload};  

        case 'set_colaborador':
            return {...state, id_Colaborador: action.payload};    

        case 'set_endereco':
            return {...state, endereco: action.payload};

        case 'set_fone':
            return {...state, foneCelular: action.payload};
        
        case 'set_usuario':
            return {...state, usuario: action.payload};

        case 'set_logado':
            return {...state, logado: action.payload};
             
        case 'set_limparcliente':
            return {...state, cliente: clienteInicialState.cliente}; 

        case 'set_limparUsuario':
            return {...state, usuario: clienteInicialState.usuario}; 
        
        case 'set_limparLogado':
            return {...state, logado: clienteInicialState.logado};

            default:
            return state;
            
    }
};
//-----------------------------------------------------
