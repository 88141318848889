import React, { useEffect, useState, useContext } from 'react';
import * as S from './styles';
import { Context } from '../../services/contexts/StateContext';
import MaskedInput from 'react-text-mask';

import { ListaItemBD } from '../ListarPesquisas/listaItemBD';
import { Functions, SetFunctions } from '../Functions';

import { Api_Clientes } from '../../services/FiltrosApi/clienteApi';
import { Api_Veiculos } from '../../services/FiltrosApi/veiculos_api';
import { PesquisaPadrao } from '../Pesquisas/PesquisaPadrao';

export function ModalEventoPlaca(props) {
    const { state, dispatch } = useContext(Context);
    const { cliente } = state.cliente;
    const { veiculo } = state.veiculo;
    const { parametroInit, playmodal, listaPesquisa } = state.parametros;

    const { SetCliente, SetVeiculo } = SetFunctions();
    const { PesqCPFdigitando, EditarSelect } = Functions();
    const { AddCPF } = Api_Clientes();
    const { PesqVeiculoPlaca, AddPlaca, AtualizarModelo } = Api_Veiculos();

    // ---------------------------
    useEffect(() => {
        if (listaPesquisa.nomeItem?.length == 11 && !cliente.id_Cliente) {
            PesqCPFdigitando(listaPesquisa.nomeItem)
        };

        if (listaPesquisa.nomeItem?.length == 14 && !cliente.id_Cliente) {
            PesqCPFdigitando(listaPesquisa.nomeItem)
        };

        if (listaPesquisa.nomeItem?.length <= 11) {
            dispatch({
                type: 'SET_parametroInit',
                payload: { ...parametroInit, botaoCPF: false }
            });
        };
    }, [listaPesquisa.nomeItem]);

    // -------------------------------
    useEffect(() => {
        if (veiculo.placa?.length == 7 && cliente.id_Cliente && !veiculo.id_Veiculo) {
            PesqVeiculoPlaca(veiculo.placa);
        } else if (veiculo.placa?.length == 7 && cliente.id_Cliente && veiculo.id_Veiculo) {
            dispatch({
                type: 'SET_parametroInit',
                payload: { ...parametroInit, editarModelo: true }
            });
        }
        else {
            dispatch({
                type: 'SET_parametroInit',
                payload: { ...parametroInit, novoCadastro: false }
            });

            dispatch({
                type: 'set_ListaPesquisa',
                payload: { ...listaPesquisa, alertaNovo: false }
            });
        }
    }, [veiculo.placa]);
    // ---------------------------------


    //###################################################################################
    return (
        <S.body
            playModalEvenPlaca={playmodal.playModalEvenPlaca}
        >
            <S.Container>
                <S.div3>
                    <div className='titulo'>
                        {!cliente.id_Cliente &&
                            <div className={'tituloUser'}>
                                <span>Qual CPF do Cliente?</span>
                            </div>
                        }
                        <div
                            className='fechar'
                            onClick={() => props.FecharModal()}>X
                        </div>
                    </div>
                </S.div3>

                {/* ---------- */}
                <S.div3>
                    {!listaPesquisa.selectItem && !cliente.id_Cliente ?
                        <>
                            <S.divPesq>
                                <PesquisaPadrao
                                    lupa={props.lupa}
                                    titulo="Buscar CPF"
                                    placeholder="cpf"
                                    nomeBotao="Novo"
                                    playModal_Add={props.playModal_Add}
                                    Pesquisar={PesqVeiculoPlaca}
                                    playBotao={false}
                                    Lupa={false}
                                />
                            </S.divPesq>

                            {!cliente.id_Cliente &&
                                <S.divListaBD>
                                    <ListaItemBD
                                        listaItem={listaPesquisa.listaCliente}
                                    />
                                </S.divListaBD>
                            }

                            {listaPesquisa.nomeItem?.length >= 11 && parametroInit.botaoCPF &&
                                <S.box1>
                                    <div className='listaFlexCol'>
                                        <span>Nome Completo:</span>
                                        <input
                                            disabled={parametroInit.editarModelo}
                                            className='maiusculo'
                                            value={cliente.nome}
                                            type='text'
                                            placeholder='Nome'
                                            onChange={e => SetCliente('nome', e.target.value)} />
                                    </div>
                                </S.box1>
                            }
                        </>

                        :

                        listaPesquisa.selectItem || cliente.id_Cliente &&
                        <S.box1>
                            <div
                                style={{ display: "flex", width: "98%", 'flexDirection': "column", margin: "0 5px" }}
                                onClick={() => !parametroInit.editarModelo ? EditarSelect() : ""}
                            >
                                <span>Associado:</span>
                                <div className='intemSelect'>
                                    {listaPesquisa.selectItem || cliente.nome}
                                </div>
                            </div>
                        </S.box1>
                    }
                </S.div3>

                <S.div3>
                    {listaPesquisa.nomeItem?.length >= 11 && parametroInit.botaoCPF && cliente.nome.length > 3 &&
                        <S.divButton>
                            <button
                                type='button'
                                onClick={() => AddCPF(listaPesquisa.nomeItem)}>Salvar
                            </button>
                        </S.divButton>
                    }

                    {/* ---------- */}

                    {cliente.id_Cliente && <>
                        <div className='titulo'>
                            {!veiculo.id_Veiculo &&
                                <div className={'tituloUser'}>
                                    <span>Qual PLACA do Veiculo?</span>
                                </div>
                            }
                        </div>


                        <S.box1>
                            <div className='listaFlexCol'>
                                <span>Placa:</span>
                                <MaskedInput
                                    disabled={parametroInit.editarModelo}
                                    value={veiculo.placa}
                                    className='uppercase'
                                    type='text'
                                    placeholder='placa'
                                    mask={[/\D/, /\D/, /\D/, /\d/, /\w/, /\d/, /\d/]}
                                    onChange={e => SetVeiculo('placa', e.target.value)}
                                    guide={false}
                                />
                            </div>
                        </S.box1>

                        {veiculo.placa?.length == 7 && (parametroInit.novoCadastro || parametroInit.editarModelo) &&
                            <>
                                <S.box1>
                                    <div className='listaFlexCol'>
                                        <span>Modelo Veiculo:</span>
                                        <input
                                            value={veiculo.modelo}
                                            className='maiusculo'
                                            type='text'
                                            placeholder='Modelo'
                                            onChange={e => SetVeiculo('modelo', e.target.value)} />
                                    </div>
                                </S.box1>

                                {!parametroInit.editarModelo ?
                                    <S.divButton>
                                        <button
                                            type='button'
                                            onClick={() => AddPlaca()}>Salvar
                                        </button>
                                    </S.divButton>
                                    :
                                    <S.divButton>
                                        <button
                                            type='button'
                                            onClick={() => AtualizarModelo()}>Alterar Modelo
                                        </button>
                                    </S.divButton>
                                }
                            </>}
                    </>}
                </S.div3>
            </S.Container>
        </S.body>
    );
};
