import styled from 'styled-components';

// ------------------------------
export const body = styled.div`
display: flex ;
height:100vh ;
width: 100vw;
min-width: 300px ;
margin: 0 ;
padding: 0 ;
font-size:14px;

background-color: black;
flex-direction: column ;
align-items: center ;

button{
  cursor: pointer;
}
`
// ---------------------------------
export const bodyMeio = styled.div` 
display:flex;
flex-direction: column ;
width: 100% ;
background-color: black ;
align-items: center ;
margin-top:60px ;

button{
  width: 100px ;
}
`
//---------------------------------------------
export const imagem = styled.div`
display: flex ;
width: 100% ;
justify-content: center ;

img{
  margin: 15px 0 ;
  width: 25% ;
  min-width: 200px ;
}

`
// --------------------------
export const Login = styled.div`
display: flex;
height: 100% ;
position:fixed ;
right:20px ;
border-left:1px solid #5656f7;

@media (max-width: 780px) {
  position:relative ;
  right:0 ;
  border:none ;
};

`
// --------------------------
export const box = styled.div`
display: flex;
flex-direction:column;
width: 20vw ;
min-width:250px ;
max-width:300px ;
align-items: center ;
margin-top:100px ;
margin-left: 20px;

@media (max-width: 780px) {
  margin-top:0 ;
  margin-left: 0;
};

span{
  color: white ;
}

input{
  display: flex ;
  height: 30px ;
  width:95% ;
  font-size:16px;
  padding:5px;
  outline:0;
  transition:all ease .4s;
  border:1px solid black;
  border-radius:3px;
  color: black ;

  &:focus{
    border:1px solid #00c7ff;
  }
}
button{
  width: 100px ;
  height: 25px ;
  margin-top: 10px ;
  border:none ;
  border-radius: 2px ;
  
  &:hover{
    background-color:#00ff9d;
    }
}
`
// ---------------------------
export const box1 = styled.div`
display: flex;
width: 100% ;
align-items: center ;

span{
  height:25px ;
}
input{
  width: 17px;
}
`
// -----------------------------
export const Footer = styled.div`
display: flex ;
align-items: center ;
justify-content: center ;
position:absolute ;
bottom:0px ;
width:100%;
height:25px ;
border-top:1px solid #5656f7;
font-size: 11px ;
color: white ;
div{
  display: flex ;
  margin-right: 10px ;
  align-items: center ;
  color: white ;
  padding: 10px 0 ;
}

`
