import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../services/contexts/StateContext';
import api from '../../services/api';

//---------SERVICES----------------------
import { Api_Clientes } from '../../services/FiltrosApi/clienteApi';
import { Api_Veiculos } from '../../services/FiltrosApi/veiculos_api';
import { ApiEvento } from '../../services/FiltrosApi/eventoApi';

// #################################################################
export function SetFunctions() {
    const { state, dispatch } = useContext(Context);
    const { cliente, usuario } = state.cliente;
    const { veiculo } = state.veiculo;
    const { evento } = state.evento;
    const { listaPesquisa, parametroInit } = state.parametros;
    // -------------
    const SetCliente = (key, value) => {
        dispatch({
            type: 'set_cliente',
            payload: { ...cliente, [key]: value }
        });
    };
    //--------------
    const SetVeiculo = (key, value) => {
        dispatch({
            type: 'set_veiculo',
            payload: { ...veiculo, [key]: value }
        });
    };
    // -------------
    const SetEvento = (key, value) => {
        dispatch({
            type: 'set_Evento',
            payload: { ...evento, [key]: value }
        });
    };
    // --------------
    const SetUsuario = (key, value) => {
        dispatch({
            type: 'set_usuario',
            payload: { ...usuario, [key]: value }
        });
    };
    // --------------
    const SetListaPesquisa = (key, value) => {
        dispatch({
            type: 'set_ListaPesquisa',
            payload: { ...listaPesquisa, [key]: value }
        });
    };
    // --------------
    const SetParametroInit = (key, value) => {
        dispatch({
            type: 'SET_parametroInit',
            payload: { ...parametroInit, [key]: value }
        });
    };

    return { SetCliente, SetVeiculo, SetEvento, SetParametroInit, SetUsuario, SetListaPesquisa }
};

// ###################################################################
export function FunctModal() {
    const { state, dispatch } = useContext(Context);
    const { parametroInit, playmodal } = state.parametros;

    //--------------------------------------- 
    function FecharModal() {
        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit,
                detalhesModal: false,
                editModal: false,
            }
        });
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {
                ...playmodal,
                playModalCliente: false,
                playModalVeiculo: false,
                playModalBanco: false,
                playModalCobranca: false,
                playModalEven: false,
                playModalEvenPlaca: false,
                playModalPagamento: false,
                playModalEditar: false,
                playModalUsuario: false,
                playModalDetalhesEven: false,
            }
        });

        dispatch({ type: 'set_limparcliente' });
        dispatch({ type: 'set_limparEvento' });
        dispatch({ type: 'set_limparVeiculo' });
        dispatch({ type: 'set_limparCobranca' });
        dispatch({ type: 'set_limparUsuario' });
    };

    return { FecharModal }
};

// ###################################################################
export function Functions() {
    const { state, dispatch } = useContext(Context);
    const { parametroInit, playmodal, playLista, displayModal, listaPesquisa } = state.parametros;
    const { evento } = state.evento;
    const { cliente, usuario } = state.cliente;
    const { veiculo } = state.veiculo;
    const { id_Veiculo } = veiculo;
    const { cobranca } = state.cobrancas;
    const { id_ContaBanco } = cobranca;
    const { id_Evento } = evento;
    const { id_Cliente } = cliente;

    const { PesqVeiculoPlaca, TodosTipos } = Api_Veiculos();
    const { PesqEventoCod, BuscaTodosEventos } = ApiEvento();
    const { BuscaTodosClientes, BuscaTodosUsuarios } = Api_Clientes();
    const { SetListaPesquisa } = SetFunctions();
    const localRota = useLocation();

    const [concluido, setConcluido] = useState('');
    const [somaValorPago, setSomaValorPago] = useState('');
    const [TotalEven, setTotalEven] = useState('');
    const [idPag, setIdPag] = useState('');
    const [listaPag, setListaPag] = useState([]);
    const [newStatus, setNewStatus] = useState('');
    const [refDelete, setRefDelet] = useState('');
    const [alertDelete, setAletDelete] = useState(false);

    const { SetEvento } = SetFunctions();

    //--Atualizar Evento - Colocar como Pago "concluido" ou Aberto--
    async function AtualizarConcluido() {
        if (somaValorPago >= TotalEven && concluido === false) {
            await api.put(`/evento/atualizar/${id_Evento}`, {
                concluido: true,
                data_modificado: Date.now(),

            }).then(res => {
                if (res.data.error) {
                    alert('erro')
                } else {
                    alert('Evento Pago!');
                }
            })
        }

        //----------
        if (somaValorPago < TotalEven && concluido === true) {
            await api.put(`/evento/atualizar/${id_Evento}`, {
                concluido: false,
                data_modificado: Date.now(),

            }).then(res => {
                if (res.data.error) {
                    alert('erro')
                } else {
                    alert('Evento Esta em Abeto!');
                }
            })
        }
    };

    //-----------Pesquisar Pag por id pag-------------
    async function PesqIdPag() {
        if (idPag) {
            await api.get(`/paguevento/${idPag}`)
                .then(res => {

                    setListaPag([res.data]);
                });
        }
    };

    // ---------Abrir Modal Even1----------------------
    function PlayEvento() {
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {
                ...playmodal,
                playModalEven: false,
                playModalEvenPlaca: true
            }
        });

        dispatch({
            type: 'set_ListaPesquisa',
            payload: {
                ...listaPesquisa,
                nomeItem: "",
                selectItem: '',
                alertaNovo: false
            }
        });
    };


    // ----------------------------------------------
    function Play_Modal_Add() {
        Limpar_Modal();

        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit,
                detalhesLista: false,
                disabled: true,
                disabledEven: false,
                editModal: false,
            }
        });

        // ------
        if (displayModal.clienteDisplay) {
            dispatch({
                type: 'SET_parametroInit',
                payload: { ...parametroInit, disabled: false }
            });
            dispatch({
                type: 'set_playLista',
                payload: { ...playLista, playListaClie: false }
            });

            dispatch({
                type: 'SET_PLAYMODAL',
                payload: { ...playmodal, playModalCliente: true }
            });
        };

        // ------
        if (displayModal.veiculosDisplay) {
            TodosTipos();
            dispatch({
                type: 'set_playLista',
                payload: { ...playLista, playListaVeic: false }
            });

            dispatch({
                type: 'SET_PLAYMODAL',
                payload: {
                    ...playmodal, playModalVeiculo: true
                }
            });
        };

        // --------
        if (displayModal.eventoDisplay) {
            dispatch({
                type: 'set_playLista',
                payload: { ...playLista, playListaEven: false }
            });

            dispatch({
                type: 'SET_PLAYMODAL',
                payload: {
                    ...playmodal,
                    playModalEven: true,
                }
            });
        };

        // --------
        if (displayModal.usuarioDisplay) {
            dispatch({
                type: 'SET_parametroInit',
                payload: { ...parametroInit, disabled: false }
            });
            dispatch({
                type: 'set_playLista',
                payload: { ...playLista, playListaUsuario: false }
            });
            dispatch({
                type: 'SET_PLAYMODAL',
                payload: { ...playmodal, playModalUsuario: true }
            });
        };

        // --------
        if (localRota.pathname === "/cobranca") {
            dispatch({
                type: 'set_playLista',
                payload: { ...playLista, playListaCobranca: false }
            });

            dispatch({
                type: 'SET_PLAYMODAL',
                payload: {
                    ...playmodal, playModalCobranca: true
                }
            });
        };
    };


    //-----------Limpar Modal e Lista Pesquisas------
    function Limpar_Modal() {
        // if(!parametroInit.editModal){
        dispatch({ type: 'set_limparcliente' });
        dispatch({ type: 'set_limparEvento' });
        dispatch({ type: 'set_limparVeiculo' });
        dispatch({ type: 'set_limparCobranca' });
        dispatch({ type: 'set_limparUsuario' });
        dispatch({ type: 'set_limparlistaPesquisa' });

        // };
    };

    // ----------------------------------------------
    async function ExcluirEste() {
        if (refDelete === "cliente") {
            if (newStatus === 'E' && alertDelete) {
                await api.put(`cliente/atualizar/${id_Cliente}`, {
                    status: newStatus

                })
                    .then(res => {
                        BuscaTodosClientes();
                        setAletDelete(false);
                    })
            }
        };

        //---------
        if (refDelete === "usuario") {
            if (newStatus === 'E' && alertDelete) {
                await api.put(`usuario/atualizar/${usuario.id_Usuario}`, {
                    status: newStatus

                })
                    .then(res => {
                        BuscaTodosUsuarios();
                        setAletDelete(false);
                    })
            }
        };

        //-------
        if (refDelete === "veiculo") {
            if (newStatus === 'E' && alertDelete) {
                await api.put(`veiculo/atualizar/${id_Veiculo}`, {
                    status: newStatus
                })
                    .then(res => {
                        if (displayModal.veiculosDisplay) {
                            PesqVeiculoPlaca();
                        };
                        if (displayModal.clienteDisplay) {
                            dispatch({
                                type: 'SET_parametroInit',
                                payload: { ...parametroInit, buscarVeic: true }
                            });
                        };
                        setNewStatus('');
                        setAletDelete(false);
                    })
            }
        } //----------
        if (refDelete === "evento") {
            if (newStatus === 'E' && alertDelete) {
                await api.put(`evento/atualizar/${id_Evento}`, {
                    status: newStatus

                })
                    .then(res => {
                        PesqEventoCod();
                        setNewStatus('');
                        setAletDelete(false);

                        BuscaTodosEventos();
                    })
            }
        } //----------
        if (refDelete === "banco") {
            if (newStatus === 'E' && alertDelete) {
                await api.put(`contabanco/atualizar/${id_ContaBanco}`, {
                    status: newStatus
                })
                    .then(res => {
                        if (displayModal.bancoDisplay) {
                        };

                        if (displayModal.clienteDisplay) {
                            dispatch({
                                type: 'SET_parametroInit',
                                payload: { ...parametroInit, bcarBanco: true }
                            });
                        };
                        setNewStatus('');
                        setAletDelete(false);
                    })
            }
        }
    };

    //---------------------------------------------------
    function FecharAlerte() {
        setAletDelete(false)
    };

    // ---------------------------------
    function EditarSelect(item) {
        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit,
                editModal: false,
                disabled: false,
            }
        });

        if (displayModal.eventoDisplay || playmodal.playModalCobranca) {
            if (item == "eventoPlaca") {
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload: {
                        ...listaPesquisa,
                        nomeItem: veiculo.placa,
                        selectItem: "",
                    }
                });

                dispatch({
                    type: 'set_veiculo',
                    payload: {
                        ...veiculo,
                        id_Veiculo: "",
                        placa: listaPesquisa.nomeItem && "",
                        modelo: "",
                        nomeCliente: ""
                    }
                });
            };

            // ----
            if (item == "RefFornecedor") {
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload: {
                        ...listaPesquisa,
                        nomeForn: "",
                        SelectForn: "",
                        id_nomeForn: false,
                    }
                });

                dispatch({
                    type: 'set_Evento',
                    payload: {
                        ...evento,
                        id_Fornecedor: false,
                        fornecedor: "",
                        cnpjForne: "",
                        chavePix: "",
                    }
                });
            };
        };

        if (playmodal.playModalEvenPlaca) {
            dispatch({
                type: 'set_ListaPesquisa',
                payload: {
                    ...listaPesquisa,
                    nomeItem: cliente.cpfCnpj,
                    selectItem: "",
                }
            });

            dispatch({
                type: 'set_cliente',
                payload: {
                    ...cliente,
                    id_Cliente: '',
                    cpfCnpj: "",
                    nome: ""
                }
            })
        };
    };

    // ----Pesquisa cpf apos digitar--------------------------
    async function PesqCPFdigitando(cpf) {
        await api.get(`cliente/filtrar/${cpf}`)
            .then(res => {
                const buscar = res.data;
                if (buscar.length > 0) {
                    if (playmodal.playModalCliente && !parametroInit.detalhesModal) {
                        alert('cpf ja existe!');
                        SetListaPesquisa("nomeItem", "");
                    };
                    dispatch({
                        type: 'SET_parametroInit',
                        payload: { ...parametroInit, botaoCPF: false }
                    });
                } else {
                    dispatch({
                        type: 'SET_parametroInit',
                        payload: { ...parametroInit, botaoCPF: true }
                    });
                };

                if (playmodal.playModalEvenPlaca) {
                    dispatch({
                        type: 'set_ListaPesquisa',
                        payload: { ...listaPesquisa, listaCliente: buscar.length > 0 ? buscar : [] }
                    });
                };
            })
    };

    // ----Pesquisa Usuario cpf apos digitar
    async function PesqCPFUsuario(cpf) {
        await api.get(`usuario/filtrar/${cpf}`)
            .then(res => {
                const buscar = res.data;
                if (buscar.length > 0 && playmodal.playModalUsuario) {
                    alert('cpf ja existe!');
                    dispatch({
                        type: 'SET_parametroInit',
                        payload: { ...parametroInit, botaoCPF: false }
                    });
                } else {
                    dispatch({
                        type: 'SET_parametroInit',
                        payload: { ...parametroInit, botaoCPF: true }
                    });
                }
            })
    };
    // ---------------

    return {
        PesqCPFUsuario, PesqCPFdigitando, Limpar_Modal, PlayEvento,
        Play_Modal_Add, EditarSelect, ExcluirEste
    }
}