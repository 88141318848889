export const veiculoInicialState = {
    veiculo:{
        id_Veiculo:"",
        placa:"",
        chassi:"",
        n_motor:"",
        renavam:"",
        anoFab:"",
        anoMod:"", 
        marca:"", 
        modelo:"", 
        cor:"", 
        km:"",
        estado:"",
        cidade:"",
        valorFipe:"",
        codigoFipe:"",

        id_Tipo:undefined,
        id_Plano:undefined,

        listaTipo:[],
        
        id_Franquia:undefined,
        id_Colaborador:undefined,

        nomeCliente:"",

        status:"", 
        dataCadastro:"",

        userCadastro:"",
    }
}
//-----------------------------------------------------
export const veiculoReducer = (state = veiculoInicialState, action)=>{

    switch(action.type){
        
        case 'set_veiculo':
            return {...state, veiculo: action.payload}; 
            
        case 'set_limparVeiculo':
            return veiculoInicialState; 

        default:
            return state;
            
    }
};
//-----------------------------------------------------
