import React from 'react';
import * as S from './styles';
import filter from '../../midia/filter.svg';


export const FiltroParam = ({titulo, ativo}) => {
    return (
      <S.Filtro ativo={ativo}>
          <img src={filter}></img>
          <span>{titulo}</span>
      </S.Filtro>  
)};
//-----------------------------------------------------
export const FiltroParamSub = (props) => {
    return (
        <S.Filtro>
            <img src={filter}></img>
            <span>{props.titulo}</span>
        </S.Filtro>  
  )};
