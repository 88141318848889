import React, { useState, useEffect, useContext } from 'react';
import {Context} from '../../services/contexts/StateContext';
import * as S from './styles';
import { Redirect } from 'react-router-dom';
import Header from '../../componentes/Header/index';
import Footer from '../../componentes/Footer/index';
import api from '../../services/api';
import Cookies from 'js-cookie';

import logo_bg from '../../midia/app_vistoria.jpg'

export default function Autoboox() {
  const {state, dispatch} = useContext(Context);
  const {logado} = state.cliente;
  
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [lembrarPass, setLembrarPass] = useState(false);
  const [error, setError] = useState(false);

  // -----------------------------

  function submitLogin(e) {
    e.preventDefault(); // prevenir que o formulario não seja enviado antes de preenchido
    // setDisabled(true);
    entrarLogin(email, senha)
  };
  // ----------------------------------------------------------------------------------------------
  async function entrarLogin(email, senha) {
    try {
      const { data } = await api.post('/usuario/authlogin', { email, senha });
      if (lembrarPass) {
        Cookies.set('token', data.token, { expires: 3 }); // caso clique em lembrar senha expira em 1 dia
        window.location.href = '/';
      } else {
        Cookies.set('token', data.token, { expires: 1 }); // caso não clique em lembrar, token expira apos fechar a pagina
        window.location.href = '/';
      };
    } catch (erro) {
      setError(true)
    };
  };

  // ----------------------------------------------------------------------------------------------
  return (
    <S.body>
      <Header playLogo={false} />

      <S.bodyMeio>
        <S.imagem >
          <img src={logo_bg} />
        </S.imagem>
      </S.bodyMeio>
      <Footer />
    </S.body>
  )
}