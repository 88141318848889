import React, { useState, useEffect, useContext } from 'react';
import {Context} from '../../services/contexts/StateContext';
import * as S from './styles';
import {Redirect} from 'react-router-dom';

import api from '../../services/api';

import Header from '../../componentes/Header/index';
import Footer from '../../componentes/Footer/index';
import {FiltroLateral, Filtro} from '../../componentes/Filtro/index';
import BoxFiltro from '../../componentes/BoxFiltro/index';
import {format} from 'date-fns';
//------------------------------------------------------------------------------
function Home() {
  const {state, dispatch} = useContext(Context);
  const {logado} = state.cliente;

  const [filtroClientes, setFiltroClientes] = useState('');
  const [filtroEventos, setFiltroEvento] = useState('');
  
  const [selecAtivo, setSelectAtivo] = useState('');

  const [listCliente, setListCliente] = useState([]);
  const [listEvento, setListaEvento] = useState([]);
  const [listPag, setListPag] = useState([]);

  const [feito, setFeito]=useState(false);

  const [placa, setPlaca]=useState('');
  const [tipo, settipo]=useState('Todos');
  const [dataInicio, setDataInicio] = useState("2000-01-01");
  const [dataFim, setDataFim] = useState('');
  const [concluido, setConcluido]=useState('')
  const [aberto, setAberto]=useState('');

  const [pagina, setPagina]=useState(0);
  const [pageAtual, setpageAtual]=useState(0);
  const [limitPagina, setTlimitPagina]=useState(10);

//-----------------------------------------------------------------------
  useEffect(() => {
    const dataAtual = format(new Date(), 'yyyy-MM-dd');
    setDataFim(dataAtual)
  }, [])
//----------------------Paginação-------------------------------------------------
const startPagina = pageAtual * limitPagina;
const endPagina = startPagina + limitPagina;
const atuallistCliente = listCliente.slice(startPagina, endPagina);
const atuallistEvento = listEvento.slice(startPagina, endPagina);
const atuallistPague = listPag.slice(startPagina, endPagina);


// --------------------------FILTROS-----------------------------------------

function filtroAtivo(filtro){

  if(filtro==='ClienteAtivo'){
    setFiltroClientes('todos');
    setSelectAtivo('ClienteAtivo');
    
  }
  if(filtro==='EventoAtivo'){
  setFiltroEvento('todos');
  setSelectAtivo('EventoAtivo');
  }
  if(filtro==='pagmetAtivo'){
    setSelectAtivo('pagmetAtivo');
    }
}
// -------------------LISTA-----------------------------------------------------
const [playUser, setPlayUser] = useState(false);
const [playEven, setPlayEven] = useState(false);
const [playPag, setPlayPag] = useState(false);
const [playrodaPe, setPlayRPe] = useState(true);

function playLista(){
if(selecAtivo==='ClienteAtivo'){
  setPlayUser(true);
  setPlayEven(false);
  setPlayPag(false);
  setPlayRPe(false);
}
if(selecAtivo==='EventoAtivo'){
  setPlayEven(true);
  setPlayUser(false);
  setPlayPag(false);
  setPlayRPe(false);
}
if(selecAtivo==='pagmetAtivo'){
  setPlayPag(true);
  setPlayEven(false);
  setPlayUser(false);
  setPlayRPe(false);
}};

  //---------------Listar Todos clientes--------------
 
  async function listarcliente(){
    setpageAtual(0)

    setConcluido('');
    setAberto('');
    setPlaca('');

    if(dataInicio && dataFim){
    await api.get(`/cliente/filtrar/${dataInicio}/${dataFim}/todos`)
    .then(res => {
        const busca = res.data;
        setListCliente(busca)

        const page = Math.ceil(busca.length/limitPagina)
        setPagina(page)
    });
  }
};

  //-----------Pesquisar Evento por Data------------------
  async function pesqEventoData(){
    setpageAtual(0)

    if(filtroEventos.length > 0){
      setConcluido('');
      setAberto('');
      if(tipo != 'Todos'){
        settipo('');

      }
      await api.get(`/evento/data/${dataInicio}/${dataFim}`)
      .then(res => {

        const busca = res.data;
        setListaEvento(busca);

        const page = Math.ceil(busca.length/limitPagina)
        setPagina(page)

        setPlayEven(true);
        setPlayUser(false);
        setPlayPag(false);
        setPlayRPe(false);
      })
  }
};

//---------------Listar todos Pagamento -------------- 
async function listarPag(){
  setpageAtual(0)

  if(dataInicio && dataFim){
  await api.get(`/paguevento/filtrar/${dataInicio}/${dataFim}/todos`) 
  .then(res => {

    const busca = res.data;
    setListPag(busca);

    const page = Math.ceil(busca.length/limitPagina)
    setPagina(page)
    
  });
}
}

 //-----------Pesquisar Evento por Data / Placa------------------
 async function PesqEventoPlaca(){
  if(placa.length > 0 && tipo==='Todos'){ 
    
    setSelectAtivo('EventoAtivo');
    setConcluido('');
    setAberto('');

    setFiltroEvento('');
      await api.get(`/evento/placa_veic/${placa}/${dataInicio}/${dataFim}`)
      .then(res => {
      setListaEvento(res.data);

      setPlayEven(true);
      setPlayUser(false);
      setPlayPag(false);
      setPlayRPe(false);
      })
  }
};
 //-----------Pesquisar Evento por Data / Placa / Concluido------------------
 async function pesqEventoConcluido(){
   if(concluido || aberto){
     if(placa.length > 0 && tipo === 'Todos'){ 

      setSelectAtivo('EventoAtivo');
      setFiltroEvento('');

        await api.get(`/evento/${concluido}/${placa}/${dataInicio}/${dataFim}`)
        .then(res => {
        setListaEvento(res.data);

        setPlayEven(true);
        setPlayUser(false);
        setPlayPag(false);
        setPlayRPe(false);
        })
      }
    }
  };

 //-----------Pesquisar Evento por Data / Placa / tipo------------------
 async function pesqEventoTipoPlaca(){

  if(placa.length > 0 && tipo!=='Todos'){ 

    setSelectAtivo('EventoAtivo');
    setFiltroEvento('');

      await api.get(`/evento/filtrar/${placa}/${tipo}/${dataInicio}/${dataFim}`)
      .then(res => {
      setListaEvento(res.data);

      setPlayEven(true);
      setPlayUser(false);
      setPlayPag(false);
      setPlayRPe(false);
      })
  }
};
  //-----------Pesquisar Evento por Data / Tipo/ Concluido-----------
  async function pesqEventoTipoConcl(){

    if(concluido || aberto){
      if(tipo!=='Todos' && placa.length === 0 ){ 

      setSelectAtivo('EventoAtivo');
      setFiltroEvento('');

        await api.get(`/evento/filtrar/${concluido}/tipo/${tipo}/${dataInicio}/${dataFim}`)
        .then(res => {
        setListaEvento(res.data);

        setPlayEven(true);
        setPlayUser(false);
        setPlayPag(false);
        setPlayRPe(false);
        })
    }
  }
};
 //-----------Pesquisar Evento por Data / Placa / tipo / concluido------------------
 async function pesqEventoTipoPlacaConcluido(){
  
  if(concluido || aberto){
    if(tipo!=='Todos' && placa.length > 0){ 

      setSelectAtivo('EventoAtivo');
      setFiltroEvento('');

        await api.get(`/evento/${concluido}/${placa}/${tipo}/${dataInicio}/${dataFim}`)
        .then(res => {
        setListaEvento(res.data);

        setPlayEven(true);
        setPlayUser(false);
        setPlayPag(false);
        setPlayRPe(false);
        })
      }
  }
};
//----------------------------------------------
useEffect(() => {
  if(selecAtivo === 'ClienteAtivo'){
    listarcliente();
  }
  if(selecAtivo === 'pagmetAtivo'){
    listarPag();
  }
  if(selecAtivo === 'EventoAtivo'){
    pesqEventoData()
  }
}, [dataInicio, dataFim,selecAtivo, pagina])

useEffect(() => {
  PesqEventoPlaca()
}, [placa, tipo])

useEffect(() => {
  pesqEventoConcluido()
}, [placa && concluido])

useEffect(() => {
  pesqEventoTipoPlaca()
}, [placa && tipo])

useEffect(() => {
  pesqEventoTipoConcl()
}, [tipo && concluido || placa])

useEffect(() => {
  pesqEventoTipoPlacaConcluido()
}, [placa && tipo && concluido])

useEffect(() => {
  setConcluido('');
  setAberto('');
}, [tipo])

//--------------------------------------------
useEffect(() => {
  playLista();

}, [selecAtivo])

//----------------Pendente---------------------
  function clickNotific(){ // clicar na notificação e ir para pagina de pendente
    setFiltroEvento('pendente');
  }


// ---------------------Teste de Datas------------------------------
var inicio = '2021,6,1';
var fim = '2021,7,1';
var result = new Date(fim) - new Date(inicio);
var dataAtual = new Date()

if(dataAtual.getDay()===6){
}
//##################################################################################
  return (
  
  <S.App>
    { !logado.conectado && <Redirect to='/login' />}
      
    <Header 
    clickNotf={clickNotific}
    conectado={logado.conectado}
    />

<S.body>

{/* ------------------------FILTROS---------------------------- */}       
<FiltroLateral
tipo={tipo}
settipo={settipo}
setPlaca={setPlaca}
setDataInicio={setDataInicio}
setDataFim={setDataFim}
setConcluido={setConcluido}
concluido={concluido}
setAberto={setAberto}
aberto={aberto}
/>

<S.body1>
  
  <S.bodyFiltro>
    
    <Filtro // filtro central
    filtroAtivo={filtroAtivo}
    selecAtivo={selecAtivo}
    listarcliente={listarcliente}
    listarPag={listarPag}
    />
{/* ----------Titulos da LISTA----------------------------- */}
    
    {playrodaPe && <> 
      <S.tituloBody>
        <span>PESQUISA</span>
      </S.tituloBody> 
    </>}

    {playUser && <> 
      <S.tituloBody>
        <span>ASSOCIADOS</span>
      </S.tituloBody> 
    </>}

      {playEven && <> 
        <S.tituloBody>
          <span>{filtroEventos==='pendente'?'EM ATRASO':'EVENTOS'}</span>
        </S.tituloBody>
      </>}

      {playPag && <> 
        <S.tituloBody>
          <span>PAGAMENTOS</span>
        </S.tituloBody>
      </>}

  </S.bodyFiltro>
  {/* -----------------Associado---------------------------------- */}
      {playUser && <> 
          <S.body2>
              <S.Container>

                <S.Titulo>
                  <input type='checkbox' checked={feito} onChange={()=>setFeito(!feito)}/>
                  <span>{feito ? 'concluido':''}</span>
                </S.Titulo>
                <S.Titulo1> 
                  NOME
                </S.Titulo1>

                <S.Titulo1>
                  EMAIL
                </S.Titulo1>

              </S.Container>

            {atuallistCliente.map((t,k) => (
            <BoxFiltro 
            key={k}
            id={t._id}
            nome={t.nome}
            email={t.email}
            playUser={playUser}
            />
            ))} 
          </S.body2>
        </>}

  {/* -----------------Eventos------------------------------------ */}
  
            {playEven && <> 
              <S.body2>
                <S.Container>            
                  <S.Titulo>
                    <input type='checkbox' checked={feito} onChange={()=>setFeito(!feito)}/>
                    <span>{feito ? 'concluido':''}</span>
                  </S.Titulo>
                  <S.Titulo1>
                    NOME
                  </S.Titulo1>
                  <S.Titulo1>
                    PLACA
                  </S.Titulo1>
                  <S.Titulo1> 
                    TIPO
                  </S.Titulo1>
                  <S.Titulo1>
                    DATA EVENTO
                  </S.Titulo1>
                  <S.Titulo1>
                    DATA RESSARCIMENTO
                  </S.Titulo1>
                  <S.Titulo1>
                    VALOR TOTAL
                  </S.Titulo1>
                </S.Container>

            {
            atuallistEvento.map((t,k) => (
              <BoxFiltro 
              key={k}
              id={t._id}
              // tipo={t.tipo}
              // participacao={t.participacao}
              dataEvento={t.dataEvento}
              pedido={t.pedido}
              TotalEven={t.TotalEven}
              // concluido={t.concluido}
              // codEvento={t.codEvento} 
              // descricao={t.descricao}
              // //nome={nome}
              //placa={t.id_veiculo.placa}
              playEven={playEven}
              />
            ))
            } 
        </S.body2>
        </>}

  {/* -----------------Pagamentos---------------------------------- */}
      {playPag && <> 
          <S.body2>
              <S.Container>
                <S.Titulo>
                  <input type='checkbox' checked={feito} onChange={()=>setFeito(!feito)}/>
                  <span>{feito ? 'concluido':''}</span>
                </S.Titulo>
                <S.Titulo1> 
                  Nome
                </S.Titulo1>
                <S.Titulo1> 
                  Placa
                </S.Titulo1>
                <S.Titulo1> 
                  Data Pagto
                </S.Titulo1>
                <S.Titulo1> 
                  Form. Pagto
                </S.Titulo1>
                <S.Titulo1>
                  Valor Pago
                </S.Titulo1>
              </S.Container>

            {atuallistPague.map((t,k) => (
            <BoxFiltro 
            key={k}
            dataPag={t.dataPag}
            formaPagamento={t.formaPagamento}
            valor_pago={t.valor_pago}

            playPag={playPag}
            />
            ))} 
          </S.body2>
        </>}

        <S.pagina pagina={pagina}>
          {Array.from(Array(pagina), (item, index)=>{
            return( 
              <button 
              style={index === pageAtual? {backgroundColor:"#3881ff"}:null}
              key={index}
              value={index}
              onClick={(e) => setpageAtual(Number(e.target.value))}
              >
                {index + 1}
              </button>
              )
          })}
        </S.pagina>

      </S.body1>
      
    </S.body>


{/* -------------------------------------------------------------------------------- */}      
      {/* <S.ddd>
          <S.Select>
            <S.Icone>
              <a><img src={concluido}/></a>
            </S.Icone>
            <S.Icone>
              <a><img src={cancel}/></a>
            </S.Icone>
          </S.Select>
        </S.ddd> */}
{/* -------------------------------------------------------------------------------- */}
      <Footer></Footer>
    </S.App>
  );
  
}

export default (Home);
