import React, { useState, useEffect, useContext, useRef } from 'react';
import { Context } from '../../services/contexts/StateContext';
import * as S from './styles';
import LoadingContent from '../../componentes/loading/loading';
import Tesseract from 'tesseract.js';
import { Add_Arquivos } from '../../componentes/Cadastro/Add_Arquivos';
import icon_addArquivo from '../../midia/add-upload.svg';
import { ApiEvento } from '../../services/FiltrosApi/eventoApi';

//----------------------------------------------------
export function LerImagem(props) {
    const { state, dispatch } = useContext(Context);
    const [loadingEx, setLoading] = useState(false);
    const [vinChassiText, setVin] = useState("");
    const [imgText, setimgText] = useState("");

    const { SelectFile, AddArquivos } = Add_Arquivos();
    const { AddEvento } = ApiEvento();

    // ---------------
    function LerImagens(e) {
        const file = e.target.files[0];
        // Extrair Texto sem processar img
        // extrairTextimage(file);
        // Processar efeitos na imagen antes de extrair o texto
        preProcessamento(file);
        setLoading(true);
    };

    // Pre Processamento de Imagens-------
    const preProcessamento = async (imgFile) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Tamnho do canvas
                canvas.width = img.width;
                canvas.height = img.height;

                // Desenhar a imagem no canvas
                ctx.drawImage(img, 0, 0, img.width, img.height);

                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

                // aplicar pre processamento
                const processImagData = aplicarPreProcess(imageData);

                // Exibir a imagem processada
                ctx.putImageData(processImagData, 0, 0);
                const dataURL = canvas.toDataURL();

                // Extrair caracteristicas da imagem
                extrairTextimage(dataURL);
            };
        };
        reader.readAsDataURL(imgFile);
    };

    // ---------------
    const aplicarPreProcess = (imageData) => {
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
            //converter para escala de cinza
            const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
            data[i] = avg; //Red
            data[i + 1] = avg; //Green
            data[i + 2] = avg; //Blue

            // converte imagem para preto e branco // Binarização
            const threshold = 128;
            data[i] = data[i] > threshold ? 255 : 0;
            data[i + 1] = data[i + 1] > threshold ? 255 : 0;
            data[i + 2] = data[i + 2] > threshold ? 255 : 0;
        };
        return imageData;
    };

    // --------------
    async function extrairTextimage(dataURL) {
        await Tesseract.recognize(dataURL, 'por')
            .then(({ data: { text } }) => {
                setLoading(false);
                setimgText(text);
                // chassi
                const chassiVIN = extrairVinChassi(text);
                setVin(chassiVIN);
            })
    };

    // Regex para identificar um VIN valido
    function extrairVinChassi(text) {
        const vinRegex = /[A-HJ-NPR-Z0-9]/;
        const match = text.match(vinRegex);
        // console.log(text);
        return match ? match[0] : "VIN não encontrado";
    };

    console.log(imgText)

    // #####################
    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100vh", maxWidth: "100%", alignItems: "center" }}>

            <div>
                <input
                    type='file'
                    onChange={LerImagens}
                />
                {loadingEx &&
                    <p>Carregando...</p>
                }
                {vinChassiText && <>
                    <p style={{ color: "white" }}>img Normal: {imgText}</p>
                    {/* <p style={{ color: "white" }}>Chassi: {vinChassiText}</p> */}
                </>
                }
            </div>

            <div className='listaFlexCol'>
                <span>Selecione Img:</span>
                <div style={{ display: "flex" }}>
                    <img
                        src={icon_addArquivo}
                        onClick={() => SelectFile()}
                        style={{ width: "25px", cursor: "pointer" }}
                    />
                </div>

                <AddArquivos />
            </div>

            <button
                style={{ cursor: "pointer" }}
                type='button'
                onClick={() => AddEvento()}>
                Salvar
            </button>

        </div>
    );
};

