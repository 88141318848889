import React, { useState, useEffect, useContext } from 'react';
import {Context} from '../../services/contexts/StateContext';
import * as S from './styles';
import { Redirect } from 'react-router-dom';
import Header from '../../componentes/Header/index';
import Footer from '../../componentes/Footer/index';
import api from '../../services/api';
import Cookies from 'js-cookie';

import logo_bg from '../../midia/maisAP.svg'
import {ApiUsuario} from '../../services/FiltrosApi/logado';

function Login() {
  const {state, dispatch} = useContext(Context);
  const {logado} = state.cliente;
  
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [lembrarPass, setLembrarPass] = useState(false);
  const [error, setError] = useState(false);

  const {LogUsuario} = ApiUsuario();

  // -----------------------------
  const token = Cookies.get('token');
  
      useEffect(()=>{
          if(token){
              LogUsuario(token);
          }else{
              dispatch({
                  type: 'set_logado',
                  payload:{...logado, conectado:false}
              });
          }
      },[token]);
  // -----------------------------

  function submitLogin(e) {
    e.preventDefault(); // prevenir que o formulario não seja enviado antes de preenchido
    // setDisabled(true);
    entrarLogin(email, senha)
  };
  // ----------------------------------------------------------------------------------------------
  async function entrarLogin(email, senha) {
    try {
      const { data } = await api.post('/usuario/authlogin', { email, senha });
      if (lembrarPass) {
        Cookies.set('token', data.token, { expires: 3 }); // caso clique em lembrar senha expira em 1 dia
        window.location.href = '/';
      } else {
        Cookies.set('token', data.token, { expires: 1 }); // caso não clique em lembrar, token expira apos fechar a pagina
        window.location.href = '/';
      };
    } catch (erro) {
      setError(true)
    };
  };

  // ----------------------------------------------------------------------------------------------
  return (
    <S.body>
      {token && <Redirect to='/' />}
      <Header playLogo={false} />

      <S.bodyMeio>
        <S.imagem >
          <img src={logo_bg} />
        </S.imagem>
      </S.bodyMeio>

      <S.Login>
        {error &&
          <>Erro no Login</>
        }
        <form onSubmit={submitLogin}>
          <S.box>
            <div style={{ 'width': "100%" }}>
              <span>email:</span>
              <input
                autoFocus
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                // disabled={disabled}
                required
              />
            </div>

            <div style={{ 'width': "100%" }}>
              <span>Senha:</span>
              <input
                type="password"
                value={senha}
                // disabled={disabled} 
                onChange={e => setSenha(e.target.value)}
                required
              />
            </div>

            <S.box1>
              <span style={{ display: "flex", color: "#00ff9d", fontSize: "12px", alignItems: "center" }}>Lembrar 3 dias</span>
              <input
                type='checkbox'
                // disabled={disabled}
                checked={lembrarPass}
                onChange={() => setLembrarPass(!lembrarPass)}
              />
            </S.box1>
            <button
              type='submit'
            //  disabled={disabled}
            >
              Entrar
            </button>
          </S.box>
        </form>
      </S.Login>
      <Footer />
    </S.body>
  )
}

export default Login;