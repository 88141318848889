import React, { useState, useEffect, useContext, useRef } from 'react';
import api from '../../services/api';
import {Context} from '../../services/contexts/StateContext';
import * as S from './styles';

import {useReactToPrint} from "react-to-print";

import {UsuarioLog} from '../../services/FiltrosApi/logado';
//----------------------------------------------------
function ModalCliente(props) {
    const {state, dispatch} = useContext(Context);
    const {cliente} = state.cliente;
    const {parametroInit, playmodal,listaPesquisa,playLista} = state.parametros;
    const {veiculo} = state.veiculo;
    const {franquia} = state.franquia;

    const {
        detalhesModal, 
    } = parametroInit;

    const {idLogado} = UsuarioLog();
    

    
    //---------------------------------------
    useEffect(() => {
        dispatch({
            type: 'set_veiculo',
            payload: {...veiculo, id_Cliente: cliente.id}
            })
    }, [cliente.id])
    //---------------------------------------------------
    const setcliente = (key, value) => {
        dispatch({
            type: 'set_cliente',
            payload: {...cliente, [key]: value}
            });
    };
    //---------------------------------------------------
    useEffect(() => {
        dispatch({
            type: 'SET_Franquia',
            payload: {...franquia, id: cliente.id_Franquia}
            });
    }, [cliente.id_Franquia])


    //----------------------------------------------------    

    function FecharModal(){
        dispatch({
            type: 'set_limparVeiculo',
        });
        dispatch({
            type: 'set_limparcliente',
        });

        dispatch({
            type: 'SET_parametroInit',
            payload: {
                ...parametroInit,
                detalhesModal:false, 
                editModal:false, 
                buscarVeic: false, 
                checkVeic:false,
                pesquisaIdCliente:false,
            }
        });

        dispatch({
            type: 'set_playLista',
            payload: {...playLista, playListaVeic:false}
        });
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {
                ...playmodal, 
                playModalCliente: false,
                playModalVeiculo:false,
                playModalBanco: false, 
                playModalCobranca:false
            }
        });

        dispatch({
            type: 'set_ListaPesquisa',
            payload:{
                ...listaPesquisa,
                listaVeiculo:[], 
                selectItem:"",
                nomeItem:'',
            }
        });
    };
    //-----------------Retirando Pontos do cpf---------------------------------
    const [cpfFormat, setcpfFormat]=useState('');

    useEffect(() => {
    let value = cpfFormat;
    if(cpfFormat){
        let rr = value.replace(/\D/g,'');
        setcliente('cpfCnpj', rr)
        }
    }, [cpfFormat])
    // ----------------------

    useEffect(() => {
        setcpfFormat(cliente.cpfCnpj)
    }, [cliente.cpfCnpj])

    //------------------- // 

    const contantDocument = useRef();
    const ImprimirPDF = useReactToPrint({
        content: ()=> contantDocument.current,
        documentTitle : "Nome Contratos MaisApoio" , 
    });

//###############################################################################
  return (
    <S.body 
    detalhesLista={parametroInit.detalhesLista}
    editModal={parametroInit.editModal}
    >
        <S.div3>
            <div className='titulo'>
                <div className={'tituloUser'}>
                    <span>PDF</span>
                </div>
                <div 
                className='fechar'
                onClick={()=>FecharModal()}>X
                </div>
            </div>

            <S.box1 ref={contantDocument}>
                <div>
                    <span>Robson Bendo PDF</span>

                </div>
            </S.box1>
            
            
            <S.divButton editModal={parametroInit.editModal}>
                <S.Salvar>
                    <button type='button' onClick={ImprimirPDF}>
                        Imprimir
                    </button>
                </S.Salvar>
            </S.divButton>            
        </S.div3>
    </S.body>
  );
}

export default ModalCliente;
