import React, {useEffect, useContext} from 'react';
import * as S from './styles';
import {format} from 'date-fns';
import {Context} from '../../services/contexts/StateContext';

function PesquisaEvent(props){
    const {state, dispatch} = useContext(Context);
    const {parametroInit, playmodal, playLista, listaPesquisa} = state.parametros;

//---------------------------------------------------
const setparametroInit = (key, value) => {
    dispatch({
        type: 'SET_parametroInit',
        payload: {...parametroInit, [key]: value}
    });
};

//-----------------------------------------------------------------
function novoCadastro(){
    dispatch({type: 'set_limparEvento'});
    dispatch({
        type: 'SET_parametroInit',
        payload: {
            ...parametroInit, 
            editModal:false, 
            disabledEven:false,
        }
    });
    dispatch({
        type: 'set_playLista',
        payload:{...playLista, playListaEven:false}
    });
    dispatch({
        type: 'set_ListaPesquisa',
        payload:{...listaPesquisa, listaEvento:[]}
    });
    dispatch({
        type: 'SET_PLAYMODAL',
        payload: {
            ...playmodal, 
            playModalEven: true,
            playModalDetalhesEven:false
        }
    });
    
};
//###################################################################################################
    return(
        <S.divInfor>
            <S.boxInfor>
                <span>Pesquisar Orçamento:</span>
                <S.ddd>
                    <input 
                        type='search' 
                        placeholder='Codigo'
                        onChange={e=>setparametroInit('filtroCodigoEven', e.target.value)}
                    />
                    <S.Pesq>
                        <button 
                        type='button' 
                        onClick={()=>props.PesqEventoCod()}>
                        <img src={props.lupa}/>
                        </button>
                    </S.Pesq>
                </S.ddd>
            </S.boxInfor>
            <S.botao>
                <div
                className='abrirModal'
                onClick={()=>novoCadastro()}
                >Novo Cadastro
                </div>
            </S.botao>
        </S.divInfor>

    )}
    export default PesquisaEvent;