import React, { useRef, useEffect, useState, useContext} from 'react';
import * as S from './styles';
import {Context} from '../../services/contexts/StateContext';
import icon_pdf from '../../midia/icon_pdf.svg'

// COMPONENTES---------
import {SetFunctions} from '../../componentes/Functions';


//----------------------------------------------------
export function Add_Arquivos(){  
    const {state, dispatch} = useContext(Context);
    const {evento} = state.evento;  
    const UploadRef = useRef(null);    

    const [arrayFiles, setarrayFiles] = useState([]);
    const [arrayImag, setarrayImag] = useState([]);
    const {SetListaPesquisa} = SetFunctions();

    // -----------------------------------
    function AddFile(file){
        const imgFile = {fileUrl: file ? URL.createObjectURL(file) : undefined, nome: file.name, type: file.type};
            
        if(/image/i.test(file.type) || file.type === "application/pdf"){
            if(file.size <= 10000000){
                const reverseImag = [...arrayImag, imgFile];
                setarrayImag(reverseImag.reverse());
                setarrayFiles([...arrayFiles, file]);
            }else{alert("ERRO: Arquivo muito grande! max. 10MB")}
            
        }else{alert("ERRO: Arquivo não suportado, selecione imagem ou PDF")}
    };

    // -----------------------------------
    useEffect(() => {        
        SetListaPesquisa("listaArquivos", arrayFiles);
    }, [arrayFiles]);

    // ----------------------------------
    function SelectFile(){
        UploadRef.current.click();

    };
    // ----------------------------------
    function Delete(t){
        const xx = arrayImag.filter(e=> e.fileUrl !== t.fileUrl);
        setarrayImag(xx)
    };

    useEffect(() => {
        setarrayImag([]);
    }, [evento.arquivos])

    // ----------------------------------
    function AddArquivos(){
        return(
            <S.Arquivos>
                <input
                ref={UploadRef}
                type='file'
                onChange={(e)=>{AddFile(e.target.files?.[0])}} 
                style={{display:"none"}}
                accept='image/*, application/pdf' 
                />
                {arrayImag.length > 0 &&
                <div style={{display:"flex", flexDirection:"column", margin:"5px 0"}}>
                    {arrayImag.map((t,k)=> (
                        <div key={k} style={{display:"flex", alignItems:"center", gap:"10px"}}>
                            {t.type === "application/pdf" &&
                            <img src={icon_pdf} style={{width:"20px", margin:"3px 0"}}/>  
                            }
                            {/image/i.test(t.type) &&
                            <img src={t.fileUrl} width={60} style={{margin:"3px 0"}}/> 
                            }
                            {/* <span>{t.nome}</span> */}
                            <div 
                            className='fechar'
                            onClick={()=>Delete(t)}>X
                            </div>
                        </div>
                    ))}
                </div>
                }
                {evento.arquivos.length > 0 &&
                <div style={{display:"flex", margin:"5px 0", flexDirection:"column", }}>
                    {evento.arquivos.map((t,k)=> (
                        <div key={k} style={{display:"flex", alignItems:"center", gap:"10px"}}>
                            {/* {t.type === "application/pdf" &&
                            <img src={icon_pdf} style={{width:"20px", margin:"3px 0"}}/>  
                            }
                            {/image/i.test(t.type) &&
                            <img src={t.fileUrl} width={60} style={{margin:"3px 0"}}/> 
                            } */}

                            <img src={`https://rbento-web.s3.us-east-1.amazonaws.com/${t}`} width={60} style={{margin:"3px 0"}}/> 
                            {/* <span>{t.nome}</span> */}
                            {/* <div 
                            className='fechar'
                            onClick={()=>Delete(t)}>X
                            </div> */}
                        </div>
                    ))}
                </div>
                }
            </S.Arquivos>
            
        )
    };

    // ####################################################
    return{SelectFile, AddArquivos}
}