import React, { useState, useEffect, useContext } from 'react';
import * as S from './styles';
import {Redirect} from 'react-router-dom';
import {Context} from '../../services/contexts/StateContext';
import api from '../../services/api';
//---------COMPONENTES----------------------
import Header from '../../componentes/Header/index';
import Footer from '../../componentes/Footer/index';

import {Passo01_Placa} from '../../componentes/Modal/ModalCotacao/Passo01_Placa';
import {Passo02_Endereco} from '../../componentes/Modal/ModalCotacao/Passo02_Endereco';

import {PesquisaCotacaoPlaca} from '../../componentes/Pesquisas/pesqCotacaoPlaca';

import {AlertComp} from '../../componentes/alerte/index';

import {estaLogado} from '../../services/FiltrosApi/LoginToken';
import {UsuarioLog} from '../../services/FiltrosApi/logado';
import lupa from '../../midia/lupa.svg';

export default function Add({match}) {
    const {state, dispatch} = useContext(Context);
    const {cliente} = state.cliente;
    const {veiculo} = state.veiculo;
    const {evento} = state.evento;
    const {contaBancaria} = state.contabanco;
    const {parametroInit, displayModal, playmodal, listaPesquisa, playLista} = state.parametros;
    const {
        checkVeic, 
        pesquisaIdCliente,
        pesquisaIdVeiculo ,
        pesquisaIdEvento,
        loading, 
    } = parametroInit;

    const {id_Cliente} = cliente;
    const {id_Veiculo} = veiculo;
    const {id_Evento} = evento;
    const {id_ContaBanco} = contaBancaria;
    
    const [idPag, setIdPag]=useState('');

    const [concluido, setConcluido]=useState('');

    const [codEvento, setCodEvento]=useState('');

    const [valor_pago, setvalor_pago] = useState('');
    const [somaValorPago, setSomaValorPago]=useState('')
    const [valorPendente, setValorPend] = useState('');
    
    const [checkEven, setcheckEven] = useState(false);
    const [checkPag, setcheckPag] = useState(false);
    
    const [newValor_produtos, editValorProdutos]=useState('');
    const [newValor_servico, editValorServicos]=useState('');
    const [TotalEven, setTotalEven]=useState('');
    
    const [setFiltroVeic] = useState('');
    const [filtroPag, setFiltroPag] = useState('');

    const [listaEvento, setListaEvento] = useState([]);
    const [listaPag, setListaPag] = useState([]);

    const[ModalFormVeic, setModalFormVeic] = useState(false);

    const[alertDelete, setAletDelete]=useState(false);
    const[newStatus, setNewStatus]=useState('');
    const[refDelete, setRefDelet]=useState('');

    let conectado = estaLogado();

    const {idLogado} = UsuarioLog();

//--------------Pesquisar cliente por CPF-----------------------------

async function pesqclienteCpf() {

    dispatch({
        type: 'set_limparcliente'
    });
    
    dispatch({
        type: 'set_veiculo',
        payload:{...veiculo, id:''}
    });
    dispatch({
        type: 'set_playLista',
        payload:{...playLista, playListaClie:false, playListaVeic:false}
    });
    dispatch({
        type: 'SET_PLAYMODAL',
        payload:{
            ...playmodal, 
            playModalCliente:false, 
            playModalVeiculo:false,
            playModalBanco:false
        }
    });
    
    if(parametroInit.filtroCpf){
        dispatch({
            type: 'SET_parametroInit',
            payload:{...parametroInit, loading:true}
        });
        
        await api.get(`cliente/filtrar/${parametroInit.filtroCpf}`)
        .then(res => {
            const buscar = res.data;
            dispatch({
                type: 'set_ListaPesquisa',
                payload:{...listaPesquisa, listaCliente:buscar}
            });
            dispatch({
                type: 'SET_parametroInit',
                payload:{
                    ...parametroInit, 
                    loading:false, 
                    bcarBanco: false, 
                    buscarVeic: false,
                }
            });
            dispatch({
                type: 'set_playLista',
                payload:{playListaClie:true}
            });
        })
    };
};

//--------------Pesquisar cliente por id----------------------------
async function pesqidcliente() {
    if(id_Cliente){
        await api.get(`cliente/${id_Cliente}`)
        .then(res => {
            dispatch({
                type: 'set_ListaPesquisa',
                payload:{...listaPesquisa, listaCliente:res.data}
            });
            dispatch({
                type:'SET_parametroInit',
                payload: {...parametroInit, pesquisaIdCliente:false}
            });
        })
    }
};
useEffect(() => {
    
    if(pesquisaIdCliente){
        pesqidcliente()
    }
}, [pesquisaIdCliente]);

//---------------Pesquisar Veiculos por Placa--------------
async function pesqVeiculoPlaca(){
    dispatch({
        type: 'set_cliente',
        payload:{...cliente, id_Cliente:''}
    });
    dispatch({
        type: 'set_veiculo',
        payload:{...veiculo, id_Veiculo:''}
    });
    dispatch({
        type: 'set_playLista',
        payload:{...playLista, playListaVeic:false}
    });
    dispatch({
        type: 'SET_PLAYMODAL',
        payload:{...playmodal, playModalVeiculo:false}
    });
    
    if(parametroInit.filtroPlaca){
        if(displayModal.veiculosDisplay){
            dispatch({
                type: 'SET_parametroInit',
                payload:{...parametroInit, loading:true}
            });
        }
        
        await api.get(`veiculo/filtrar/placa/${parametroInit.filtroPlaca}`)
        .then(res => {
            dispatch({
                type: 'SET_parametroInit',
                payload:{...parametroInit, loading:false}
            });
            dispatch({
                type: 'set_playLista',
                payload:{...playLista, playListaVeic:true}
            });
            dispatch({
                type: 'set_ListaPesquisa',
                payload:{...listaPesquisa, listaVeiculo:res.data}
            });
            
        })
    };
};

//---------------Pesquisar Veiculos por Id------------------
async function pesqIDveiculo(){
    if(id_Veiculo){
        await api.get(`veiculo/${id_Veiculo}`)
        .then(res => {
            dispatch({
                type: 'set_ListaPesquisa',
                payload:{...listaPesquisa, listaVeiculo:[res.data]}
            });

            dispatch({
                type: 'SET_parametroInit',
                payload:{...parametroInit, pesquisaIdVeiculo:false}
            });
            
            if(res.data.id_cliente){
                dispatch({
                    type: 'set_cliente',
                    payload:{...cliente, id:res.data.id_cliente._id}
                });
            }
        }) 
    }
};
useEffect(() => {
    if(pesquisaIdVeiculo){
        pesqIDveiculo();
    }
}, [pesquisaIdVeiculo])


//-------------------Pesquisar Evento por codEvento-------------
async function PesqEventoCod(){
    dispatch({
        type: 'set_cliente',
        payload:{...cliente, id_Cliente:''}
    });
    dispatch({
        type: 'set_veiculo',
        payload:{...veiculo, id_Veiculo:''}
    });
    dispatch({
        type: 'set_Evento',
        payload:{...evento, id_Evento:''}
    });
    dispatch({
        type: 'set_playLista',
        payload:{...playLista, playListaEven:false}
    });
    dispatch({
        type: 'SET_PLAYMODAL',
        payload:{...playmodal, playModalEven:false}
    });
    if(parametroInit.filtroCodigoEven){
        dispatch({
            type: 'SET_parametroInit',
            payload:{...parametroInit, loading:true}
        });
        await api.get(`/evento/filtrar/${parametroInit.filtroCodigoEven}`)
        .then(res => {
            dispatch({
                type: 'SET_parametroInit',
                payload:{...parametroInit, loading:false}
            });
            dispatch({
                type: 'set_playLista',
                payload:{...playLista, playListaEven:true}
            });
            dispatch({
                type: 'set_ListaPesquisa',
                payload:{...listaPesquisa, listaEvento:res.data}
            });
        })
    };
};
//-------------------Pesquisar Evento por id------------------

async function PesqIdEvento(){
    if(id_Evento){
        await api.get(`/evento/id/${id_Evento}`)
        .then(res => {
            dispatch({
                type: 'set_ListaPesquisa',
                payload:{...listaPesquisa, listaEvento:[res.data]}
            });
            dispatch({
                type: 'set_playLista',
                payload:{...playLista, playListaEven:true}
            });
            dispatch({
                type: 'SET_parametroInit',
                payload:{...parametroInit, pesquisaIdEvento:false}
            });

        })
    }
};
useEffect(() => {
    if(pesquisaIdEvento){
        PesqIdEvento(evento.id_Evento);
    }
}, [pesquisaIdEvento])

//---------------Pesquisar Veiculos por id cliente----------
async function pesqVeiXcliente(){
    if(id_Cliente){
    await api.get(`veiculo/filtrar/${id_Cliente}`)
    .then(res => {
        dispatch({
            type: 'set_ListaPesquisa',
            payload:{...listaPesquisa, listaVeiculo:res.data}
        });
    })
    };
};


//-----Pesquisar todos Evento Para comparar os CodEvento----
async function pesqTodosEvento(){
};  
//---------------Pesquisar Evento por Veiculo---------------
  async function pesqEventoXveiculo(){
      if(id_Veiculo){
    await api.get(`/evento/idveiculo/${id_Veiculo}`)
    .then(res => {
      setListaEvento(res.data);
    })
  }}


//---------------Pesquisar Pag pelo codPagmt-----------------
async function pesqPagCod(){
    dispatch({
        type: 'set_cliente',
        payload:{...cliente, id:''}
    });
    dispatch({
            type: 'set_veiculo',
            payload:{...veiculo, 'id':''}
        });
    setIdPag();
    
    if(filtroPag){
        await api.get(`paguevento/codPag/${filtroPag}`)
        .then(res => {
            setListaPag(res.data);
            setIdPag();
        }) 
    }
    dispatch({
        type: 'set_ListaPesquisa',
        payload:{...listaPesquisa, listaCliente:[]}
    });
    setListaEvento([]);    
}
//---------------Pesquisar Pag por Id evento----------------------------
async function pesqPagXevento(){
    if(id_Evento){
        await api.get(`paguevento/idevento/${id_Evento}`)
        .then(res => {
            setListaPag(res.data);

            const busc = res.data
            //----Soma Valores Pagos----
            const restbusc = busc.map(t=>t.valor_pago);
            let soma = 0;
            for(let i = 0; i < restbusc.length; i++){
                soma += restbusc[i]
            }
            setSomaValorPago(soma)

        });
    }
};

//---Atualizar Evento - Colocar como Pago "concluido" ou Aberto---
async function AtualizarConcluido(){
if(somaValorPago >= TotalEven && concluido === false){
    await api.put(`/evento/atualizar/${id_Evento}`, { 
        concluido: true,
        data_modificado:Date.now(),
                
    }).then(res => {
    if(res.data.error){
        alert('erro')
    } else{
        alert('Evento Pago!');
    }
})} //-----------------------

if(somaValorPago < TotalEven && concluido === true){
    await api.put(`/evento/atualizar/${id_Evento}`, { 
        concluido: false,
        data_modificado:Date.now(),
                
    }).then(res => {
    if(res.data.error){
        alert('erro')
    } else{
        alert('Evento Esta em Abeto!');
    }
})} 
}
//-----------------------
useEffect(() => {
    AtualizarConcluido()
}, [somaValorPago && TotalEven && concluido])

//-----------Valores Falta Pagar Pendente--------
useEffect(() => {
    setValorPend(TotalEven - somaValorPago) 
}, [valor_pago, newValor_servico, newValor_produtos, somaValorPago])

//-------------------Pesquisar Pag por id pag-----
  async function PesqIdPag(){
      if(idPag){
        await api.get(`/paguevento/${idPag}`)
        .then(res => {
            
            setListaPag([res.data]);
        });
    }
} 
//---------------------------------------------
async function deletar(){
    if(refDelete === "cliente"){
        if(newStatus === 'E' && alertDelete){
            await api.put(`cliente/atualizar/${id_Cliente}`,{
                status:newStatus

            })
            .then(res => {
                pesqclienteCpf();
                setNewStatus('');
                setAletDelete(false);
            })
        }
    } //----------------------------------------
    if(refDelete === "veiculo"){
        if(newStatus === 'E' && alertDelete){
            await api.put(`veiculo/atualizar/${id_Veiculo}`,{
                status:newStatus
            })
            .then(res => {
                if(displayModal.veiculosDisplay){
                    pesqVeiculoPlaca();
                };
                if(displayModal.clienteDisplay){
                    dispatch({
                        type: 'SET_parametroInit',
                        payload: {...parametroInit, buscarVeic: true}
                    });
                };
                setNewStatus('');
                setAletDelete(false);
            })
        }
    } //----------------------------------------
    if(refDelete === "evento"){
        if(newStatus === 'E' && alertDelete){
            await api.put(`evento/atualizar/${id_Evento}`,{
                status:newStatus

            })
            .then(res => {
                PesqEventoCod();
                setNewStatus('');
                setAletDelete(false);
            })
        }
    } //----------------------------------------
    if(refDelete === "banco"){
        if(newStatus === 'E' && alertDelete){
            await api.put(`contabanco/atualizar/${id_ContaBanco}`,{
                status:newStatus
            })
            .then(res => {
                if(displayModal.bancoDisplay){
                };
        
                if(displayModal.clienteDisplay){
                    dispatch({
                        type: 'SET_parametroInit',
                        payload: {...parametroInit, bcarBanco: true}
                    });
                };
                setNewStatus('');
                setAletDelete(false);
            })
        }
    }
};


//---------------------------------
 useEffect(() => {
    if(!checkEven){
        if(checkVeic){
            pesqEventoXveiculo();
            pesqTodosEvento()
        }
    }
    if(!checkVeic){
        setcheckEven(false)
        dispatch({
            type: 'set_veiculo',
            payload:{...veiculo, 'id':''}
        });
        setListaEvento([])
    } 
}, [checkVeic])

//----------------------------------

useEffect(() => {
    if(checkEven){
        if(!checkPag){  
        pesqPagXevento(); // pesquisar todos os pagamento deste evento
    }}
    if(!checkEven){ 
      setcheckPag(false);
      setIdPag();
      setListaPag([])
    }    
}, [checkEven])

//---------------------------------
useEffect(() => {
    if(checkPag){
        PesqIdPag() // pesquisar id do pagamento
        setcheckPag(idPag) //selecionar o id pesquisado
    }
   }, [idPag])

//-----------Se checkEven for desselecionado fazer pesquisa de todos eventos----
useEffect(() => {
    if(!checkEven)
    pesqEventoXveiculo();
}, [checkEven])

//-----------Se checkPag for desselecionado fazer pesquisa de todos Pagamentos----
useEffect(() => {
    if(!checkPag)
    pesqPagXevento();
}, [checkPag])

//------------Somar o Total-------------------------

const[Total, setTotal]=useState(0)

let tt = parseFloat(newValor_servico)+parseFloat(newValor_produtos)

useEffect(() => {
    if(tt){
        setTotal(tt)
}
}, [tt])

//-------------------Gerar Codigos------------------
const [codEvenBD, setcodEvenBD] = useState('');

useEffect(() => { 
    let inic = (1);
    let codinic =  veiculo.placa+'-0'+inic;
    const regex = new RegExp(codinic, 'i'); // Expresao regular
    let verific = regex.test(codEvenBD) // verificar se ja existe este codigo no banco de dados
//----------------------------------
    if(verific) { // se cod for igual, gerar novo
        for(; verific; inic++){ 
            let codNovo = veiculo.placa+'-0'+inic
            const regex = new RegExp(codNovo, 'i');
            let verific = regex.test(codEvenBD)
            
            if(!verific){
                dispatch({
                    type: 'set_Evento',
                    payload: {...evento, codEvento:codNovo}
                });
                break
            }
        };
    }else{
        if(veiculo.placa){
            dispatch({
                type: 'set_Evento',
                payload: {...evento, codEvento:codinic}
            });
        }
    }
}, [codEvenBD && veiculo.placa]);
//-----------------------------------------------------
function FecharAlerte(){
    setAletDelete(false)
};
//-----------------------------------------------------

//###################################################################################################
  return (
    <S.App>
        { !conectado && 
        <Redirect to='/login'/>
        }
        <Header/>
        <S.body>
            {alertDelete && <>
            <AlertComp
            alertDescrit={'Deseja mesmo Excluir?'}
            seSim={deletar}
            seNao={FecharAlerte}
            />
            </>}
{/* --------------------Pesquisa------------------------------------ */}
            <S.pesquisa>
                <S.containerPesq>
                {/* ------------------------------------- */} 
                    {displayModal.veiculosDisplay && <>
                    <PesquisaCotacaoPlaca
                    lupa={lupa}
                    setCodEvento={setCodEvento}
                    ModalFormVeic={ModalFormVeic}
                    setModalFormVeic={setModalFormVeic}
                    pesqVeiculoPlaca={pesqVeiculoPlaca}
                    /> 
                    </>}
                {/* ------------------------------------- */}  
                </S.containerPesq>
{/* //------------------Lista e Modal--------------------------*/}
                <S.containerLista>
                    <Passo01_Placa
                    pesqIDveiculo={pesqIDveiculo}
                    pesqVeiculoPlaca={pesqVeiculoPlaca}
                    />     
                    <Passo02_Endereco
                    pesqIDveiculo={pesqIDveiculo}
                    pesqVeiculoPlaca={pesqVeiculoPlaca}
                    /> 
                </S.containerLista>          
            </S.pesquisa>
        </S.body>
        <Footer></Footer>
    </S.App>   
)}