import React, { useState, useEffect} from 'react';
import * as S from './styles';

import {FiltroParam} from '../FiltroParam/index';

//--------------------------------------------------------------------------------------------
export const FiltroLateral = (props) => {

  const [listaVeic, setListaVeic] = useState([]);
  
//------------------------------------------------------------------
  function checkConcluido(r){

    if(r==='c'){
      props.setConcluido(true);
      props.setAberto(false);
  }
  if(r==='a'){
    props.setConcluido(false);
    props.setAberto(true);
  }

    if(props.concluido){

      props.setConcluido(false);
      props.setAberto(true);
    }
    if(props.aberto){

      props.setConcluido(true);
      props.setAberto(false);
    }

  };

//##################################################################################
  return (
        
        <S.FiltroLateral>
          <S.containe1>
            <S.pesq>
              <S.box>
                <span>Inicio</span>
                <input
                type='date' 
                placeholder='Data Incial'
                onChange={e=>props.setDataInicio(e.target.value)}
                />
              </S.box>
              <S.box>
              <span>Fim</span>
                <input
                type='date' 
                placeholder='Data Final'
                onChange={e=>props.setDataFim(e.target.value)}
                />
              </S.box>
            </S.pesq>
{/* //----------------------------------------------------- */}
              <S.box>
                <input
                type='text' 
                placeholder='Placa'
                onChange={e=>props.setPlaca(e.target.value)}
                />
              </S.box>
{/* //----------------------------------------------------- */}
              <select
              value={props.tipo}
              onChange={e=>props.settipo(e.target.value)} 
              >
                <option>Todos</option>
                <option>Revisão</option>
                <option>Eventos</option>
                <option>Guincho</option>
                <option>Parabrisa</option>
                <option>Retrovisor</option>
                <option>Lanterna</option>
                <option>Colisão</option>
                <option>Perda Total</option>
                <option>Roubo</option>
              </select>
{/* //----------------------------------------------------- */}
              <S.check>
                <input
                  type='checkbox'
                  checked={props.concluido}
                  onChange={()=>checkConcluido('c')}
                />
                <span>Pago</span>
              </S.check>

              <S.check>
                <input
                  type='checkbox'
                  checked={props.aberto}
                  onChange={()=>checkConcluido('a')}
                  />
                <span>Aberto</span>
              </S.check>

              {/* <S.check>
              <input
              type='checkbox'/>
              <span>Pendente</span>
              </S.check> */}
{/* //----------------------------------------------------- */}
            
            {/* <button
            type='button' 
            onClick={()=>buscar()}
            >Buscar
            </button> */}

{/* //------------Resultado-------------------- */}
            {listaVeic.map((a,k)=>(
            <S.result key={k}>
              
                    <input 
                    className='checkbox'
                    type='checkbox'
                    />
                    <span>{a.placa}</span>
                

            </S.result>
            ))}
          </S.containe1>
      </S.FiltroLateral> 
)}


//############################################################################################
export const Filtro = (props) => {
  return (
        <S.FiltroArea>
            <button 
            type="button" 
            onClick={()=>props.filtroAtivo('ClienteAtivo')}>
              <FiltroParam 
              titulo="Associados" 
              ativo={props.selecAtivo==='ClienteAtivo'} 
              />
            </button>

            <button
            type="button" 
            onClick={()=>props.filtroAtivo('EventoAtivo')}>
              <FiltroParam 
              titulo="Eventos" 
              ativo={props.selecAtivo==='EventoAtivo'}
              />
            </button>

            <button 
            type="button" 
            onClick={()=>props.filtroAtivo('pagmetAtivo')}>
              <FiltroParam 
              titulo="Pagamentos" 
              ativo={props.selecAtivo==='pagmetAtivo'}
              />
            </button> 
        </S.FiltroArea>   
)}
//---------------------------------------------------------------------------------------------------
