import React, { useContext } from 'react';
import api from '../../services/api';
import { Context } from '../../services/contexts/StateContext';
import { format } from 'date-fns';

import { FunctModal } from '../../componentes/Functions';

//-------------------------------
export function ApiEvento() {
    const { state, dispatch } = useContext(Context);
    const { evento, terceiro } = state.evento;
    const { parametroInit, playmodal, listaPesquisa, playLista } = state.parametros;
    const { cliente, logado } = state.cliente;
    const { veiculo } = state.veiculo;
    const { inforCaixa } = state.produtos;

    const { FecharModal } = FunctModal();

    //----Add------------------------------------
    async function AddEvento() {
        if (!evento.id_Evento) {
            const eventoArray = {
                userCadastro: logado.idLogado,
                id_Veiculo: veiculo.id_Veiculo,
                id_Fornecedor: evento.id_Fornecedor,

                placa: veiculo.placa,
                nomeCliente: evento.nomeCliente,
                tipo: evento.tipo,
                tipoEvento: evento.tipoEvento,
                codEvento: evento.placa + Math.floor(Math.random() * 1000),
                dataEvento: new Date(evento.dataEvento + "T00:00:00-03:00"),
                ocorrenciaBO: evento.ocorrenciaBO,
                descricao: evento.descricao,

                inicioCReserva: new Date(evento.inicioCReserva + "T00:00:00-03:00"),
                fimCReserva: new Date(evento.fimCReserva + "T00:00:00-03:00"),

                origem: evento.origem,
                destino: evento.destino,
                orcamento: parseFloat(evento.orcamento.replace(",", ".")),
                tipoFile: "eventos",

                terceiro: {
                    tipo: terceiro.tipo,
                    placa: terceiro.placa,
                    chassi: terceiro.chassi,
                    anoMod: terceiro.anoMod,
                    montadora: terceiro.montadora,
                    modelo: terceiro.modelo,
                    cor: terceiro.cor,
                    valorFipe: terceiro.valorFipe,
                }
            };
            const formData = new FormData();
            formData.append("evento", JSON.stringify(eventoArray));

            let fileNew = listaPesquisa.listaArquivos;
            for (let i = 0; i < fileNew.length; i++) {
                formData.append(`file[${i}]`, fileNew[i]);
            };


            await api.post('/evento/addEventoArquivo',
                formData
            ).then(res => {
                if (res.data) {
                    dispatch({
                        type: 'set_Evento',
                        payload: { ...evento, id_Evento: res.data.evento._id }
                    });
                    FecharModal();
                    PesqIdEvento(res.data.evento._id);
                    alert('Evento Salvo!');
                }
            }).catch(erro => alert(" ERRO - algo preenchido errado!"))
        }
        //--Atualizar/Editar---
        else { AtualizarIdEvento() };
    };

    // -----------------------------------
    async function AtualizarIdEvento() {
        if (evento.id_Evento) {
            const eventoArray = {
                placa: veiculo.placa,
                id_Fornecedor: evento?.id_Fornecedor,
                tipo: evento?.tipo,
                tipoEvento: evento?.tipoEvento,
                dataEvento: new Date(evento.dataEvento + "T00:00:00-03:00"),
                ocorrenciaBO: evento?.ocorrenciaBO,
                descricao: evento?.descricao,
                status: evento?.status,

                origem: evento.origem,
                destino: evento.destino,
                orcamento: evento.orcamento ? evento.orcamento.toString().replace(",", ".") : evento.orcamento,
                fornecedor: evento.fornecedor,
                cnpjForne: evento.cnpjForne,
                chavePix: evento.chavePix,
                tipoFile: "eventos",

                inicioCReserva: new Date(evento.inicioCReserva + "T00:00:00-03:00"),
                fimCReserva: new Date(evento.fimCReserva + "T00:00:00-03:00"),

                terceiro: {
                    tipo: terceiro?.tipo,
                    placa: terceiro?.placa,
                    chassi: terceiro?.chassi,
                    anoMod: terceiro?.anoMod,
                    montadora: terceiro?.montadora,
                    modelo: terceiro?.modelo,
                    cor: terceiro?.cor,
                    valorFipe: evento?.valorFipe,
                    dataPart: new Date(evento.dataPart + "T00:00:00-03:00"),
                    valorPart: evento?.valorPart,
                },
                data_modificado: Date.now(),
            };

            const formData = new FormData();
            formData.append("evento", JSON.stringify(eventoArray));

            let fileNew = listaPesquisa.listaArquivos;
            for (let i = 0; i < fileNew.length; i++) {
                formData.append(`file[${i}]`, fileNew[i]);
            };

            await api.put(`/evento/atualizar/${evento.id_Evento}`,
                formData
            ).then(res => {
                alert('Evento Atualizado!');
                if (playmodal.playModalEven) {
                    FecharModal();
                };
                PesqIdEvento(evento.id_Evento);

            }).catch((error) => {
                alert("Ocorreu um erro");
            });
        }
    };
    // -----------------------------------
    async function AtualizarPartic() {
        if (evento.id_Evento) {
            await api.put(`/evento/atualizarSimples/${evento.id_Evento}`, {

                dataPart: new Date(evento.dataPart + "T00:00:00-03:00"),
                valorPart: evento?.valorPart,
                detalhePart: evento?.detalhePart,

            }).then(res => {
                alert('Evento Atualizado!');
                if (playmodal.playModalEven) {
                    FecharModal();
                };
                PesqIdEvento(evento.id_Evento);

            }).catch((error) => {
                alert("Ocorreu um erro");
            });
        }
    };

    // -----------------------------------
    async function BuscaTodosEventos(Tudo) {
        FecharModal();
        dispatch({
            type: 'SET_parametroInit',
            payload: { ...parametroInit, loading: true, detalhesLista: false }
        });

        if (listaPesquisa.id_nomeForn) {
            BuscaEventosFornecedor(Tudo);
        } else {
            await api.get(`/evento/Tudo/${logado.idLogado}/${Tudo}`)
                .then(res => {

                    dispatch({
                        type: 'SET_parametroInit',
                        payload: { ...parametroInit, loading: false }
                    });

                    dispatch({
                        type: 'set_ListaPesquisa',
                        payload: { ...listaPesquisa, listaEvento: res.data }
                    });
                }).catch((error) => {
                    alert("Ocorreu um erro ao buscar os items");
                });
        }
    };

    // ---------------------------------
    async function BuscaEventosFornecedor(Tudo) {
        FecharModal();
        dispatch({
            type: 'SET_parametroInit',
            payload: { ...parametroInit, loading: true, detalhesLista: false }
        });

        await api.get(`/evento/Tudo/Fornecedor/${listaPesquisa.nomeItem}/${listaPesquisa.id_nomeForn}/${logado.idLogado}/${Tudo}`)
            .then(res => {

                dispatch({
                    type: 'SET_parametroInit',
                    payload: { ...parametroInit, loading: false }
                });

                dispatch({
                    type: 'set_ListaPesquisa',
                    payload: { ...listaPesquisa, listaEvento: res.data }
                });
            }).catch((error) => {
                alert("Ocorreu um erro ao buscar os items");
            });
    };

    // -----------------------------------------
    async function PesqTudoData(inicio, fim, Tudo) {
        FecharModal();
        dispatch({
            type: 'SET_parametroInit',
            payload: { ...parametroInit, loading: true, detalhesLista: false }
        });

        await api.get(`/evento/Tudo/Data/${listaPesquisa.nomeItem}/${inicio}/${fim}/${listaPesquisa.id_nomeForn}/${logado.idLogado}/${Tudo}`)
            .then(res => {

                dispatch({
                    type: 'SET_parametroInit',
                    payload: { ...parametroInit, loading: false }
                });

                dispatch({
                    type: 'set_ListaPesquisa',
                    payload: { ...listaPesquisa, listaEvento: res.data }
                });
            }).catch((error) => {
                alert("Ocorreu um erro ao buscar os items");
            });

    };

    //----------Pesquisar Evento por codEvento----
    async function PesqEventoCod() {
        if (parametroInit.filtroCodigoEven) {
            dispatch({
                type: 'SET_parametroInit',
                payload: { ...parametroInit, loading: true }
            });

            await api.get(`/evento/filtrar/${parametroInit.filtroCodigoEven}`)
                .then(res => {
                    dispatch({
                        type: 'SET_parametroInit',
                        payload: { ...parametroInit, loading: false }
                    });
                    dispatch({
                        type: 'set_ListaPesquisa',
                        payload: { ...listaPesquisa, listaEvento: res.data }
                    });
                })
        } else {
            BuscaTodosEventos();
        };

        dispatch({
            type: 'set_cliente',
            payload: { ...cliente, id_Cliente: '' }
        });
        dispatch({
            type: 'set_veiculo',
            payload: { ...veiculo, id_Veiculo: '' }
        });
        dispatch({
            type: 'set_Evento',
            payload: { ...evento, id_Evento: '' }
        });
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: { ...playmodal, playModalEven: false }
        });
    };

    //--Pesquisar Evento por Placa--------------
    async function PesqEventoPlaca(placa, Tudo) {
        FecharModal();

        if (listaPesquisa.id_nomeForn) {
            BuscaEventosFornecedor(Tudo);

        } else if (placa) {
            dispatch({
                type: 'SET_parametroInit',
                payload: { ...parametroInit, loading: true, detalhesLista: false }
            });

            await api.get(`/evento/Placa/${placa}/${logado.idLogado}/${Tudo}`

            ).then(res => {
                dispatch({
                    type: 'SET_parametroInit',
                    payload: { ...parametroInit, loading: false }
                });
                dispatch({
                    type: 'set_ListaPesquisa',
                    payload: {
                        ...listaPesquisa,
                        selectItem: '',
                        listaEvento: res.data.length > 0 ? res.data : [],
                        listaPagamentos: [],
                    }
                });

                const busc = res.data
                busc.map(t => (
                    dispatch({
                        type: 'set_Evento',
                        payload: { ...evento, codEvento: t.codEvento }
                    })

                    // Pesquisar todos Evento por placa Para comparar os CodEvento
                    // dispatch({
                    //     type: 'set_Evento',
                    //     payload:{
                    //         ...evento, 
                    //         codEvenBD:t.codEvento
                    //     }
                    // })
                ));
            })
        } else {
            BuscaTodosEventos(Tudo)
        };

        dispatch({
            type: 'set_caixa',
            payload: {
                ...inforCaixa,
                dataCaixa: "",
                listaDespesaLS: []
            }
        });
    };


    //--Pesquisar Evento por id-----------------
    async function PesqIdEvento(id_Evento) {
        if (id_Evento) {
            await api.get(`/evento/id/${id_Evento}`)
                .then(res => {
                    if (playmodal.playModalEven) {
                        FecharModal();
                    };
                    // ---
                    dispatch({
                        type: 'set_ListaPesquisa',
                        payload: {
                            ...listaPesquisa,
                            selectItem: '',
                            listaEvento: [res.data],
                            listaPagamentos: res.data.pagamentos
                        }
                    });
                    // ---
                    dispatch({
                        type: 'set_Evento',
                        payload: {
                            ...evento,
                            "id_Evento": res.data._id,
                            "nomeCliente": res.data?.id_Veiculo?.id_Cliente.nome,
                            id_Fornecedor: res.data?.id_Fornecedor,
                            "tipo": res.data.tipo,
                            "tipoEvento": res.data.tipoEvento,
                            "codEvento": res.data.codEvento,
                            "dataEvento": res.data.dataEvento ? format(new Date(res.data.dataEvento), 'yyyy-MM-dd') : '',
                            "ocorrenciaBO": res.data.ocorrenciaBO,
                            "descricao": res.data.descricao,
                            "status": res.data.status,
                            "placa": res.data.placa,
                            orcamento: res.data.orcamento ? res.data.orcamento : 0,

                            inicioCReserva: res.data.inicioCReserva ? format(new Date(res.data.inicioCReserva), 'yyyy-MM-dd') : '',
                            fimCReserva: res.data.fimCReserva ? format(new Date(res.data.fimCReserva), 'yyyy-MM-dd') : '',

                            dataPart: res.data.dataPart ? format(new Date(res.data.dataPart), 'yyyy-MM-dd') : '',
                            valorPart: res.data.valorPart,
                            detalhePart: res.data.detalhePart,

                            dataPag: res.data.dataPag ? format(new Date(res.data.dataPag), 'yyyy-MM-dd') : '',
                            valorPag: res.data.valorPag,
                            formaPag: res.data.formaPag,

                            arquivos: res.data.arquivos.reverse()
                        }
                    });
                    // ---
                    if (res.data.terceiro) {
                        dispatch({
                            type: 'set_Terceiro',
                            payload: {
                                ...terceiro,
                                "tipo": res.data.terceiro.tipo,
                                "placa": res.data.terceiro.placa,
                                "chassi": res.data.terceiro.chassi,
                                "anoMod": res.data.terceiro.anoMod,
                                "montadora": res.data.terceiro.montadora,
                                "modelo": res.data.terceiro.modelo,
                                "cor": res.data.terceiro.cor,
                                "valorFipe": res.data.terceiro.valorFipe,
                                "anexoCNH": res.data.terceiro.anexoCNH,
                            }
                        });
                    };
                    // ---
                })
        }
    };


    // -----------------------------------
    async function BaixarEvento(seteditPag) {
        if (evento.id_Evento && evento.dataPag && evento.formaPag) {
            const eventoArray = {
                placa: evento.placa,
                dataPag: new Date(evento.dataPag + 'T00:00:00'),
                valorPag: evento?.orcamento,
                formaPag: evento?.formaPag,
                status: "C",
                tipoFile: "pagamentos"
            };

            const formData = new FormData();
            formData.append("evento", JSON.stringify(eventoArray));

            let fileNew = listaPesquisa.listaArquivos;
            for (let i = 0; i < fileNew.length; i++) {
                formData.append(`file[${i}]`, fileNew[i]);
            };

            await api.put(`/evento/atualizar/${evento.id_Evento}`,
                formData
            ).then(res => {
                seteditPag(false)
                alert('Pagamento Confirmando!');
                PesqIdEvento(evento.id_Evento);

            }).catch((error) => {
                alert("Ocorreu um erro");
            });
        } else { alert("Preencha todos os campos!") };
    };
    //----Add----------------------------------
    async function AddFornecedor() {
        await api.post('/evento/addFornecedor', {
            userCadastro: logado.idLogado,
            nome: evento.fornecedor,
            cnpj: evento.cnpjForne,
            chavePix: evento.chavePix

        }).then(res => {
            if (res.data) {
                dispatch({
                    type: 'set_Evento',
                    payload: {
                        ...evento,
                        id_Fornecedor: res.data._id,
                        nome: res.data.nome,
                        chavePix: res.data.chavePix,
                    }
                });
                alert('Fornecedor OK!!!');
            }
        }).catch(erro => alert(" ERRO - algo preenchido errado!"))
    };

    //--Pesquisar por id-----------------
    async function PesqIdFornecedor(id_Fornecedor) {
        if (id_Fornecedor) {
            await api.get(`/evento/id/fornecedor/${id_Fornecedor}`)
                .then(res => {
                    dispatch({
                        type: 'set_ListaPesquisa',
                        payload: {
                            ...listaPesquisa,
                            listaFornecedor: [res.data],
                        }
                    });
                })
        }
    };
    //-------Pesquisar por CNPJ ou Nome---------
    async function PesqFornecedor(cnpj) {
        if (cnpj) {
            await api.get(`/evento/fornecedor/${cnpj}`)
                .then(res => {
                    dispatch({
                        type: 'set_ListaPesquisa',
                        payload: {
                            ...listaPesquisa,
                            listaFornecedor: res.data.length > 0 ? res.data : [],
                            alertaNovo: res.data.length > 0 ? false : true
                        }
                    });
                })
        }
    };

    return {
        AddEvento, PesqIdFornecedor, PesqFornecedor, AddFornecedor, AtualizarIdEvento,
        AtualizarPartic, BaixarEvento, PesqEventoCod, BuscaTodosEventos, PesqTudoData, PesqEventoPlaca, PesqIdEvento
    }

}


