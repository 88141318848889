import styled from 'styled-components';

export const App = styled.div`
  display: flex;
  background-color: rgb(36, 36, 36);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width:300px;
  position:fixed;
  right:0;
  left:0;
`

export const body = styled.div`
display: flex;
flex-direction: column;
width: 90%;
padding-top: 10px;
min-height: 100vh;
font-size: 14px;
color: black;
background-color: #d3d3d3;
margin-top:70px;

button{
  background-color:#3881ff;
  width:80px;
  border:none;
  border-radius:5px;
  color:white;

  &:hover{
    background-color:#5656F7;
}

}
span{
  display:flex;
  justify-content:center;
}
hr{
  width:100%;
}
`
export const div2 = styled.div` 
display:flex;
justify-content:center;
`
export const div3 = styled.div` 
display:flex;
flex-direction: column;
width:300px;
align-items: center;

input{
border:none;
background:none;
font-size:14px;
color: white;
border-bottom:1px solid #3881ff;
}
`

export const box = styled.div`
display: flex;
width:100%;
padding:5px;
font-size:14px;

span{
  display:flex;
  color: #3881ff;
  margin:5px;
}
input{
  display:flex;
  border:none;
  font-size:14px;
  color: white;
  outline:0;
  margin:5px;

}
`
export const Salvar = styled.div`
height:25px;
margin:5px;
display:flex;

`
export const ddd = styled.div`
display: flex;
flex-direction:row;
`
export const div4 = styled.div` 
display:flex;
width:50%;
justify-content:space-between;
`
export const div5 = styled.div` 
display:flex;
padding:3px;
border-radius:5px;
align-items:center;
font-size:14px;
`
export const Exc = styled.div`
display:flex;
width:70px;
background-color:#f0433d;
padding:3px;
border-radius:5px;
font-size:14px;
justify-content:center;
`

export const Pesq = styled.div`
display:flex;
justify-content:center;

button{
  background:none;
  font-size:16px;
  width:35px;
  outline:0;
  &:hover{
    background:none;
}}
img{
  width:17px;
  height:17px;
  cursor: pointer;

  &:hover{
    opacity: 0.5;
  }}

`