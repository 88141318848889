import React, { useState, useEffect, useContext } from 'react';
import {Context} from '../../services/contexts/StateContext';
import api from '../../services/api';
import {Redirect} from 'react-router-dom';
import * as S from './styles';

// COMPONENTES---------
import {ListaItemLS} from '../ListarPesquisas/ListaItemLS';
import {Add_Itens} from './Add_Itens';
import {ListaItemBD} from '../ListarPesquisas/listaItemBD';
import {PesquisaPadrao} from '../../componentes/Pesquisas/PesquisaPadrao';
import {SetFunctions} from '../../componentes/Functions';

// Filtro API---------
import {PesqLogado} from '../../services/FiltrosApi/sorteio_api';
import {Api_Produtos} from '../../services/FiltrosApi/produtos_api';
//----------------------------------------------------
export function AddPagamento(props) {
    const {state, dispatch} = useContext(Context);   
    const {evento, pagamento} = state.evento;
    const {listaPesquisa, playmodal, parametroInit} = state.parametros;
    const {inforCaixa} = state.produtos;
    const {logado} = state.cliente;

    const [somaTotalLS, setSomaTotalLS] = useState('');
    const [clickNovoItem, setClickItem] = useState(false);
    const [clickNovoTipo, setClickTipo] = useState(false);
    
    const {buscaLogado} = PesqLogado();
    //--------------------------------

    const {PesquisarTipoTodos, PesquisarItemTipo} = Api_Produtos();
    const {SetListaPesquisa} = SetFunctions();

    //--------------------------------
    const setPagamento = (key, value) => {
        dispatch({
            type: 'set_Pagamento',
            payload: {...pagamento, [key]: value}
            })
    }; 
    
    //-----------------------------------
    function addPagamentoLS(){
        const newDespesa = {
            data: pagamento.data,
            nomeItem: listaPesquisa.selectItem,
            valor: listaPesquisa.valorItem,
            tipo: pagamento.tipo,
            codigo: listaPesquisa.selectItem+Math.floor(Math.random() * 1000)
        };        

        if(pagamento.data && listaPesquisa.selectItem && listaPesquisa.valorItem && pagamento.tipo){
            const placaLS = localStorage.getItem(evento.placa);
            
            if(placaLS){
                if(JSON.parse(placaLS).length > 0){
                    const arrayStory = JSON.parse(placaLS);
                    arrayStory.push(newDespesa);
                    localStorage.setItem(evento.placa, JSON.stringify(arrayStory));
                }else{
                    localStorage.setItem(evento.placa, JSON.stringify([newDespesa]));
                }   
            }else{
                localStorage.setItem(evento.placa, JSON.stringify([newDespesa]));
            };

            dispatch({
                type: 'set_Pagamento',
                payload: {
                    ...pagamento,
                    codigoAtualiza:listaPesquisa.selectItem+Math.floor(Math.random() * 1000),
                }
            });

            dispatch({
                type: 'set_ListaPesquisa',
                payload: {
                    ...listaPesquisa,
                    nomeItem:'',
                    selectItem:"",
                    valorItem:"",
                }
            });
        }else{
            alert("Preencha todos os campos")
        }
    };

    //------------Finalizar Pagamento--------
    async function FinalizarLancamento(){  
        const arrayDesp = [];
        listaPesquisa.listaItemLS.map(e=>{
            const depLS = {
                dataPG:e.data,
                tipoPG:e.tipo,
                nomeItem:e.nomeItem,
                valorPG:e.valor,
            }
            arrayDesp.push(depLS)
        });
        if(arrayDesp.length>0){
            await api.put(`/evento/atualizar/pagamento/${evento.id_Evento}`, 
                arrayDesp
            )
            .then(res => {
                if(res.data.errors){
                    alert("ERRO: Preencha todos os campos corretamente!")
                }else{
                    alert("Sucesso!!!");
                    localStorage.removeItem(evento.placa);

                    dispatch({
                        type: 'set_Pagamento',
                        payload: {
                            ...pagamento, 
                            data:"",
                            tipo:"",
                            codigoAtualiza:Math.floor(Math.random() * 1000),
                        }
                    });

                    dispatch({
                        type: 'set_ListaPesquisa',
                        payload: {
                            ...listaPesquisa, 
                            nomeItem:"",
                            novoItem:"",
                            listaItem:[],
                            listaItemLS:[],
                            valorItem:"",
                            selectItem:"", 
                        }
                    });

                    dispatch({
                        type: 'SET_PLAYMODAL',
                        payload: {
                            ...playmodal, 
                            playModalPagamento:false,
                            playModalDetalhesEven: true, 
                        }
                    });
                }
            })
        }else{alert("erro")}
    };
    // -------Saber se ja existe Tipo no BD-------------
    async function PesqTipo(){

    }
    // -------------------------------------------------
    async function AddItemPag(){
        if(listaPesquisa.novoItem.length >= 3){
        await api.put(`/pagamento/tipo/${pagamento.tipo}`, {
            item:listaPesquisa.novoItem,
            id_Usuario: buscaLogado._id,
            })
            .then(res => {
                if(res.data.errors){
                    alert("ERRO!")
                }else{
                    alert("Sucesso!!!");
                    setClickItem(false);
                    dispatch({
                        type: 'set_ListaPesquisa',
                        payload: {
                            ...listaPesquisa, 
                            selectItem: listaPesquisa.novoItem,
                        }
                    });
                }
            })
        }else{
            alert("Não pode ter menos que 3 letras!")
        }
    };

    //----------------------------------------
    function BotaoNovoBarraPesquisa(){
        setClickItem(true);
        dispatch({
            type: 'set_ListaPesquisa',
            payload: {
                ...listaPesquisa, 
                novoItem: listaPesquisa.nomeItem,
            }
        });
    };

    //---------------------------------------
    function FiltrarItem(e){       
        let kk = [];
        listaPesquisa.listaTipoItem.map(i=>{
            const item = i.item;
             kk = item.filter(obj => e? obj.toLowerCase().includes(e.toLowerCase()):'');
        });

        dispatch({
            type: 'set_ListaPesquisa',
            payload: {
                ...listaPesquisa, 
                listaItem:kk,
                nomeItem:e?e:'',
                novoItem:kk.length === 0? e : "",
            }
        });
    };
    
    //---------------------------------------
    useEffect(() => {
        FiltrarItem(listaPesquisa.nomeItem);
    }, [listaPesquisa.nomeItem]);

    //---------------------------------------
    function EditarSelect(){
        dispatch({
            type: 'set_ListaPesquisa',
            payload: {
                ...listaPesquisa, 
                nomeItem:listaPesquisa.selectItem,
                selectItem:""
            }
        });
    };

    //---------------------------------------
    useEffect(() => {
        // Soma Total Despesa--
        const somaX = listaPesquisa.listaItemLS.map(e=> e.valor);
        if(somaX.length>0){
            let somaTotal = 0;
            for(let i = 0; i < somaX.length; i++){
                somaTotal += somaX[i];
            }
            setSomaTotalLS(somaTotal);
        };
    }, [listaPesquisa.listaItemLS])

    // ---------------------------------------
    useEffect(() => {
      if(pagamento.tipo === 'outro'){
        setClickTipo(true);
        dispatch({
            type: 'set_Pagamento',
            payload: {
                ...pagamento, 
                novoTipo:'',
            }
        });
      };

      if(pagamento.tipo){
        PesquisarItemTipo();
        };

    }, [pagamento.tipo]);

    // -------------------------------------------
    function addTipo(){
        setClickTipo(false);
        dispatch({
            type: 'set_Pagamento',
            payload: {
                ...pagamento, 
                tipo:pagamento.novoTipo,
            }
        });
    };

    // -----------------------------------------
    useEffect(() => {
        PesquisarTipoTodos();
    }, [inforCaixa.nomeItem, inforCaixa.selectItemDespesa, inforCaixa.novoItemDespesa])
    
    //---------------------------------
    useEffect(() => {     
        const DespLS = localStorage.getItem(evento.placa);
        if(DespLS){
            dispatch({
                type: 'set_ListaPesquisa',
                payload: {
                    ...listaPesquisa,
                    listaItemLS: JSON.parse(DespLS)
                }
            })
        }
    }, [pagamento.codigoAtualiza, evento.placa])

    // console.log(listaPesquisa)
    // ----------------------------------------

    // console.log(listaPesquisa.listaItemLS)
    // console.log(evento.placa)

// ##########################################################################
    return (        
        <S.bodyModal modalBloque={true}> 
            <S.divLancamento>
                {!logado.conectado && <Redirect to='/' />}
                <span style={{'display':"flex", 'color':'white', 'justifyContent':"center"}}>
                    Pagamentos
                </span>

                <div className='flexCaixa'>
                    <div style={{"display":"flex",'flexDirection':"column", 'flex':'1'}}>
                        <span> Data PG:</span>
                        <div style={{display:'flex'}}>
                            <input
                            style={{backgroundColor:"#30353F"}}
                            type="date"
                            value={pagamento.data} 
                            onChange={(e)=> setPagamento("data",e.target.value)}
                            />
                        </div>
                    </div>
                    
                    <div style={{'display':"flex", flexDirection:'column', 'flex':'1'}}>
                        <span>Tipo</span>
                        <select 
                        style={clickNovoTipo?{'display':"none"}:{"display":"flex"}}
                        value={pagamento.tipo} 
                        onChange={e=>setPagamento('tipo', e.target.value)}
                        >
                            <option> {pagamento.novoTipo? pagamento.novoTipo:""} </option>
                            {listaPesquisa.listaTipo.map((t,k)=>
                            <option key={k}>{t}</option>
                            )}
                            <option 
                            value="outro" 
                            style={{'color':"black", 'fontWeight':"600"}}
                            onClick={()=> addTipo()}
                            >
                                outro
                            </option>
                        </select>

                        <div style={clickNovoTipo?{'display':"flex"}:{"display":"none"}}>
                            <input 
                            style={{"backgroundColor":"#30353f", "border":"1px solid #00c7ff"}}
                            placeholder='minimo 3 letras'
                            onFocus={()=>true}
                            type='text' 
                            value={pagamento.novoTipo}
                            onChange={(e)=> setPagamento("novoTipo",e.target.value)}
                            /> 

                            {pagamento.novoTipo.length >= 3?
                            <button 
                            className='bottonAdd'
                            onClick={()=>addTipo()}
                            >
                                add
                            </button>
                            :
                            <button 
                            className='bottonAdd'
                            style={{"backgroundColor":"red","color":"white"}} 
                            onClick={()=>{setClickTipo(false); setPagamento("tipo",'')}}
                            >
                                X
                            </button>
                            }
                        </div>
                    </div>
                </div>

                {/*---------------------------*/}
                {pagamento.tipo && !clickNovoTipo &&
                <div style={{'display':"flex"}}>
                    {!clickNovoItem && !listaPesquisa.selectItem?
                    <div style={{display:"flex", width:"100%", 'flexDirection':"column", margin:"0 5px", position:"relative"}}>
                        <PesquisaPadrao
                        titulo="Pesquisar Subcategoria"
                        placeholder="Produto"
                        nomeBotao="Nova Subcategoria"
                        Pesquisar={false}
                        PesqDentroModal={false}
                        Lupa={false}
                        playModal_Add={false}
                        playBotao={false}
                        />

                        {listaPesquisa.nomeItem &&
                        <ListaItemBD
                        listaItem={listaPesquisa.listaItem}
                        functPlay1={false}
                        />
                        } 
                    </div>
                    :
                    listaPesquisa.selectItem &&
                    <div style={{display:"flex", width:"100%", 'flexDirection':"column", margin:"0 5px"}} 
                    onClick={()=>EditarSelect()}
                    >
                        <span>Descrição:</span>
                        <div className='intemSelect'>
                            {listaPesquisa.selectItem}
                        </div>
                    </div>
                    }                

                    {/* ----------------------- */}
                    <Add_Itens
                    setClickItem={setClickItem}
                    clickNovoItem={clickNovoItem}
                    EditarSelect={EditarSelect}
                    AddItem={AddItemPag}
                    Adicionar={addPagamentoLS}
                    BotaoNovoBarraPesquisa={BotaoNovoBarraPesquisa}
                    setInfor={SetListaPesquisa}
                    nomeItem={listaPesquisa.nomeItem}
                    valorItem={listaPesquisa.valorItem}
                    novoItem={listaPesquisa.novoItem}
                    listaItem={listaPesquisa.listaItem}
                    selectItem={listaPesquisa.selectItem}
                    />
                </div>}

                <div className='tagLista'>
                    <ListaItemLS
                    nomeLS={props.nomeLS}
                    setInfor={SetListaPesquisa}
                    listaItemLS={listaPesquisa.listaItemLS}
                    />
                </div>
                
            </S.divLancamento>    
            <S.Finalizar>
                <div style={{"display":'flex', "margin":"10px 0", }}>
                    <span>
                    Total Pagamentos:
                    {" " + somaTotalLS.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                    </span>
                </div>
                <div className='divFinal'>
                    <button 
                    style={{"margin":"10px 0", 'color':"black"}}
                    onClick={() => FinalizarLancamento()}>
                        Finalizar
                    </button> 
                </div>
            </S.Finalizar>    
        </S.bodyModal>
    )
}