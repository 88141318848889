
export const logadoInicialState = {
    nome:'Robson',
};

//-----------------------------------------------------
export const logadoReducer = (state, action)=>{

    switch(action.type){
        case 'setLogado':
            return {...state, nome:action.payload};

            default:
            return state;
    }  
};
//-----------------------------------------------------
