
export const produtoInicial = {
    inforProduto:{  
        id_Produto:"",
        nome:"",
        volume:"",
        medida:"",
        categoria:"",
        recipiente:"",
        valorVenda:"",
        listaProduto:[],   
        listaLStory:[],
        listaLProdStory:[],
        listaRelatorio:[],   
        listaCodigo:[],
        listaRelatFornecedor:[],
        listaRelatProd:[],
        listaNomeProd:[],
        pesqProduto:false,
        PesqData:'',
        dataInicio:"",
        dataFim:"",
        codigo:"",
        tipoOperacao:"Compra",
        fornecedor:"",
        novoFornecedor:"",
    },
    estoqueEntrada:{
        usuario:"",
        dataCompra:"",
        Qtdade:"",
        valorCusto:"",
        codigo:"",    
        
    },
    estoqueSaida:{
        usuario:"",
        dataSaida:"",
        Qtdade:"",
        totalVenda:"",
        listaVendaDia:[],
        codigo:"",
    },
    inforCaixa:{
        dataCaixa:"",
        valorMaquina:0,
        valorPix:0,
        valorEspecie:0,
        valorCrediario:0,
        listaItem:[],
        listaItemDespesa:[],
        selectItem:'',
        selectItemDespesa:"",
        novoItem:'',
        novoItemDespesa:"",
        nomeItem:"",
        categoriaDespesa:"",
        valorItem:"",
        listaCaixaLS:[],
        listaDespesaLS:[],
        codigoAtualiza:'',
        
        
    },
    inforSaldoCredito:{
        dataCredito:"",
        fonteCredito:"",
        valorCredito:"",
        dataCaixa:"",
        saldoCredito:"",
        codigoAtualiza:"",
        pesqSaldoCred:false
    },
};
//-----------------------------------------------------
export const produtosReducer = (state = produtoInicial, action)=>{

    switch(action.type){
            
        case 'set_produtos':
            return {...state, inforProduto: action.payload};   
        
            case 'set_produtosEntrada':
            return {...state, estoqueEntrada: action.payload};  
        
            case 'set_produtosSaida':
            return {...state, estoqueSaida: action.payload};  
        
            case 'set_caixa':
            return {...state, inforCaixa: action.payload};  
        
            case 'set_saldoCredito':
            return {...state, inforSaldoCredito: action.payload};  
        
        case 'set_limparProduto':
            return produtoInicial;

            default:
                return state;
            
    }
};
//-----------------------------------------------------
