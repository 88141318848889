
export const colabInicialState = {
    colaborador:{
        id:"",
        nome:"",
        tipoDoc:"",
        cpfCnpj:"",
        dataNascimento:"",
        sexo:"",
        email:"",
        senha:"",
        confirmeSenha:"",

        userCadastro:"",
        id_Franquia:[],
        id_Servico:[],
        id_Recebedor_Pagarme:"",
        
    },
    foneCelular:{
        codigoArea:"",
        numeroCel:"",
    },
    endereco:{
        cep: "",
        estado: "",
        cidade: "",
        rua:"",
        numeroCasa: "",
        bairro:"",
        complemento:"",
    },
}
//-----------------------------------------------------
export const colabReducer = (state = colabInicialState, action)=>{

    switch(action.type){
        
        case 'SET_COLABORADOR':
            return {...state, colaborador: action.payload};

        case 'set_enderColab':
        return {...state, endereco: action.payload};

        case 'set_foneColab':
        return {...state, foneCelular: action.payload};
            
        case 'set_limparColab':
        return colabInicialState;

            default:
            return state;
            
    }
};
//-----------------------------------------------------
