import React, { useContext, useState } from 'react';
import {Context} from '../../services/contexts/StateContext';
import * as S from './styles';
import editar from '../../midia/editar.svg';
import delet from '../../midia/delete.svg';
import ativar from '../../midia/ativar.svg';
import api from '../../services/api';
import {ListaCobranca} from '../../services/FiltrosApi/cobrancaApi';

import {AlertComp} from '../../componentes/alerte/index';

//-----------------------------------------------
export const ListaCarnes = (props) => {
    ListaCobranca();
    const {state, dispatch} = useContext(Context);
    const {veiculo} = state.veiculo;
    const {parametroInit, playmodal, listaPesquisa, displayModal} = state.parametros;
    const {listaCarnes} = listaPesquisa;

    const [listaChecked, setCheck]=useState([]) ;

//----------------------------------------------
function funcCheckBox(e,t){
    let addList = [...listaChecked];
    if(e.target.checked){
        addList = [...listaChecked, t._id];

    }else{
        addList.splice(listaChecked.indexOf(t._id),1);
    }
    setCheck(addList);
}
//-----------------------------------------------

function lixeira(t){
    dispatch({
        type: 'SET_parametroInit',
        payload: {...parametroInit, buscarVeic: false}
    });
    dispatch({
        type: 'set_veiculo',
        payload:{...veiculo, 'id_Veiculo':t._id,}
    });
    props.setAletDelete(true);
    props.setNewStatus('E');
    props.setRefDelet("veiculo");
};
//-----------------------------------------------
async function ativarEste(t){
    dispatch({
        type: 'SET_parametroInit',
        payload: {...parametroInit, buscarVeic: false}
    });

    await api.put(`veiculo/atualizar/${t._id}`,{
        status:'A'
    })
    .then(res => {
        if(displayModal.veiculosDisplay){
            props.pesqVeiculoPlaca();
        };

        if(displayModal.clienteDisplay){
            dispatch({
                type: 'SET_parametroInit',
                payload: {...parametroInit, buscarVeic: true}
            });
        };
    })
};
//---------------------------------------------------
function detalheVeiculo(t){
    dispatch({
        type: 'set_veiculo',
        payload:
        {
            ...veiculo, 
            'id_Veiculo':t._id,
            "placa":t.placa? t.placa:'',
            "chassi":t.chassi? t.chassi:'',
            "n_motor":t.n_motor? t.n_motor:'',
            "renavam":t.renavam? t.renavam:'',
            "anoFab":t.anoFab? t.anoFab:'',
            "anoMod":t.anoMod? t.anoMod:'', 
            "marca":t.marca? t.marca:'', 
            "modelo":t.modelo? t.modelo:'', 
            "cor":t.cor? t.cor:'', 
            "km":t.km? t.km:'',
            "estado":t.estado? t.estado:'',
            "cidade":t.cidade? t.cidade:'',
            "valorFipe":t.valorFipe? t.valorFipe:'',
            "codigoFipe":t.codigoFipe? t.codigoFipe:'',
            "status":t.status? t.status:'', 
            "dataCadastro":t.dataCadastro? t.dataCadastro:'',

            "id_Cliente":t.id_Cliente._id? t.id_Cliente._id:'',
            "id_Franquia":t.id_Franquia? t.id_Franquia:'',
            "colaborador":t.colaborador? t.colaborador:'',

            "nomeCliente": t.id_Cliente.nome,

            "id_Tipo":t.id_Tipo? t.id_Tipo:'',
            "id_Plano":t.id_Plano? t.id_Plano:'',
        }
    });
        dispatch({
            type:'SET_parametroInit',
            payload: {
                ...parametroInit, 
                checkVeic: t._id, 
                detalhesLista:true,
                detalhesModal: true, 
                disabled: true,
                pesquisaIdVeiculo:true,
                buscarVeic:false,
            }
        });
        dispatch({
            type:'SET_PLAYMODAL',
            payload: {...playmodal, playModalVeiculo: true}
        });
};

//----------------------------------------------------
    return(
        <div style={{'display':"flex", "flexDirection":"column"}}>    
            {listaCarnes.length > 0 && 
            <>
            <S.bodyTitulo>
                <div className={'titulo'}>
                    <div className={'tituloUser'}>
                        Carnes
                    </div>
                </div>
            </S.bodyTitulo>

            <S.bodyresult>
                {props.alertDelete && 
                <AlertComp
                alertDescrit={'Deseja mesmo Excluir?'}
                seSim={props.ExcluirEste}
                seNao={props.FecharAlerte}
                />
                }
                {listaCarnes.map((t) => (
                    t.charges.map((k, l)=>(
                        <S.result key={l} statusLista={k.status}>
                            <input 
                            className='checkbox'
                            type='checkbox'
                            onChange={(e)=>funcCheckBox(e,t)}
                            />
                            <span>{t.veiculo.placa}</span>
                            <span>{k.charge_id}</span>
                            <span>{k.parcel}</span>
                            <span>{k.value}</span>
                            <span>{k.status}</span>
                            <span>{k.expire_at}</span>
                            <img 
                            className='icone'
                            src={editar} 
                            onClick={()=>detalheVeiculo(t)} 
                            alt="icone"
                            />

                            {t.status !== 'E' &&
                                <img 
                                className='icone'
                                src={delet} 
                                onClick={()=>lixeira(t)} 
                                alt="icone"
                                />
                                }
                            
                            {t.status === 'E' &&
                                <img 
                                className='icone'
                                src={ativar} 
                                onClick={()=>ativarEste(t)} 
                                alt="icone"
                                />
                                }

                        </S.result>
                    ))
                ))}
            </S.bodyresult>
        </>}
    </div>
    
)}