import React, { useState, useEffect} from 'react';
import * as S from './styles';

//-----------------------------------------------
export const Paginacao = ({ListaItens, setPaginaAtual, itemsPage, pageN}) => {
    const [maisPages, setmaisPages] = useState(0);
    const [botaoPage, setbotaoPage] = useState([0]);
    
    const NumBotoes = Math.ceil(ListaItens.length / itemsPage);
    
    // adicionando mais botoes
    const BotoesMais = maisPages + NumBotoes;
    const yy = Array(BotoesMais);
    const pageArray = [];

    // for laço de repetição, para criar array adicionando e removendo
    for (let i = 0; i < yy.length; i++){
        pageArray.push(i);
    };

    // ------------
    const maior = Math.max(...pageArray);
    useEffect(() => {
        if(pageN > 10){
            let diminuir = maior - parseInt(pageN);

            for (let i = 0; i < diminuir; i++){
                pageArray.pop();
            };

            let menor = pageArray.length - 11;            
            setbotaoPage(pageArray.slice(menor, maior));
        }else{
            //se clicar no botao de zero a 10 / zera o contador
            setmaisPages(0);
        }
    }, [pageN, ListaItens]);

    // ------------
    useEffect(() => {
        // shift - remover os primeiros botoes
        for (let i = 0; i < maisPages; i++){
            pageArray.shift();
        };
        // slice - criar array so com 11 elementos
        setbotaoPage(pageArray.slice(0, 11));
    }, [maisPages, ListaItens]);

    
    // console.log(pageN)
    //######################################
    return(
        <S.Body>{ 
            Array.from(botaoPage, (item, index) => {
                if(botaoPage.length > 1){
                    return (
                        <S.BU 
                        pageN={pageN == item}
                        key={index}
                        value={item} 
                        onClick={e=>(setPaginaAtual(e.target.value))}>
                            {item}
                        </S.BU>
                    )
                }
            })}
            
            {maior > 10 &&
            <span 
            style={{color:"black", margin:"0 5px 5px 5px", color:"white", cursor:"pointer"}} 
            onClick={e=>setmaisPages(maisPages+4)}>
                mais
            </span>
            }
        </S.Body>
    )
}