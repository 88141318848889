import React, {useEffect, useState, useContext} from 'react';
import * as S from './styles';
import {Context} from '../../services/contexts/StateContext';

export default function ModalCobranca(props) { 
    const {state, dispatch} = useContext(Context);

    const {cobranca} = state.cobrancas;
    
    const {parametroInit, playmodal, listaPesquisa} = state.parametros;
    const {
        editModal, 
        disabled} = parametroInit; 

    //--------------------------------------------
    function FecharModal(){
        dispatch({
            type: 'SET_PLAYMODAL',
            payload: {...playmodal, playModalCobranca: false}
            });

            if(editModal){
                dispatch({
                    type: 'SET_parametroInit',
                    payload: {
                        ...parametroInit, 
                        editModal: false, 
                    }
                });
            };
            
    };
    //---------------------------------------------------
    const setCobranca = (key, value) => {
        dispatch({
            type: 'set_cobranca',
            payload: {...cobranca, [key]: value}
            });
    };

    //----------Pesquisar e Selecionar Veiculo em Novo Evento---------------------
    const[pesqInput, setpesqInput]=useState('');

    useEffect(() => {
        if(playmodal.playModalCobranca && pesqInput){
            props.pesqVeiculoPlaca()
        }
        if(!pesqInput){
            dispatch({
                type: 'set_ListaPesquisa',
                payload: {...listaPesquisa, listaVeiculo:[]}
            });
        }    
    }, [pesqInput])
    // -----------------------------------------------
    useEffect(() => {
        const soma = cobranca.valorParcela * cobranca.N_carnes;
        soma? setCobranca('total', soma) : setCobranca('total', 0);
    }, [cobranca.valorParcela, cobranca.N_carnes])

//###################################################################################
  return (
    <S.body>
        <S.div3>
            <div className='titulo'>
                <div className={'tituloUser'}>
                    <span>VALORES</span>
                </div>

                <div 
                className='fechar'
                onClick={()=>FecharModal()}>X
                </div>
            </div>

            <S.box1>
                <div>
                    <span>Valor Parcela:</span>
                    <input
                    className='maiusculo'
                    value={cobranca.valorParcela < 1 ? "" : cobranca.valorParcela}
                    type='number' 
                    placeholder='valor parcela' 
                    onChange={e=>setCobranca('valorParcela', parseFloat(!e.target.value? 0 : e.target.value < 0? 0 : e.target.value))}/>
                </div>
                <div>
                    <span>Quantas Parcela:</span>
                    <input
                    className='maiusculo'
                    value={cobranca.N_carnes < 1 ? "" : cobranca.N_carnes}
                    type='number' 
                    placeholder='maximo 12' 
                    onChange={e=>setCobranca('N_carnes', parseFloat(!e.target.value? 0 : e.target.value < 0? 0 : e.target.value > 12? 12 : e.target.value))}/>
                </div>
            </S.box1>
            <S.box1>
                <div>
                    <span>Data Vencimento inicial:</span>
                    <input
                    className='maiusculo'
                    value={cobranca.vencimento}
                    type="date" 
                    placeholder='Data Nascimentp' 
                    onChange={e=>setCobranca('vencimento', e.target.value)}/>
                </div>
                <div>
                    <span>Total R$:</span>
                    <span>{(cobranca.total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                </div>
            </S.box1>
        </S.div3>
    </S.body>
  );
};
