import React, { useState, useEffect, useContext } from 'react';
import api from '../api';
import {Context} from '../contexts/StateContext';


//---------------Listar Todos Colaborador por Franquia--------------
export const ColabFranquia=()=>{
    
    const {state} = useContext(Context);
    const {cliente} = state.cliente;
    const {id_Franquia} = cliente;

    const [lista, setLista]=useState([]);

    async function buscar(){
        if(id_Franquia){
            await api.get(`/colaborador/colabfranquia/${id_Franquia}`)
            .then(res => {
                const busca = res.data;
                const result = busca.map(s=> ({value:s._id, label:s.nome}));
                setLista(result);
            });
        }
    };
  
//-----------------------------------
useEffect(() => {
    buscar();
}, [id_Franquia])

return lista
};


