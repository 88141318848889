import React, { useState, useEffect} from 'react';
import Cookies from 'js-cookie';
import {Redirect} from 'react-router-dom';



//---------------------------------------------------------
export const estaLogado = () => {
    let token = Cookies.get('token');
    return (token) ? true : false;
}
//---------------------------------------------------------

export const fazLogin = (token, lembrarPass=false)=>{
    if(lembrarPass){
    Cookies.set('token', token, {expires:1}); // caso clique em lembrar senha expira em 1 dia
    }else{
        Cookies.set('token', token); // caso não clique em lembrar, token expira apos fechar a pagina
    }
};
//------------SAIR------------------------------------------

export const deslogar = ()=>{
    Cookies.remove('token');
}

//----------------------------------------------------
export const redirecionar = ()=>{

    let redir = true

    return  (redir && <Redirect to='/login' />)

    
}