import React, { useContext, useEffect, useState } from 'react';
import * as S from './styles';
import { Context } from '../../services/contexts/StateContext';
import lupa from '../../midia/lupa.svg';
import { ListaItemBD } from '../ListarPesquisas/listaItemBD';
// /-------------------------------------------------
export function PesquisaSub(props) {
    const { state, dispatch } = useContext(Context);
    const { listaPesquisa } = state.parametros;

    // --------------
    function SetListaPesquisa(key, value) {
        dispatch({
            type: 'set_ListaPesquisa',
            payload: { ...listaPesquisa, [key]: value }
        });
    };

    // --------------
    useEffect(() => {
        dispatch({
            type: 'set_ListaPesquisa',
            payload: { ...listaPesquisa, alertaNovo: false }
        });
        if (!props.nome) {
            dispatch({
                type: 'set_ListaPesquisa',
                payload: {
                    ...listaPesquisa,
                    listaVeiculo: [],
                    listaCliente: [],
                    listaFornecedor: [],
                }
            });
        }
    }, [props.nome]);

    // --------------
    function PesquisarItem() {
        props.Pesquisar(props.nome);
        // if(!props.PesqDentroModal){
        //     FecharModal();
        // };

    };
    // --------------


    //###################################################################################################
    return (
        <S.divInfor>
            <S.ddd>
                <input
                    type='search'
                    style={{ textTransform: "capitalize" }}
                    placeholder={props.placeholder}
                    value={props.nome}
                    onChange={e => SetListaPesquisa(props.nomeItem, e.target.value)}
                />

                {props.Lupa &&
                    <S.Pesq>
                        <button
                            type='button'
                            onClick={PesquisarItem}>
                            <img src={lupa} />
                        </button>
                    </S.Pesq>
                }
            </S.ddd>

            <S.divListaBD>
                <ListaItemBD
                    listaItem={listaPesquisa.listaFornecedor}
                    NovoCadastro={null}
                    referenciaRef="RefFornecedor"
                    pesqForn = {true}
                />
            </S.divListaBD>
        </S.divInfor>

    )
}