import styled from 'styled-components';

export const App = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #282c34;
  align-items: center ;
`

export const body = styled.div`
display: flex;
width: 90% ;
font-size: 12px;
margin-top:50px ;
justify-content: center ;

button{
  border:none;
  color:white ;
}

`
export const pesquisa = styled.div` 
display: flex;
width: 90%;
flex-direction: column ;
color: white;
align-items: center ;

`  
export const containerPesq = styled.div` 
display: flex;
width: 400px ;
flex-direction: column ;

`  
export const containerLista = styled.div` 
display: flex;
width: 100% ;
justify-content: center ;


` 
// --------------LISTA-----------------------------------------------

export const ContainerLista = styled.div`
display: flex;
flex-direction: column ;
width:100% ;
height:80% ;

`

export const div3 = styled.div` 
display:flex;
flex:1;
flex-direction: column;
align-items: center;
margin:5px;

.fechar{
  display:flex;
    color:white;
    border-radius:3px;
    width:20px;
    height:20px;
    font-size:13px;
    justify-content:center;
    align-items:center;
    padding-bottom:1px;
    position:relative;
    left:140px;
    cursor: pointer;
    font-weight:bold;

    &:hover{
    background-color: #f0433d;
  }

}

`

export const boxBut = styled.div`

button{
  background:none;
  cursor:pointer;
  outline:0;
  

  &:hover{
    background: none;
    opacity: 0.5;
}
}
`
export const box = styled.div`
display:flex;
width:100%;
flex-direction:row;
margin:5px;
border-bottom:1px solid #3881ff;
font-size:14px;

select{
  display:flex;
  border-radius:3px;
  color: white;
  background-color:#3881ff;
  border:none;
  outline:0;
  margin:5px;
}
span{
  color: #00D594;
}
.spa{
  color:white;
}

input{
border:none;
background:none;
font-size:14px;
color: white;
outline:0;
}
`
export const div4 = styled.div` 
display:flex;
width:50%;
justify-content:space-between;
`
export const div5 = styled.div` 
display:flex;
padding:3px;
border-radius:5px;
align-items:center;
`
export const Exc = styled.div`
display:flex;
width:70px;
background-color:#f0433d;
padding:3px;
border-radius:5px;
justify-content:center;
`
export const Salvar = styled.div`
display:flex;
height:25px;
margin:5px;
justify-content:center;

button{
  background-color:#00D594;
  width:80px;
  font-size: 12px;
  border:none;
  border-radius:5px;
  color:white;

&:hover{
  background-color:#5656F7;
}
}

`
//-------------------Resultado-------------------------------
export const bodyresult = styled.div`
overflow: auto;

.abrirModal{
  display:flex;
  max-width: 90px ;
  min-width: 90px;
  height:17px;
  align-items: center ;
  justify-content: center ;
  background-color:#3881ff;
  border-radius:1px;
  padding-bottom: 2px;
  font-size: 12px ;
  
  cursor:pointer ;

}

`
