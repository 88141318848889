import styled from 'styled-components';

export const App = styled.div `
  display: flex;
  background-color: white;
  min-height: 100vh;
  flex-direction:column;
  align-items: center;
  
`

export const body = styled.div`
  display: flex;
  width: 98%;
  margin-top: 70px;
  
  
  
  `
  