import styled from 'styled-components';

export const App = styled.div`
  display: flex;
  background-color: white;
  align-items: center;
  flex-direction: column;
  
`

export const body = styled.div`
display: flex;
flex-direction:column;
width: 90%;
padding-top: 10px;
margin-top:70px;
align-items:center;
justify-content:center;


`

export const LoginArea = styled.div`
display: flex;
width:100%;
margin:10px;
align-items:center;
justify-content:center;


`


export const ValidarCode = styled.div`
display: flex;
flex-direction:column;
align-items:center;
justify-content:center;


`